import * as THREE from "three";
// import { Vector3 } from "three";

export {activedUuid, AllLines};
var activedUuid: Array<string> = [];
/**
   * AllLines:存储辅助线信息
   * AllLines[i][0]: 起点
   * AllLines[i][1]: 归一化方向
   * AllLines[i][2]: flag, 记录鼠标移到射线上，或是离开射线的状态
   * AllLines[i][3]: 参考线的uuid
   * AllLines[i][4]: 对应物体（mesh）的uuid
   */
var AllLines: any = [];

const MAX_ACTIVE_OBJ = 3;

export function init () {

}

export function activeUuid(uuid: string) {
  if (!activedUuid.includes(uuid)){
    activedUuid.push(uuid);
    if (activedUuid.length > MAX_ACTIVE_OBJ) {
      activedUuid.splice(0, 1);
    }
  }
}

export function isActivedUuid(uuid: string) {
  return activedUuid.includes(uuid);
}

export function deactivateAll() {
  activedUuid = [];
}

export function updataActivatedUuid(uuid: string) {
  if (activedUuid.includes(uuid)) {
    activedUuid.splice(activedUuid.indexOf(uuid), 1);
  }
}

// 删除物体时，对应的延长现也要删除，[i][3]存储对应物体的uuid，不是延长线的uuid
export function removeLinesByObjUuid (uuid: string) {
  console.log("before", AllLines);
  for(let i = AllLines.length - 1; i >= 0 ; i--) {
    if (AllLines[i][4] === uuid) {
      AllLines.splice(i, 1);
    }
  }
  console.log("after", AllLines);
}

export function replaceAllLines (newAllLines: any) {
  AllLines = newAllLines;
}

export function addLines (points: any, uuid: string, isClosed: boolean) {
  // add info to all lines
  let len = points.length;
  let n = points.length - 1;
  if (isClosed) {
    n = points.length;
  } 
  for (let i = 0; i < n; i++) {
    let ed = points[(i+1) % len];
    let st = points[i];
    let dir = new THREE.Vector2(ed.x - st.x, ed.y - st.y);
    dir = dir.normalize();
    AllLines.push([
      new THREE.Vector2(st.x, st.y),
      dir,
      0,
      0,
      uuid]
      );
  }
}

export function setFromJsonList (json_list: any[]) {
  let res = [];
  for (let i = 0; i < json_list.length; i++) {
    let item = json_list[i];
    item[0] = new THREE.Vector2(item[0].x, item[0].y);
    item[1] = new THREE.Vector2(item[1].x, item[1].y);
    res.push(item);
  }
  replaceAllLines(res);
}