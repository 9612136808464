import * as THREE from "three";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

export function init (
  divWidth: number,
  divHeight: number,
  document: Document,
) {
  var renderer = new THREE.WebGLRenderer({
    // canvas: this.canvasRef.current!,
    antialias: true,
  });
  renderer.setClearColor(0x000000, 1);
  renderer.setSize(divWidth, divHeight);
  document.getElementById("canvas-container")!.appendChild(renderer.domElement);

  var labelRenderer = new CSS2DRenderer();
  labelRenderer.setSize(divWidth, divHeight);
  labelRenderer.domElement.style.position = "absolute";
  labelRenderer.domElement.style.top = "0px";
  document.getElementById("canvas-container")!.appendChild(labelRenderer.domElement);

  return {renderer: renderer, labelRenderer: labelRenderer};
}