import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Vector2, Vector3 } from "three";
import GEO from "./GeometryCreator";
import { getCenterPoint, v3Clone } from "./util"
import { ObjType } from "../enums/ObjType";
import { ObjName } from "../enums/ObjName";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { ModelItem } from "../components/overlay/panels/ModelAddPanel";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { PipeType } from "../enums/PipeProp";
import { isNumber } from "util";
import { addLines } from "./GuideLineHelper";
import SpriteText from 'three-spritetext';
import { resolve } from "path";


const electricTexture : any = new THREE.TextureLoader().load('images/texture/electric.png');
const electricRTexture : any = new THREE.TextureLoader().load('images/texture/electricR.png');
const gasTexture : any = new THREE.TextureLoader().load('images/texture/gas.png');
const gasRTexture : any = new THREE.TextureLoader().load('images/texture/gasR.png');
const waterTexture : any = new THREE.TextureLoader().load('images/texture/water.png');
const waterRTexture : any = new THREE.TextureLoader().load('images/texture/waterR.png');

export default{
  /**
   * 生成一块墙体的mesh
   * @param points 
   * @param width 
   * @param height 
   * @param color 
   * @param rideHeight 
   * @param transparency 
   * @returns 
   */
  createWallMesh(
    points: Vector3[],
    width: number,
    height: number,
    color: string,
    uuid: null | undefined | string = null,
    rotation: any[] | null = null,
    rideHeight: number = 0,
    transparency: number = 1,
  ){
    let geometry = GEO.createWallGeometry(
      points,
      width,
      height,
      rideHeight,
    );
    // 纠正位置偏移，使TranslateControls正常工作
    let midPnt = getCenterPoint(geometry);
    geometry.translate(-midPnt.x, -midPnt.y, -midPnt.z);
    
    if(transparency == 1){
      var material = new THREE.MeshLambertMaterial({
        color: color,
        // depthWrite: false,
      });
    }else{
      var material = new THREE.MeshLambertMaterial({
        color: color,
        transparent: true,
        opacity: 1 - transparency,
        depthWrite: false,
      });
    }

    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(midPnt.x, midPnt.y, midPnt.z + rideHeight);
    if (rotation !== null)
      mesh.rotation.fromArray(rotation);

    // 将自定义属性打包到mesh中
    mesh.userData.points = points;
    mesh.userData.width = width;
    mesh.userData.height = height;
    mesh.userData.color = color;
    mesh.userData.rideHeight = rideHeight;
    mesh.userData.type = ObjType.Wall;
    mesh.userData.transparency = transparency;
    mesh.userData.rotation = mesh.rotation.toArray();
    if (uuid !== undefined && uuid !== null) {
      mesh.userData.uuid = uuid;
    } else {
      mesh.userData.uuid = mesh.uuid;
    }
    
    return mesh;
  },


  /**
   * 生成多边形房间mesh
   * @param points 
   * @param height 
   * @param color 
   * @param uuid 
   * @param rotation 
   * @param rideHeight 
   * @param transparency 
   * @returns 
   */
  createPolRoomMesh(
    points: Vector3[], // 顺时针或逆时针，不需要封闭 
    height: number,
    color: string,
    uuid: null | undefined | string = null,
    rotation: any[] | null = null,
    rideHeight: number = 0,
    transparency: number = 1,
    isHole: boolean = false
  ){
    let geometry = GEO.createExtrudeGeometry(
      points,
      height,
      rideHeight
    );
    // 纠正位置偏移，使TranslateControls正常工作
    let midPnt = getCenterPoint(geometry);
    geometry.translate(-midPnt.x, -midPnt.y, -midPnt.z);

    if(transparency == 1){
      var material = new THREE.MeshLambertMaterial({
        color: color,
      });
    }else{
      var material = new THREE.MeshLambertMaterial({
        color: color,
        transparent: true,
        opacity: 1 - transparency,
        depthTest: true,
        side: THREE.FrontSide,
        depthWrite: false,
      });
    }

    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(midPnt.x, midPnt.y, midPnt.z + rideHeight);
    if (rotation !== null)
      mesh.rotation.fromArray(rotation);
    
    // 将自定义属性打包到mesh中
    mesh.userData.points = points;
    mesh.userData.height = height;
    mesh.userData.color = color;
    mesh.userData.rideHeight = rideHeight;
    if(isHole){
      mesh.userData.type = ObjType.Hole;
    }else{
      mesh.userData.type = ObjType.Room;
    }
    
    mesh.userData.transparency = transparency;
    mesh.userData.rotation = mesh.rotation.toArray();
    if (uuid !== undefined && uuid !== null) {
      mesh.userData.uuid = uuid;
    } else {
      mesh.userData.uuid = mesh.uuid;
    }

    return mesh;
  },


  createRectRoomMesh(
    p1: Vector3, 
    p2: Vector3, 
    height: number,
    color: string,
    uuid: null | undefined | string = null,
    rotation: [] | null = null,
    rideHeight: number = 0,
    transparency: number = 1,
    isHole: boolean = false
  ){
    var p3 = new Vector3(p1.x, p2.y);
    var p4 = new Vector3(p2.x, p1.y);
    var points: Vector3[] = [p1, p3, p2, p4];

    return this.createPolRoomMesh(
      points,
      height,
      color,
      uuid,
      rotation,
      rideHeight,
      transparency,
      isHole
    );
  },


  createEditPoints(vertices: Vector3[], rotation: Array<any>=[0,0,0,'XYZ'], color=0x880000, name=ObjName.EditShapePnt, size=8.0, opacity=0.6, isTube : boolean = false) {
    let geometry = new THREE.BufferGeometry().setFromPoints(vertices);
    geometry.morphAttributes = {}; // threejs的bug，否则无法new Points()
    let midPnt = getCenterPoint(geometry);
    geometry.translate(-midPnt.x, -midPnt.y, -midPnt.z);
    let material = new THREE.PointsMaterial({ color: color, size: size });
    material.transparent = true;
    material.opacity = opacity;
    let editPoints = new THREE.Points(geometry, material);
    if(!isTube){
      editPoints.position.set(midPnt.x, midPnt.y, midPnt.z + 10);
    }else{
      editPoints.position.set(midPnt.x, midPnt.y, midPnt.z);
    }
    
    editPoints.rotation.fromArray(rotation);
    
    editPoints.name = name;
    // 让编辑点不被遮挡
    editPoints.renderOrder=99;
    editPoints.material.depthTest=false;
    return editPoints;
  },

  createLineMesh(
    points: Vector3[],
    color: number = 0x6078c8,
    width = 0.005,
  ){
    // let color_s = parseInt(color);
    // console.log(color, color_s);
    var material = new LineMaterial({
      color: color,
      linewidth: width,
    });
    var geometry = GEO.createLineGeometry(points);
    return new Line2(geometry, material);
  },

  createWholeTubeMesh(
    points: Vector3[],
    radius: number,
    color: string,
    pipeType: PipeType,
    textureDir: number,
    translation: Vector3
  ){
    var meshGroup : any[] = [];
    if(points.length == 1) return meshGroup;

    var tempDir = new THREE.Vector3();
    var cnt = 0;
    for(let i = 0; i < points.length - 1; i++){

      // tube mesh
      var stDir = new THREE.Vector3();
      var edDir = new THREE.Vector3();
      if(i == 0){
        stDir.set(0, 0, 0);
      }else{
        stDir.set(tempDir.x, tempDir.y, tempDir.z);
        stDir.normalize();
      }

    
      edDir.set(
        points[i+1].x - points[i].x,
        points[i+1].y - points[i].y,
        points[i+1].z - points[i].z,
      )
      edDir.normalize();
      tempDir.set(edDir.x, edDir.y, edDir.z);

      // fillet mesh
      if(i != 0){
        var filletmesh = this.createOneFilletMesh(
          stDir,
          edDir,
          points[i],
          color,
          radius,
          pipeType
        )
        filletmesh.geometry.translate(
          -translation.x,
          -translation.y,
          -translation.z
        );

        meshGroup.push(filletmesh);
        cnt++;
      }
 
      var tubemesh;
      if(i == points.length - 2){
        tubemesh = this.createOneTubeMeshWithoutSpare(
          stDir,
          edDir,
          points[i],
          points[i+1],
          color,
          radius,
          pipeType,
          textureDir
        );
      }else{
        tubemesh = this.createOneTubeMesh(
          stDir,
          edDir,
          points[i],
          points[i+1],
          color,
          radius,
          pipeType,
          textureDir
        );
      }
      tubemesh.geometry.translate(
        -translation.x,
        -translation.y,
        -translation.z
      );
      meshGroup.push(tubemesh);
      cnt++;

    
             
    }
    // console.log("meshGroup 233", meshGroup);
    // console.log("cnt233", cnt);
    return meshGroup;
  },

  createWholeTubeMeshWithoutSpare(
    points: Vector3[],
    radius: number,
    color: string,
    pipeType: PipeType,
    textureDir: number,
    translation: Vector3
  ){
    var meshGroup : any[] = [];
    if(points.length == 1) return meshGroup;

    var tempDir = new THREE.Vector3();
    var cnt = 0;
    for(let i = 0; i < points.length - 1; i++){

      // tube mesh
      var stDir = new THREE.Vector3();
      var edDir = new THREE.Vector3();
      if(i == 0){
        stDir.set(0, 0, 0);
      }else{
        stDir.set(tempDir.x, tempDir.y, tempDir.z);
        stDir.normalize();
      }

    
      edDir.set(
        points[i+1].x - points[i].x,
        points[i+1].y - points[i].y,
        points[i+1].z - points[i].z,
      )
      edDir.normalize();
      tempDir.set(edDir.x, edDir.y, edDir.z);

      // fillet mesh
      if(i != 0){
        var filletmesh = this.createOneFilletMesh(
          stDir,
          edDir,
          points[i],
          color,
          radius,
          pipeType
        )
        filletmesh.geometry.translate(
          -translation.x,
          -translation.y,
          -translation.z
        );

        meshGroup.push(filletmesh);
        cnt++;
      }
 
      var tubemesh;
      if(i == points.length - 2){
        tubemesh = this.createOneTubeMeshWithoutSpare(
          stDir,
          edDir,
          points[i],
          points[i+1],
          color,
          radius,
          pipeType,
          textureDir
        );
      }else{
        tubemesh = this.createOneTubeMesh(
          stDir,
          edDir,
          points[i],
          points[i+1],
          color,
          radius,
          pipeType,
          textureDir
        );
      }
      
      tubemesh.geometry.translate(
        -translation.x,
        -translation.y,
        -translation.z
      );
      meshGroup.push(tubemesh);
      cnt++;

    
             
    }
    // console.log("meshGroup 233", meshGroup);
    // console.log("cnt233", cnt);
    return meshGroup;
  },

  createOneTubeMesh(
    stDir: Vector3,
    edDir: Vector3,
    stPoint: Vector3,
    edPoint: Vector3,
    color: string,
    radius: number,
    pipeType: PipeType,
    textureDir: number
  ){
    // console.log(stDir,edDir,stPoint,edPoint,radius);
    let geometry = GEO.createTubeGeometry(
      stDir,
      edDir,
      stPoint,
      edPoint,
      radius
    );

    var temp_color = color;
    if(pipeType === PipeType.others){
      // 默认为其他，使用颜色
    }else{
      // 否则颜色设为白色
      color = "#FFFFFF";        
    }
    var Material = new THREE.MeshPhongMaterial({
      color: color,
      shininess: 80,
      side: THREE.DoubleSide,
      transparent: false
    });
    let x = Math.max(
      Math.max(
        Math.abs(stPoint.x - edPoint.x),
        Math.abs(stPoint.y - edPoint.y)
      ),
      Math.abs(stPoint.z - edPoint.z)
    )
    let repeatNum = Math.ceil(x / 30);
    let repeatNumVerticle = Math.ceil(radius / 10);
    if(textureDir === 0){
      if(pipeType === PipeType.electric){
        let texture = electricTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.gas){
        let texture = gasTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.water){
        let texture = waterTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }
    }else{
      if(pipeType === PipeType.electric){
        let texture = electricRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.gas){
        let texture = gasRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.water){
        let texture = waterRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }
    }
    
    
    let mesh = new THREE.Mesh(geometry, Material);

    mesh.name = "";
    mesh.userData.uuid = mesh.uuid;
    mesh.userData.stDir = stDir;
    mesh.userData.edDir = edDir;
    mesh.userData.stPoint = stPoint;
    mesh.userData.edPoint = edPoint;
    mesh.userData.color = temp_color;
    mesh.userData.type = ObjType.Tube;
    mesh.userData.radius = radius;
    mesh.userData.pipeType = pipeType;
    mesh.userData.textureDir = textureDir;
    return mesh;
  },

  createOneTubeMeshWithoutSpare(
    stDir: Vector3,
    edDir: Vector3,
    stPoint: Vector3,
    edPoint: Vector3,
    color: string,
    radius: number,
    pipeType: PipeType,
    textureDir: number
  ){
    // console.log(stDir,edDir,stPoint,edPoint,radius);
    let geometry = GEO.createTubeGeometryWithoutSpare(
      stDir,
      edDir,
      stPoint,
      edPoint,
      radius
    );

    var temp_color = color;
    if(pipeType === PipeType.others){
      // 默认为其他，使用颜色
    }else{
      // 否则颜色设为白色
      color = "#FFFFFF";        
    }
    var Material = new THREE.MeshPhongMaterial({
      color: color,
      shininess: 80,
      side: THREE.DoubleSide,
      transparent: false
    });
    let x = Math.max(
      Math.max(
        Math.abs(stPoint.x - edPoint.x),
        Math.abs(stPoint.y - edPoint.y)
      ),
      Math.abs(stPoint.z - edPoint.z)
    )
    let repeatNum = Math.ceil(x / 30);
    let repeatNumVerticle = Math.ceil(radius / 10);
    if(textureDir === 0){
      if(pipeType === PipeType.electric){
        let texture = electricTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.gas){
        let texture = gasTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.water){
        let texture = waterTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }
    }else{
      if(pipeType === PipeType.electric){
        let texture = electricRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.gas){
        let texture = gasRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }else if(pipeType === PipeType.water){
        let texture = waterRTexture.clone();
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.offset.set( 0, 0 );
        texture.repeat.set( repeatNum, repeatNumVerticle );
        Material.map = texture;
      }
    }
    
    
    let mesh = new THREE.Mesh(geometry, Material);

    mesh.name = "";
    mesh.userData.uuid = mesh.uuid;
    mesh.userData.stDir = stDir;
    mesh.userData.edDir = edDir;
    mesh.userData.stPoint = stPoint;
    mesh.userData.edPoint = edPoint;
    mesh.userData.color = temp_color;
    mesh.userData.type = ObjType.Tube;
    mesh.userData.radius = radius;
    mesh.userData.pipeType = pipeType;
    mesh.userData.textureDir = textureDir;
    return mesh;
  },

  createOneFilletMesh(
    stDir: Vector3,
    edDir: Vector3,
    point: Vector3,
    color: string,
    radius: number,
    pipeType: PipeType
  ) {
    let geometry = GEO.createFilletGeometry(
      stDir,
      edDir,
      point,
      radius
    );

    var temp_color = color;
    if(pipeType === PipeType.electric){
      color = "#FF0000";
    }else if(pipeType === PipeType.gas){
      color = "#FF5F00";
    }else if(pipeType === PipeType.water){
      color = "#0489F0";
    }

    var material = new THREE.MeshPhongMaterial({
      color: color,
      shininess: 80,
      side: THREE.DoubleSide,
      transparent: true
    });
    let mesh = new THREE.Mesh(geometry, material);

    mesh.name = "";
    mesh.userData.stDir = stDir;
    mesh.userData.edDir = edDir;
    mesh.userData.point = point;
    mesh.userData.color = temp_color;
    mesh.userData.radius = radius;
    mesh.userData.pipeType = pipeType;
    mesh.userData.type = ObjType.Fillet;
    return mesh;
  },

  signal(x: number){
    if(x < 0) return 1;
    else return 0;
  },

  vec2rad(op : Vector2){
    var x = op.x;
    var y = op.y;
    var ans;
    if(x >= 0 && y >= 0){
      ans =  Math.atan(y / x);
    }else if(x < 0 && y >= 0){
      ans = Math.PI / 2 + Math.atan(Math.abs(x / y));
    }else if(x < 0 && y < 0){
      ans = Math.PI + Math.atan(y / x);
    }else{
      ans = 2 * Math.PI + Math.atan(y / x);
    }
    console.log("vec2rad", x," ", y, " ",ans);
    return ans;
  },

  createGreenCircleMesh(
    p1: Vector3,
    p2: Vector3,
    p3: Vector3,
    room_center: Vector3
  ):[THREE.Line, Vector2, number, Vector2[]]{
    

    // get outer center of 3points
    var center = this.findCircumcenter(p1.x, p1.y,
      p2.x,p2.y,
      p3.x,p3.y);
    console.log("center:", center);
    const radius = new Vector2(center[0], center[1]).distanceTo(
      new Vector2(p1.x, p1.y)
    );
    var op1 = new Vector2(p1.x, p1.y).sub(new Vector2(center[0], center[1]));
    var op2 = new Vector2(p2.x, p2.y).sub(new Vector2(center[0], center[1]));
    console.log("op1 op2", op1, op2);
    var circleCurve;
    var frag: number = 0;
    var each_x_degree: number = 10;
    if(op1.cross(op2) > 0){
      var theta = Math.acos(op1.clone().normalize().dot(op2.clone().normalize()));
      var r = this.vec2rad(op1);
      var frag = theta / (2 * Math.PI) * 360 / each_x_degree;
      circleCurve = new THREE.EllipseCurve(
        center[0], center[1],   // x, y center
        radius, radius,   // x, y radius
        0, theta,   // start and end angle
        false,  // clockwise
        r    // rotation
      );
    var material = new THREE.LineBasicMaterial( { color: 0x62A438} );
    }else{
      var theta = Math.acos(op1.normalize().dot(op2.normalize()));
      theta = 2 * Math.PI - theta;
      var r = this.vec2rad(op1);
      console.log("r:", r / Math.PI);
      var frag = theta  / (2 * Math.PI) * 360 / each_x_degree;
      circleCurve = new THREE.EllipseCurve(
        center[0], center[1],   // x, y center
        radius, radius,   // x, y radius
        0, theta,   // start and end angle
        false,  // clockwise
        r     // rotation
      );
      // Create a material for the line (e.g. white color)
    var material = new THREE.LineBasicMaterial( { color: 0xFF0000} );
    }
    
    
    // Create a path for the circle curve
    var circlePath = new THREE.Path(circleCurve?.getPoints(frag));
    
    // Create a geometry for the circle line by extruding the circle path
    var geometry = new THREE.BufferGeometry().setFromPoints(circlePath.getPoints(frag));
    
    
    
    // Create a line object from the geometry and material
    var circleLine = new THREE.Line(geometry, material);
    circleLine.name = ObjName.LineToCircleSelectLine;
    return [circleLine, new Vector2(center[0],center[1]), radius, circlePath.getPoints(frag)];
  },

  findCircumcenter(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number): [number, number] {
    // // Step 2: Calculate slopes of lines passing through (x1,y1)-(x2,y2) and (x2,y2)-(x3,y3)
    // let m1, m2;
    // if (x2 - x1 === 0 || y1 - y2 === 0) {
    //   [x1, y1, x3, y3] = [x3, y3, x1, y1];  
    // }
    // m1 = (y2 - y1) / (x2 - x1);
    // m2 = (y3 - y2) / (x3 - x2);
  
    // // Step 3: Calculate midpoints of (x1,y1)-(x2,y2) and (x2,y2)-(x3,y3)
    // const midpoint1: [number, number] = [(x1 + x2) / 2, (y1 + y2) / 2];
    // const midpoint2: [number, number] = [(x2 + x3) / 2, (y2 + y3) / 2];
  
    // // Step 4: Calculate slopes of perpendicular bisectors of (x1,y1)-(x2,y2) and (x2,y2)-(x3,y3)
    // const perp_slope1 = -1 / m1;
    // const perp_slope2 = -1 / m2;
    // // const delta = -(m1 * m2) / (m2 -m1) ;
    // // Step 5: Calculate y-intercepts of perpendicular bisectors
    // const y_int1 = midpoint1[1] - perp_slope1 * midpoint1[0];
    // const y_int2 = midpoint2[1] - perp_slope2 * midpoint2[0];
  
    // // Step 6: Calculate x-coordinate of circumcenter
    // const x_center = (y_int2 - y_int1) / (perp_slope1 - perp_slope2);
  
    // // Step 7: Calculate y-coordinate of circumcenter
    // const y_center = perp_slope1 * x_center + y_int1;
  
    // // Step 8: Return the coordinates of the circumcenter as a tuple
    // return [x_center, y_center];

    var x12 = (x1 - x2);
    var x13 = (x1 - x3);
 
    var y12 =( y1 - y2);
    var y13 = (y1 - y3);
 
    var y31 = (y3 - y1);
    var y21 = (y2 - y1);
 
    var x31 = (x3 - x1);
    var x21 = (x2 - x1);
 
    //x1^2 - x3^2
    var sx13 = Math.pow(x1, 2) - Math.pow(x3, 2);
 
    // y1^2 - y3^2
    var sy13 = Math.pow(y1, 2) - Math.pow(y3, 2);
 
    var sx21 = Math.pow(x2, 2) - Math.pow(x1, 2);
    var sy21 = Math.pow(y2, 2) - Math.pow(y1, 2);
 
    var f = ((sx13) * (x12)
            + (sy13) * (x12)
            + (sx21) * (x13)
            + (sy21) * (x13))
            / (2 * ((y31) * (x12) - (y21) * (x13)));
    var g = ((sx13) * (y12)
            + (sy13) * (y12)
            + (sx21) * (y13)
            + (sy21) * (y13))
            / (2 * ((x31) * (y12) - (x21) * (y13)));
 
    var c = -(Math.pow(x1, 2)) -
    Math.pow(y1, 2) - 2 * g * x1 - 2 * f * y1;
 
    // eqn of circle be
    // x^2 + y^2 + 2*g*x + 2*f*y + c = 0
    // where centre is (h = -g, k = -f) and radius r
    // as r^2 = h^2 + k^2 - c
    var h = -g;
    var k = -f;
    
    return [h, k];
  },

  createGreenLineMesh(
    p1: Vector3,
    p2: Vector3
  ){
    var mesh = this.createLineMesh(
      [p1, p2],
      0x62A438,
      0.005
    );
    mesh.name = ObjName.LineToCircleSelectLine;
    return  mesh;
  },

  createTextLabel(
    content:string, 
    position:Vector3, 
    onClick: any, 
    uuid: null | undefined | string = null,
    fontSize:string='24px',
  ){
    const textLabel = new SpriteText(content, 14, 'black');
    textLabel.strokeWidth = 2;
    textLabel.strokeColor = "white";
    textLabel.material.side = THREE.DoubleSide;

    textLabel.position.set(position.x, position.y, position.z);
    textLabel.userData.type = ObjType.Text;
    textLabel.userData.rideHeight = position.z;
    textLabel.userData.content = content;
    textLabel.userData.pos = position;
    if (uuid !== undefined && uuid !== null) {
      textLabel.userData.uuid = uuid;
    } else {
      textLabel.userData.uuid = textLabel.uuid;
    }
    return textLabel;
  },

  createPicLabel(
    imgSrc:string, 
    position:Vector3, 
    name:string, 
    onClick:any, 
    uuid: null | undefined | string = null,
    width:number = 20, 
    height:number = 20,
  ) {
    // 创建一个精灵材质
    const spriteTexture = new THREE.TextureLoader().load('/images/icon_tmp/test.svg');
    const spriteMaterial = new THREE.SpriteMaterial({ map: spriteTexture });

    // 创建一个精灵对象
    const picLabel = new THREE.Sprite(spriteMaterial);

    
    // const picDiv = document.createElement('div');
    // const img = document.createElement('img');
    // img.src = imgSrc;
    // img.style.width = width + "px";
    // img.style.height = height + "px";
    // img.className = 'label';
    // img.style.borderStyle = "none";
    // img.style.borderColor = "red";
    // img.style.borderWidth = "2px";

    // const picLabel = new CSS2DObject(img);
    // picLabel.element.addEventListener("mousedown", (event: any) => {
      // if(event.ctrlKey === true) return;
      // console.log("pic click");
      // if (event.button === 0)
      // onClick(picLabel);
    // }, false);

    picLabel.position.set(position.x, position.y, position.z);
    picLabel.scale.set(width, height, 1);

    picLabel.userData.type = ObjType.Picture;
    // picLabel.userData.rideHeight = position.z;
    picLabel.userData.width = width;
    picLabel.userData.height = height;
    picLabel.userData.name = name;
    picLabel.userData.imgSrc = imgSrc;
    picLabel.userData.pos = position;
    if (uuid !== undefined && uuid !== null) {
      picLabel.userData.uuid = uuid;
    } else {
      picLabel.userData.uuid = picLabel.uuid;
    }

    console.log(picLabel);

    return picLabel;
  },

  loadPicLabel(
    scene: THREE.Scene,
    imgSrc:string, 
    position:Vector3, 
    name:string, 
    onClick:any, 
    uuid: null | undefined | string = null,
    width:number = 30, 
    height:number = 30,
  ) {
    // const picDiv = document.createElement('div');
    const img = document.createElement('img');
    img.src = imgSrc;
    img.style.width = width + "px";
    img.style.height = height + "px";
    img.className = 'label';
    img.style.borderStyle = "none";
    img.style.borderColor = "red";
    img.style.borderWidth = "2px";

    const picLabel = new CSS2DObject(img);
    picLabel.element.addEventListener("mousedown", (event: any) => {
      if(event.ctrlKey === true) return;
      console.log("pic click");
      if (event.button === 0)
      onClick(picLabel);
    }, false);
    picLabel.position.set(position.x, position.y, position.z);

    picLabel.userData.type = ObjType.Picture;
    // picLabel.userData.rideHeight = position.z;
    picLabel.userData.width = width;
    picLabel.userData.height = height;
    picLabel.userData.name = name;
    picLabel.userData.imgSrc = imgSrc;
    picLabel.userData.pos = position;
    if (uuid !== undefined && uuid !== null) {
      picLabel.userData.uuid = uuid;
    } else {
      picLabel.userData.uuid = picLabel.uuid;
    }

    return picLabel;
  },

  // 会直接把模型加载到 scene 中
  loadModel(
    data: ModelItem, 
    pos: Vector3, 
    scene: any,
    uuid: null | undefined | string = null,
    rotation: [] | null = null,
    scale: any = null,
  ) {
    var mtlLoader = new MTLLoader();
    // var mesh: THREE.Group;
    mtlLoader.load(data.mtlUrl, (mtl) => {
      mtl.baseUrl = "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/";
      mtl.preload();
      
      
      console.log("mtl",mtl);
      var objLoader = new OBJLoader();
      objLoader.setMaterials(mtl);
      objLoader.load(data.objUrl, (object) => {
        if (scale !== null)
          object.scale.set(scale.x, scale.y, scale.z);
        else
          object.scale.set(1, 1, 1);
        object.position.set(pos.x, pos.y, pos.z);
        object.rotation.x = Math.PI / 2;
        if (rotation !== null)
          object.rotation.fromArray(rotation);

        object.userData.type = ObjType.Model;
        object.userData.data = data;
        object.userData.position = pos;
        var uuid2 = uuid ? uuid : object.uuid;
        object.userData.rotation = object.rotation.toArray();
        object.userData.scale = object.scale;
        for (var o of object.children) {
          o.userData.type = ObjType.Model;
          o.userData.data = data;
          o.userData.position = pos;
          o.userData.uuid = uuid2;
          o.userData.scale = object.scale;
        }
        // console.log("obj", object);
        scene.add(object);
        // mesh = object;
      });
      console.log("mtl",mtl);
    });
    // console.log("mesh", mesh!);
    // this.scene.add(mesh!);
    // return mesh!;
  },

createPlane(basemap: string, floor: THREE.Group, pnts=null, origin_pnts=null) {
    // 获取图片尺寸
    let img = new Image();
    img.src = basemap;

    // 等图片加载完再装入贴图，否则尺寸会为0
    const doCreate = () => {
      var w = img.width;
      var h = img.height;
      // 纹理贴图映射到一个矩形平面上
      // PlaneGeometry似乎有最大的尺寸限制
      console.log(w, h);

      while (w > 800) {
        w *= 0.9;
        h *= 0.9
      }
      console.log(w, h);

      // let geometry = new THREE.PlaneGeometry(w, h); //矩形平面

      const shape = new THREE.Shape();
      shape.moveTo(-w/2, h/2);
      shape.lineTo(-w/2, -h/2);
      shape.lineTo(w/2, -h/2);
      shape.lineTo(w/2, h/2);
      shape.lineTo(-w/2, h/2);
      let geometry = new THREE.ShapeGeometry(shape);
      this.assignUVs(geometry);


      // console.log(geometry.parameters.width, geometry.parameters.height);
      // let geometry = new THREE.PlaneGeometry(1550, 934);
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      let textureLoader = new THREE.TextureLoader();
      // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
      textureLoader.load(basemap, function (texture) {
        let material = new THREE.MeshLambertMaterial({
          color: 0xffffff,
          // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
          map: texture, //设置颜色贴图属性值
          // transparent: true,
          depthTest: true,
          // depthWrite: false,
          side: THREE.DoubleSide,
        }); //材质对象Material
        let basemapPlane = new THREE.Mesh(geometry, material); //网格模型对象Mesh
        basemapPlane.translateZ(-1);
        // basemapPlane.material.color.set(0xffffff);
        basemapPlane.name = ObjName.Ground;
        basemapPlane.userData.type = ObjType.Ground;
        basemapPlane.userData.points = [
          new THREE.Vector3(-w/2, h/2, -1),
          new THREE.Vector3(-w/2, -h/2, -1),
          new THREE.Vector3(w/2, -h/2, -1),
          new THREE.Vector3(w/2, h/2, -1),
        ];

        floor.add(basemapPlane); //网格模型添加到场景中

        // 添加白色的平面
        // // let geo2 = new THREE.PlaneGeometry(w, h);
        // let geo2 = new THREE.ShapeGeometry(shape);
        // let material2 = new THREE.MeshLambertMaterial({
        //   color: 0xffffff,
        //   side: THREE.DoubleSide,
        // });
        // let mesh2 = new THREE.Mesh(geo2, material2);
        // mesh2.name = ObjName.Ground;
        // mesh2.userData.type = ObjType.BlankGround;
        // mesh2.userData.points = [
        //   new THREE.Vector3(-w/2, h/2, 0),
        //   new THREE.Vector3(-w/2, -h/2, 0),
        //   new THREE.Vector3(w/2, -h/2, 0),
        //   new THREE.Vector3(w/2, h/2, 0),
        // ];
        // mesh2.visible = false;
        // floor.add(mesh2);

        // add floor(slab)
        let material3 = new THREE.MeshLambertMaterial({
          color: 0xaaaaaa,
          transparent: true,
          opacity: 0.5,
          depthTest: true,
          depthWrite: false,
          side: THREE.DoubleSide,
        });
        let pnts3 = [
          new THREE.Vector3(-w/2, h/2, 0),
          new THREE.Vector3(-w/2, -h/2, 0),
          new THREE.Vector3(w/2, -h/2, 0),
          new THREE.Vector3(w/2, h/2, 0),
        ];
        let newGeo = GEO.createPlaneGeometry(pnts3);
        let newGround = new THREE.Mesh(newGeo, material3);
        newGround.userData.points = pnts3;
        newGround.userData.type = ObjType.Floor;
        newGround.userData.transparent = 0.5;
        floor.add(newGround);
      });
    };

    img.onload = doCreate;
  },

  _createPlane(basemap: string, pnts: any=null, origin_pnts: any=null) {
    // 获取图片尺寸
    const loadItem = () : Promise<THREE.Mesh> => {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = basemap;

        const doCreate = () => {
          var w = img.width;
          var h = img.height;
          // 纹理贴图映射到一个矩形平面上
          // PlaneGeometry似乎有最大的尺寸限制
          console.log(w, h);
    
          while (w > 800) {
            w *= 0.9;
            h *= 0.9
          }
          console.log(w, h);
    
          // let geometry = new THREE.PlaneGeometry(w, h); //矩形平面
          let geometry: THREE.ShapeGeometry;
          if (pnts === null){
            let shape = new THREE.Shape();
            shape.moveTo(-w/2, h/2);
            shape.lineTo(-w/2, -h/2);
            shape.lineTo(w/2, -h/2);
            shape.lineTo(w/2, h/2);
            shape.lineTo(-w/2, h/2);
            geometry = new THREE.ShapeGeometry(shape);
          }
          else {
            geometry = GEO.createPlaneGeometry(pnts);
          }
          this.assignUVs(geometry);
    
    
          // console.log(geometry.parameters.width, geometry.parameters.height);
          // let geometry = new THREE.PlaneGeometry(1550, 934);
          // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
          let textureLoader = new THREE.TextureLoader();
          // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
          textureLoader.load(basemap, function (texture) {
            let material = new THREE.MeshLambertMaterial({
              color: 0xffffff,
              // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
              map: texture, //设置颜色贴图属性值
              // transparent: true,
              depthTest: true,
              // depthWrite: false,
              side: THREE.DoubleSide,
            }); //材质对象Material
            let basemapPlane = new THREE.Mesh(geometry, material); //网格模型对象Mesh
            // basemapPlane.material.color.set(0xffffff);
            basemapPlane.name = ObjName.Ground;
            basemapPlane.userData.type = ObjType.Ground;
            basemapPlane.userData.points = [
              new THREE.Vector3(-w/2, h/2, 0),
              new THREE.Vector3(-w/2, -h/2, 0),
              new THREE.Vector3(w/2, -h/2, 0),
              new THREE.Vector3(w/2, h/2, 0),
            ];
    
            // floor.add(basemapPlane); //网格模型添加到场景中
            resolve(basemapPlane);
          });
        };
    
        img.onload = doCreate;
      })
    }

    return loadItem;
    
  },

  

  assignUVs(geometry: THREE.ShapeGeometry) { 
    geometry.computeBoundingBox(); 
    var max = geometry.boundingBox!.max,
            min = geometry.boundingBox!.min;
    var offset = new THREE.Vector3(0 - min.x, 0 - min.y, 0 - min.z);
    var range = new THREE.Vector3(max.x - min.x, max.y - min.y, max.z - min.z);

    var uvAttribute = geometry.attributes.uv;

    for ( var i = 0; i < uvAttribute.count; i ++ ) {
        
        var u = uvAttribute.getX( i );
        var v = uvAttribute.getY( i );
        // var z = uvAttribute.getZ( i );
                
        // do something with uv
        u=(u+offset.x)/range.x
        v=(v+offset.y)/range.y
        // something error
        // z=(z+offset.z)/range.z
        // z=(z+offset.y)/range.y
        // write values back to attribute
                
        uvAttribute.setXY( i, u, v);
        // uvAttribute.setZ(i,z)
    }
    uvAttribute.needsUpdate = true;
},

  initScene() {
    const scene = new THREE.Scene();

    // const point = new THREE.PointLight(0x9f9f9f);
    for (var i = 0; i < 7; i++) {
      let point = new THREE.PointLight(0x101010);
      point.position.set(0, -100, 400 * (i + 1));
      scene.add(point); 
    }
    const ambient = new THREE.AmbientLight(0xafafaf);
    scene.add(ambient);

    return scene;
  },

  copyWall (userData: any) {
    // 深拷贝
    var points2 = [];
    for (var p of userData.points) {
      points2.push(v3Clone(p));
    }
    let mesh = this.createWallMesh(
      points2,
      userData.width,
      userData.height,
      userData.color,
      null,
      userData.rotation,
      userData.rideHeight,
      userData.transparency,
    );
    mesh.userData.name = userData.name;

    return mesh;
  },

  copyRoom (userData: any) {
    // 深拷贝
    var points2 = [];
    for (var p of userData.points) {
      points2.push(v3Clone(p));
    }
    let mesh = this.createPolRoomMesh(
      points2,
      userData.height,
      userData.color,
      null,
      userData.rotation,
      userData.rideHeight,
      userData.transparency,
    );
    mesh.userData.name = userData.name;

    return mesh;
  },

  copyFloorSlab(obj: any) {
    const ud = obj.userData;
    let material = new THREE.MeshLambertMaterial({
      color: 0xaaaaaa,
      transparent: true,
      opacity: 1 - ud.transparent,
      depthTest: true,
      side: THREE.DoubleSide,
    }); 
    
    // console.log("material", material);
    console.log(ud);
    let points = ud.points.slice(0);

    let newGeo = GEO.createPlaneGeometry(points);
    let newGround = new THREE.Mesh(newGeo, material);
    newGround.userData.points = points;
    newGround!.userData.name = ud.name;
    newGround!.userData.type = ObjType.Floor;
    newGround!.userData.transparent = ud.transparent;
    newGround!.name = ObjName.EditShape;
    return newGround;
  },

  copyModel (obj: THREE.Object3D) {
    var mesh = obj.clone();
    mesh.userData.uuid = mesh.uuid;
    return mesh;
  },

  copyText (userData: any, onClick: any) {
    // 深拷贝
    var pos = v3Clone(userData.pos);
    let mesh = this.createTextLabel(
      userData.content,
      pos,
      onClick,
      null,
      userData.fontSize,
    );

    return mesh;
  },

  copyPic (userData: any, onClick: any) {
    var pos = v3Clone(userData.pos);
    
    let mesh = this.createPicLabel(
      userData.imgSrc,
      pos,
      userData.name,
      onClick,
      null,
      userData.width,
      userData.height,
    );

    return mesh;
  },

  copyTube(userData: any, obj: THREE.Object3D){
    var tubeGroup = new THREE.Group();
    tubeGroup.userData.type = userData["type"];
    tubeGroup.userData.textureDir = userData["textureDir"];
    tubeGroup.userData.color = userData["color"];
    tubeGroup.userData.pipeType = userData["pipeType"];
    tubeGroup.userData.name = userData["name"];
    tubeGroup.userData.tubeID = userData["tubeID"];
    tubeGroup.userData.height = userData["height"];
    tubeGroup.userData.radius = userData["radius"];
    tubeGroup.userData.center = userData["center"];
    tubeGroup.userData.rotation = userData["rotation"];

    var cnt = 0;
    var len = obj.children.length;

    for(let i = 0;i  < len; i++){
      var item = obj.children[i].userData;
      cnt++;
      if(item.type === ObjType.Tube){
        let mesh = this.createOneTubeMesh(
          item.stDir,
          item.edDir,
          item.stPoint,
          item.edPoint,
          item.color,
          item.radius,
          item.pipeType,
          item.textureDir
        );
        mesh.geometry.translate(
          -tubeGroup.userData.center.x,
          -tubeGroup.userData.center.y,
          -tubeGroup.userData.center.z
        );
        tubeGroup.add(mesh);
        console.log(cnt);
      }else{
        let mesh = this.createOneFilletMesh(
          item.stDir,
          item.edDir,
          item.point,
          item.color,
          item.radius,
          item.pipeType
        );
        mesh.geometry.translate(
          -tubeGroup.userData.center.x,
          -tubeGroup.userData.center.y,
          -tubeGroup.userData.center.z
        );
        tubeGroup.add(mesh);
        console.log(cnt);
      }
    }
    tubeGroup.position.set(
      tubeGroup.userData.center.x,
      tubeGroup.userData.center.y,
      tubeGroup.userData.center.z,
    );
    if(tubeGroup.userData.rotation !== undefined){
      tubeGroup.rotation.fromArray(tubeGroup.userData.rotation);
    }
    return tubeGroup;
  },


  copyFloor (srcFloor: THREE.Group, tarFloor: THREE.Group, copyType: any, onClickText: any, onClickPic: any) {
    for (let obj of srcFloor.children) {
      let ud = obj.userData;
      switch (ud.type) {
        case ObjType.Ground:
        case ObjType.BlankGround:
          if (copyType.ground){
            tarFloor.add(obj.clone());
          }
          break;
        
        case ObjType.Wall:
          if (copyType.wall){
            let mesh = this.copyWall(ud);
            tarFloor.add(mesh);
            addLines(mesh.userData.points, mesh.userData.uuid, mesh.userData.type === ObjType.Room);
          }
          break;

        case ObjType.Room:
          if (copyType.room) {
            let mesh = this.copyRoom(ud);
            tarFloor.add(mesh);
            addLines(mesh.userData.points, mesh.userData.uuid, mesh.userData.type === ObjType.Room);
          }
          break;

        case ObjType.Model:
          if (copyType.model) {
            tarFloor.add(this.copyModel(obj.parent!));
          }
          break;

        case ObjType.Picture:
          if (copyType.picture) {
            tarFloor.add(this.copyPic(ud, onClickPic));
          }
          break;

        case ObjType.Text:
          if (copyType.text) {
            tarFloor.add(this.copyText(ud, onClickText));
          }
          break;

        case ObjType.Tube:
          if(copyType.tube){
            tarFloor.add(this.copyTube(ud, obj));
            // TODO: copy support line
          }
          break;

        case ObjType.Floor:
          if(copyType.floorSlab) {
            let mesh = this.copyFloorSlab(obj);
            tarFloor.add(mesh);
          }
          break;
        
        default:
          break;
      }
    }
  },
}

