import { Typography, Stack, Input } from "@mui/material";
import React from "react";
import { HoleProperty } from "../../RenderContent";

interface HolePropertyPanelProps {
  holeProperty: HoleProperty;
  onNameChange: (name: string) => void;
  onColorChange: (color: string) => void;
  onHeightChange: (height: number) => void;
  onLengthChange: (length: number) => void;
  onTransparencyChange: (transparency: number) => void;
  onAreaChange: (area: number) => void;
}

export const HolePropertyPanel: React.FC<HolePropertyPanelProps> = ({
  holeProperty,
  onNameChange,
  onColorChange,
  onHeightChange,
  onLengthChange,
  onTransparencyChange,
  onAreaChange,
}) => {
  const [nameState, setNameState] = React.useState(holeProperty.name);
  const [colorState, setColorState] = React.useState(holeProperty.color);
  const [heightState, setHeightState] = React.useState(holeProperty.rideHeight);
  const [lengthState, setLengthState] = React.useState(holeProperty.height);
  const [transparencyState, setTransparencyState] = React.useState(holeProperty.transparency);
  const [areaState, setAreaState] = React.useState(holeProperty.area);

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        maxWidth: "250px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          padding: "6px",
        }}
      >
        楼板打洞
      </Typography>
      <Stack
        sx={{
          padding: "6px 16px",
          backgroundColor: "white",
        }}
        spacing={0}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            名称
          </Typography>
          <Input
            value={nameState}
            onChange={(e: any) => {
              setNameState(e.target.value);
              onNameChange(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            颜色
          </Typography>
          <Input
            type="color"
            value={colorState}
            onChange={(e: any) => {
              setColorState(e.target.value);
              onColorChange(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            高度(m)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={lengthState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onLengthChange(value);
              }
              setLengthState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            离地高度(m)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={heightState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onHeightChange(value);
              }
              setHeightState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            透明度(%)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "5" }}
            value={transparencyState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onTransparencyChange(value);
              }
              setTransparencyState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "125px" }}>
            面积(㎡)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={areaState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onAreaChange(value);
              }
              setAreaState(value);
            }}
            sx={{
              width: "100%",
            }}
            readOnly
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
