import { Button, Divider, Stack } from "@mui/material";
import { url } from "inspector";
import React, { useEffect } from "react";

interface CompassIconProps {
  angle: number;
  onClick: () => void;
}

export const CompassIcon: React.FC<CompassIconProps> = ({
  angle,
  onClick,
}) => {

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        width: "50px",
        height: "50px",
        background:"url('images/compass/u95.svg') 0 0 no-repeat",
        userSelect:"none",
      }}
      onClick={() => onClick()}
    >
      <img 
        style={{
          transform: `rotate(${angle}deg)`,
          // position: 'relative',
          // left: '50%',
          // top: '50%',
        }} 
        src={"images/compass/u96.svg"} 
        height="48px" 
        width="48px" 
      />
    </div>
  );
};
