import { Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { selectMode } from "../../../slices/uiSlice";
import { useSelector } from "react-redux";
import { Mode } from "../../../enums/UI/Mode";

interface TextAddingOperationHintsProps {
  
}



export const TextAddingOperationHints: React.FC<
TextAddingOperationHintsProps
> = ({}) => {
  const mode = useSelector(selectMode);

  const [isShowExpand, setIsShowExpand] = React.useState(false);

  const handleUp = () =>{
    setIsShowExpand(!isShowExpand);
  }

  let text = "1、可自定义文字并点击绘制区域上任一位置在地板上添加文字\n2、选中文字，在属性面板随时更改文字及离地高度\n3、也可Esc/查看按钮退出绘制状态并在查看状态进行属性编辑";
  let isExpandDiv;

  if(text.length > 30){
    if(isShowExpand) {
      isExpandDiv = (<button onClick = {handleUp} style = {{right : "35px",position : "absolute",bottom : "10px"}}>折叠</button>)
    } else {
      isExpandDiv = (<button onClick = {handleUp} style = {{right : "35px",position : "absolute",bottom : "10px"}}>展开</button>)
    }
  } else {
      isExpandDiv = '';
  }


  return (
    <Stack
      style={{
        position: "absolute",
        right: "10px",
        bottom: "10px",
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "10px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
        border: "1px solid #ccc",
        maxWidth: "300px",
        display: mode === Mode.TextAdding ? "block" : "none",
      }}
      spacing={0.5}
    >
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          marginBottom: "20px"
        }}
      >
        操作提示
      </Typography>
      <Typography variant="body1"
      style = {isShowExpand ?
        {whiteSpace: 'pre-wrap',marginBottom: "20px"}:
        {
          whiteSpace: 'pre-wrap',
          display:'none'
        }
      }
      >{text}
        </Typography>
        {isExpandDiv}
      
    </Stack>
  );
};

