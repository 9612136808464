import { groupEnd } from "console";
import * as THREE from "three";
import { Vector3 } from "three";
import { ObjName } from "../enums/ObjName";
import { ObjType } from "../enums/ObjType";

export function getCenterPoint(geometry: any) {
  var middle = new THREE.Vector3();
  // var geometry = mesh.geometry;

  geometry.computeBoundingBox();

  middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
  middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
  middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;

  return middle;
}

export function hideLabel(floors: any) {
  for (var floor of floors){
    for (var obj of floor.children) {
      if (
        obj.userData.type !== undefined && false
      ) {
          obj.element.style.display = 'none';
      }
    }
  }
}

export function showLabel(floors: any) {
  for (var floor of floors){
    for (var obj of floor.children) {
      if (
        obj.userData.type !== undefined && false
      ) {
        if (obj.visible === true)
          obj.element.style.display = 'display';
      }
    }
  }
}

// 深拷贝Vector3
export function v3Clone(v: any) {
  const r = new Vector3(v.x, v.y, v.z);
  return r;
}

// 字典按key排序
export function sortByKey(arys: any) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort();
  //console.log('newkey='+newkey);
  var newObj: any = {}; //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]];
    //向新创建的对象中按照排好的顺序依次增加键值对
  }

  return newObj; //返回排好序的新对象
}

/**
 * 根据类型设置物体的可见性
 * @param type
 * @param visible
 * @param floors
 */
export function setTypeVis(
  type: ObjType | string,
  visible: boolean,
  floors: any
) {
  if (type === ObjType.Ground) {
    // 隐藏底图
    for (let floor of floors) {
      for (let obj of floor.children) {
        if (obj.userData.type !== undefined && obj.userData.type === type) {
          obj.visible = visible;
        }
        // if (
        //   obj.userData.type !== undefined &&
        //   obj.userData.type === ObjType.BlankGround
        // ) {
        //   obj.visible = !visible;
        // }
      }
    }

  } else {
    for (let floor of floors) {
      for (let obj of floor.children) {
        if (obj.userData.type && obj.userData.type === type) {
          obj.visible = visible;
          // if (obj.userData.type === ObjType.Picture){
          //     obj.element.style.display = visible ? 'visible' : 'none';
          //   }
        }
      }
    }
  }
}

export function getExistType(floors: THREE.Group[]) {
  var exist = {
    wall: false,
    room: false,
    tube: false,
    model: false,
    picture: false,
    text: false,
  };

  for (let floor of floors) {
    for (let obj of floor.children) {
      if (obj.userData.type === undefined) continue;

      switch (obj.userData.type) {
        case ObjType.Wall:
          if (!exist.wall) exist.wall = true;
          break;
        case ObjType.Room:
          if (!exist.room) exist.room = true;
          break;
        case ObjType.Tube:
          if (!exist.tube && obj.children.length !== 0) exist.tube = true;
          break;
        case ObjType.Model:
          if (!exist.model) exist.model = true;
          break;
        case ObjType.Picture:
          if (!exist.picture) exist.picture = true;
          break;
        case ObjType.Text:
          if (!exist.text) exist.text = true;
          break;
        default:
          break;
      }
    }

    if (
      exist.room &&
      exist.wall &&
      exist.tube &&
      exist.model &&
      exist.picture &&
      exist.text
    )
      break;
  }

  return exist;
}

export function removeObjectsByName(floor: THREE.Group, name: string){
  let obj = floor.getObjectByName(name);
  var i = 0;
  while (obj && i < 6) {
    console.log("while loop:", i);
    i++;
    floor.remove(obj);
    obj = floor.getObjectByName(name);
  }
}

// Object.keys(ObjType).includes(obj.userData.type as keyof typeof ObjType)
// get intersecting object and judge whether it is formal object(not temporary objects) in the scene, not included ground
export function isFormalObj(obj: THREE.Object3D | null | undefined) {
  try{
    if (obj && obj.userData && 
      Object.values(ObjType).includes(obj.userData.type) && // 不能直接 xxx in enum
      obj.userData.type.indexOf('ground') == -1 && 
      obj.name == "")
      // console.log("tttture")
      return true;
  }
  catch (e) { return false; }
  return false;
}

export function getFirstFormalObjUuid(objs: Array<any>) {
  // console.log(objs);
  for (let i = 0; i < objs.length; i++) {
    if (isFormalObj(objs[i].object)){
      if(objs[i].object.userData.type == ObjType.Tube){
        // console.log("Tube:", objs[i].object);
      }else{
        // console.log("not Tube:", objs[i].object);
      }
      return objs[i].object.userData.uuid;
      
    }
  }
  return "-1";
}

export function isEmptyFloor(floors: any) {
  // 检测是否有底图
  for (let obj of floors.children) {
    if (obj.userData.type !== undefined && obj.userData.type === ObjType.Ground){
      return false;
    }
  }
  return true;
}