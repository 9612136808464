import { 
  Typography, 
  Stack, 
  ImageList, 
  ImageListItem, 
  ImageListItemBar,
  Tooltip
} from "@mui/material";
import React from "react";
import { StringLiteralType } from "typescript";
import { PicAddItem } from "./component/PicAddItem"

interface PicAddPanelProps {
  getPicProps: (url: string, name: string) => void; // 将url和name传递回 RenderContent
  itemData: Array<{name: string, url: string}>;
}

export const PicAddPanel: React.FC<PicAddPanelProps> = ({
  getPicProps,
  itemData,
}) => {
  const [selected, setSelected] = React.useState(-1);
  // const [recent, setRecent] = React.useState([0,1,2,3,4,5]);
  var str = localStorage.getItem("recentPic");
  if (str === null) {
    var l: number[] = [];
  } else {
    var l: number[] = JSON.parse(str);
  }
  const [recent, setRecent] = React.useState(l);
  // const [url, setUrl] = React.useState("");

  const handleClick = (i: number, url: string, name: string) => {
    setSelected(i);
    // setUrl(url);
    getPicProps(url, name);
    if (!recent.includes(i)) {
      const newRecent = recent.slice();
      if (newRecent.length >= 6)
        newRecent.pop();
      newRecent.unshift(i);
      setRecent(newRecent);
    } else {
      var idx = recent.indexOf(i);
      const newRecent = recent.slice();
      for (var j = idx; j > 0; j--) {
        newRecent[j] = newRecent[j-1];
      }
      newRecent[0] = i;
      setRecent(newRecent);
    }
    localStorage.setItem("recentPic", JSON.stringify(recent))
  }

  const getRecentPicItem = () => {
    const list = [];
    for (let i of recent){
      const item = itemData[i];
      var o = (
        <PicAddItem
          name={item.name}
          url={item.url}
          onClick={(i: number, url: string, name: string) => handleClick(i, url, name)}
          selected={selected}
          index={i}
          key={item.url}
        />);

      list.push(o);
    }
    
    return list;
 }

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        width: "325px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          padding: "6px",
        }}
      >
        图片
      </Typography>
      <Stack
        sx={{
          padding: "6px 16px",
          backgroundColor: "white",
          overflow: 'auto',
          maxHeight:'500px',
        }}
        spacing={0}
      >
        <Stack spacing={1} alignItems={"flex-start"}>
          <Typography variant="subtitle1" sx={{ width: "100px" }}>
            最近使用
          </Typography>
          <ImageList cols={6} gap={10}>
            {getRecentPicItem()}
          </ImageList>
          <Typography variant="subtitle1" sx={{ width: "100px" }}>
            图片库
          </Typography>
          <ImageList cols={6} gap={10}>
            {itemData.map((item, i) => (            
              <PicAddItem
                name={item.name}
                url={item.url}
                onClick={(i: number, url: string, name: string) => handleClick(i, url, name)}
                selected={selected}
                index={i}
                key={i}
              />
            ))}
          </ImageList>
        </Stack>
      </Stack>
    </Stack> 
  );
};
