import { Typography, Stack } from "@mui/material";
import React from "react";


// 显示实时比例尺
export const ScaleIcon: React.FC<{ value: number }> = (
  { 
    value 
  }
) => {

  return (
    <Stack
      style={{
        position: "absolute",
        bottom: "10px",
        left: "10px",
        width: "60px",
        // height: "10px",
        // border: "1px solid rgba(0, 0, 0, 0.12)",
        // boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          // padding: "6px",
        }}
      >
        {value.toFixed(2) + ' m'}
      </Typography>
     
    </Stack>
  );
};
