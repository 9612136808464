import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { RoomDrawingMode } from "../../enums/UI/RoomDrawingMode";
import {
  selectRoomDrawingMode,
  setRoomDrawingMode,
} from "../../slices/uiSlice";

interface RoomDrawingModeButtonProps {
  bindMode: RoomDrawingMode;
  image: string;
  selectedImage: string;
  onClick: () => void;
  disabled: boolean
}

export const RoomDrawingModeButton: React.FC<RoomDrawingModeButtonProps> = ({
  bindMode,
  image,
  selectedImage,
  onClick,
  disabled
}) => {
  const mode = useSelector(selectRoomDrawingMode);
  const dispatch = useAppDispatch();

  return (
    <div style={{ height: "40px" }}>
      <img
        alt="logo"
        src={mode === bindMode ? selectedImage : image}
        style={{
          width: "100%",
          height: "100%",
          padding: "4px",   
        }}
        onClick={
          !disabled?
          () => {
          dispatch(setRoomDrawingMode(bindMode));
          onClick();
        }:
        ()=>{}
      }
      />
    </div>
  );
};
