import { UnitLength } from "../entity/UnitLength"
import * as THREE from "three";
import { Vector3 } from "three";
import { StarHalfSharp } from "@mui/icons-material";

//按顶点顺序，以数组形式传入管线坐标/墙体坐标，计算总长度,返回转换后的真实长度
export function caculateLenth (ArryOfPoints: any, unitLength?: UnitLength) {
  if (!unitLength) return;
  //debug
  //alert("in caculateLenth"+ ArryOfPoints.length);
  if (!Array.isArray(ArryOfPoints) || ArryOfPoints.length < 2) {
    alert("not an arry or no points!");
    return;
  }
  let x = ArryOfPoints[0];
  var sumOfLen = 0;
  for (var i = 0; i < ArryOfPoints.length - 1; i++) {
    let dx = Math.abs(ArryOfPoints[i].x - ArryOfPoints[i + 1].x);
    let dy = Math.abs(ArryOfPoints[i].y - ArryOfPoints[i + 1].y);
    //z坐标不同理论上不会出现，但任加上以增加程序鲁棒性
    // let dz = Math.abs(ArryOfPoints[i].z - ArryOfPoints[i + 1].z);
    sumOfLen += Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
  }
  //alert(sumOfLen+" "+sumOfLen*lenthOfEachPixel);
  // window.confirm(
  //   `当前像素点长度${sumOfLen}经设置比例尺转换后长度: ${
  //     sumOfLen * unitLength.lenthOfEachPixel
  //   } 米`
  // );
  return sumOfLen * unitLength.lenthOfEachPixel;
};


//对于任意多边形，顶点的顺序必须为顺时针或逆时针；
export function caculateArea(ArryOfPoints: any, unitLength?: UnitLength){
  if (!unitLength) return;

  if (ArryOfPoints.length < 3) return;
  var sum0 = 0;
  for (var i = 0; i < ArryOfPoints.length - 1; i++) {
    sum0 +=
      ArryOfPoints[i].x * ArryOfPoints[i + 1].y -
      ArryOfPoints[i + 1].x * ArryOfPoints[i].y;
  }
  var square = Math.abs(
    Math.abs(
      sum0 +
        ArryOfPoints[i].x * ArryOfPoints[0].y -
        ArryOfPoints[0].x * ArryOfPoints[i].y
    ) / 2
  );
  // window.confirm(
  //   `当前像素面积：${square} 设置比例尺转换后面积 ${
  //     square * unitLength.lenthOfEachPixel * unitLength.lenthOfEachPixel
  //   } 平方米`
  // );
  return Number((square * unitLength.lenthOfEachPixel * unitLength.lenthOfEachPixel).toFixed(2));
};

// AABB相交，充要条件： 每一个维度都满足 中心距离 < halfsizeA + halfsizeB
export function isRectIntersect(
  ALowerConner: THREE.Vector2, 
  AUpperConner : THREE.Vector2, 
  BLowerConner: THREE.Vector2, 
  BUpperConner : THREE.Vector2){
  // console.log("ALowerConner", ALowerConner);
  // console.log("AUpperConner", AUpperConner);
  // console.log("BLowerConner", BLowerConner);
  // console.log("BUpperConner", BUpperConner);
  var centerA = new THREE.Vector2(
    0.5 * (ALowerConner.x + AUpperConner.x),
    0.5 * (ALowerConner.y + AUpperConner.y)
  );
  var centerB = new THREE.Vector2(
    0.5 * (BLowerConner.x + BUpperConner.x),
    0.5 * (BLowerConner.y + BUpperConner.y)
  );
  var centerDistance = new THREE.Vector2(
    Math.abs(centerA.x - centerB.x),
    Math.abs(centerA.y - centerB.y),
    );
  var halfsizeA = new THREE.Vector2().subVectors(AUpperConner, ALowerConner).multiplyScalar(0.5);
  var halfsizeB = new THREE.Vector2().subVectors(BUpperConner, BLowerConner).multiplyScalar(0.5);
  var halfsizeSum = new THREE.Vector2().addVectors(halfsizeA, halfsizeB);
  
  // console.log("halfsizeSum", halfsizeSum);
  // console.log("centerDistance", centerDistance);
  return (
    halfsizeSum.x > centerDistance.x
    && halfsizeSum.y > centerDistance.y
  )
}

// poly相交
// 辅助函数：检测两条线段是否相交
function areLinesIntersecting(a: THREE.Vector2, b: THREE.Vector2, c: THREE.Vector2, d: THREE.Vector2): boolean {
  const denominator = (b.x - a.x) * (d.y - c.y) - (b.y - a.y) * (d.x - c.x);
  if (denominator === 0) {
    return false;
  }

  const t1 = ((c.x - a.x) * (d.y - c.y) - (c.y - a.y) * (d.x - c.x)) / denominator;
  const t2 = ((c.x - a.x) * (b.y - a.y) - (c.y - a.y) * (b.x - a.x)) / denominator;

  return t1 >= 0 && t1 <= 1 && t2 >= 0 && t2 <= 1;
}



// 主要函数：检测两个多边形是否相交或包含
export function isPolyIntersect(polyA: THREE.Vector2[], polyB: THREE.Vector2[]): boolean {
  if(isPolyInside(polyA, polyB) || isPolyInside(polyB, polyA)){
    return true;
  }
  for (let i = 0; i < polyA.length; i++) {
    const p1 = polyA[i];
    const p2 = polyA[(i + 1) % polyA.length];
    for (let j = 0; j < polyB.length; j++) {
      const p3 = polyB[j];
      const p4 = polyB[(j + 1) % polyB.length];
      if (areLinesIntersecting(p1, p2, p3, p4)) {
        return true; // 相交
      }
    }
  }
  return false; // 不相交
}

// 检测一个多边形是不是被另一个多边形包含
// 辅助函数： 点在多边形内部
function isPointInside(point: THREE.Vector2, poly: THREE.Vector2[]) : boolean {
  var temp = 1;
  for(var i = 0; i < poly.length - 1; i++){
     var a = poly[i + 1].clone().sub(poly[i]); 
     var b = point.clone().sub(poly[i]); 
     if(i === 0){
      temp = a.cross(b);
     }else{
      var curr = a.cross(b);
      if(temp * curr < 0) return false;
      temp = curr;
     }
  }
  return true;
}

export function isPolyInside(polyA: THREE.Vector2[], polyB: THREE.Vector2[]): boolean {
  for (let i = 0; i < polyA.length; i++){
    if(!isPointInside(polyA[i], polyB)) return false;
  }
  return true;
}


/**
 * 世界坐标转屏幕坐标，1世界px等于多少屏幕px
 * @param window 
 * @param camera 
 * @returns 1世界px等于多少屏幕px
 */
export function calWorldScale(window: Window, camera: THREE.Camera) {
  // TODO: 改为在相机视角下，向右平移100
  var worldVec0 = new THREE.Vector3(0, 0, 0);
  var worldVec1 = new THREE.Vector3(100, 0, 0);

  var stdVec0 = worldVec0.project(camera);//世界坐标转标准设备坐标
  var stdVec1 = worldVec1.project(camera);//世界坐标转标准设备坐标
  // debugger;
  var a = window.innerWidth / 2;
  var b = window.innerHeight / 2;
  var x0 = Math.round(stdVec0.x * a + a);//标准设备坐标转屏幕坐标
  var x1 = Math.round(stdVec1.x * a + a);
  var y0 = Math.round(-stdVec0.y * b + b);
  var y1 = Math.round(-stdVec1.y * b + b);

  return Math.sqrt((x1 - x0)**2 + (y1 - y0)**2) / 100;
}


export function calDist(p: any, p1: any, p2: any){
  let x  = p.x,  y  = p.y;
  let x1 = p1.x, y1 = p1.y;
  let x2 = p2.x, y2 = p2.y;
  //三角形三个边长
  var A = Math.abs(Math.sqrt(Math.pow((x - x1), 2) + Math.pow((y - y1), 2)));
  var B = Math.abs(Math.sqrt(Math.pow((x - x2), 2) + Math.pow((y - y2), 2)));
  var C = Math.abs(Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2)));
  //利用海伦公式计算三角形面积
  //周长的一半
  var P = (A + B + C) / 2;
  var allArea = Math.abs(Math.sqrt(P * (P - A) * (P - B) * (P - C)));
  //普通公式计算三角形面积反推点到线的垂直距离
  var dis = (2 * allArea) / C;
  return dis;
}

export function calNearestLine(pnts: Array<any>, newPnt: THREE.Vector2, isclosed: boolean = true) {
  let footIsOnLineSeg = function(foot: any, p1: any, p2: any){
    let minX = Math.min(p1.x, p2.x), maxX = Math.max(p1.x, p2.x);
    let minY = Math.min(p1.y, p2.y), maxY = Math.max(p1.y, p2.y);
    let epi = -3;

    return ( epi < foot.x - minX && epi < maxX - foot.x) &&
            (epi < foot.y - minY && epi < maxY - foot.y);
  };

  let minDist = Number.MAX_VALUE;
  let idx = -1;
  let foot: THREE.Vector3 | null = null;
  let i_max = isclosed ? pnts.length : pnts.length - 1;
  for (let i = 0; i < i_max; i++) {
    let j = (i + 1) % pnts.length;
    let p1 = pnts[i], p2 = pnts[j];
    let dist = calDist(newPnt, p1, p2);
    if (dist < minDist) {
      let foot_i = calFootPnt(newPnt, p1, p2);
      // 判断垂足是否在线段内
      if (footIsOnLineSeg(foot_i, p1, p2)){
        minDist = dist;
        idx = i;
        foot = foot_i;
      }
    }
  }
  // 离最近线段的距离，最近线段的编号，垂足位置
  return {dist: minDist, idx: idx, foot: foot};
};

export function calFootPnt (pnt: any, p1: any, p2: any) {
  let x = pnt.x, y = pnt.y;
  let x1 = p1.x, y1 = p1.y;
  let x2 = p2.x, y2 = p2.y;
  let dx = x1 - x2;
  let dy = y1 - y2;
  if (Math.abs(dx) < 0.00000001 && Math.abs(dy) < 0.00000001){
    return new THREE.Vector3(x, y);
  }
  let u = (x-x1)*(x1-x2) + (y-y1)*(y1-y2);
  u = u / ((dx * dx) + (dy * dy));

  let x_foot = x1 + u*dx;
  let y_foot = y1 + u*dy;

  return new THREE.Vector3(x_foot, y_foot);
}

// 横平竖直的点
export function getHorizonVertiPoint (lastPoint: any, newPoint: any) {
  let diff_X = newPoint.x - lastPoint.x;
  let diff_Y = newPoint.y - lastPoint.y;
  let detX = Math.abs(diff_X);
  let detY = Math.abs(diff_Y);
  if (detX < detY) {
    newPoint.setX(lastPoint.x);
  } else {
    newPoint.setY(lastPoint.y);
  }
  newPoint.setZ(lastPoint.z);
  return newPoint;
};

// 获取垂直的点
export function getPerpendicularPoint(p1: any, p2: any, p3: any) {
  // 计算p1到p2的向量
  const v = { x: p2.x - p1.x, y: p2.y - p1.y };
  const length = Math.sqrt(v.x * v.x + v.y * v.y);
  // 计算垂直向量的坐标
  const perpendicular = { x: -v.y/length, y: v.x/length };
  let p4 = new Vector3(p2.x + perpendicular.x, p2.y + perpendicular.y);

  let res = calFootPnt(p3, p2, p4);
  return res;
}

export function getIntersectionOfLines (e1: any, d1:any, e2:any, d2:any) {
    let a = d1.x;
    let b = -d2.x;
    let c = (e2.x - e1.x);
    let d = d1.y;
    let e = -d2.y;
    let f = e2.y - e1.y;
    return solve2DEquation(a,b,c,d,e,f);
};

export function solve2DEquation(a:any, b :any, c:any, d:any, e:any, f:any){
  return new THREE.Vector2((c * e - f * b)/(a * e - b * d),
                            (a * f - b * c) / (a * e - b * d) );
}

export function interpVec3(vectors: Vector3[]) {
  let res = [];
  for(let i = 0; i < vectors.length-1; i++) {
    res.push(vectors[i]);
    let t = new THREE.Vector3((vectors[i].x+ vectors[i+1].x)/2, 
                              (vectors[i].y+ vectors[i+1].y)/2,
                              (vectors[i].z+ vectors[i+1].z)/2);
    res.push(t);
  }
  let n = vectors.length - 1;
  res.push(vectors[n]);
  let t = new THREE.Vector3((vectors[0].x+ vectors[n].x)/2, 
                            (vectors[0].y+ vectors[n].y)/2,
                            (vectors[0].z+ vectors[n].z)/2);
  res.push(t);
  return res;
}

export function scaleRectRoom(pnts: Vector3[], pnt2: Vector3, idx: number) {
  let room_pnt = [];
  let n = pnts.length;
  // 奇数在边上，偶数在顶点上
  if(idx % 2 == 1) {
    // 将pnt2投影到边上
    pnt2 = calFootPnt(pnt2, pnts[(idx+4)%n], pnts[idx]);
    let delta = new Vector3(pnt2.x - pnts[idx].x,
                            pnt2.y - pnts[idx].y);
    pnts[idx-1].addVectors(delta, pnts[idx-1]);
    pnts[(idx+1)%n].addVectors(delta, pnts[(idx+1)%n]);
  } else {
    // 将pnt2投影到对角线上
    pnt2 = calFootPnt(pnt2, pnts[idx], pnts[(idx + 4) % n]);
    let old = pnts[idx];
    pnts[idx] = pnt2;
    let move = pnt2;
    let fixed = pnts[(idx + 4) % n];
    for(let j = 0; j < 2; j++ ) {
      let i = (idx + 2 + j * 4) % n;
      let div = old.x - fixed.x;
      if (Math.abs(div) < 1e-7) div = 1e-7;
      let new_x = (pnts[i].x-fixed.x) * (move.x-fixed.x) / div + fixed.x;
      div = old.y - fixed.y;
      if (Math.abs(div) < 1e-7) div = 1e-7;
      let new_y = (pnts[i].y-fixed.y) * (move.y-fixed.y) / div + fixed.y;
      pnts[i] = new Vector3(new_x, new_y);
    }

  }
  for (let i = 0; i < n; i=i+2) room_pnt.push(pnts[i]);
  return room_pnt;
}

export function detectSafetyAngle (p1: any, p2: any, p3: any) {
  let angle = calculateAngle(p1, p2, p3);
  console.log("angle", angle);
  // let factor = Math.min(width, 7) / 3;
  let factor = 0.3490658503988659; // 20 degree
  // console.log(Math.PI - Math.abs(angle));
  // 等于pi时也符合条件
  if (Math.PI - angle < 0.001) return true;

  if (Math.PI - Math.abs(angle) < factor || Math.abs(angle) < 0.01) {
    return false;
  }
  return true;
} 

function calculateAngle(p1: any, p2: any, p3: any) {
  let v1 = { x: p1.x - p2.x, y: p1.y - p2.y };
  let v2 = { x: p3.x - p2.x, y: p3.y - p2.y };
  let dot = v1.x * v2.x + v1.y * v2.y;
  let len1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
  let len2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
  let cos = dot / (len1 * len2);
  let angle = Math.acos(cos);
  return angle;
}

export function calPntsDist(p1: any, p2: any) {
  return Math.sqrt((p1.x - p2.x)**2 + (p1.y - p2.y)**2);
}

export function toFixedPnts(pnts: Array<any>, factor=7) {
  let res = []
  for(let i = 0; i < pnts.length; i++) {
    let p = new Vector3;
    p.setX(Number(pnts[i].x.toFixed(factor)));
    p.setY(Number(pnts[i].y.toFixed(factor)));
    p.setZ(Number(pnts[i].z.toFixed(factor)));
    res.push(p);
  }
  return res;
} 

export function isRectangle(points: Array<any>) {
  if (points.length !== 4) return false;
  let epi = 1e-4;
  let pointA = points[0], pointB = points[1], pointC = points[2], pointD = points[3];
  // 计算矩形的4条边的长度
  const AB = Math.sqrt((pointB.x - pointA.x) ** 2 + (pointB.y - pointA.y) ** 2);
  const BC = Math.sqrt((pointC.x - pointB.x) ** 2 + (pointC.y - pointB.y) ** 2);
  const CD = Math.sqrt((pointD.x - pointC.x) ** 2 + (pointD.y - pointC.y) ** 2);
  const DA = Math.sqrt((pointA.x - pointD.x) ** 2 + (pointA.y - pointD.y) ** 2);

  // 计算矩形的2条对角线的长度
  const AC = Math.sqrt((pointC.x - pointA.x) ** 2 + (pointC.y - pointA.y) ** 2);
  const BD = Math.sqrt((pointD.x - pointB.x) ** 2 + (pointD.y - pointB.y) ** 2);

  // 判断是否为矩形
  if (Math.abs(AB - CD) < epi && Math.abs(BC - DA) < epi && Math.abs(AC - BD) < epi) {
    return true;
  } else {
    return false;
  }
}

export function tooClose(points: Array<any>, pnt: any) {
  let threshold = 1;
  for (let p in points) {
    if (calPntsDist(p, pnt) < threshold ) {
      return true;
    }
  }
  return false;
}

export function rotatePoint(point: any, center: THREE.Vector2|Vector3, angle: number) {
  var sin = Math.sin(angle);
  var cos = Math.cos(angle);
  var x = point.x - center.x;
  var y = point.y - center.y;
  point.x = x * cos - y * sin + center.x;
  point.y = x * sin + y * cos + center.y;
  return point;
}

// p1 p2 和 p2 p3的夹角
export function getAngle(p1: any, p2: any, p3: any) {
  let v1 = { x: p1.x - p2.x, y: p1.y - p2.y };
  let v2 = { x: p3.x - p2.x, y: p3.y - p2.y };
  let dot = v1.x * v2.x + v1.y * v2.y;
  let len1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
  let len2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
  let cos = dot / (len1 * len2);
  let angle = Math.acos(cos);
  return angle;
}