import { 
  Typography, 
  Stack, 
  ImageList, 
  ImageListItem, 
  ImageListItemBar,
  Button,
} from "@mui/material";
import React from "react";

interface ModelAddSideButtonProps {
  index: number;
  selected: number;
  name: string;
  onClick: (i: number) => void;
}

export const ModelAddSideButton: React.FC<ModelAddSideButtonProps> = ({
  index,
  selected,
  name,
  onClick,
}) => {
  const [indexState, setIndexState] = React.useState(index);
  const [nameState, setNameState] = React.useState(name);


  return (
    <Button
      style={{
        width: "100%",
        padding: "1px",
        height: "40px",
        color: "black",
        borderRadius: "0px",
        borderStyle: "none",
        backgroundColor: indexState === selected ? "#deeefb" : "#f8f8f8",
        fontSize: "10px",
        // textPrimary: {
        //   color: "black",
        // },
      }}
      onClick={() => {
        onClick(indexState);
      }}
    >
      {nameState}
    </Button>
  );
}