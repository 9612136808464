import { Button, Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { RenderingUIEvent } from "../../../enums/UI/RendererUIEvent";

interface ImportBasemapCmptProps {
  switchCopyFloor: (isCopyFloor: boolean) => void,
}

export const ImportBasemapCmpt : React.FC<ImportBasemapCmptProps> = ({
  switchCopyFloor,
}) => {
  const dispatchRenderingUIEvent = (
    event: RenderingUIEvent,
    payload: any = null
  ) => {
    document.dispatchEvent(new CustomEvent(event, { detail: payload }));
  };

  var uploadBasemapBtnRef = React.createRef<HTMLInputElement>();

  const onClickCopyFloor = () => {
    switchCopyFloor(true);
  };
  
  return (
    <Stack
    sx={{
      width: "100%",
      height: "100%",
      position: "absolute",
      margin: "auto",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "white",
      borderRadius: "5px",
      
    }}
  >
    <Stack
      sx={{
        position: "relative",
        marginLeft: "10%",
        marginRight: "10%",
        marginTop: "5%",
        marginBottom: "0%",
        backgroundImage: "url('images/hello_page/u25.svg')",
        backgroundSize:"100% 100%",
        height: "85%",
      }}
    >
      <Typography
      variant="h5"
      sx={{
        textAlign: "center",
        color: "black",
        marginTop: "13%",
        marginBottom: "7%",
        fontWeight: "bold",
      }}
      >
        导入参考图
      </Typography>
      <Typography
      variant="body2"
      sx={{
        textAlign: "center",
        color: "black",
      }}
      >
        上传png、jpg图片，大小不超过5M，长宽不超过5000像素 <br/>
        用于背景参考图来人工绘图
      </Typography>
      <Button
      variant="contained"
      color="info"
      onClick={() => {uploadBasemapBtnRef.current!.click()}}
      sx={{
        height: "44px",
        width: "200px",
        margin: "auto",
        marginTop: "10%",
      }}
      >
        上传
      </Button>
      <input
        type="file"
        ref={uploadBasemapBtnRef}
        accept=".jpg,.jpeg,.png,"
        hidden
        onClick={(event) => { (event.target as HTMLInputElement).value = "" }} 
        onChange={(e) =>
          dispatchRenderingUIEvent(RenderingUIEvent.UploadBaseMap, e)
        }
      />
      <Button
      variant="text"
      color="info"
      disableRipple
      onClick = {onClickCopyFloor}
      sx={{
        // height: "44px",
        // width: "200px",
        margin: "auto",
        marginTop: "5%",
        marginBottom: "5%",
      }}
      >
        复制楼层
      </Button>
    </Stack>
    <Typography
    variant="body2"
    sx={{
      textAlign: "center",
      color: "black",
      marginTop: "7%",
      marginBottom: "10%",
    }}
  >
    提示：新导入的参考图将覆盖原有参考图，请谨慎操作
  </Typography>
  </Stack>
  )
}