import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RenderingUIEvent } from "../../enums/UI/RendererUIEvent";
import { SideBarButton } from "./SideBarButton";
import { selectMode, setRoomDrawingMode, setHoleDrawingMode } from "../../slices/uiSlice";
import { useSelector } from "react-redux";
import { Mode } from "../../enums/UI/Mode";
import { useAppDispatch } from "../../app/store";
import { RoomDrawingModeMenu } from "./RoomDrawingModeMenu";
import { RoomDrawingMode } from "../../enums/UI/RoomDrawingMode";
import { HoleDrawingModeMenu } from "./HoleDrawingModeMenu";
import { HoleDrawingMode } from "../../enums/UI/HoleDrawingMode";

interface SidebarProps {
  isDrawingRoomType: boolean,
  isDrawingHoleType: boolean
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const dispatchRenderingUIEvent = (
    event: RenderingUIEvent,
    payload: any = null
  ) => {
    document.dispatchEvent(new CustomEvent(event, { detail: payload }));
  };

  const mode = useSelector(selectMode);
  const dispatch = useAppDispatch();

  const {isDrawingRoomType} = props;
  const {isDrawingHoleType} = props;

  const conditionalRoomDrawingModeMenu = () => {
    if (mode === Mode.RoomDrawing) {
      setRoomDrawingMode(RoomDrawingMode.Rectangle);
      return (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "calc(100% + 16px)",
            zIndex: "1",
          }}
        >
          <RoomDrawingModeMenu 
          isDrawingRoomType = {isDrawingRoomType}
          />
        </div>
      );
    }
  };

  const conditionalHoleDrawingModeMenu = () => {
    if (mode === Mode.HoleDrawing) {
      setHoleDrawingMode(HoleDrawingMode.Rectangle);
      return (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "calc(100% + 16px)",
            zIndex: "1",
          }}
        >
          <HoleDrawingModeMenu 
          isDrawingHoleType = {isDrawingHoleType}
          />
        </div>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "10px",
        backgroundColor: "rgba(71, 103, 199, 1)",
      }}
    >
      <Stack spacing={1}>
        <img alt="logo" src="images/sidebar/u41.png" />
        <div style={{ height: "25px" }} />
        <SideBarButton
          bindMode={Mode.Browsing}
          image="images/sidebar/u511.png"
          selectedImage="images/sidebar/u514.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.Pick);
          }}
        />
        <SideBarButton
          bindMode={Mode.WallDrawing}
          image="images/sidebar/u519.png"
          selectedImage="images/sidebar/u522.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.DrawWall);
          }}
        />
        <div
          style={{
            position: "relative",
          }}
        >
          <SideBarButton
            bindMode={Mode.RoomDrawing}
            image="images/sidebar/u526.svg"
            selectedImage="images/sidebar/u529.svg"
            onClick={() => {
              dispatchRenderingUIEvent(RenderingUIEvent.DrawRoom);
            }}
          />
          {conditionalRoomDrawingModeMenu()}
        </div>

        <div
          style={{
            position: "relative",
          }}
        >
          <SideBarButton
            bindMode={Mode.HoleDrawing}
            image="images/sidebar/u526.svg"
            selectedImage="images/sidebar/u529.svg"
            onClick={() => {
              dispatchRenderingUIEvent(RenderingUIEvent.DrawHole);
            }}
          />
          {conditionalHoleDrawingModeMenu()}
        </div>

        <SideBarButton
          bindMode={Mode.ModelAdding}
          image="images/sidebar/u540.png"
          selectedImage="images/sidebar/u543.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.AddModel);
          }}
        />
        <SideBarButton
          bindMode={Mode.PicAdding}
          image="images/sidebar/u554.png"
          selectedImage="images/sidebar/u557.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.AddPic);
          }}
        />
        <SideBarButton
          bindMode={Mode.TextAdding}
          image="images/sidebar/u533.png"
          selectedImage="images/sidebar/u536.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.AddText);
          }}
        />
        <SideBarButton
          bindMode={Mode.PipeDrawing}
          image="images/sidebar/u547.png"
          selectedImage="images/sidebar/u550.png"
          onClick={() => {
            dispatchRenderingUIEvent(RenderingUIEvent.DrawTube);
          }}
        />
      </Stack>
    </Box>
  );
};
