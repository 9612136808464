import { Button, Divider, Stack } from "@mui/material";
import React from "react";

export interface AdjustHeightMenuProps {
    isVisible: boolean;
    height: number;
    position: AdjustMousePosition;
}

export interface AdjustMousePosition {
    x: number;
    y: number;
}

export const AdjustHeightMenu: React.FC<AdjustHeightMenuProps> = ({
    isVisible,
    height,
    position
}) => {
    if(!isVisible) return null;
    return (
        <div
            style={{
                position: "absolute",
                left: `${position.x}px`,
                top: `${position.y}px`,
                backgroundColor: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
                maxWidth: "250px",
            }}
        >
            <div style={{ padding: '2px', border: '1px solid #ccc'}}>
                <div>离地高度</div>
                <input type="number" value={height} />
            </div>
        </div>
    )

}


