export enum ObjType{
  Wall = "wall",
  Room = "room",
  Hole = "hole",
  Tube = "tube",
  Fillet = "fillet",
  Model = "model", // 上传的模型
  Ground = "ground", // 地面
  BlankGround = "blankground", // 没有底图的地面
  Picture = "picture", // 上传的图片
  Text = "text",   // 上传的文字
  Floor = "floor", // 可编辑的楼层底板，不是底图
}