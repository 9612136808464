import { Typography, Stack, Input, Select, MenuItem } from "@mui/material";
import React from "react";
import { PipeProperty } from "../../RenderContent";
import { PipePattern, PipeType } from "../../../enums/PipeProp";

interface PipePropertyPanelProps {
  pipeProperty: PipeProperty;
  onNameChange: (name: string) => void;
  onHeightChange: (height: number) => void;
  onRadiusChange: (radius: number) => void;
  onColorChange: (color: string) => void;
  onPatternChange: (pattern: PipePattern) => void;
  onTypeChange: (type: PipeType) => void;
}

export const PipePropertyPanel: React.FC<PipePropertyPanelProps> = ({
  pipeProperty,
  onNameChange,
  onHeightChange,
  onRadiusChange,
  onColorChange,
  onPatternChange,
  onTypeChange,
}) => {
  const [nameState, setNameState] = React.useState(pipeProperty.name);
  const [heightState, setHeightState] = React.useState(pipeProperty.rideHeight);
  const [radiusState, setRadiusState] = React.useState(
    pipeProperty.radius
  );
  const [colorState, setColorState] = React.useState(pipeProperty.color);
  const [patternState, setPatternState] = React.useState(pipeProperty.pattern);
  const [typeState, setTypeState] = React.useState(pipeProperty.type);

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        maxWidth: "250px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          padding: "6px",
        }}
      >
        管线编辑
      </Typography>
      <Stack
        sx={{
          padding: "6px 16px",
          backgroundColor: "white",
        }}
        spacing={0.5}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            管线名称
          </Typography>
          <Input
            value={nameState}
            onChange={(e: any) => {
              setNameState(e.target.value);
              onNameChange(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            离地高度(m)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={heightState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onHeightChange(value);
              }
              setHeightState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            管线直径(m)
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={radiusState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onRadiusChange(value);
              }
              setRadiusState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            管线颜色
          </Typography>
          <Input
            type="color"
            value={colorState}
            onChange={(e: any) => {
              setColorState(e.target.value);
              onColorChange(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        {/* <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            管线纹路
          </Typography>
          <Select
            variant="standard"
            size="small"
            value={patternState}
            onChange={(e: any) => {
              setPatternState(e.target.value as PipePattern);
              onPatternChange(e.target.value as PipePattern);
            }}
            displayEmpty
            sx={{
              width: "100%",
            }}
          >
            {Object.values(PipePattern).map((pattern) => (
              <MenuItem key={pattern} value={pattern}>
                {pattern}
              </MenuItem>
            ))}
          </Select>
        </Stack> */}
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "120px" }}>
            管线类型
          </Typography>
          <Select
            variant="standard"
            size="small"
            value={typeState}
            onChange={(e: any) => {
              setTypeState(e.target.value as PipeType);
              onTypeChange(e.target.value as PipeType);
            }}
            displayEmpty
            sx={{
              width: "100%",
            }}
          >
            {Object.values(PipeType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};
