import { 
  ImageListItem, 
  ImageListItemBar,
  Tooltip
} from "@mui/material";
import React from "react";
import { ModelItemRaw, ModelItem } from "../ModelAddPanel";

interface ModelAddItemProps {
  data: ModelItemRaw,
  index: ModelIdx,      // 当前图片的编号
  selected: ModelIdx,   // 被选中的图片是哪个编号
  onClick: (i: ModelIdx, data: ModelItem) => void;
}

export interface ModelIdx {
  priIdx: number, subIdx: number, idx: number,
}

export const ModelAddItem: React.FC<ModelAddItemProps> = ({
  data,
  index,
  selected,
  onClick,
}) => {
  const d: ModelItem = {
    objUrl: data.file.objUrl,
    mtlUrl: data.file.mtlUrl.url,
    jpgUrl: data.file.jpgFiles?.map((item) => item.url),
    name: data.name,
    icon: data.icon,
  }
  const [dataState, setDataState] = React.useState(d);
  const [indexState, setIndexState] = React.useState(index);

  return (
    <ImageListItem 
      // key={urlState} 
      sx={{
        width:"55px", 
        height:"55px",
        alignItems:"center",
      }}
      >
      <img
        src={dataState.icon}
        alt={dataState.name}
        loading="lazy"
        height="100%" 
        width="100%"
        style={{
          borderWidth: selected === indexState ? "2px" : "1px",
          borderStyle: "solid",
          borderColor: selected === indexState ? "#ff0000" : "#d0d0d0",
          padding: "2px"
        }}
        onClick={
          (event: any) => {
            onClick(indexState, dataState);
          }
        }
      />
      <Tooltip title = {dataState.name}>
      <ImageListItemBar
        subtitle={dataState.name}
        position="below"
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          marginLeft: "auto",
          marginRight: "auto",
          width: "inherit",
          textAlign: "center"
        }}
      />
      </Tooltip>
    </ImageListItem>
  )

}