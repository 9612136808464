import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { HoleDrawingMode } from "../../enums/UI/HoleDrawingMode";
import {
  selectHoleDrawingMode,
  setHoleDrawingMode
} from "../../slices/uiSlice";

interface HoleDrawingModeButtonProps {
  bindMode: HoleDrawingMode;
  image: string;
  selectedImage: string;
  onClick: () => void;
  disabled: boolean
}

export const HoleDrawingModeButton: React.FC<HoleDrawingModeButtonProps> = ({
  bindMode,
  image,
  selectedImage,
  onClick,
  disabled
}) => {
  const mode = useSelector(selectHoleDrawingMode);
  const dispatch = useAppDispatch();

  return (
    <div style={{ height: "40px" }}>
      <img
        alt="logo"
        src={mode === bindMode ? selectedImage : image}
        style={{
          width: "100%",
          height: "100%",
          padding: "4px",   
        }}
        onClick={
          !disabled?
          () => {
          dispatch(setHoleDrawingMode(bindMode));
          onClick();
        }:
        ()=>{}
      }
      />
    </div>
  );
};
