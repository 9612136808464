import { Button, Divider, Stack } from "@mui/material";
import React from "react";
import * as THREE from "three";
import { Vector2, Vector3 } from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OrbitControls } from 'three-stdlib/controls/OrbitControls'
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { UnitLength } from "../entity/UnitLength";
import { RenderingUIEvent } from "../enums/UI/RendererUIEvent";
import { ObjName } from "../enums/ObjName";
import { ObjType } from "../enums/ObjType";
import { ProjectData, SceneData, FloorData } from "../entity/ProjectData";
import {
  caculateLenth,
  caculateArea,
  isPolyInside,
  calWorldScale,
  calNearestLine,
  getHorizonVertiPoint,
  getIntersectionOfLines,
  interpVec3,
  scaleRectRoom,
  detectSafetyAngle,
  calPntsDist,
  isRectangle,
  tooClose,
  rotatePoint,
  isRectIntersect,
  isPolyIntersect,
  getPerpendicularPoint,
  getAngle,
  calFootPnt
} from "../util/CalculateFunc";
import {
  getCenterPoint,
  hideLabel,
  showLabel,
  sortByKey,
  v3Clone,
  setTypeVis,
  getExistType,
  removeObjectsByName,
  isFormalObj,
  getFirstFormalObjUuid,
  isEmptyFloor,
} from "../util/util";
import MESH from "../util/MeshCreator";
import GEO from "../util/GeometryCreator";
import * as FILE from "../util/FileHelper";
import * as CAM from "../util/CameraHelper";
import * as RENDER from "../util/RendererHelper";
import { BrowserOperationHints } from "./overlay/hints/BrowserOperationHints";
import { WallDrawingOperationHints } from "./overlay/hints/WallDrawingOperationHints";
import { PipelineDrawingOperationHints } from "./overlay/hints/PiplineDrawingOperatoinHints";
import { RoomDrawingOperationHints } from "./overlay/hints/RoomDrawingOperationHints";
import { ModelAddingOperationHints } from "./overlay/hints/ModelAddingOperationHints";
import { TextAddingOperationHints } from "./overlay/hints/TextAddingOperationHints";
import { PicAddingOperationHints } from "./overlay/hints/PicAddingOperationHints";
import { threadId } from "worker_threads";
import {
  RightClickMenu,
  RightClickMenuItem,
  RightClickPosition,
} from "./overlay/RightClickMenu";
import {
  AdjustHeightMenu,
  AdjustMousePosition,
  AdjustHeightMenuProps
}from "./overlay/AdjustHeightMenu"
import { ScaleIcon } from "./overlay/ScaleIcon";
import { Mode } from "../enums/UI/Mode";
import { AppDispatch, RootState } from "../app/store";
import { connect } from "react-redux";
import { WallPropertyPanel } from "./overlay/panels/WallPropertyPanel";
import { RoomPropertyPanel } from "./overlay/panels/RoomPropertyPanel";
import { HolePropertyPanel } from "./overlay/panels/HolePropertyPanel";
import { PipePropertyPanel } from "./overlay/panels/PipePropertyPanel";
import { MultiPropertyPanel } from "./overlay/panels/MultiPropertyPanel";
import { TextPropertyPanel } from "./overlay/panels/TextPropertyPanel";
import { PicPropertyPanel } from "./overlay/panels/PicPropertyPanel";
import { PicAddPanel } from "./overlay/panels/PicAddPanel";
import {
  ModelAddPanel,
  ModelItem,
  ModelPrimaryCat,
} from "./overlay/panels/ModelAddPanel";
import { setMode } from "../slices/uiSlice";
import { RoomDrawingMode } from "../enums/UI/RoomDrawingMode";
import { HoleDrawingMode } from "../enums/UI/HoleDrawingMode";
import { saveAs } from "file-saver";
import { CompassIcon } from "./overlay/CompassIcon";
import { TypeFilter } from "./overlay/TypeFilter";
import { padding } from "@mui/system";
import { ModelPropertyPanel } from "./overlay/panels/ModelPropertyPanel";
import { ImportBasemapHint } from "./overlay/ImportBasemapHint";
import { isNullishCoalesce } from "typescript";
import { FortTwoTone } from "@mui/icons-material";
import * as GLINE from "../util/GuideLineHelper";
import * as DefaultValues from "../util/DefaultValues";
import { PipePattern, PipeType } from "../enums/PipeProp";
import { Console } from "console";

// TODO: Should we use react-three-fiber?

interface RenderContentProps {
  readonly mode: Mode;
  readonly roomDrawingMode: RoomDrawingMode;
  readonly holeDrawingMode: HoleDrawingMode;
  readonly setMode: (mode: Mode) => void;
  parentFloor: number;
  setFloor:(floor: number) => void;


  // 正在绘制/拾取状态
  isDrawing: boolean,
  setIsDrawing: (isDrawing: boolean)=>void

  // 正在绘制矩形/多边形房间
  setIsDrawingRoomType: (isDrawingRoomType: boolean)=>void

  // 正在绘制矩形/多边形 洞
  setIsDrawingHoleType: (isDrawingHoleType: boolean)=>void
}

export interface WallProperty {
  name: string;
  color: string;
  width?: number; // 宽度
  height?: number; // 高度
  rideHeight?: number; // 离地高度
  transparency?: number; // 透明度
}

export interface MultiProperty {
  name: string;
  rideHeight: number; // 离地高度
}

export interface RoomProperty {
  name: string;
  color: string;
  height?: number; // 高度
  rideHeight?: number; // 离地高度
  transparency?: number; // 透明度
  area?: number;
}

export interface HoleProperty {
  name: string;
  color: string;
  height?: number; // 高度
  rideHeight?: number; // 离地高度
  transparency?: number; // 透明度
  area?: number;
}

export interface TextProperty {
  content: string;
  rideHeight: number; // 离地高度
}

export interface PicProperty {
  width: number;
  height: number;
  name: string;
  rideHeight: number; // 离地高度
}

export interface ModelProperty {
  name: string;
  imgUrl: string;
  scaleX: number;
  scaleY: number;
  scaleZ: number;
  rideHeight: number;
}

export interface PipeProperty {
  name: string;
  radius: number;
  color: string;
  rideHeight: number;
  type: PipeType;
  pattern: PipePattern;
}

interface RenderContentStates {
  rightClickPosition: RightClickPosition;
  adjustHeightMenuProps: AdjustHeightMenuProps;
  showRightClickMenu: boolean;
  isEditingOnBrowser: boolean;
  selectedWallProp?: WallProperty;
  selectedRoomProp?: RoomProperty;
  selectedHoleProp?: HoleProperty;
  selectedPipeProp?: PipeProperty;
  selectedMultiProp?: MultiProperty;
  selectedTextProp?: TextProperty;
  selectedPicProp?: PicProperty;
  selectedModelProp?: ModelProperty;
  isAddingPic: boolean;
  isAddingModel: boolean;
  scaleIconProp: number;
  compassAngle: number;
  imgList: Array<{ name: string; url: string }>;
  modelDataRaw: Array<ModelPrimaryCat>;
  currentFloor: number;
  existType: any;
  // 是否展示filter的一个子条件，在编辑状态下不应该显示filter，可以将值设为false
  showFilter: boolean;
  showImportBasemapHint: boolean;
}



class RenderContent extends React.Component<
  RenderContentProps,
  RenderContentStates
> {
  // handleClick = ()=>{
  //   this.props.setFloor(1);
  // }
  private eventFunction: Array<
    [RenderingUIEvent, EventListenerOrEventListenerObject]
  > = [];

  // private canvasRef = React.createRef<HTMLCanvasElement>();
  private divRef = React.createRef<HTMLDivElement>();
  private div!: HTMLDivElement;
  // private canvas!: HTMLCanvasElement;
  private divHeight!: number;
  private divWidth!: number;

  private scene: any;
  private floors?: Array<THREE.Group>;
  private currentFloor?: THREE.Group;
  private floor2IdxDict: any = {};
  private idx2FloorDict: any = {};
  // 层高，单位米
  private floorHeight = 6;
  // private material: THREE.Material;
  private renderer!: THREE.WebGLRenderer;
  private labelRenderer!: CSS2DRenderer;

  private camera_p!: THREE.PerspectiveCamera;
  private camera_o!: THREE.OrthographicCamera;

  private controls_p!: OrbitControls;
  private controls_o!: OrbitControls;
  private transCtrl_o!: TransformControls;
  private transCtrl_p!: TransformControls;

  private is2D = true;
  private isMultiFloor = false;

  private unitLength: UnitLength;

  private origCenter = new THREE.Vector3(); // 记录物体的原始中心点，用于计算物体移动量
  private pickedObj: any = null;
  private raycaster = new THREE.Raycaster();
  private clickPoss: any = []; // 绘制多边形时记录每次点击的位置

  //一整段管道的数据结构是Group，内含直线管道和圆角的Mesh
  private tubeGroup = new THREE.Group();
  //存储管道的关键点
  private tubePoints: any = [];
  //存储每段管道的方向
  private tubeDirection: any = [];
  //存储上一段绘制的管道，方便之后读取相关数据
  private lastDrewTube = new THREE.Mesh();

  // 是否被多选，若发生多选且面板值不做改变，则多选的物体属性不随面板值而变化
  private isMultiPick: boolean = false;

  private currentFloorNum: number = 1; // 当前层数
  private tubeID: number = -1; // 单层管道都是-1 夸楼层从0累加
  private isMultiTubeDrawing: boolean = false; // 是否正在夸楼层绘制

  // 用于清除面板的点击状态
  private ModelAddPanelRef = React.createRef<any>();
  // 用于设置类型筛选器的状态
  private TypeFilterRef = React.createRef<any>();

  // 虚拟鼠标
  private GhostCursor: any = null;

  private AllVertex: any = [];
  private CatchingRadius: number = 5;
  // zoom的初始值为0.5，因此除以2
  private CatchingRadiusInit: number = 5 / 2;
  private LastClickPoint: any = null;
  private CurrentE: any;
  private CurrentD: any;
  private FootrootLineUuid: string = "a";

  // hole
  private AllHoles: any = [];
  private holeId: number = 0;
  private AllPolyHoles: any = [];


  constructor(props: RenderContentProps) {
    super(props);
    this.state = {
      rightClickPosition: { x: 10, y: 10 },
      adjustHeightMenuProps: {isVisible: false, height: -1, position: {x: 10, y: 10}},
      showRightClickMenu: false,
      isEditingOnBrowser: false,
      selectedWallProp: undefined,
      selectedRoomProp: undefined,
      selectedHoleProp: undefined,
      selectedPipeProp: undefined,
      selectedMultiProp: undefined,
      selectedTextProp: undefined,
      selectedModelProp: undefined,
      isAddingPic: false,
      isAddingModel: false,
      scaleIconProp: 0,
      compassAngle: 0,
      imgList: [],
      modelDataRaw: [],
      currentFloor: 4,  // 1楼对应的idx为4
      existType: {
        wall: false,
        room: false,
        tube: false,
        model: false,
        picture: false,
        text: false,
        ground: true,
      },
      // 是否展示filter的一个子条件，在编辑状态下不应该显示filter，可以将值设为false
      showFilter: true,
      showImportBasemapHint:true,
    };

    // 比例尺
    this.unitLength = new UnitLength();

    this.scene = MESH.initScene();

    // tube
    this.tubeID = -1;
    this.MultiTubeInfo = new Map();
  }

  setReneringUIEvent = () => {
    let eventFunction = this.eventFunction;
    eventFunction.push([RenderingUIEvent.Switch2D3D, this.switch2D_3D]);
    eventFunction.push([RenderingUIEvent.DrawWall, this.onClickDrawWall]);
    eventFunction.push([RenderingUIEvent.DrawPolRoom, this.onClickDrawPolRoom]);
    eventFunction.push([
      RenderingUIEvent.DrawPolRoomComp,
      this.onClickDrawPolRoomComp,
    ]);
    eventFunction.push([RenderingUIEvent.DrawPolHole, this.onClickDrawPolHole]);
    eventFunction.push([
      RenderingUIEvent.DrawPolHoleComp,
      this.onClickDrawPolHoleComp,
    ]);
    eventFunction.push([RenderingUIEvent.DrawTube, this.onClickDrawTube]);
    eventFunction.push([RenderingUIEvent.Pick, this.backToBrowseMode]);
    eventFunction.push([RenderingUIEvent.AddModel, this.onClickAddModel]);
    eventFunction.push([RenderingUIEvent.ChangeShape, this.onClickChangeShape]);
    eventFunction.push([
      RenderingUIEvent.ChangeShapeComp,
      this.onKeyupChangeShapeComp,
    ]);
    eventFunction.push([
      RenderingUIEvent.ChangeUnitLength,
      this.onClickChangeUnitLength,
    ]);
    // eventFunction.push([RenderingUIEvent.EditObj, this.onClickTransObj]);
    eventFunction.push([
      RenderingUIEvent.UploadBaseMap,
      this.onUploadBaseMap as any,
    ]);
    eventFunction.push([RenderingUIEvent.DrawRoom, this.onClickDrawRoom]);
    eventFunction.push([RenderingUIEvent.DrawHole, this.onClickDrawHole]);
    eventFunction.push([RenderingUIEvent.AddText, this.onClickAddText]);
    eventFunction.push([RenderingUIEvent.AddPic, this.onClickAddPic]);
    eventFunction.push([RenderingUIEvent.Save, this.onClickSave]);
    eventFunction.push([RenderingUIEvent.LoadProj, this.onUploadProj as any]);
    eventFunction.push([RenderingUIEvent.ViewRestore, this.onClickViewRestore]);
    eventFunction.push([RenderingUIEvent.ZoomIn, this.onClickZoomIn]);
    eventFunction.push([RenderingUIEvent.ZoomOut, this.onClickZoomOut]);
    eventFunction.push([RenderingUIEvent.SwitchFloor, this.switchFloor as any]);
    eventFunction.push([RenderingUIEvent.SetFloorHeight, this.onClickSetFloorHeight]);
    eventFunction.push([
      RenderingUIEvent.SwitchFloorMode,
      this.switchFloorMode,
    ]);
    eventFunction.push([RenderingUIEvent.EditFloorSlab, this.onClickEditFloorSlab]);
    eventFunction.push([RenderingUIEvent.CopyFromFloor, this.onClickCopyFromFloor]);
    eventFunction.push([
      RenderingUIEvent.SwitchBasemapMode,
      this.onClickSwitchBasemapMode,
    ]);
    eventFunction.push([RenderingUIEvent.LocateByUuid, this.onLocateByUuid as any]);
    eventFunction.push([RenderingUIEvent.SetTypeVis, this.onSetTypeVis as any]);

    for (let [key, value] of eventFunction) {
      document.addEventListener(key, value);
    }

    return () => {
      for (let [key, value] of eventFunction) {
        document.removeEventListener(key, value);
      }
    };
  };

  componentDidMount() {
    //console.log("Did Mount");

    this.setReneringUIEvent();

    const scene = this.scene;

    // this.canvas = this.canvasRef.current!;
    // this.canvasWidth = this.canvas.offsetWidth;
    // this.canvasHeight = this.canvas.offsetHeight;

    this.div = this.divRef.current!;
    this.divWidth = this.div.offsetWidth;
    this.divHeight = this.div.offsetHeight;

    // Set renderer
    var r = RENDER.init(this.divWidth, this.divHeight, document);
    const renderer = this.renderer = r.renderer;
    const labelRenderer = this.labelRenderer = r.labelRenderer;

    // Set camera and control
    var c = CAM.init(this.divWidth, this.divHeight, scene, labelRenderer);
    const camera_o = this.camera_o = c.camera_o;


    const camera_p = this.camera_p = c.camera_p;
    const controls_o = this.controls_o = c.controls_o;
    const controls_p = this.controls_p = c.controls_p;


    // set TransformControls
    this.transCtrl_o = new TransformControls(
      camera_o,
      labelRenderer.domElement
    );
    this.transCtrl_o.size = 0.5;
    // this.transCtrl.addEventListener('change', this.renderNextFrame);
    this.transCtrl_o.addEventListener("dragging-changed", (event) => {
      if (this.is2D){
        this.controls_o.enabled = !event.value;
      } 
      else this.controls_p.enabled = !event.value;

      // 等比缩放
      if (this.transCtrl_o.getMode() === "scale") {
        const scale = this.transCtrl_o.object?.scale;
        let a = scale!.x;
        let b = scale!.y;
        let c = scale!.z;
        if (a === b) {
          scale?.setX(c);
          scale?.setY(c);
        } else if (b === c) {
          scale?.setY(a);
          scale?.setZ(a);
        } else if (a === c) {
          scale?.setX(b);
          scale?.setZ(b);
        }
      }
    });

    this.transCtrl_p = new TransformControls(
      camera_p,
      labelRenderer.domElement
    );
    this.transCtrl_p.size = 0.5;
    this.transCtrl_p.addEventListener("dragging-changed", (event) => {
      if (this.is2D) this.controls_o.enabled = !event.value;
      else this.controls_p.enabled = !event.value;

      // 等比缩放
      if (this.transCtrl_p.getMode() === "scale") {
        const scale = this.transCtrl_p.object?.scale;
        let a = scale!.x;
        let b = scale!.y;
        let c = scale!.z;
        if (a === b) {
          scale?.setX(c);
          scale?.setY(c);
        } else if (b === c) {
          scale?.setY(a);
          scale?.setZ(a);
        } else if (a === c) {
          scale?.setX(b);
          scale?.setZ(b);
        }
      }
    });

    //// 将楼层放到场景中
    this.floors = [];
    for (var i = 0; i < 19; i++) {
      // -4 -3 -2 -1
      if (i < 4){
        this.floor2IdxDict["" + (i - 4)] = i;
        this.idx2FloorDict[i] = i - 4;
      }
      // 1 2 3 4 ...
      else{
        this.floor2IdxDict["" + (i - 3)] = i;
        this.idx2FloorDict[i] = i - 3;
      }
      this.floors.push(new THREE.Group());
    }

    this.currentFloor = this.floors[4]; // 1楼对应的idx为4
    scene.add(this.currentFloor);

    controls_o.addEventListener("change", (e) => {
      if (this.camera_o.zoom != 0 && this.GhostCursor){
        this.CatchingRadius = this.CatchingRadiusInit / this.camera_o.zoom;
        console.log('camera_o.zoom', this.camera_o.zoom);
        console.log('CatchingRadius', this.CatchingRadius);
        var pos = this.GhostCursor.position.clone();
        this.deleteGhostCursor();
        this.generateGhostCursor(pos);
        
      }
    });

    // set listener
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onRightClick, false);
    document.addEventListener("keydown", this.onClickEsc, false);
    document.getElementById("canvas-container")!.addEventListener("mousewheel", this.changeScaleIcon, false);
    controls_o.addEventListener("change", this.setCompassAngle);
    controls_p.addEventListener("change", this.setCompassAngle);

    // 屏蔽默认的右键菜单
    document.oncontextmenu = (e) => {
      e.preventDefault();
    };
    // 屏蔽空格
    document.onkeydown = (e) => {
      if(e.keyCode == 32) e.preventDefault();
    };

    window.onbeforeunload = e => {
      // e.preventDefault();
      e.returnValue = '离开当前页后，未保存的数据将不可恢复';
    }

    this.renderNextFrame();

    this.startPick();

    // TODO: 从后端接口获取图片数据
    const imgItems = [
      {
        name: "餐饮",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/餐饮.svg",
      },
      {
        name: "服务中心",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/服务中心.svg",
      },
      {
        name: "购物车",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/购物车.svg",
      },
      {
        name: "母婴室",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/母婴室.svg",
      },
      {
        name: "停车场",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/停车场.svg",
      },
      {
        name: "卫生间",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/卫生间.svg",
      },
      {
        name: "优先安检",
        url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/优先安检.svg",
      },
    ];
    this.setState({ imgList: imgItems });

    const modelData = [
      {
        name: "电",
        child: [
          {
            name: "应急照明",
            model: [
              {
                file: {
                  jpgFiles: [
                    {
                      name: "3d66Model-1212348-files-11.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-11.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/应急照明灯模型.obj",
                  mtlUrl: {
                    name: "消防工具模型.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/应急照明灯.mtl",
                  },
                },
                name: "应急照明灯",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/应急照明灯.png",
              },
            ],
          },
        ],
      },
      {
        name: "暖通",
        child: [
          {
            name: "空调",
            model: [
              {
                file: {
                  jpgFiles: [
                    {
                      name: "cgaxis_models_90_15_01.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/cgaxis_models_90_15_01.jpg",
                    },
                    {
                      name: "cgaxis_models_90_15_03.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/cgaxis_models_90_15_03.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/cgaxis_models_90_15_c4d.obj",
                  mtlUrl: {
                    name: "cgaxis_models_90_15_c4d.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/cgaxis_models_90_15_c4d.mtl",
                  },
                },
                name: "空调面板",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/空调面板.png",
              },
            ],
          },
        ],
      },
      {
        name: "弱电",
        child: [
          {
            name: "监控",
            model: [
              {
                file: {
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/cgaxis_models_90_04_c4d.obj",
                  mtlUrl: {
                    name: "cgaxis_models_90_04_c4d.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/cgaxis_models_90_04_c4d.mtl",
                  },
                },
                name: "监控",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/监控.png",
              },
            ],
          },
        ],
      },
      {
        name: "设施设备",
        child: [
          {
            name: "扶梯",
            model: [
              {
                file: {
                  jpgFiles: [
                    {
                      name: "cgaxis_models_107_01_01.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/cgaxis_models_107_01_01.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/cgaxis_models_107_01_c4d.obj",
                  mtlUrl: {
                    name: "cgaxis_models_107_01_c4d.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/cgaxis_models_107_01_c4d.mtl",
                  },
                },
                name: "扶梯",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/扶梯.png",
              },
            ],
          },
        ],
      },
      {
        name: "水",
        child: [
          {
            name: "给水",
            model: [
              {
                file: {
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/ind_kitbash_24.obj",
                  mtlUrl: {
                    name: "ind_kitbash_24.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/ind_kitbash_24.mtl",
                  },
                },
                name: "压力调节阀",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/压力调节阀.png",
              },
            ],
          },
        ],
      },
      {
        name: "消防",
        child: [
          {
            name: "报警系统",
            model: [
              {
                file: {
                  jpgFiles: [
                    {
                      name: "3d66Model-1212348-files-18.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-18.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/消防工具模型1.obj",
                  mtlUrl: {
                    name: "消防工具模型.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/消防工具模型1.mtl",
                  },
                },
                name: "手动报警器",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/手动报警器.png",
              },
            ],
          },
          {
            name: "灭火系统",
            model: [
              {
                file: {
                  jpgFiles: [
                    {
                      name: "3d66Model-1212348-files-4.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-4.jpg",
                    },
                    {
                      name: "3d66Model-1212348-files-6.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-6.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/消防工具模型2.obj",
                  mtlUrl: {
                    name: "消防工具模型.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/消防工具模型2.mtl",
                  },
                },
                name: "手提灭火器",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/手提灭火器.png",
              },
              {
                file: {
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/消防工具模型3.obj",
                  mtlUrl: {
                    name: "消防工具模型.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/消防工具模型3.mtl",
                  },
                },
                name: "消火栓（公用）",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/消火栓（公用）.png",
              },
              {
                file: {
                  jpgFiles: [
                    {
                      name: "3d66Model-1212348-files-1.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-1.jpg",
                    },
                    {
                      name: "3d66Model-1212348-files-4.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-4.jpg",
                    },
                    {
                      name: "3d66Model-1212348-files-6.jpg",
                      url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/jpg/3d66Model-1212348-files-6.jpg",
                    },
                  ],
                  objUrl:
                    "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/obj/消防工具模型4.obj",
                  mtlUrl: {
                    name: "消防工具模型.mtl",
                    url: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/mtl/消防工具模型4.mtl",
                  },
                },
                name: "消火栓（自用）",
                icon: "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/icon/消火栓（自用）.png",
              },
            ],
          },
        ],
      },
    ];
    this.setState({ modelDataRaw: modelData });


    
  }

  componentDidUpdate() {
    // console.log("Update");
  }

  renderNextFrame = () => {
    const renderer = this.renderer;
    const scene = this.scene;
    const camera_p = this.camera_p;
    const camera_o = this.camera_o;

    if (this.is2D) {
      renderer.render(scene, camera_o);
      this.labelRenderer.render(scene, camera_o);
    } else {
      renderer.render(scene, camera_p);
      this.labelRenderer.render(scene, camera_p);
    }
    requestAnimationFrame(this.renderNextFrame); //请求再次执行渲染函数render
  };

  floor2Idx = (floor: number) => {
    return this.floor2IdxDict["" + floor];
  };

 

  /////// 比例尺
  onClickChangeUnitLength = () => {
    if (this.unitLength!.lenthOfEachPixel !== 0) {
      var reset = window.confirm("您已经设置过比例尺，确定重新设定吗？");
      if (!reset) return;
    }
    if (!this.is2D) this.switch2D_3D();
    this.controls_o.enablePan = false;
    let originalLength = this.unitLength!.lenthOfEachPixel;
    this.unitLength!.lenthOfEachPixel = originalLength;
    document.getElementById("canvas-container")!.addEventListener(
      "mousedown",
      this.onMouseDown_SetUnitLength,
      false
    );
  };

  onMouseDown_SetUnitLength = (event: any) => {
    if (event.button !== 0) {
      // 如果不是右键点击
      return;
    }
    if (!document.getElementById("canvas-container")!.contains(event.target)) {
      // 如果点不在画布上
      return;
    }
    const unitLength = this.unitLength;
    if (!unitLength) return;
    const scene = this.scene;

    const raycaster = new THREE.Raycaster();
    // console.log("unitLen.ClickTimes" + unitLength.ClickTimes);
    let mouse = this.getMouseStdPos(event);
    raycaster.setFromCamera(mouse, this.camera_o);
    // intersects是交点数组
    const intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length < 1) {
      alert("请在底图上点击！");
      return;
    }
    unitLength.ClickTimes = (unitLength.ClickTimes + 1) % 2;
    if (unitLength.ClickTimes === 1) {
      // 第一次点击
      unitLength.firstClickpos = intersects[0].point;
      // console.log("mouse down:", unitLength.firstClickpos);
      document.getElementById("canvas-container")!.addEventListener(
        "mousedown",
        this.onMouseDown_SetUnitLength,
        false
      );
      document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_SetUnitLength, false);
    } else if (unitLength.ClickTimes === 0) {
      // 第二次点击
      // console.log(unitLength.intersects[0].point);
      this.controls_o.enablePan = true;
      var i = prompt("请输入这段距离的真实长度(m): ");
      if (i === null){
        alert("取消比例尺设置！");
      } else if (isNaN(parseFloat(i))) {
        alert("比例尺设置失败，请输入有效数字!");
      } else {
        let realLen = parseFloat(i);
        unitLength.changeUnitLenth(
          unitLength.firstClickpos,
          intersects[0].point,
          realLen
        );
        var alertStr = "成功设置单位长度，一像素点长" + unitLength.lenthOfEachPixel;
        if (unitLength.lenthOfEachPixel >= 0.3) {
          alertStr += "\n\n设置的比例尺可能太小，建议选取线段时画长一些。";
        } else if (unitLength.lenthOfEachPixel <= 0.01) {
          alertStr += "\n\n设置的比例尺可能太大，建议选取线段时画短一些。";
        }
        alert(alertStr);
      }
      removeObjectsByName(this.scene, ObjName.Drawing);
      document.getElementById("canvas-container")!.removeEventListener(
        "mousedown",
        this.onMouseDown_SetUnitLength,
        false
      );
      document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_SetUnitLength, false);

      this.changeScaleIcon();
    }
  };

  onMouseMove_SetUnitLength = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target)) {
      // 如果点不在画布上
      return;
    }
    removeObjectsByName(this.scene, ObjName.Drawing);
    let mouse = this.getMousePos(event, this.camera_o, this.scene.children);
    let mesh = MESH.createLineMesh(
      [this.unitLength?.firstClickpos, mouse],
    );
    mesh.name = ObjName.Drawing;
    this.scene.add(mesh);
  };


  ////// 设置层高
  onClickSetFloorHeight = () => {
    var realHeight = prompt("请输入层高(m): ");
    if (realHeight === null){
      return;
    } else if (isNaN(parseFloat(realHeight))) {
      alert("层高设置失败，请输入有效数字!");
    } else {
      let h = parseFloat(realHeight);
      this.floorHeight = h;
      console.log("设置层高", h);
      this.changeMultiTubeWithFloorHeight()
      if (this.isMultiFloor) {
        // 如果是多层显示，需要刷新展示界面，所以调用两次
        this.switchFloorMode();
        this.switchFloorMode();
      }
    }
  };

  ////// 按钮控制缩放
  onClickZoomIn = () => {
    const camera = this.is2D ? this.camera_o : this.camera_p;
    const control = this.is2D ? this.controls_o : this.controls_p;
    
    CAM.zoom(1.2, camera, control);
    this.changeScaleIcon();
  };

  onClickZoomOut = () => {
    const camera = this.is2D ? this.camera_o : this.camera_p;
    const control = this.is2D ? this.controls_o : this.controls_p;
    
    CAM.zoom(0.8, camera, control);
    this.changeScaleIcon();
  };

  ////// 根据uuid定位
  onLocateByUuid = (evt: CustomEvent) => {
    const uuid: string = evt.detail;
    const camera = this.is2D ? this.camera_o : this.camera_p;
    const floors = this.isMultiFloor ? this.floors! : [this.currentFloor!]
    const control = this.is2D ? this.controls_o : this.controls_p;
    CAM.locateByUuid(uuid, camera, floors, control);
  };


  /////// 设置射线时要用std pos
  getMouseStdPos = (event: any) => {
    let mouse = new THREE.Vector2();
    event.preventDefault();
    // 获取转换后的设备坐标（即屏幕标准化后的坐标从 -1 到 +1）:
    mouse.x = (event.offsetX / this.divWidth) * 2 - 1;
    mouse.y = -(event.offsetY / this.divHeight) * 2 + 1;
    return mouse;
  };

  getMousePos = (event: any, camera: any, plane: any) => {
    let std_mouse = this.getMouseStdPos(event);
    let raycaster = new THREE.Raycaster();
    // 从相机发射一条射线，经过鼠标点击位置 mouse为鼠标的二维设备坐标，camera射线起点处的相机
    raycaster.setFromCamera(std_mouse, camera);
    // intersects是交点数组
    const intersects = raycaster.intersectObjects(plane);
    // if(intersects.length == 0) return null;
    // console.log(intersects[0]);
    return intersects[0].point;
  };

  getMousePosSafe = (event: any, camera: any, plane: any) => {
    let std_mouse = this.getMouseStdPos(event);
    let raycaster = new THREE.Raycaster();
    // 从相机发射一条射线，经过鼠标点击位置 mouse为鼠标的二维设备坐标，camera射线起点处的相机
    raycaster.setFromCamera(std_mouse, camera);
    // intersects是交点数组
    const intersects = raycaster.intersectObjects(plane);
    if(intersects.length == 0) return null;
    // console.log(intersects[0]);
    return intersects[0].point;
  };


  getMousePosSafeAndRay = (event: any, camera: any, plane: any) => {
    let std_mouse = this.getMouseStdPos(event);
    let raycaster = new THREE.Raycaster();
    // 从相机发射一条射线，经过鼠标点击位置 mouse为鼠标的二维设备坐标，camera射线起点处的相机
    raycaster.setFromCamera(std_mouse, camera);
    // intersects是交点数组
    const intersects = raycaster.intersectObjects(plane);
    if(intersects.length == 0) return null;
    // console.log(intersects[0]);
    return {pnt:intersects[0].point, ray:raycaster};
  };

  private refPlane = new THREE.Mesh();


  //////// 虚拟光标
  generateGhostCursor = (pos:Vector3|null = null) => {
    const floor = this.currentFloor!;
    if (this.GhostCursor !== null) return;
    removeObjectsByName(floor, ObjName.GhostCursor);
    // removeObjectsByName(floor, ObjName.BlackPlane);

    // var geometry = new THREE.PlaneGeometry();
    // var material_p = new THREE.MeshPhongMaterial({
    //   color: 0x000000,
    //   shininess: 0,
    //   side: THREE.DoubleSide,
    //   transparent: true,
    //   opacity: 0,
    //   depthWrite: false,
    // });

    // var plane = new THREE.Mesh(geometry, material_p);
    // plane.scale.set(10000, 10000, -0.1);
    // plane.name = ObjName.BlackPlane;
    // floor.add(plane);
   
    const radius = this.CatchingRadius;
    const segments = 32;
    var circleGeometry = new THREE.CircleGeometry(radius, segments);  
    var material = new THREE.MeshBasicMaterial({ color: 0xaa0000 ,transparent: true, opacity: 0.7});
    var circleMesh = new THREE.Mesh(circleGeometry, material);
    circleMesh.name = ObjName.GhostCursor;
    circleMesh.renderOrder=99;
    circleMesh.material.depthTest=false;
    this.GhostCursor = circleMesh;
    if (pos !== null) this.GhostCursor.position.set(pos.x, pos.y, 10);
    else this.GhostCursor.position.set(-9999999, -9999999, 10);
    floor.add(this.GhostCursor);
  }

  deleteGhostCursor = () => {
    removeObjectsByName(this.currentFloor!, ObjName.GhostCursor);
    this.GhostCursor = null;
    // document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
  }


  onMouseMoveShowGhostCursor = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (event.button !== 0) return;
    const floor = this.currentFloor!;
    let rtn = this.getMousePosSafeAndRay(event, this.camera_o, floor.children);
    let mouse = null, ray = null;
    if(rtn) {
      mouse = rtn!['pnt']; 
      ray = rtn!['ray'];
    }
    if (mouse !== null){
      // var material = new THREE.MeshBasicMaterial({ color: 0xaa0000 ,transparent: true, opacity: 0.7});
      this.GhostCursor.material.opacity = 0.7;
      this.GhostCursor.position.set(mouse.x, mouse.y, 5);

      // get intersecting object and judge whether it is formal object(not temporary objects) in the scene
      let uuid = getFirstFormalObjUuid(ray!.intersectObjects(floor.children));
      // console.log(uuid);
      if (uuid != "-1") {
        GLINE.activeUuid(uuid);
      }

      if(!this.is2D){
        var material = new THREE.MeshBasicMaterial({ color: 0xaa0000 ,transparent: true, opacity: 0});
        this.GhostCursor.material = material;
      }
    } else {
      var material = new THREE.MeshBasicMaterial({ color: 0xaa0000 ,transparent: true, opacity: 0});
      this.GhostCursor.material = material;
    }
    // console.log(GLINE.AllLines);
    // console.log(this.camera_o);
  }


  onMouseMove_CatchFootroot = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if(!this.is2D) return;
    if (event.button !== 0) return;
    const floor = this.currentFloor!;
    let mouse3D = this.getMousePosSafe(event, this.camera_o, floor.children);
    if(!mouse3D) return;
    let mouse = new THREE.Vector2(mouse3D.x,mouse3D.y)

    let e = this.CurrentE;
    let d = this.CurrentD;
    
    // console.log("this.LastClickPoint", this.LastClickPoint);
    if(this.LastClickPoint !== null){
      let p  = new THREE.Vector2(this.LastClickPoint.x,
        this.LastClickPoint.y);
      let footroot = e.clone().add(d.clone().multiplyScalar(p.clone().sub(e).clone().dot(d)));
      // console.log("mouse.distanceTo(footroot)",  mouse.distanceTo(footroot));


      if(mouse.distanceTo(footroot) < this.CatchingRadius ){
        this.GhostCursor.position.set(footroot.x, footroot.y, 5);
        if(this.FootrootLineUuid == "a"){
          // draw lines
          const lineMaterial = new THREE.LineBasicMaterial( { color: 0xFF0000, linewidth: 5} );

          let pt1 = new THREE.Vector3(p.x, p.y, 5);
          let pt2 = new THREE.Vector3(footroot.x, footroot.y, 5);
          const lineGeometry = new THREE.BufferGeometry().setFromPoints([pt1, pt2]);
          const line = new THREE.Line(lineGeometry, lineMaterial);
          floor.add(line);
          this.FootrootLineUuid = line.uuid;

          document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CursorMoveOnLines, false);
        }
      }else{
        document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CursorMoveOnLines, false);
        if (this.FootrootLineUuid != "a" && floor.getObjectByProperty('uuid',this.FootrootLineUuid)) {
          floor.remove(floor.getObjectByProperty('uuid', this.FootrootLineUuid)!);
          this.FootrootLineUuid = "a";
        }
      }

    }
  }

  //////// 捕捉顶点
  onMouseMove_CatchVertex = (event: any) =>{
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if(!this.is2D) return;
    if (event.button !== 0) return;
    const floor = this.currentFloor!;
    let mouse3D = this.getMousePosSafe(event, this.camera_o, floor.children);
    if(!mouse3D) return;
    let mouse = new THREE.Vector2(mouse3D.x,mouse3D.y)
    for(var i = 0; i < this.AllVertex.length; i++){
      let testPoint = this.AllVertex[i][0];
      if(mouse.distanceTo(testPoint) < this.CatchingRadius){
        this.GhostCursor.position.set(testPoint.x, testPoint.y, 5);



      }else{

      }
    }

  }

  
  //////// 捕捉延长线 以及 垂足
  dPointLine = (a: THREE.Vector2 , d: THREE.Vector2) => {
    var v = a.clone();
    // console.log("v.cross(d)", v.cross(d));
    return v.cross(d);;
  }


  clearSupportLines = ()=>{
    const floor = this.currentFloor!;
    for(var i = 0; i < GLINE.AllLines.length; i++){
      let uuid = GLINE.AllLines[i][3];
      if(uuid!=0){
        if (floor.getObjectByProperty('uuid',uuid)) {
          floor.remove(floor.getObjectByProperty('uuid', uuid)!);
        }
      }
    }
  }
  
  removeAllVecByUuid = (uuid: string) => {
    let rm = [];
    for(let i = 0; i < this.AllVertex.length; i++) {
      if (this.AllVertex[i][1] === uuid) {
        rm.push(i);
      }
    }
    for(let i = rm.length-1; i >= 0; i--)
      this.AllVertex.splice(rm[i], 1);
  }

  onMouseMove_CursorMoveOnLines = (event: any)=>{
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if(!this.is2D) return;
    if (event.button !== 0) return;
    const floor = this.currentFloor!;
    let mouse3D = this.getMousePosSafe(event, this.camera_o, floor.children);
    if(!mouse3D) return;
    let mouse = new THREE.Vector2(mouse3D.x,mouse3D.y);

    let e = this.CurrentE;
    let d = this.CurrentD;
    let footroot = e.clone().add(d.clone().multiplyScalar(mouse.clone().sub(e).clone().dot(d)));
    this.GhostCursor.position.setX(footroot.x);
    this.GhostCursor.position.setY(footroot.y);
    // console.log("Mouse",mouse);
    // console.log("footroot", footroot);

  }
  onMouseMove_CatchLines = (event: any)=>{
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if(!this.is2D) return;
    if (event.button !== 0) return;

    const floor = this.currentFloor!;
    let mouse3D = this.getMousePosSafe(event, this.camera_o, floor.children);
    if(!mouse3D) return;
    let mouse = new THREE.Vector2(mouse3D.x,mouse3D.y)
    // console.log(GLINE.AllLines);
    var cnt = 0;
    var e1 = new THREE.Vector2();
    var d1 = new THREE.Vector2();
    var e2 = new THREE.Vector2();
    var d2 = new THREE.Vector2();



    for(var i = 0; i < GLINE.AllLines.length; i++){
      let e = GLINE.AllLines[i][0];
      let d = GLINE.AllLines[i][1];
      
      let a = mouse.clone().sub(e);
      let flag = GLINE.AllLines[i][2];
      let objUuid = GLINE.AllLines[i][4];

      if(Math.abs(this.dPointLine(a, d)) < this.CatchingRadius && flag === 0 && GLINE.isActivedUuid(objUuid)){
        cnt++;
        if(cnt == 1){
          e1 = e.clone();
          d1 = d.clone();
        }else if(cnt == 2){
          cnt = 0;
          e2 = e.clone();
          d2 = d.clone();
          let inter = getIntersectionOfLines(e1, d1, e2, d2);
          this.GhostCursor.position.setX(inter.x);
          this.GhostCursor.position.setY(inter.y);
        }
        // generator a line
        const lineMaterial = new THREE.LineBasicMaterial( { color: 0x0000ff} );
        var scale = 5000;
        let pt1 = new THREE.Vector3(e.x - scale * d.x, e.y - scale * d.y, 5);
        let pt2 = new THREE.Vector3(e.x + scale * d.x, e.y + scale * d.y, 5);
        const lineGeometry = new THREE.BufferGeometry().setFromPoints([pt1, pt2]);
        const line = new THREE.Line(lineGeometry, lineMaterial);
        // console.log("gen line");
        floor.add(line);
        GLINE.AllLines[i][2] = line.uuid;
        GLINE.AllLines[i][3] = line.uuid;
        
        this.CurrentD = d;
        this.CurrentE = e;
      
      
        // document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
        document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchFootroot, false);
        document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CursorMoveOnLines, false);
        


      }else if(Math.abs(this.dPointLine(a, d)) > this.CatchingRadius && flag !== 0){
        if (floor.getObjectByProperty('uuid',GLINE.AllLines[i][2])) {
          floor.remove(floor.getObjectByProperty('uuid', GLINE.AllLines[i][2])!);
        }
        GLINE.AllLines[i][2] = 0;
        document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchFootroot, false);
        document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CursorMoveOnLines, false);
        // document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
      }
    }
  }

  ///////// 绘制管道
  onClickDrawTube = () => {
    if (this.pickedObj !== null)
          this.cancelPickedState([this.pickedObj]);
    if (this.multiPickedObjs.length > 0)
      this.cancelPickedState(this.multiPickedObjs);
    console.log("进入绘制管道模式");
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    

    if (!this.is2D) this.switch2D_3D();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Tube, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Tube, true);

    // 进入任何模式前，先移除其他模式的listener和小面板
    if (this.props.mode !== Mode.PipeDrawing){
      this.removeSidebarEvent();
      this.setState({
        selectedPipeProp: DefaultValues.defaultTubeProp
      });
    }
      


    // 设置默认属性
    if (this.state.selectedPipeProp === undefined){
      this.setState({
        selectedPipeProp: DefaultValues.defaultTubeProp
      });
    }

    
    


    this.tubeGroup = new THREE.Group();
    
    // this.tubeGroup.userData.color = this.state.selectedPipeProp!.color;
    // this.tubeGroup.userData.radius = this.state.selectedPipeProp!.radius;
    this.tubeGroup.userData.type = ObjType.Tube;
    this.tubeGroup.userData.textureDir = 0;
    this.currentFloor!.add(this.tubeGroup);
    // this.scene.add(this.tubeGroup);

    //在增加管道垂直关键点时，可能光线投射无法穿过场景中的任何物体，
    //因此增加一个垂直的平面以保证可以获取到鼠标点击位置
    this.refPlane = this.createRefPlane();

    this.currentFloor!.add(this.refPlane);

    this.tubePoints = [];
    this.tubeDirection = [];

    
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.addEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.addEventListener("keydown", this.onKeydown_Switch2D_3D, false);

    this.generateGhostCursor();
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchLines, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchVertex, false);
  };

  SwitchSupportLineOpacity = () => {
    var lineMaterial : any;
    if(!this.is2D){
      // 切成3D 辅助线改为透明
      lineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff, opacity: 0, transparent: true });
    }else{
      // 切成2D 辅助线不透明
      lineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff});
    }
    for(let i = 0; i < GLINE.AllLines.length; i++){
      var line = this.currentFloor!.getObjectByProperty('uuid',GLINE.AllLines[i][3]);
      // console.log(line);
      if(line instanceof THREE.Line){
        // console.log("is instance of line");
        line.material = lineMaterial;
      }
    }
  }

  onKeydown_Switch2D_3D = (event: any) => {
    //D
    if (event.keyCode === 68){
      this.switch2D_3D();
      this.SwitchSupportLineOpacity();
    } 
  };

  createRefPlane = (isGround=false) => {
    var geometry = new THREE.PlaneGeometry();
    var material = new THREE.MeshPhongMaterial({
      color: 0xaaaaaa,
      shininess: 80,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0,
      depthWrite: false,
    });

    var plane = new THREE.Mesh(geometry, material);
    if (!isGround){
      var dir = new THREE.Vector3(1, 0, 0);
      plane.setRotationFromAxisAngle(dir, Math.PI/2);
    }
    plane.scale.set(10000, 10000, 1);

    return plane;
  };

  // onMouseDown_DrawTube = (event: any) => {
  //   if (!document.getElementById("canvas-container")!.contains(event.target))
  //     return;

  //   this.tubeGroup.userData.color = this.state.selectedPipeProp!.color;
  //   this.tubeGroup.userData.pipeType = this.state.selectedPipeProp!.type;
  //   this.tubeGroup.userData.name = this.state.selectedPipeProp!.name;
  //   this.tubeGroup.userData.type = ObjType.Tube;
  //   // if(this.tubeGroup.userData.pipeType === PipeType.others){

  //   // }else{
  //   //   this.tubeGroup.userData.color = "#ffffff";
  //   // }
  //   //鼠标左键
  //   if (event.button === 0) {
  //     // console.log('this.isMultiTubeDrawing',this.isMultiTubeDrawing);
  //     if(!this.isMultiTubeDrawing) this.tubeGroup.userData.tubeID = -1;
  //     else this.tubeGroup.userData.tubeID = this.tubeID;
  //     //本次点击的新关键点
  //     var newPoint = new THREE.Vector3();
  //     //本次增加关键点相对于上一个关键点的方向
  //     var dir2 = new THREE.Vector3();

  //     //已有至少一个关键点时，便可以根据新增加的关键点绘制新的管道
  //     if (this.tubePoints.length > 0) {
  //       var lastIndex = this.tubePoints.length - 1;
  //       //获取到上一个关键点的方向
  //       var dir1 = this.tubeDirection[lastIndex];
  //       //获取到上一个关键点本身
  //       var lastClickPoint = this.tubePoints[lastIndex];
  //       var isExtend = 0;
  //       //处于2D视角下时，管道只能增加水平方向的关键点
  //       if (this.is2D) {
  //         //获取新关键点的位置
  //         newPoint = this.getMousePos(
  //           event,
  //           this.camera_o,
  //           this.currentFloor!.children
  //         );
  //         newPoint.setZ(lastClickPoint.z);

  //         // 吸附
  //         // 首先计算夹角
  //         dir2.setX(newPoint.x - lastClickPoint.x);
  //         dir2.setY(newPoint.y - lastClickPoint.y);
  //         dir2.setZ(newPoint.z - lastClickPoint.z);
  //         dir2.normalize();
  //         dir1.normalize();
  //         if(dir1.z === 0){
  //           // 只有前一根是横着的才需要吸附
  //           var cos_theta = dir2.dot(dir1);
  //           console.log("cos_theta",cos_theta);
  //           if(this.tubePoints.length === 1){
  
  //           }else{
  //             if(cos_theta > Math.sqrt(2) / 2 && cos_theta < 1){
  //               // 应当延长上一根管道的情况
  //               // 吸附点击位置到延长线上（垂直吸附过去）
  //               let dx = dir1.x;
  //               let dy = dir1.y;
  //               let x0 = lastClickPoint.x;
  //               let y0 = lastClickPoint.y;
  //               let x1 = newPoint.x;
  //               let y1 = newPoint.y;
  //               let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //               newPoint.setX(x0 + dx * t);
  //               newPoint.setY(y0 + dy * t); 

  //               isExtend = 1;
  //             }else{
  //               // 应当与上根管道垂直的情况
  //               if(dir2.cross(dir1).z > 0){
  //                 let dx = -dir1.y;
  //                 let dy = dir1.x;
  //                 let x0 = lastClickPoint.x;
  //                 let y0 = lastClickPoint.y;
  //                 let x1 = newPoint.x;
  //                 let y1 = newPoint.y;
  //                 let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //                 newPoint.setX(x0 + dx * t);
  //                 newPoint.setY(y0 + dy * t); 
  //               }else{
  //                 let dx = dir1.y;
  //                 let dy = -dir1.x;
  //                 let x0 = lastClickPoint.x;
  //                 let y0 = lastClickPoint.y;
  //                 let x1 = newPoint.x;
  //                 let y1 = newPoint.y;
  //                 let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //                 newPoint.setX(x0 + dx * t);
  //                 newPoint.setY(y0 + dy * t); 
  //               }
  //             }
  //           }
          
  //           // 更新dir2
  //           dir2.setX(newPoint.x - lastClickPoint.x);
  //           dir2.setY(newPoint.y - lastClickPoint.y);
  //           dir2.setZ(newPoint.z - lastClickPoint.z);
  //           dir2.normalize();
  //         }
          
  //       }else {
  //         //处于3D视角下时，管道只能增加垂直方向的关键点
  //         newPoint = this.getMousePos(
  //           event,
  //           this.camera_p,
  //           this.currentFloor!.children
  //         );

  //         //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
  //         newPoint.setX(lastClickPoint.x);
  //         newPoint.setY(lastClickPoint.y);
  //         let diff_Z = newPoint.z - lastClickPoint.z;

  //         //垂直方向新关键点的方向只需判断z轴即可
  //         dir2.setX(0);
  //         dir2.setY(0);
  //         dir2.setZ(diff_Z);
  //         dir2.normalize();
  //       }

  //       if (this.tubePoints.length > 1 && isExtend === 0) {
  //         //增加圆角Mesh
  //         var filletMesh = MESH.createOneFilletMesh(
  //           dir1,
  //           dir2,
  //           lastClickPoint,
  //           this.tubeGroup.userData.color,
  //           Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
  //           this.tubeGroup.userData.pipeType
  //         );
  //         filletMesh.userData.type = ObjType.Fillet;
  //         filletMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
  //         this.tubeGroup?.add(filletMesh);
  //       }

  //       //增加管道Mesh
  //       var tubeMesh = MESH.createOneTubeMesh(
  //         dir1,
  //         dir2,
  //         lastClickPoint,
  //         newPoint,
  //         this.tubeGroup.userData.color,
  //         Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
  //         this.tubeGroup.userData.pipeType,
  //         this.tubeGroup.userData.textureDir
  //       );
        
  //       tubeMesh.userData.type = ObjType.Tube;
  //       tubeMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
  //       this.tubeGroup.userData.radius = tubeMesh.userData.radius;


  //       this.tubeGroup?.add(tubeMesh);

  //       //每个管道的userData中有三个方向：
  //       //stDir上一段管道的方向
  //       //edDir本段管道的方向
  //       //nxDir下一段管道的方向
  //       this.lastDrewTube.userData.nxDir = dir2;
  //       this.lastDrewTube = tubeMesh;

  //       this.tubePoints.push(newPoint);
  //       this.tubeDirection.push(dir2);

  //       //将参考平面设置在上一个关键点的位置，减少鼠标点击位置与视觉上点击位置的误差
  //       this.refPlane.position.set(this.tubePoints[this.tubePoints.length - 1].x, 
  //                                 this.tubePoints[this.tubePoints.length - 1].y,
  //                                  0);

  //     } else {
  //       //第一个关键点只能在2D视角下增加
  //       if (!this.is2D) this.switch2D_3D();

  //       //获取到第一个关键点的位置
  //       newPoint = this.getMousePos(event, this.camera_o, this.currentFloor!.children);
  //       newPoint.setZ(this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel);

  //       //第一个关键点的方向为(0，0，0)
  //       var dir = new THREE.Vector3(0, 0, 0);

  //       this.tubeDirection.push(dir);
  //       this.tubePoints.push(newPoint);

  //       // 离地高度userdata
  //       this.tubeGroup.userData.height = this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel;
  //     }
  //   }
  // };

  onMouseMove_DrawTube = (event: any) =>{
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (event.button !== 0) return;

    const floor = this.currentFloor!;

    removeObjectsByName(floor, ObjName.DrawingTube);
    var newPoint;
    if(this.is2D){
      newPoint = this.getMousePos(
        event, 
        this.camera_o, 
        floor.children);
    }else{
      // 3D case
      newPoint = this.getMousePos(
        event,
        this.camera_p,
        this.currentFloor!.children
      );
    }
    

    removeObjectsByName(floor, ObjName.DrawingTube);

    var lastIndex = this.tubePoints.length - 1;
    var dir1 = this.tubeDirection[lastIndex];
    var lastClickPoint = this.tubePoints[lastIndex];
    if(!this.is2D){
      // 3D case
      newPoint.setX(lastClickPoint.x);
      newPoint.setY(lastClickPoint.y);
    }else{
      // 2D case
      var eps = 3.2 * this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel;
      var dist = Math.abs(newPoint.x - lastClickPoint.x)
                + Math.abs(newPoint.y - lastClickPoint.y)
      if(dist < eps) return;
      newPoint.setZ(lastClickPoint.z);
    }
    var dir2 = new THREE.Vector3();
    dir2.setX(newPoint.x - lastClickPoint.x);
    dir2.setY(newPoint.y - lastClickPoint.y);
    dir2.setZ(newPoint.z - lastClickPoint.z);
    dir2.normalize();
    dir1.normalize();

    if (this.tubePoints.length > 1) {
      var filletMesh = MESH.createOneFilletMesh(
        dir1,
        dir2,
        lastClickPoint,
        this.tubeGroup.userData.color,
        Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
        this.tubeGroup.userData.pipeType
      );
      filletMesh.name = ObjName.DrawingTube;
      floor.add(filletMesh);
    }

    var tubeMesh = MESH.createOneTubeMesh(
      dir1,
      dir2,
      lastClickPoint,
      newPoint,
      this.tubeGroup.userData.color,
      Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    tubeMesh.name = ObjName.DrawingTube;
    floor.add(tubeMesh);

  };

  onMouseDown_DrawTube = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    this.tubeGroup.userData.color = this.state.selectedPipeProp!.color;
    this.tubeGroup.userData.pipeType = this.state.selectedPipeProp!.type;
    this.tubeGroup.userData.name = this.state.selectedPipeProp!.name;
    this.tubeGroup.userData.type = ObjType.Tube;
    // if(this.tubeGroup.userData.pipeType === PipeType.others){

    // }else{
    //   this.tubeGroup.userData.color = "#ffffff";
    // }
    //鼠标左键
    if (event.button === 0) {
      removeObjectsByName(this.currentFloor!, ObjName.DrawingTube);
      // console.log('this.isMultiTubeDrawing',this.isMultiTubeDrawing);
      if(!this.isMultiTubeDrawing) this.tubeGroup.userData.tubeID = -1;
      else this.tubeGroup.userData.tubeID = this.tubeID;
      //本次点击的新关键点
      var newPoint = new THREE.Vector3();
      //本次增加关键点相对于上一个关键点的方向
      var dir2 = new THREE.Vector3();

      //已有至少一个关键点时，便可以根据新增加的关键点绘制新的管道
      if (this.tubePoints.length > 0) {
        var lastIndex = this.tubePoints.length - 1;
        //获取到上一个关键点的方向
        var dir1 = this.tubeDirection[lastIndex];
        //获取到上一个关键点本身
        var lastClickPoint = this.tubePoints[lastIndex];
        var isExtend = 0;
        //处于2D视角下时，管道只能增加水平方向的关键点
        if (this.is2D) {
          //获取新关键点的位置
          // newPoint = this.getMousePos(
          //   event,
          //   this.camera_o,
          //   this.currentFloor!.children
          // );
          newPoint.setX(this.GhostCursor.position.x);
          newPoint.setY(this.GhostCursor.position.y);
          newPoint.setZ(lastClickPoint.z);

          var eps = 3.2 * this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel;
          var dist = Math.abs(newPoint.x - lastClickPoint.x)
                + Math.abs(newPoint.y - lastClickPoint.y)
          if(dist < eps) return;


          if(event.ctrlKey === true){
            // 吸附
            // 首先计算夹角
            dir2.setX(newPoint.x - lastClickPoint.x);
            dir2.setY(newPoint.y - lastClickPoint.y);
            dir2.setZ(newPoint.z - lastClickPoint.z);
            dir2.normalize();
            dir1.normalize();
            if(dir1.z === 0){
              // 只有前一根是横着的才需要吸附
              var cos_theta = dir2.dot(dir1);
              console.log("cos_theta",cos_theta);
              if(this.tubePoints.length === 1){
    
              }else{
                if(cos_theta > Math.sqrt(2) / 2 && cos_theta < 1){
                  // 应当延长上一根管道的情况
                  // 吸附点击位置到延长线上（垂直吸附过去）
                  let dx = dir1.x;
                  let dy = dir1.y;
                  let x0 = lastClickPoint.x;
                  let y0 = lastClickPoint.y;
                  let x1 = newPoint.x;
                  let y1 = newPoint.y;
                  let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
                  newPoint.setX(x0 + dx * t);
                  newPoint.setY(y0 + dy * t); 

                  isExtend = 1;
                }else{
                  // 应当与上根管道垂直的情况
                  if(dir2.cross(dir1).z > 0){
                    let dx = -dir1.y;
                    let dy = dir1.x;
                    let x0 = lastClickPoint.x;
                    let y0 = lastClickPoint.y;
                    let x1 = newPoint.x;
                    let y1 = newPoint.y;
                    let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
                    newPoint.setX(x0 + dx * t);
                    newPoint.setY(y0 + dy * t); 
                  }else{
                    let dx = dir1.y;
                    let dy = -dir1.x;
                    let x0 = lastClickPoint.x;
                    let y0 = lastClickPoint.y;
                    let x1 = newPoint.x;
                    let y1 = newPoint.y;
                    let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
                    newPoint.setX(x0 + dx * t);
                    newPoint.setY(y0 + dy * t); 
                  }
                }
              }
            
              // 更新dir2
              dir2.setX(newPoint.x - lastClickPoint.x);
              dir2.setY(newPoint.y - lastClickPoint.y);
              dir2.setZ(newPoint.z - lastClickPoint.z);
              dir2.normalize();
            }
          }else if(event.shiftKey === true){
            var dx = newPoint.x - lastClickPoint.x;
            var dy = newPoint.y - lastClickPoint.y;
            if(Math.abs(dx) < Math.abs(dy)){
              newPoint.setX(lastClickPoint.x);
            }else{
              newPoint.setY(lastClickPoint.y);
            }
            dir2.setX(newPoint.x - lastClickPoint.x);
            dir2.setY(newPoint.y - lastClickPoint.y);
            dir2.setZ(newPoint.z - lastClickPoint.z);
            dir2.normalize();
            dir1.normalize();
          }else{
            dir2.setX(newPoint.x - lastClickPoint.x);
            dir2.setY(newPoint.y - lastClickPoint.y);
            dir2.setZ(newPoint.z - lastClickPoint.z);
            dir2.normalize();
            dir1.normalize();
          }
          
          
        }else {
          //处于3D视角下时，管道只能增加垂直方向的关键点
          newPoint = this.getMousePos(
            event,
            this.camera_p,
            this.currentFloor!.children
          );

          //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
          newPoint.setX(lastClickPoint.x);
          newPoint.setY(lastClickPoint.y);
          let diff_Z = newPoint.z - lastClickPoint.z;

          //垂直方向新关键点的方向只需判断z轴即可
          dir2.setX(0);
          dir2.setY(0);
          dir2.setZ(diff_Z);
          dir2.normalize();
        }

        if (this.tubePoints.length > 1 && isExtend === 0) {
          //增加圆角Mesh
          var filletMesh = MESH.createOneFilletMesh(
            dir1,
            dir2,
            lastClickPoint,
            this.tubeGroup.userData.color,
            Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
            this.tubeGroup.userData.pipeType
          );
          filletMesh.userData.type = ObjType.Fillet;
          filletMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
          this.tubeGroup?.add(filletMesh);
        }

        //增加管道Mesh
        var tubeMesh = MESH.createOneTubeMesh(
          dir1,
          dir2,
          lastClickPoint,
          newPoint,
          this.tubeGroup.userData.color,
          Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
          this.tubeGroup.userData.pipeType,
          this.tubeGroup.userData.textureDir
        );
        // add info to all lines, all vertex
        if(this.is2D){
          // TODO: AllLine modify
          GLINE.AllLines.push([
            new THREE.Vector2(lastClickPoint.x, lastClickPoint.y), 
            new THREE.Vector2(dir2.x, dir2.y),
            0,
            0,
            tubeMesh.uuid]
            );  
            // TODO
          this.AllVertex.push(
            [new THREE.Vector2(newPoint.x, newPoint.y), tubeMesh.uuid]
          )
        }
        this.LastClickPoint = newPoint;
        // console.log(GLINE.AllLines);

        tubeMesh.userData.type = ObjType.Tube;
        tubeMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
        this.tubeGroup.userData.radius = tubeMesh.userData.radius;


        this.tubeGroup?.add(tubeMesh);

        //每个管道的userData中有三个方向：
        //stDir上一段管道的方向
        //edDir本段管道的方向
        //nxDir下一段管道的方向
        this.lastDrewTube.userData.nxDir = dir2;
        this.lastDrewTube = tubeMesh;

        this.tubePoints.push(newPoint);
        this.tubeDirection.push(dir2);

        //将参考平面设置在上一个关键点的位置，减少鼠标点击位置与视觉上点击位置的误差
        this.refPlane.position.set(this.tubePoints[this.tubePoints.length - 1].x, 
                                  this.tubePoints[this.tubePoints.length - 1].y,
                                   0);

      } else {
        //第一个关键点只能在2D视角下增加
        if (!this.is2D) this.switch2D_3D();

        //获取到第一个关键点的位置
        // newPoint = this.getMousePos(event, this.camera_o, this.currentFloor!.children);
        newPoint.setX(this.GhostCursor.position.x);
        newPoint.setY(this.GhostCursor.position.y);
        newPoint.setZ(this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel);

        // add info to all vertex
        // TODO: add uuid
        this.AllVertex.push(
          [new THREE.Vector2(newPoint.x, newPoint.y), "tubeMesh.userData.uuid"]
        )
        this.LastClickPoint = newPoint;

        //第一个关键点的方向为(0，0，0)
        var dir = new THREE.Vector3(0, 0, 0);

        this.tubeDirection.push(dir);
        this.tubePoints.push(newPoint);

        // 更新参考面位置
        this.refPlane.position.set(this.tubePoints[this.tubePoints.length - 1].x, 
          this.tubePoints[this.tubePoints.length - 1].y,
           0);


        // 离地高度userdata
        this.tubeGroup.userData.height = this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel;

        // 监听移动
        document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_DrawTube, false);
      }
    }
  };

  // onMouseDown_DrawTube = (event: any) => {
  //   if (!document.getElementById("canvas-container")!.contains(event.target))
  //     return;

  //   this.tubeGroup.userData.color = this.state.selectedPipeProp!.color;
  //   this.tubeGroup.userData.pipeType = this.state.selectedPipeProp!.type;
  //   this.tubeGroup.userData.name = this.state.selectedPipeProp!.name;
  //   this.tubeGroup.userData.type = ObjType.Tube;
  //   // if(this.tubeGroup.userData.pipeType === PipeType.others){

  //   // }else{
  //   //   this.tubeGroup.userData.color = "#ffffff";
  //   // }
  //   //鼠标左键
  //   if (event.button === 0) {
  //     // console.log('this.isMultiTubeDrawing',this.isMultiTubeDrawing);
  //     if(!this.isMultiTubeDrawing) this.tubeGroup.userData.tubeID = -1;
  //     else this.tubeGroup.userData.tubeID = this.tubeID;
  //     //本次点击的新关键点
  //     var newPoint = new THREE.Vector3();
  //     //本次增加关键点相对于上一个关键点的方向
  //     var dir2 = new THREE.Vector3();

  //     //已有至少一个关键点时，便可以根据新增加的关键点绘制新的管道
  //     if (this.tubePoints.length > 0) {
  //       var lastIndex = this.tubePoints.length - 1;
  //       //获取到上一个关键点的方向
  //       var dir1 = this.tubeDirection[lastIndex];
  //       //获取到上一个关键点本身
  //       var lastClickPoint = this.tubePoints[lastIndex];
  //       var isExtend = 0;
  //       //处于2D视角下时，管道只能增加水平方向的关键点
  //       if (this.is2D) {
  //         //获取新关键点的位置
  //         newPoint = this.getMousePos(
  //           event,
  //           this.camera_o,
  //           this.currentFloor!.children
  //         );
  //         newPoint.setZ(lastClickPoint.z);

  //         if(event.shiftKey === true){
  //           // 吸附
  //           // 首先计算夹角
  //           dir2.setX(newPoint.x - lastClickPoint.x);
  //           dir2.setY(newPoint.y - lastClickPoint.y);
  //           dir2.setZ(newPoint.z - lastClickPoint.z);
  //           dir2.normalize();
  //           dir1.normalize();
  //           if(dir1.z === 0){
  //             // 只有前一根是横着的才需要吸附
  //             var cos_theta = dir2.dot(dir1);
  //             console.log("cos_theta",cos_theta);
  //             if(this.tubePoints.length === 1){
    
  //             }else{
  //               if(cos_theta > Math.sqrt(2) / 2 && cos_theta < 1){
  //                 // 应当延长上一根管道的情况
  //                 // 吸附点击位置到延长线上（垂直吸附过去）
  //                 let dx = dir1.x;
  //                 let dy = dir1.y;
  //                 let x0 = lastClickPoint.x;
  //                 let y0 = lastClickPoint.y;
  //                 let x1 = newPoint.x;
  //                 let y1 = newPoint.y;
  //                 let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //                 newPoint.setX(x0 + dx * t);
  //                 newPoint.setY(y0 + dy * t); 

  //                 isExtend = 1;
  //               }else{
  //                 // 应当与上根管道垂直的情况
  //                 if(dir2.cross(dir1).z > 0){
  //                   let dx = -dir1.y;
  //                   let dy = dir1.x;
  //                   let x0 = lastClickPoint.x;
  //                   let y0 = lastClickPoint.y;
  //                   let x1 = newPoint.x;
  //                   let y1 = newPoint.y;
  //                   let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //                   newPoint.setX(x0 + dx * t);
  //                   newPoint.setY(y0 + dy * t); 
  //                 }else{
  //                   let dx = dir1.y;
  //                   let dy = -dir1.x;
  //                   let x0 = lastClickPoint.x;
  //                   let y0 = lastClickPoint.y;
  //                   let x1 = newPoint.x;
  //                   let y1 = newPoint.y;
  //                   let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
  //                   newPoint.setX(x0 + dx * t);
  //                   newPoint.setY(y0 + dy * t); 
  //                 }
  //               }
  //             }
            
  //             // 更新dir2
  //             dir2.setX(newPoint.x - lastClickPoint.x);
  //             dir2.setY(newPoint.y - lastClickPoint.y);
  //             dir2.setZ(newPoint.z - lastClickPoint.z);
  //             dir2.normalize();
  //           }
  //         }else{
  //           dir2.setX(newPoint.x - lastClickPoint.x);
  //           dir2.setY(newPoint.y - lastClickPoint.y);
  //           dir2.setZ(newPoint.z - lastClickPoint.z);
  //           dir2.normalize();
  //           dir1.normalize();
  //         }
          
          
  //       }else {
  //         //处于3D视角下时，管道只能增加垂直方向的关键点
  //         newPoint = this.getMousePos(
  //           event,
  //           this.camera_p,
  //           this.currentFloor!.children
  //         );

  //         //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
  //         newPoint.setX(lastClickPoint.x);
  //         newPoint.setY(lastClickPoint.y);
  //         let diff_Z = newPoint.z - lastClickPoint.z;

  //         //垂直方向新关键点的方向只需判断z轴即可
  //         dir2.setX(0);
  //         dir2.setY(0);
  //         dir2.setZ(diff_Z);
  //         dir2.normalize();
  //       }

  //       if (this.tubePoints.length > 1 && isExtend === 0) {
  //         //增加圆角Mesh
  //         var filletMesh = MESH.createOneFilletMesh(
  //           dir1,
  //           dir2,
  //           lastClickPoint,
  //           this.tubeGroup.userData.color,
  //           Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
  //           this.tubeGroup.userData.pipeType
  //         );
  //         filletMesh.userData.type = ObjType.Fillet;
  //         filletMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
  //         this.tubeGroup?.add(filletMesh);
  //       }

  //       //增加管道Mesh
  //       var tubeMesh = MESH.createOneTubeMesh(
  //         dir1,
  //         dir2,
  //         lastClickPoint,
  //         newPoint,
  //         this.tubeGroup.userData.color,
  //         Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2)),
  //         this.tubeGroup.userData.pipeType,
  //         this.tubeGroup.userData.textureDir
  //       );
        
  //       tubeMesh.userData.type = ObjType.Tube;
  //       tubeMesh.userData.radius = Number((this.state.selectedPipeProp!.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
  //       this.tubeGroup.userData.radius = tubeMesh.userData.radius;


  //       this.tubeGroup?.add(tubeMesh);

  //       //每个管道的userData中有三个方向：
  //       //stDir上一段管道的方向
  //       //edDir本段管道的方向
  //       //nxDir下一段管道的方向
  //       this.lastDrewTube.userData.nxDir = dir2;
  //       this.lastDrewTube = tubeMesh;

  //       this.tubePoints.push(newPoint);
  //       this.tubeDirection.push(dir2);

  //       //将参考平面设置在上一个关键点的位置，减少鼠标点击位置与视觉上点击位置的误差
  //       this.refPlane.position.set(this.tubePoints[this.tubePoints.length - 1].x, 
  //                                 this.tubePoints[this.tubePoints.length - 1].y,
  //                                  0);

  //     } else {
  //       //第一个关键点只能在2D视角下增加
  //       if (!this.is2D) this.switch2D_3D();

  //       //获取到第一个关键点的位置
  //       newPoint = this.getMousePos(event, this.camera_o, this.currentFloor!.children);
  //       newPoint.setZ(this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel);

  //       //第一个关键点的方向为(0，0，0)
  //       var dir = new THREE.Vector3(0, 0, 0);

  //       this.tubeDirection.push(dir);
  //       this.tubePoints.push(newPoint);

  //       // 离地高度userdata
  //       this.tubeGroup.userData.height = this.state.selectedPipeProp!.rideHeight / this.unitLength!.lenthOfEachPixel;
  //     }
  //   }
  // };

  drawTubeComp = () => {
    

    var dir = new THREE.Vector3(0, 0, 0);
    this.lastDrewTube.userData.nxDir = dir;

    //完成管道绘制后，设置一个管道的包围盒，再计算包围盒的中心作为管道的中心
    var tubeBox = new THREE.Box3().setFromObject(this.tubeGroup, true);
    this.tubeGroup.userData.center = new Vector3();
    tubeBox.getCenter(this.tubeGroup.userData.center);

    var i = 0;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;

    // 最后一根管道的终点需要在实际位置，所以向edDir方向延伸 2 * radius距离
    var tempTube  = this.tubeGroup.children[tubeNumber - 1];
    var oldPoint = tempTube.userData.edPoint;
    var times = 2;
    var radius = this.tubeGroup.userData.radius;
    var newPoint = new THREE.Vector3(
      oldPoint.x + times* radius * tempTube.userData.edDir.x ,
      oldPoint.y + times* radius * tempTube.userData.edDir.y ,
      oldPoint.z + times* radius * tempTube.userData.edDir.z 
    );
    var newTube = MESH.createOneTubeMesh(
      tempTube.userData.stDir,
      tempTube.userData.edDir,
      tempTube.userData.stPoint,
      newPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    for(let j = 0; j < GLINE.AllLines.length; j++){
      if(GLINE.AllLines[j][4] === tempTube.uuid){
        console.log("Find");
        GLINE.AllLines[j][4] = newTube.uuid;
      }
    }
    this.tubeGroup.remove(tempTube);
    this.tubeGroup.add(newTube);


    //所有的管道和圆角Mesh的Geometry都要向管道中心的反方向平移
    //以避免在将管道的位置设置为其中心时，管道整体发生偏移
    for (i = 0; i < tubeNumber; i++) {
      oneTube = this.tubeGroup.children[i];
      oneTube.geometry.translate(
        -this.tubeGroup.userData.center.x,
        -this.tubeGroup.userData.center.y,
        -this.tubeGroup.userData.center.z
      );
    }

    //将管道的位置设置为其中心时
    this.tubeGroup.position.set(
      this.tubeGroup.userData.center.x,
      this.tubeGroup.userData.center.y,
      this.tubeGroup.userData.center.z
    );

    // 跨楼层
    this.tubeGroup.userData.floor = this.currentFloorNum;


    // 如果只有一根竖直的

    // const scene = this.scene;
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.DrawingTube);
    floor.remove(this.refPlane);
    this.tubeDirection = [];
    this.tubePoints = [];
    this.isMultiTubeDrawing = false;

    // document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onClickDrawTube, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_DrawTube, false);
    document.removeEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.removeEventListener("keydown", this.onKeydown_Switch2D_3D, false);
    
    // this.deleteGhostCursor();
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);

    
  };

  onKeydown_DrawTubeComp = (event: any) => {
    if (event.keyCode === 13) {
      //enter
      this.drawTubeComp();
    }
  };

  redrawTube = () => {
    // 清除可能的参考线
    this.clearSupportLines();
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.DrawingTube);
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;

    for (var i = tubeNumber - 1; i >= 0; i--) {
      oneTube = this.tubeGroup.children[i];
      // TODO: AllLine modify
      for(var j = GLINE.AllLines.length - 1; j >= 0; j--){
        if(oneTube.uuid === GLINE.AllLines[j][4]){
          GLINE.AllLines.splice(j, 1);
        }
      }
      this.tubeGroup.remove(oneTube);
    }


    // console.log(GLINE.AllLines);

    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.removeEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.removeEventListener("keydown", this.onKeydown_Switch2D_3D, false);
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawTube,
      false
    );
    this.currentFloor!.remove(this.refPlane);

    this.tubePoints = [];
    this.tubeDirection = [];
    this.setState({
      showRightClickMenu: false,
    });
  };

  redrawTube_multiFloor = () => {
    var tubeID = this.tubeGroup.userData.tubeID;
    this.redrawTube();
    this.deleteOneMultiTubeByTubeID(tubeID);
  }

  backOneStepTube = () => {
    var oneTubeUuid: any;
    let tubeNumber = this.tubeGroup.children.length;
    const floor = this.currentFloor!;
    if (tubeNumber == 1) {
      oneTubeUuid = this.tubeGroup.children[0].uuid;
      this.tubeGroup.remove(this.tubeGroup.children[0]);
      // clear
      this.tubePoints.splice(0);
      this.tubeDirection.splice(0);
      document.getElementById("canvas-container")!.removeEventListener(
        "mousemove",
        this.onMouseMove_DrawTube,
        false
      );
    } else {
      oneTubeUuid = this.tubeGroup.children[tubeNumber - 1].uuid;
      this.tubeGroup.remove(this.tubeGroup.children[tubeNumber - 1]);
      this.tubeGroup.remove(this.tubeGroup.children[tubeNumber - 2]);
      tubeNumber -= 2;
      this.tubePoints.pop();
      this.tubeDirection.pop();
    }
    
    for(var j = GLINE.AllLines.length - 1; j >= 0; j--){
      if(oneTubeUuid === GLINE.AllLines[j][4]){
        let uuid = GLINE.AllLines[j][3];
        if (floor.getObjectByProperty('uuid',uuid)) {
          floor.remove(floor.getObjectByProperty('uuid', uuid)!);
        }
        GLINE.AllLines.splice(j, 1);
      }
    }
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMoveShowGhostCursor,
      false
    );
    document.getElementById("canvas-container")!.addEventListener(
      "mousemove", 
      this.onMouseMoveShowGhostCursor, 
      false);
    
    // console.log("tubeNumber",this.tubeGroup.children.length);
    this.setState({
      showRightClickMenu: false,
    });

    removeObjectsByName(floor, ObjName.DrawingTube);
  };

  tubeSwitchFloor = (floor:number)=>{
    // 自动跳转到所选楼层的2D平面视角
    const scene = this.scene;
    const newFloor = floor;

    // 当前楼层号
    this.currentFloorNum = newFloor;

    console.log('switchFloor', newFloor);

    hideLabel([this.currentFloor!]);

    if (this.is2D) {
      this.renderer.render(scene, this.camera_o);
      this.labelRenderer.render(scene, this.camera_o);
    } else {
      this.renderer.render(scene, this.camera_p);
      this.labelRenderer.render(scene, this.camera_p);
    }

    scene.remove(this.currentFloor!);
    this.currentFloor = this.floors![this.floor2Idx(newFloor)];

    showLabel([this.currentFloor]);

    scene.add(this.currentFloor);

    this.props.setFloor(floor);
  }

  // addMultiTubeID = (tubes:any, ID:number)=>{
  //   tubes.userData.multiTubeID = ID;
  // }

  mutilFloorTubeDrawing = (floor: number)=>{
    // 弹窗输入楼层
    var inputFloor = prompt("请输入楼层: ");

    if(inputFloor === null){
      this.setState({
        showRightClickMenu: false,
      });
      alert("取消夸楼层绘制！");
      return;
    }else if(isNaN(parseFloat(inputFloor)) || !Number.isInteger(Number(inputFloor))){
      this.setState({
        showRightClickMenu: false,
      });
      alert("夸楼层失败，请输入有效整数!");
      return;
    }
    // 判断目标楼层有没有建立底图
    floor = Number(inputFloor);
    if(this.floors![this.floor2Idx(floor)] === undefined){
      this.setState({
        showRightClickMenu: false,
      });
      alert("该楼层未建立底图，请输入有效楼层!");
      return;
    }

    var drawType;
    if(floor === this.currentFloorNum) return;
    if(floor > this.currentFloorNum) drawType = 1;
    else drawType = 0;

    this.isMultiTubeDrawing = true;

    var lastIndex = this.tubePoints.length - 1;
    var dir1 = this.tubeDirection[lastIndex];
    var lastClickPoint = this.tubePoints[lastIndex];
    var newPoint = new THREE.Vector3();
    var dir2 = new THREE.Vector3();

    //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
    newPoint.setX(lastClickPoint.x);
    newPoint.setY(lastClickPoint.y);
    var pixelPerFloor = this.floorHeight / this.unitLength!.lenthOfEachPixel; 
    // var lastPointFloor = Math.floor(lastClickPoint.z / pixelPerFloor) + 1;
    // 楼高15的话，夸楼层绘制到第n层，默认离第n层地面高(15*0.2 = 3米)
    if(drawType) newPoint.setZ((floor - 1) * pixelPerFloor + 0.0 * pixelPerFloor);
    else newPoint.setZ(1.0 * pixelPerFloor - (this.currentFloorNum - floor)*pixelPerFloor);

    console.log(newPoint.z * this.unitLength!.lenthOfEachPixel);
    // 新关键点方向
    dir2.setX(0);
    dir2.setY(0);
    if(floor > this.currentFloorNum) dir2.setZ(1);// 向上跨楼层
    else if(floor < this.currentFloorNum)dir2.setZ(-1);// 向下跨楼层

    if(this.tubePoints.length > 1){
      if (
        Math.abs(dir2.x - dir1.x) == 2 ||
        Math.abs(dir2.y - dir1.y) == 2 ||
        Math.abs(dir2.z - dir1.z) == 2
      ) {
        return;
      }
      var filletMesh = MESH.createOneFilletMesh(
        dir1,
        dir2,
        lastClickPoint,
        this.tubeGroup.userData.color,
        this.tubeGroup.userData.radius,
        this.tubeGroup.userData.pipeType
      );
      filletMesh.userData.type = ObjType.Fillet;
      filletMesh.userData.radius = this.tubeGroup.userData.radius;
      this.tubeGroup?.add(filletMesh);
    }
    var tubeMesh = MESH.createOneTubeMesh(
      dir1,
      dir2,
      lastClickPoint,
      newPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    tubeMesh.userData.type = ObjType.Tube;
    tubeMesh.userData.radius = this.tubeGroup.userData.radius;
    this.tubeGroup?.add(tubeMesh);
    this.lastDrewTube.userData.nxDir = dir2;
    this.lastDrewTube = tubeMesh;
    this.tubePoints.push(newPoint);
    this.tubeDirection.push(dir2);
    
    this.tubeID++;
    this.tubeGroup.userData.tubeID = this.tubeID;

    // 记录下当前管道颜色/radius
    let tempColor = this.tubeGroup.userData.color;
    let tempRadius = this.tubeGroup.userData.radius;
    // console.log(this.tubeGroup);
    // 完成该楼层绘制
    this.drawTubeComp();
    this.isMultiTubeDrawing = true;
    // var tempFloor = this.floorNum;
    this.tubeSwitchFloor(floor);
  

    // 增加一个标记点

    var left_x = newPoint.x - 1*this.tubeGroup.userData.radius  / 1.414;
    var right_x = newPoint.x + 1*this.tubeGroup.userData.radius  / 1.414;
    var bottom_y = newPoint.y - 1*this.tubeGroup.userData.radius / 1.414;
    var top_y = newPoint.y + 1*this.tubeGroup.userData.radius / 1.414;

    var point1 = new THREE.Vector3(left_x,top_y,0);
    var point2 = new THREE.Vector3(right_x,bottom_y,0);
    var point3 = new THREE.Vector3(right_x,top_y,0);
    var point4 = new THREE.Vector3(left_x,bottom_y,0);


    let lineMesh1 = MESH.createLineMesh(
      [point1,point2],
    );
    let lineMesh2 = MESH.createLineMesh(
      [point3,point4],
    );

    this.currentFloor!.add(lineMesh1);
    this.currentFloor!.add(lineMesh2);

    // 目标楼层增加初始点(2个以绘制原点)
    this.onClickDrawTube();
    this.refPlane.position.set(newPoint.x, newPoint.y, 0);
    // console.log('this.onClickDrawTube');
    if(drawType === 1){
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 0));
      this.tubeDirection.push(new THREE.Vector3(0, 0, 0));
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 0.0 * pixelPerFloor));
      this.tubeDirection.push(new THREE.Vector3(0, 0, 1));
      //增加管道Mesh
      // var tubeMeshCopy = MESH.createOneTubeMesh(
      //   new THREE.Vector3(0, 0, 0),
      //   new THREE.Vector3(0, 0, 1),
      //   new THREE.Vector3(newPoint.x, newPoint.y, 0),
      //   new THREE.Vector3(newPoint.x, newPoint.y, 0.2 * pixelPerFloor),
      //   tempColor,
      //   tempRadius
      // );

      // tubeMeshCopy.userData.type = ObjType.Tube;
      // this.tubeGroup.userData.radius = tubeMeshCopy.userData.radius;
      // this.tubeGroup?.add(tubeMeshCopy);
      // this.lastDrewTube = tubeMeshCopy;
    }else{
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 1 * pixelPerFloor));
      this.tubeDirection.push(new THREE.Vector3(0, 0, 0));
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 1 * pixelPerFloor));
      this.tubeDirection.push(new THREE.Vector3(0, 0, -1));
    }
    

    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.addEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.addEventListener("keydown", this.onKeydown_Switch2D_3D, false);


    this.setState({
      showRightClickMenu: false,
    });
  }


  onChangeTubeRadius_multi = (radius: number) => {
    var newTube: any;
    var oneTube: any;
    var currentTube: any;
    for(let i = 0;i < this.floors!.length;i++){
      let cFloor = this.floors![i]; 
      for(let j = 0; j < cFloor.children.length; j++){
        let tempObj = cFloor.children[j];
        if(tempObj.userData.type === ObjType.Tube && 
          tempObj.userData.tubeID === this.tubeGroup.userData.tubeID
          ){
          currentTube = tempObj;
        }
      }
    }
    let tubeNumber = currentTube.children.length;
    radius = Number((radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
    for (let i = tubeNumber - 1; i >= 0; i--) {
      oneTube = currentTube.children[i];
      if (oneTube.userData.type === ObjType.Tube) {
        newTube = MESH.createOneTubeMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.stPoint,
          oneTube.userData.edPoint,
          oneTube.userData.color,
          radius,
          oneTube.userData.pipeType,
          currentTube.userData.textureDir
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
      } else {
        newTube = MESH.createOneFilletMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.point,
          oneTube.userData.color,
          radius,
          oneTube.userData.pipeType
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
      }
      newTube.geometry.translate(
        -currentTube.userData.center.x,
        -currentTube.userData.center.y,
        -currentTube.userData.center.z
      );
      currentTube.remove(oneTube);
      currentTube.add(newTube);
    }
  }

  onChangeTubeRadius = (radius: number, currenntTubeGroup: THREE.Group | null = null) => {
    if(!currenntTubeGroup){

    }else{
      this.tubeGroup = currenntTubeGroup;
    }
    var i = 0;
    var newTube: any;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;
    console.log(this.tubeGroup.children);
    console.log(tubeNumber);
    
    radius = Number((radius / this.unitLength!.lenthOfEachPixel).toFixed(2));

    // 添加进editVers
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
      }
      if(i == this.tubeGroup.children.length - 1){
        this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
      }
    }
    // 先删除管道
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }

    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }
    // 再根据editVers重建管道
    let color = this.tubeGroup.userData.color;
    let pipeType = this.tubeGroup.userData.pipeType;
    let textureDir = this.tubeGroup.userData.textureDir;
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
    console.log("now tubegroup", newTubeGroup);
    let cnt = 0;
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      cnt++;
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
            GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
    }
    // 保存新数据
    this.tubeGroup.userData.radius = radius;



    // for (i = tubeNumber - 1; i >= 0; i--) {
    //   oneTube = this.tubeGroup.children[i];
    //   if (oneTube.userData.type === ObjType.Tube) {
    //     newTube = MESH.createOneTubeMesh(
    //       oneTube.userData.stDir,
    //       oneTube.userData.edDir,
    //       oneTube.userData.stPoint,
    //       oneTube.userData.edPoint,
    //       oneTube.userData.color,
    //       radius,
    //       oneTube.userData.pipeType,
    //       this.tubeGroup.userData.textureDir
    //     );
    //     newTube.userData.stDir = oneTube.userData.stDir;
    //     newTube.userData.edDir = oneTube.userData.edDir;
    //   } else {
    //     newTube = MESH.createOneFilletMesh(
    //       oneTube.userData.stDir,
    //       oneTube.userData.edDir,
    //       oneTube.userData.point,
    //       oneTube.userData.color,
    //       radius,
    //       oneTube.userData.pipeType
    //     );
    //     newTube.userData.stDir = oneTube.userData.stDir;
    //     newTube.userData.edDir = oneTube.userData.edDir;
    //   }
    //   newTube.geometry.translate(
    //     -this.tubeGroup.userData.center.x,
    //     -this.tubeGroup.userData.center.y,
    //     -this.tubeGroup.userData.center.z
    //   );
    //   this.tubeGroup.remove(oneTube);
    //   this.tubeGroup.add(newTube);
    // }
  };

  onChangeTubeColor = (color: number, currenntTubeGroup: THREE.Group | null = null) => {
    if(!currenntTubeGroup){

    }else{
      this.tubeGroup = currenntTubeGroup;
    }
    var i = 0;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;
    // console.log("change color tubegroup", this.tubeGroup);
    for (i = 0; i < tubeNumber; i++) {
      oneTube = this.tubeGroup.children[i];
      if(this.tubeGroup.userData.pipeType === PipeType.others){
        oneTube.material.color.set(color);
        oneTube.userData.color = color;
        oneTube.parent.userData.color = color;
      }else{
        if(oneTube.userData.type === ObjType.Tube){
          oneTube.material.color.set("#FFFFFF");
        }else{
          oneTube.material.color.set(this.pipeType2Color(oneTube.userData.pipeType));
        }
      }
    }

  };

  onChangeTubeType = (type: PipeType, currenntTubeGroup: THREE.Group | null = null) =>{
        if(!currenntTubeGroup){

        }else{
          this.tubeGroup = currenntTubeGroup;
        }
        // 添加进editVers
        this.editVers = [];
        for(let i = 0; i < this.tubeGroup.children.length; i++){
          if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
            this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
          }
          if(i == this.tubeGroup.children.length - 1){
            this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
          }
        }
        // 先删除管道
        var temp_uuids = [];
        for(let i = 0; i < this.tubeGroup.children.length; i++){
          if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
            this.tubeGroup.children[i].userData.edDir.z === 0){
              temp_uuids.push(this.tubeGroup.children[i].uuid);
            }
        }
    
        for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
          let oneTube = this.tubeGroup.children[i];
          this.tubeGroup.remove(oneTube);
        }
        // 再根据editVers重建管道
        let color = this.tubeGroup.userData.color;
        let radius = this.tubeGroup.userData.radius;
        // let pipeType = this.tubeGroup.userData.pipeType;
        let textureDir = this.tubeGroup.userData.textureDir;
        let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
          radius, color, type, textureDir, this.tubeGroup.userData.center);
        console.log("now tubegroup", newTubeGroup);
        let cnt = 0;
        var k = 0;
        for(let i = 0; i < newTubeGroup.length; i++){
          cnt++;
          this.tubeGroup.add(newTubeGroup[i]);
          if(newTubeGroup[i].userData.type === ObjType.Tube &&
            newTubeGroup[i].userData.edDir.z === 0){
            console.log("Tube", i);
            for(let j = 0; j < GLINE.AllLines.length; j++){
              if(GLINE.AllLines[j][4] === temp_uuids[k]){
                console.log("Find", k);
                GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
                GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
                GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
                k++;
                break;
              }
            }
          }
        }
        // 保存新数据
        this.tubeGroup.userData.pipeType = type;

        // TODO: update this.MultiTubeInfo
  }

  onChangeTubeType_multi = (type: PipeType) =>{
    var newTube: any;
    var oneTube: any;
    var currentTube: any;
    for(let i = 0;i < this.floors!.length;i++){
      let cFloor = this.floors![i]; 
      for(let j = 0; j < cFloor.children.length; j++){
        let tempObj = cFloor.children[j];
        if(tempObj.userData.type === ObjType.Tube && 
          tempObj.userData.tubeID === this.tubeGroup.userData.tubeID
          ){
          currentTube = tempObj;
        }
      }
    }
    let tubeNumber = currentTube.children.length;
    
    for (let i = tubeNumber - 1; i >= 0; i--) {
      oneTube = currentTube.children[i];
      if (oneTube.userData.type === ObjType.Tube) {
        newTube = MESH.createOneTubeMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.stPoint,
          oneTube.userData.edPoint,
          oneTube.userData.color,
          oneTube.userData.radius,
          type,
          oneTube.userData.textureDir
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
        newTube.userData.texture = type;
      } else {
        newTube = MESH.createOneFilletMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.point,
          oneTube.userData.color,
          oneTube.userData.radius,
          type
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
        newTube.userData.texture = type;
      }
      newTube.geometry.translate(
        -currentTube.userData.center.x,
        -currentTube.userData.center.y,
        -currentTube.userData.center.z
      );
      currentTube.remove(oneTube);
      currentTube.add(newTube);
    }
  }

  height2Floor = (height: number) =>{
    
    // 单位（米）下高度转楼层
    var floorHeight = this.floorHeight;
    return Math.floor(height / floorHeight) + 1;
  }

  onChangeTubeHeight_fix2 = (height: number)=>{
    //获取需要改变高度的管道段
    var thisTube = this.pickedObj;
    if(thisTube.userData.stPoint.x == thisTube.userData.edPoint.x
      && thisTube.userData.stPoint.y == thisTube.userData.edPoint.y){
        // 垂直管道不可修改
        return;
      }

    height = height / this.unitLength!.lenthOfEachPixel;
    var color = this.tubeGroup.userData.color;
    var radius = this.tubeGroup.userData.radius;
    var pipeType = this.tubeGroup.userData.pipeType;
    var textureDir = this.tubeGroup.userData.textureDir;


    // 获得顶点数组 并加以修改/添加顶点
    // 分3类
    this.editVers = [];
    if(this.pickedObj === this.tubeGroup.children[0]){
      for(let i = 0; i < this.tubeGroup.children.length; i++){
        if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
          this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
        }
        if(i == this.tubeGroup.children.length - 1){
          this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
        }
      }
      var nextTube = this.tubeGroup.children[2];
      if(nextTube.userData.stPoint.x == nextTube.userData.edPoint.x
        && nextTube.userData.stPoint.y == nextTube.userData.edPoint.y){
        // 改一二两个点
        this.editVers[0].setZ(height);
        this.editVers[1].setZ(height);
      }else{
        // 改第一个点， 插入第二个点
        this.editVers[0].setZ(height);
        this.editVers.splice(1, 0, new THREE.Vector3(
        this.editVers[1].x,
        this.editVers[1].y, height))
      }
    }else if(this.pickedObj === this.tubeGroup.children[this.tubeGroup.children.length - 1]){
      for(let i = 0; i < this.tubeGroup.children.length; i++){
        if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
          this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
        }
        if(i == this.tubeGroup.children.length - 1){
          this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
        }
      }
      var preTube = this.tubeGroup.children[this.tubeGroup.children.length - 3];
      if(preTube.userData.stPoint.x == preTube.userData.edPoint.x
        && preTube.userData.stPoint.y == preTube.userData.edPoint.y){
        var lastIdx = this.editVers.length - 1;
        this.editVers[lastIdx].setZ(height);
        this.editVers[lastIdx - 1].setZ(height);
      }else{
        var lastIdx = this.editVers.length - 1;
        this.editVers[lastIdx].setZ(height);
        this.editVers.splice(lastIdx, 0, new THREE.Vector3(
          this.editVers[lastIdx - 1].x,
          this.editVers[lastIdx - 1].y, height));
      }
    }else{
      var stIndex = -1;
      var edIndex = -1;
      var preTube = this.tubeGroup.children[0];
      var nextTube = this.tubeGroup.children[0];
      for(let i = 0; i < this.tubeGroup.children.length; i++){
        if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
          this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
          if(this.tubeGroup.children[i] === thisTube){
            stIndex = this.editVers.length - 1;
            edIndex = stIndex + 1;
            preTube = this.tubeGroup.children[i - 2];
            nextTube = this.tubeGroup.children[i + 2];
          }
        }
        if(i == this.tubeGroup.children.length - 1){
          this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
        }
      }
      console.log("stIndex", stIndex);
      console.log("edIndex",edIndex);
      var flag = 0; // 是否插入了点
      // 对于起点
      if(preTube.userData.stPoint.x == preTube.userData.edPoint.x
        && preTube.userData.stPoint.y == preTube.userData.edPoint.y){
        this.editVers[stIndex].setZ(height);
      }else{
        flag = 1;
        this.editVers.splice(stIndex + 1, 0, new THREE.Vector3(
          this.editVers[stIndex].x,
          this.editVers[stIndex].y, height));
      }

      // 对于终点
      if(flag == 1) edIndex++;
      if(nextTube.userData.stPoint.x == nextTube.userData.edPoint.x
        && nextTube.userData.stPoint.y == nextTube.userData.edPoint.y){
        this.editVers[edIndex].setZ(height);
      }else{
        this.editVers.splice(edIndex, 0, new THREE.Vector3(
          this.editVers[edIndex].x,
          this.editVers[edIndex].y, height));
      }
    }
    
    

    // 删除管道mesh
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }
    console.log("temp_uuids", temp_uuids);
    console.log("GLINE.AllLines", GLINE.AllLines);
    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }

    // 重建
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
    var cnt = 0
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
      cnt++;
    }
    console.log(cnt);
    this.setState({ selectedPipeProp: undefined });
    this.currentFloor!.remove(this.refPlane);
  }

  onChangeTubeHeight_fix = (height: number)=>{
    //获取需要改变高度的管道段
    var thisTube = this.pickedObj;

    height = height / this.unitLength!.lenthOfEachPixel;
    var color = this.tubeGroup.userData.color;
    var radius = this.tubeGroup.userData.radius;
    var pipeType = this.tubeGroup.userData.pipeType;
    var textureDir = this.tubeGroup.userData.textureDir;

    var i = 0;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;
    var cnt = 0;
    // 开始分类
    var dir1,dir2,dir3,dir4,dir5,pt1,pt2,pt3,pt4;
    dir3 = thisTube.userData.edDir; // 选中方向
    if(thisTube.userData.stDir.z === 0 && thisTube.userData.nxDir.z === 0){
      // 不同情况不同的方向
      dir1 = thisTube.userData.stDir; // 出发方向
      dir5 = thisTube.userData.nxDir; // 结束方向
      // 不同情况不同的点
      pt1 = thisTube.userData.stPoint; 
      pt4 = thisTube.userData.edPoint; 

      // di2 dir4依赖于上面变量的
      pt2 = new THREE.Vector3(pt1.x, pt1.y, height); 
      pt3 = new THREE.Vector3(pt4.x, pt4.y, height); 
      dir2 = new THREE.Vector3(0, 0, Math.round((height - pt1.z)/ Math.abs(height - pt1.z )));
      dir4 = new THREE.Vector3(0, 0, Math.round((-height + pt4.z)/ Math.abs(-height + pt4.z)));

      for (i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if (oneTube.userData.type === ObjType.Tube) {
          
        } else {
          if (oneTube.userData.point == thisTube.userData.stPoint || 
            oneTube.userData.point == thisTube.userData.edPoint) {
            oneTube.name = 'del_';
            cnt++;
          }
        }
      }
    }else if(thisTube.userData.stDir.z !== 0 && thisTube.userData.nxDir.z !== 0){
      for(i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if(oneTube.userData.type === ObjType.Tube){
          if(oneTube.userData.edPoint === thisTube.userData.stPoint){
            oneTube.name = 'del_';
            cnt++;
            pt1 = oneTube.userData.stPoint;
          }else if(oneTube.userData.stPoint === thisTube.userData.edPoint){
            oneTube.name = 'del_';
            cnt++;
            pt4 = oneTube.userData.edPoint;
          }
        }
      }
      for(i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if(oneTube.userData.type === ObjType.Fillet){
          if(oneTube.userData.point == thisTube.userData.stPoint || 
            oneTube.userData.point == thisTube.userData.edPoint){
            oneTube.name = 'del_';
            cnt++;
          }else if(oneTube.userData.point === pt1){
            dir1 = oneTube.userData.stDir;
            oneTube.name = 'del_';
            cnt++;
          }else if(oneTube.userData.point === pt4){
            dir5 = oneTube.userData.edDir;
            oneTube.name = 'del_';
            cnt++;
          }
        }
      }
      pt2 = new THREE.Vector3(pt1.x, pt1.y, height); 
      pt3 = new THREE.Vector3(pt4.x, pt4.y, height); 
      dir2 = new THREE.Vector3(0, 0, Math.round((height - pt1.z)/ Math.abs(height - pt1.z )));
      dir4 = new THREE.Vector3(0, 0, Math.round((-height + pt4.z)/ Math.abs(-height + pt4.z)));
    }else{
      if(thisTube.userData.stDir.z !== 0){
        for(i = 0; i < tubeNumber; i++) {
          oneTube = this.tubeGroup.children[i];
          if(oneTube.userData.type === ObjType.Tube){
            if(oneTube.userData.edPoint === thisTube.userData.stPoint){
              oneTube.name = 'del_';
              cnt++;
              pt1 = oneTube.userData.stPoint;
            }
          }
        }
        for(i = 0; i < tubeNumber; i++) {
          oneTube = this.tubeGroup.children[i];
          if(oneTube.userData.type === ObjType.Fillet){
            if(oneTube.userData.point == thisTube.userData.stPoint || 
              oneTube.userData.point == thisTube.userData.edPoint){
              oneTube.name = 'del_';
              cnt++;
            }else if(oneTube.userData.point === pt1){
              dir1 = oneTube.userData.stDir;
              oneTube.name = 'del_';
              cnt++;
            }
          }
        }
        pt4 = thisTube.userData.edPoint; 
        dir5 = thisTube.userData.nxDir;
        pt2 = new THREE.Vector3(pt1.x, pt1.y, height); 
        pt3 = new THREE.Vector3(pt4.x, pt4.y, height); 
        dir2 = new THREE.Vector3(0, 0, Math.round((height - pt1.z)/ Math.abs(height - pt1.z )));
        dir4 = new THREE.Vector3(0, 0, Math.round((-height + pt4.z)/ Math.abs(-height + pt4.z)));
      }else{
        for(i = 0; i < tubeNumber; i++) {
          oneTube = this.tubeGroup.children[i];
          if(oneTube.userData.type === ObjType.Tube){
            if(oneTube.userData.stPoint === thisTube.userData.edPoint){
              oneTube.name = 'del_';
              cnt++;
              pt4 = oneTube.userData.edPoint;
            }
          }
        }
        for(i = 0; i < tubeNumber; i++) {
          oneTube = this.tubeGroup.children[i];
          if(oneTube.userData.type === ObjType.Fillet){
            if(oneTube.userData.point == thisTube.userData.stPoint || 
              oneTube.userData.point == thisTube.userData.edPoint){
              oneTube.name = 'del_';
              cnt++;
            }else if(oneTube.userData.point === pt4){
              dir5 = oneTube.userData.edDir;
              oneTube.name = 'del_';
              cnt++;
            }
          }
        }
        pt1 = thisTube.userData.stPoint; 
        dir1 = thisTube.userData.stDir;
        pt2 = new THREE.Vector3(pt1.x, pt1.y, height); 
        pt3 = new THREE.Vector3(pt4.x, pt4.y, height); 
        dir2 = new THREE.Vector3(0, 0, Math.round((height - pt1.z)/ Math.abs(height - pt1.z )));
        dir4 = new THREE.Vector3(0, 0, Math.round((-height + pt4.z)/ Math.abs(-height + pt4.z)));  
      }
    }

    let meshes = [];
    let fillet1,fillet2,fillet3,fillet4,tube1,tube2,tube3;
    fillet1 = MESH.createOneFilletMesh(dir1, dir2, pt1, color, radius,pipeType);
    this.tubeGroup.add(fillet1); meshes.push(fillet1);
    tube1 = MESH.createOneTubeMesh(dir1, dir2, pt1, pt2, color, radius,pipeType,textureDir);
    this.tubeGroup.add(tube1); meshes.push(tube1);
    fillet2 = MESH.createOneFilletMesh(dir2, dir3, pt2, color, radius,pipeType);
    this.tubeGroup.add(fillet2); meshes.push(fillet2);
    tube2 = MESH.createOneTubeMesh(dir2, dir3, pt2, pt3, color, radius,pipeType,textureDir);
    this.tubeGroup.add(tube2); meshes.push(tube2);
    fillet3 = MESH.createOneFilletMesh(dir3, dir4, pt3, color, radius,pipeType);
    this.tubeGroup.add(fillet3); meshes.push(fillet3);
    tube3 = MESH.createOneTubeMesh(dir3, dir4, pt3, pt4, color, radius,pipeType,textureDir);
    this.tubeGroup.add(tube3); meshes.push(tube3);
    fillet4 = MESH.createOneFilletMesh(dir4, dir5, pt4, color, radius,pipeType);
    this.tubeGroup.add(fillet4); meshes.push(fillet4);
    meshes.forEach(item =>{
      item.geometry.translate(
        -this.tubeGroup.userData.center.x,
        -this.tubeGroup.userData.center.y,
        -this.tubeGroup.userData.center.z
      );
    });

    this.tubeGroup.remove(thisTube);
    var rcnt = 0;
    for(i = tubeNumber - 1; i >= 0; i--) {
      oneTube = this.tubeGroup.children[i];
      if (oneTube.name === 'del_') {
        this.tubeGroup.remove(oneTube);
        rcnt++;
      }
    }
    console.log('rcnt',rcnt);
    this.setState({ selectedPipeProp: undefined });
    this.currentFloor!.remove(this.refPlane);
  }

  onChangeTubeHeight = (height: number) => {
    //获取需要改变高度的管道段
    var thisTube = this.pickedObj;
    console.log(thisTube);

    // var tubeFloorNum = this.height2Floor(thisTube.userData.stPoint.z * this.unitLength!.lenthOfEachPixel);
    // console.log('tubeFloorNum',tubeFloorNum);
    // console.log('floornum',this.floorNum);

    // if(tubeFloorNum === this.floorNum){
    //   height = height / this.unitLength!.lenthOfEachPixel;
    // }else{
    //   height = (height + (tubeFloorNum - this.floorNum) * this.floorHeight) / this.unitLength!.lenthOfEachPixel;
    // }


    height = height / this.unitLength!.lenthOfEachPixel;
    var color = this.tubeGroup.userData.color;
    var radius = this.tubeGroup.userData.radius;
    var pipeType = this.tubeGroup.userData.pipeType;
    var textureDir = this.tubeGroup.userData.textureDir
    // var radius = Number((this.tubeGroup.userData.radius / this.unitLength!.lenthOfEachPixel).toFixed(2));
    
    //根据传入的管道段新高度设置新的方向
    var diffZ = height - thisTube.userData.stPoint.z;
    var detZ = Math.abs(diffZ);

    //需要新增的共有垂直方向的两个管道段，水平方向的一个管道段，四个圆角
    //需要移除的至少有一个被选中的管道段，其两端的两个圆角，可能还需要移除原本相邻的垂直方向管道段
    //以下的方向和关键点均按照其在整段管道中的顺序设置
    var dir1 = thisTube.userData.stDir;
    var dir3 = thisTube.userData.edDir;
    var dir5 = thisTube.userData.nxDir;
    var dir2 = new THREE.Vector3(0, 0, Math.round(diffZ / detZ));
    var dir4 = new THREE.Vector3(0, 0, -Math.round(diffZ / detZ));

    var pt1 = thisTube.userData.stPoint;
    var pt4 = thisTube.userData.edPoint;
    var pt2 = new THREE.Vector3(pt1.x, pt1.y, height);
    var pt3 = new THREE.Vector3(pt4.x, pt4.y, height);

    var i = 0;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;
    var cnt = 0;
    if(dir1.z !== 0 && dir5.z !== 0 && diffZ < 0){
      for (i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if (oneTube.userData.type === ObjType.Tube) {
          if(oneTube.userData.stPoint === thisTube.userData.stPoint || oneTube.userData.stPoint === thisTube.userData.edPoint){
            oneTube.name = 'del_';
            cnt++;
          }
        }
      }
    }else if(dir1.z !== 0 && dir2.z === 0){
      for (i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if (oneTube.userData.type === ObjType.Tube) {
          if(diffZ < 0){

          }else{

          }
        }
      }
    }else if(dir1.z === 0 && dir2.z !== 0){
      for (i = 0; i < tubeNumber; i++) {
        oneTube = this.tubeGroup.children[i];
        if (oneTube.userData.type === ObjType.Tube) {
          if(diffZ < 0){

          }else{
            
          }
        }
      }
    }

    for (i = 0; i < tubeNumber; i++) {
      oneTube = this.tubeGroup.children[i];
      if (oneTube.userData.type === ObjType.Tube) {
        
      } else {
        if (oneTube.userData.point == thisTube.userData.stPoint || 
          oneTube.userData.point == thisTube.userData.edPoint) {
          oneTube.name = 'del_';
          cnt++;
        }
      }
    }
    console.log('cnt',cnt);

    var fillet1,fillet2,fillet3,fillet4,tube1,tube2,tube3;

   
    if(dir1.z !== 0 && dir5.z !== 0 && diffZ > 0){
        // 断点情况
        fillet1 = MESH.createOneFilletMesh(dir1, dir2, pt1, color, radius,pipeType);
        this.tubeGroup.add(fillet1);

        tube1 = MESH.createOneTubeMesh(dir1, dir2, pt1, pt2, color, radius,pipeType,textureDir);
        this.tubeGroup.add(tube1);

        fillet2 = MESH.createOneFilletMesh(dir2, dir3, pt2, color, radius,pipeType);
        this.tubeGroup.add(fillet2);

        tube2 = MESH.createOneTubeMesh(dir2, dir3, pt2, pt3, color, radius,pipeType,textureDir);
        this.tubeGroup.add(tube2);

        fillet3 = MESH.createOneFilletMesh(dir3, dir4, pt3, color, radius,pipeType);
        this.tubeGroup.add(fillet3);

        tube3 = MESH.createOneTubeMesh(dir3, dir4, pt3, new THREE.Vector3(pt4.x, pt4.y, pt4.z - radius / 2), color, radius,pipeType,textureDir);
        this.tubeGroup.add(tube3);

        fillet4 = MESH.createOneFilletMesh(dir4, dir5, pt4, color, radius,pipeType);
        this.tubeGroup.add(fillet4);
    }else{
      fillet1 = MESH.createOneFilletMesh(dir1, dir2, pt1, color, radius,pipeType);
      this.tubeGroup.add(fillet1);

      tube1 = MESH.createOneTubeMesh(dir1, dir2, pt1, pt2, color, radius,pipeType,textureDir);
      this.tubeGroup.add(tube1);

      fillet2 = MESH.createOneFilletMesh(dir2, dir3, pt2, color, radius,pipeType);
      this.tubeGroup.add(fillet2);

      tube2 = MESH.createOneTubeMesh(dir2, dir3, pt2, pt3, color, radius,pipeType,textureDir);
      this.tubeGroup.add(tube2);

      fillet3 = MESH.createOneFilletMesh(dir3, dir4, pt3, color, radius,pipeType);
      this.tubeGroup.add(fillet3);

      tube3 = MESH.createOneTubeMesh(dir3, dir4, pt3, pt4, color, radius,pipeType,textureDir);
      this.tubeGroup.add(tube3);

      fillet4 = MESH.createOneFilletMesh(dir4, dir5, pt4, color, radius,pipeType);
      this.tubeGroup.add(fillet4);
    }
    

    fillet1.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    tube1.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    fillet2.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    tube2.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    fillet3.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    tube3.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    fillet4.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );

    this.tubeGroup.remove(thisTube);

    var rcnt = 0;
    for(i = tubeNumber - 1; i >= 0; i--) {
      oneTube = this.tubeGroup.children[i];
      if (oneTube.name === 'del_') {
        this.tubeGroup.remove(oneTube);
        rcnt++;
      }
    }
    console.log('rcnt',rcnt);
    this.currentFloor!.remove(this.refPlane);

  }

  switchTubeDir = (currenntTubeGroup: THREE.Group | null = null)=>{
    if(!currenntTubeGroup){

    }else{
      this.tubeGroup = currenntTubeGroup;
    }

    var i = 0;
    var newTube: any;
    var oneTube: any;
    let tubeNumber = this.tubeGroup.children.length;
    var textureDir: number;
    if(this.tubeGroup.userData.textureDir === 0){
      this.tubeGroup.userData.textureDir = 1;
      textureDir = 1;
    }else{
      this.tubeGroup.userData.textureDir = 0;
      textureDir = 0;
    }

    // 添加进editVers
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
      }
      if(i == this.tubeGroup.children.length - 1){
        this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
      }
    }
    // 先删除管道
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }

    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }
    // 再根据editVers重建管道
    let color = this.tubeGroup.userData.color;
    let radius = this.tubeGroup.userData.radius;
    let pipeType = this.tubeGroup.userData.pipeType;
    // let textureDir = this.tubeGroup.userData.textureDir;
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
    console.log("now tubegroup", newTubeGroup);
    let cnt = 0;
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      cnt++;
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
            GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
    }
    // 保存新数据
    this.tubeGroup.userData.textureDir = textureDir;
  }

  switchTubeDir_multi = ()=>{
    var newTube: any;
    var oneTube: any;
    var currentTube: any;
    var textureDir: number;
    // 单层改方向时已经改过了，不用再改了
    // if(this.tubeGroup.userData.textureDir === 0){
    //   this.tubeGroup.userData.textureDir = 1;
    //   textureDir = 1;
    // }else{
    //   this.tubeGroup.userData.textureDir = 0;
    //   textureDir = 0;
    // }

    for(let i = 0;i < this.floors!.length;i++){
      let cFloor = this.floors![i]; 
      for(let j = 0; j < cFloor.children.length; j++){
        let tempObj = cFloor.children[j];
        if(tempObj.userData.type === ObjType.Tube && 
          tempObj.userData.tubeID === this.tubeGroup.userData.tubeID
          ){
          currentTube = tempObj;
        }
      }
    }
    let tubeNumber = currentTube.children.length;
    
    for (let i = tubeNumber - 1; i >= 0; i--) {
      oneTube = currentTube.children[i];
      if (oneTube.userData.type === ObjType.Tube) {
        newTube = MESH.createOneTubeMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.stPoint,
          oneTube.userData.edPoint,
          oneTube.userData.color,
          oneTube.userData.radius,
          oneTube.userData.pipeType,
          this.tubeGroup.userData.textureDir
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
      } else {
        newTube = MESH.createOneFilletMesh(
          oneTube.userData.stDir,
          oneTube.userData.edDir,
          oneTube.userData.point,
          oneTube.userData.color,
          oneTube.userData.radius,
          oneTube.userData.pipeType
        );
        newTube.userData.stDir = oneTube.userData.stDir;
        newTube.userData.edDir = oneTube.userData.edDir;
      }
      newTube.geometry.translate(
        -currentTube.userData.center.x,
        -currentTube.userData.center.y,
        -currentTube.userData.center.z
      );
      currentTube.remove(oneTube);
      currentTube.add(newTube);
    }
  }

  private delta_height : number = 0;
  changeTubeGroupHeight = (inputHeight: number, currenntTubeGroup: THREE.Group | null = null, delta_height: number | null = null)=>{
    if(!currenntTubeGroup){

    }else{
      this.tubeGroup = currenntTubeGroup;
    }

    let tubeNumber = this.tubeGroup.children.length;
    // 判断是否会超过楼高
    // var maxH = 0;
    // for(let i = 0;i < tubeNumber;i++){
    //   let oneTube = this.tubeGroup.children[i];
    //   if(oneTube.userData.type === ObjType.Tube)
    //   maxH = Math.max(
    //     Math.max(oneTube.userData.edPoint.z,oneTube.userData.stPoint.z),
    //     maxH
    //   )
    // }

    // if((maxH - this.tubeGroup.userData.height) * this.unitLength!.lenthOfEachPixel + inputHeight >= this.floorHeight){
    //   alert("高度超过层高限制，请重新设定高度！");
    //   return;
    // }
    // 只对非夸楼层管道生效
    var i = 0;
    var newTube: any;
    var oneTube: any;

    var height = Number((inputHeight / this.unitLength!.lenthOfEachPixel).toFixed(2));
    if(!delta_height || delta_height === 0){
      delta_height = height - this.tubeGroup.userData.height;
      this.delta_height = delta_height;
    }

    console.log(delta_height);

    // 添加进editVers
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        let stPoint = this.tubeGroup.children[i].userData.stPoint;
        let newStPoint = new Vector3(
          stPoint.x,
          stPoint.y,
          stPoint.z + delta_height);
        this.editVers.push(newStPoint);
      }
      if(i == this.tubeGroup.children.length - 1){
        let edPoint = this.tubeGroup.children[i].userData.edPoint;
        let newEdPoint = new Vector3(
          edPoint.x,
          edPoint.y,
          edPoint.z + delta_height);
        this.editVers.push(newEdPoint);
      }
    }
    // 先删除管道
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }

    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }
    // 再根据editVers重建管道
    let color = this.tubeGroup.userData.color;
    let pipeType = this.tubeGroup.userData.pipeType;
    let textureDir = this.tubeGroup.userData.textureDir;
    let radius = this.tubeGroup.userData.radius;
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
    console.log("now tubegroup", newTubeGroup);
    let cnt = 0;
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      cnt++;
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
            GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
    

    // 保存新数据
    this.tubeGroup.userData.height = height;
  }

    //   for (i = tubeNumber - 1; i >= 0; i--) {
    //     oneTube = this.tubeGroup.children[i];
    //     if (oneTube.userData.type === ObjType.Tube) {
    //       newTube = MESH.createOneTubeMesh(
    //         oneTube.userData.stDir,
    //         oneTube.userData.edDir,
    //         new Vector3(oneTube.userData.stPoint.x,oneTube.userData.stPoint.y,oneTube.userData.stPoint.z + delta_height),
    //         new Vector3(oneTube.userData.edPoint.x,oneTube.userData.edPoint.y,oneTube.userData.edPoint.z + delta_height),
    //         oneTube.userData.color,
    //         oneTube.userData.radius,
    //         oneTube.userData.pipeType,
    //         this.tubeGroup.userData.textureDir
    //       );
    //       newTube.userData.stDir = oneTube.userData.stDir;
    //       newTube.userData.edDir = oneTube.userData.edDir;
    //     } else {
    //       newTube = MESH.createOneFilletMesh(
    //         oneTube.userData.stDir,
    //         oneTube.userData.edDir,
    //         new Vector3(oneTube.userData.point.x,oneTube.userData.point.y,oneTube.userData.point.z + delta_height),
    //         oneTube.userData.color,
    //         oneTube.userData.radius,
    //         oneTube.userData.pipeType
    //       );
    //       newTube.userData.stDir = oneTube.userData.stDir;
    //       newTube.userData.edDir = oneTube.userData.edDir;
    //     }
    //     newTube.geometry.translate(
    //       -this.tubeGroup.userData.center.x,
    //       -this.tubeGroup.userData.center.y,
    //       -this.tubeGroup.userData.center.z
    //     );
    //     this.tubeGroup.remove(oneTube);
    //     this.tubeGroup.add(newTube);
    //   }
    // }
  }

  nextDirFix = ()=>{
    // 修改tubeGroup每一段管道的nxDir
    for(let i = this.tubeGroup.children.length - 2; i >= 0; i--){
      this.tubeGroup.children[i].userData.nxDir = this.tubeGroup.children[i + 1].userData.edDir;
    }
    this.tubeGroup.children[this.tubeGroup.children.length - 1].userData.nxDir = new THREE.Vector3(0,0,0);
  }



  ///////// 跨楼层管道绘制相关数据结构与辅助函数
//   
// ID ==> [TubeGroup1, TubeGroup2, ...], each element in different floor
//   
  private MultiTubeInfo : Map<number, THREE.Group[]> = new Map()

  setTubeGroupCenter = (tube : THREE.Group) => {
    var tubeBox = new THREE.Box3().setFromObject(tube, true);
    tube.userData.center = new Vector3();
    tubeBox.getCenter(tube.userData.center);
  }

  mutilFloorTubeDrawingFix = (floor: number) => {
    var inputFloor = prompt("请输入楼层: ");

    if(inputFloor === null){
      this.setState({
        showRightClickMenu: false,
      });
      alert("取消夸楼层绘制！");
      return;
    }else if(isNaN(parseFloat(inputFloor)) || !Number.isInteger(Number(inputFloor))){
      this.setState({
        showRightClickMenu: false,
      });
      alert("夸楼层失败，请输入有效整数!");
      return;
    }
    // 判断目标楼层有没有建立底图
    floor = Number(inputFloor);
    var newFloor = this.floors![this.floor2Idx(floor)];
    var flag = 0;
    for (let obj of newFloor.children) {
      if (obj.userData.type !== undefined && obj.userData.type === ObjType.Ground){
        flag = 1;
      }
    }
    if(flag === 0){
      alert("本层楼无绘制信息，请导入底图！");
      this.setState({showImportBasemapHint: true});
    }
    

    var drawType;
    if(floor === this.currentFloorNum) return;
    else if(floor > this.currentFloorNum) drawType = 1;
    else drawType = 0;
    this.isMultiTubeDrawing = true;
    


    var lastIdx = this.tubePoints.length - 1;
    var dir1 = this.tubeDirection[lastIdx];
    var lastClickPoint = this.tubePoints[lastIdx];

    // original floor
    var newPoint = new THREE.Vector3();
    var dir2 = new THREE.Vector3(0, 0, 0);
    newPoint.setX(lastClickPoint.x);
    newPoint.setY(lastClickPoint.y);
    var pixelPerFloor = this.floorHeight / this.unitLength!.lenthOfEachPixel;
    if(drawType == 1){
      newPoint.setZ(pixelPerFloor);
      dir2.setZ(1);
    }else{
      newPoint.setZ(0);
      dir2.setZ(-1);
    }
    if(this.tubePoints.length > 1){
      if (
        Math.abs(dir2.x - dir1.x) == 2 ||
        Math.abs(dir2.y - dir1.y) == 2 ||
        Math.abs(dir2.z - dir1.z) == 2
      ) {
        return;
      }
      var filletMesh = MESH.createOneFilletMesh(
        dir1,
        dir2,
        lastClickPoint,
        this.tubeGroup.userData.color,
        this.tubeGroup.userData.radius,
        this.tubeGroup.userData.pipeType
      );
      filletMesh.userData.type = ObjType.Fillet;
      filletMesh.userData.radius = this.tubeGroup.userData.radius;
      this.tubeGroup?.add(filletMesh);
    }
    var tubeMesh = MESH.createOneTubeMesh(
      dir1,
      dir2,
      lastClickPoint,
      newPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    tubeMesh.userData.type = ObjType.Tube;
    tubeMesh.userData.radius = this.tubeGroup.userData.radius;
    this.tubeGroup?.add(tubeMesh);
    this.lastDrewTube = tubeMesh;
    this.tubePoints.push(newPoint);
    this.tubeDirection.push(dir2);
    this.tubeID++;
    this.tubeGroup.userData.tubeID = this.tubeID;
    // add info
    this.tubeGroup.userData.floor = this.currentFloorNum;
    this.tubeGroup.userData.drawType = drawType; // 1 up 0 down

    this.MultiTubeInfo.set(this.tubeID, [this.tubeGroup]);

    var tempRadius = this.tubeGroup.userData.radius;
    var tempColor = this.tubeGroup.userData.color;
    var tempPipeType = this.tubeGroup.userData.pipeType;
    var tempTextureDir = this.tubeGroup.userData.textureDir;
    var tempCenter = new Vector3();

    var tempName = this.tubeGroup.userData.name;
    this.drawTubeComp();
    this.isMultiTubeDrawing = true;

    // middle floors
    var left;
    var right;
    if(drawType == 1){
      left = this.currentFloorNum + 1;
      right = floor;
    }else{
      left = floor + 1;
      right = this.currentFloorNum;
    }
    if(drawType == 1){
      for(let i = left; i < right; i++){
        var tempFloor = this.floors![i - 1];
        var points : any= [];
        points.push(new Vector3(lastClickPoint.x, lastClickPoint.y, 0));
        
        points.push(new Vector3(lastClickPoint.x, lastClickPoint.y, pixelPerFloor));
        console.log("tempCenter", tempCenter);
        var tempTubeList = MESH.createWholeTubeMesh(
          points,
          tempRadius,
          tempColor,
          tempPipeType,
          tempTextureDir,
          tempCenter
        );
        var tempTubeGroup = new THREE.Group();
        for(let j = 0; j < tempTubeList.length; j++){
          tempTubeGroup.add(tempTubeList[j]);
        }
        this.setTubeGroupCenter(tempTubeGroup);
        tempTubeGroup.userData.tubeID = this.tubeID;
        tempTubeGroup.userData.name = tempName;
        tempTubeGroup.userData.color = tempColor;
        tempTubeGroup.userData.radius = tempRadius;
        tempTubeGroup.userData.pipeType = tempPipeType;
        tempTubeGroup.userData.textureDir = tempTextureDir;
        tempTubeGroup.userData.center = tempCenter;

        tempTubeGroup.userData.floor = i;
        tempTubeGroup.userData.type = ObjType.Tube;
        tempFloor!.add(tempTubeGroup);
        
        var val= this.MultiTubeInfo.get(this.tubeID);
        val!.push(tempTubeGroup);
        this.MultiTubeInfo.set(this.tubeID, val!);
      }
    }else{
      for(let i = right - 1; i >= left; i--){
        var tempFloor = this.floors![i - 1];
        var points : any= [];
        points.push(new Vector3(lastClickPoint.x, lastClickPoint.y, pixelPerFloor));
        points.push(new Vector3(lastClickPoint.x, lastClickPoint.y, 0));
        console.log("tempCenter", tempCenter);
        var tempTubeList = MESH.createWholeTubeMesh(
          points,
          tempRadius,
          tempColor,
          tempPipeType,
          tempTextureDir,
          tempCenter
        );
        var tempTubeGroup = new THREE.Group();
        for(let j = 0; j < tempTubeList.length; j++){
          tempTubeGroup.add(tempTubeList[j]);
        }
        this.setTubeGroupCenter(tempTubeGroup);
        tempTubeGroup.userData.tubeID = this.tubeID;
        tempTubeGroup.userData.name = tempName;
        tempTubeGroup.userData.color = tempColor;
        tempTubeGroup.userData.radius = tempRadius;
        tempTubeGroup.userData.pipeType = tempPipeType;
        tempTubeGroup.userData.textureDir = tempTextureDir;
        tempTubeGroup.userData.center = tempCenter;
        tempTubeGroup.userData.floor = i;
        tempTubeGroup.userData.type = ObjType.Tube;
        tempFloor!.add(tempTubeGroup);
        
        var val= this.MultiTubeInfo.get(this.tubeID);
        val!.push(tempTubeGroup);
        this.MultiTubeInfo.set(this.tubeID, val!);
      }
    }
    

  
    // destination floor
    this.tubeSwitchFloor(floor);
    this.onClickDrawTube();
    this.tubeGroup.userData.color = tempColor;
    this.tubeGroup.userData.radius = tempRadius;
    this.tubeGroup.userData.pipeType = tempPipeType;
    this.tubeGroup.userData.textureDir = tempTextureDir;
    this.tubeGroup.userData.drawType = drawType; // 1 up 0 down
    if(drawType === 1){
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 0));
      this.tubeDirection.push(new THREE.Vector3(0, 0, 0));
      // this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 0.0 * pixelPerFloor));
      // this.tubeDirection.push(new THREE.Vector3(0, 0, 1));
    }else{
      this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 1 * pixelPerFloor));
      this.tubeDirection.push(new THREE.Vector3(0, 0, 0));
      // this.tubePoints.push(new THREE.Vector3(newPoint.x, newPoint.y, 1 * pixelPerFloor));
      // this.tubeDirection.push(new THREE.Vector3(0, 0, -1));
    }

    var val= this.MultiTubeInfo.get(this.tubeID);
    val!.push(this.tubeGroup);
    this.MultiTubeInfo.set(this.tubeID, val!);


    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_DrawTube, false);
    document.addEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.addEventListener("keydown", this.onKeydown_Switch2D_3D, false);
    this.setState({
      showRightClickMenu: false,
    });

    GLINE.deactivateAll();
  }

  deleteOneMultiTubeByTubeID = (tubeId: number) =>{
    console.log("run deleteOneMultiTUbeByID");
    console.log(this.MultiTubeInfo);
    console.log(tubeId)
    var tubeGroupList = this.MultiTubeInfo.get(tubeId);
    tubeGroupList?.forEach((element, index) =>{
      var floor = this.floors![element.userData.floor - 1];
      // console.log("floor", floor);

      for(let i = 0; i < floor.children.length; i++){
        if(floor.children[i].userData.tubeID === tubeId){
          floor.remove(floor.children[i]);
          console.log("count",i);
        }
      }
    });
    this.MultiTubeInfo.delete(tubeId);
  }

  // deleteAllMultiTube = () => {
  //   this.MultiTubeInfo.forEach((value, key) => {
  //     this.deleteOneMultiTubeByTubeID(key);
  //   });
  // }

  tubeGroup2PoinsList = (tubeGroup : THREE.Group) => {
      var ret = [];
      for(let i = 0; i < tubeGroup.children.length; i++){
        if(tubeGroup.children[i].userData.type == ObjType.Tube){
          ret.push(tubeGroup.children[i].userData.stPoint);
        }
        if(i == tubeGroup.children.length - 1){
          ret.push(tubeGroup.children[i].userData.edPoint);
        }
      }
      console.log("ret", ret);
      return ret;
  }

  changeMultiTubeWithFloorHeight = () => {
    console.log("changeMultiTubeWithFloorHeight");
    var tempInfo : any= [];
    this.MultiTubeInfo.forEach((value, key) => {
      let drawType = value[0].userData.drawType;
      let color = value[0].userData.color;
      let pipeType = value[0].userData.pipeType;
      let textureDir = value[0].userData.textureDir;
      let radius = value[0].userData.radius;


      this.tubeID++;
      if(drawType === 1){
        var oneFloorMultiTubePoints;
        // 向上画的管道
        var tempTubeGroupList = [];
        for(var i = 0; i < value.length; i++){
          oneFloorMultiTubePoints = this.tubeGroup2PoinsList(value[i]);
          console.log("this.floorHeight", this.floorHeight);
          var pixelPerFloor = this.floorHeight / this.unitLength!.lenthOfEachPixel;
          if(i != value.length - 1){
            oneFloorMultiTubePoints[oneFloorMultiTubePoints.length - 1].setZ(
              pixelPerFloor
            );
          }
          var floor = this.floors![value[i].userData.floor - 1];
          // let center = value[i].userData.center;
          let center = new Vector3(0, 0, 0);
          let newTubeGroup = MESH.createWholeTubeMesh(oneFloorMultiTubePoints,
            radius, color, pipeType, textureDir, center);
          var newTubeGroupMesh = new THREE.Group();
          for(let j = 0; j < newTubeGroup.length; j++){
            newTubeGroupMesh.add(newTubeGroup[j]);
          }
          newTubeGroupMesh.userData.radius = radius;
          newTubeGroupMesh.userData.drawType = drawType;
          newTubeGroupMesh.userData.color = color;
          newTubeGroupMesh.userData.pipeType = pipeType;
          newTubeGroupMesh.userData.textureDir = textureDir;
          newTubeGroupMesh.userData.center = center;
          newTubeGroupMesh.userData.floor = value[i].userData.floor;
          newTubeGroupMesh.userData.tubeID = this.tubeID;
          floor.add(newTubeGroupMesh);       
          tempTubeGroupList.push(newTubeGroupMesh);
        }
        this.deleteOneMultiTubeByTubeID(key);
        tempInfo.push([this.tubeID, tempTubeGroupList]);
      }else{
        // 向下画的
        var tempTubeGroupList = [];
        for(var i = 0; i < value.length; i++){
          oneFloorMultiTubePoints = this.tubeGroup2PoinsList(value[i]);
          var pixelPerFloor = this.floorHeight / this.unitLength!.lenthOfEachPixel;
          if(i != 0){
            oneFloorMultiTubePoints[0].setZ(
              pixelPerFloor
            );
          }
          var floor = this.floors![value[i].userData.floor - 1];
          // let center = value[i].userData.center;
          let center = new Vector3(0, 0, 0);
          let newTubeGroup = MESH.createWholeTubeMesh(oneFloorMultiTubePoints,
            radius, color, pipeType, textureDir, center);
          var newTubeGroupMesh = new THREE.Group();
          for(let j = 0; j < newTubeGroup.length; j++){
            newTubeGroupMesh.add(newTubeGroup[j]);
          }
          newTubeGroupMesh.userData.radius = radius;
          newTubeGroupMesh.userData.drawType = drawType;
          newTubeGroupMesh.userData.color = color;
          newTubeGroupMesh.userData.pipeType = pipeType;
          newTubeGroupMesh.userData.textureDir = textureDir;
          newTubeGroupMesh.userData.center = center;
          newTubeGroupMesh.userData.floor = value[i].userData.floor;
          newTubeGroupMesh.userData.tubeID = this.tubeID;
          floor.add(newTubeGroupMesh);       
          tempTubeGroupList.push(newTubeGroupMesh);
        }
        this.deleteOneMultiTubeByTubeID(key);
        tempInfo.push([this.tubeID, tempTubeGroupList]);
      }
    });

    for(let i = 0; i < tempInfo.length; i++){
      this.MultiTubeInfo.set(tempInfo[i][0], tempInfo[i][1]);
    }
  }

  changeMultiTubeColor = (color : number) => {
    var tubeID = this.tubeGroup.userData.tubeID;
    for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
      this.onChangeTubeColor(color, this.MultiTubeInfo.get(tubeID)![i]);
      this.MultiTubeInfo.get(tubeID)![i].userData.color = color;
    }
  }

  changeMultiTubeType = (type : PipeType) => {
    var tubeID = this.tubeGroup.userData.tubeID;
    console.log("tubenumber", this.MultiTubeInfo.get(tubeID)!.length);
    for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
      this.onChangeTubeType(type, this.MultiTubeInfo.get(tubeID)![i]);
      this.MultiTubeInfo.get(tubeID)![i].userData.pipeType = type;
    }
  }

  changeMultiTubeRadius = (radius : number) => {
    var tubeID = this.tubeGroup.userData.tubeID;
    for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
      this.onChangeTubeRadius(radius, this.MultiTubeInfo.get(tubeID)![i]);
    }
  }

  changeMultiTubeGroupHeight = (inputHeight: number) => {
    var tubeID = this.tubeGroup.userData.tubeID;
    for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
      this.changeTubeGroupHeight(inputHeight, this.MultiTubeInfo.get(tubeID)![i], this.delta_height);
    }
    this.delta_height = 0;
    // this.changeMultiTubeWithFloorHeight();
  }

  switchMultiTubeDir = ()=>{
    var tubeID = this.tubeGroup.userData.tubeID;
    for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
      this.switchTubeDir(this.MultiTubeInfo.get(tubeID)![i]);
    }
  }



  /////////// 视角切换
  switch2D_3D = () => {
    if (this.isMultiFloor) this.switchFloorMode();

    this.is2D = CAM.switch2D3D(
      this.is2D,
      this.camera_o,
      this.camera_p,
      this.controls_o,
      this.controls_p
    );

    // 修改比例尺显示
    this.changeScaleIcon();
    // 修改指北针方向
    this.setCompassAngle();
  };

  ////////// 单多层切换
  switchFloorMode = () => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后操作。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    if (this.is2D) this.switch2D_3D();

    this.isMultiFloor = !this.isMultiFloor;
    const scene = this.scene;
    const floors = this.floors!;

    this.deleteGhostCursor();
    GLINE.deactivateAll();
    this.clearSupportLines();

    if (this.isMultiFloor) {
      // 多楼层显示
      this.updateExistTypeState();
      this.onClickViewRestore();
      scene.remove(this.currentFloor!);
      hideLabel([this.currentFloor!]);

      const dict = sortByKey(this.floor2IdxDict);
      const height = this.floorHeight / this.unitLength!.lenthOfEachPixel;

      var count = 0;
      for (var key in dict) {
        const idx = dict[key];
        var floorNum = parseInt(key);
        if (floorNum > 0) floorNum--;

        const floor = floors[idx];
        floor.position.setZ(floorNum * height);
        floor.renderOrder = count;
        floor.traverse((child) => (child.renderOrder = count));
        scene.add(floor);
        count += 1;
      }
      showLabel(this.floors!);
    } else {
      // 单楼层显示
      this.updateExistTypeState();
      hideLabel(this.floors!);
      for (var key in this.floor2IdxDict) {
        const idx = this.floor2IdxDict[key];

        const floor = floors[idx];
        scene.remove(floor);
        floor.position.setZ(0);
        floor.traverse((child) => (child.renderOrder = 0));
      }
      
      scene.add(this.currentFloor!);
      showLabel([this.currentFloor!]);
      this.onClickViewRestore();
    }
  };

  /////// 修改比例尺显示
  changeScaleIcon = () => {
    if (this.unitLength !== undefined) {
      const camera = this.is2D ? this.camera_o : this.camera_p;
      var len =
        (this.unitLength!.lenthOfEachPixel / calWorldScale(window, camera)) *
        60;
      this.setState({ scaleIconProp: len });
    }
  };

  ////////// 视角还原
  onClickViewRestore = () => {
    if (!this.isMultiFloor) {
      this.controls_o.reset();
      this.controls_p.reset();
    } else {
      var floorNum = this.currentFloorNum;
      const height = this.floorHeight / this.unitLength!.lenthOfEachPixel;
      if (floorNum > 0) floorNum--;
      CAM.restoreMultiFloorView(this.camera_p, this.controls_p, floorNum * height);
    }
    this.changeScaleIcon();
  };

  private showBasemap = true;
  ////////// 隐藏底图
  onClickSwitchBasemapMode = () => {
    this.showBasemap = !this.showBasemap;
    setTypeVis(ObjType.Ground, this.showBasemap, this.floors!);

    const floor = this.currentFloor!;
    for (let obj of floor.children) {
      if(obj.userData.type !== undefined && obj.userData.type === ObjType.Floor)
      {
        let slab: any = obj;
        if (this.showBasemap){
          // 展示底图，楼板半透明
          slab.material.opacity = 1 - obj.userData.transparent;
        }
        else {
          slab.material.opacity = 0.99;
        }
        break;
      }
    }
  };

  //////// 设置类型的可见性
  onSetTypeVis = (evt: CustomEvent) => {
    const detail = evt.detail;
    const type: ObjType | string = detail.type;
    const visible: boolean = detail.visible;
    setTypeVis(type, visible, this.floors!);
  };

  updateExistTypeState = () => {
    if (this.isMultiFloor)
      var r = getExistType(this.floors!);
    else 
      var r = getExistType([this.currentFloor!]);
    this.setState({existType: r});
  };

  private intersects: any;

  //////////// 画墙
  private mouse = new THREE.Vector2();
  onClickDrawWall = () => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    // 进入任何模式前，先移除其他模式的listener和小面板
    this.props.setIsDrawing(true);
    if (this.props.mode !== Mode.WallDrawing)
      this.removeSidebarEvent();
    if (!this.is2D) this.switch2D_3D();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Wall, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Wall, true);

    // 设置默认属性
    if (this.state.selectedWallProp === undefined) {
      this.setState({
        selectedWallProp: DefaultValues.defaultWallProp,
      });
    }
    this.generateGhostCursor();
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawWall, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchLines, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchVertex, false);
  };

  onMouseDown_DrawWall = (event: any) => {
    // if (!(event.target instanceof HTMLCanvasElement))
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (event.button !== 0) return;

    const floor = this.currentFloor!;
    const clickPoss = this.clickPoss;
    const property = this.state.selectedWallProp;
    let mouse;
    let newPoint = this.getMousePos(event, this.camera_o, floor.children);
    let lastClickPoint = this.clickPoss[this.clickPoss.length - 1];
    if(event.shiftKey === true) {
      console.log("shift");
      mouse = getHorizonVertiPoint(lastClickPoint, newPoint);
    }
    else if (event.ctrlKey === true && this.clickPoss.length > 1) {
      console.log("ctrl");
      let p1 = this.clickPoss[this.clickPoss.length - 2];
      let p2 = lastClickPoint;
      let p3 = newPoint;
      if (getAngle(p1, p2, p3) > Math.PI * 3 / 4) {
        mouse = calFootPnt(p3, p2, p1);
      } else{
        mouse = getPerpendicularPoint(p1, p2, p3);
      }
      console.log(this.clickPoss[this.clickPoss.length - 2]);
      console.log(lastClickPoint);
      console.log(newPoint);
      console.log(mouse);
    }
    else{
      mouse = this.getMousePos(event, this.camera_o, floor.children);
      if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
      
    }

    this.clickPoss.push(mouse);
    this.LastClickPoint = mouse;

    if (clickPoss.length == 1) {
      // 第一次点击
      this.controls_o.enablePan = false;
      document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_DrawWall, false);
      this.showDrawingRightMenu = true;
      this.AllVertex.push(
        [new THREE.Vector2(mouse.x, mouse.y), ""]
      )
      // this.transCtrl_o.enabled = false;
      // this.transCtrl_p.enabled = false;

      // console.log('mouse down:',lastClickPos);
    } else {
      let n = this.clickPoss.length;
      console.log(this.clickPoss);

      if (n > 1 && calPntsDist(this.clickPoss[n-2], this.clickPoss[n-1]) < 1) {
        this.deleteGhostCursor();
        setTimeout(function() {
          alert("两点的距离不能太近!");
        }, 100);
        this.clickPoss.pop();
        this.generateGhostCursor();
        return;
      }

      if (n > 2 && !detectSafetyAngle(this.clickPoss[n-1], this.clickPoss[n-2], this.clickPoss[n-3])) {
        this.deleteGhostCursor();
        setTimeout(function() {
          alert("夹角不能小于20度!");
        }, 100);
        this.clickPoss.pop();
        this.generateGhostCursor();
        return;
      }
      removeObjectsByName(floor, ObjName.DrawingWall);
      let wallMesh = MESH.createWallMesh(
        this.clickPoss,
        Number(
          (property!.width! / this.unitLength!.lenthOfEachPixel).toFixed(2)
        ),
        Number(
          (property!.height! / this.unitLength!.lenthOfEachPixel).toFixed(2)
        ),
        property!.color,
        null,
        null,
        Number(
          (property!.rideHeight! / this.unitLength!.lenthOfEachPixel).toFixed(2)
        ),
        0.5
      );
      wallMesh.name = ObjName.DrawingWall;
      floor.add(wallMesh);

      this.AllVertex.push(
        [new THREE.Vector2(mouse.x, mouse.y), wallMesh.userData.uuid]
      )
      
    }
  };

  onMouseMove_DrawWall = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (event.button !== 0) return;

    const floor = this.currentFloor!;
    const property = this.state.selectedWallProp;

    let mouse;
    if(event.shiftKey === true) {
      console.log("shift");
      let newPoint = this.getMousePos(event, this.camera_o, floor.children);
      let lastClickPoint = this.clickPoss[this.clickPoss.length - 1];
      mouse = getHorizonVertiPoint(lastClickPoint, newPoint);
    }else{
      mouse = this.getMousePosSafe(event, this.camera_o, floor.children);
      if (mouse === null) return;
    }
    if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
    this.clickPoss.push(mouse);

    removeObjectsByName(floor, ObjName.DrawingWall);

    let wallMesh = MESH.createWallMesh(
      //mark
      this.clickPoss,
      Number((property!.width! / this.unitLength!.lenthOfEachPixel).toFixed(2)),
      Number((property!.height! / this.unitLength!.lenthOfEachPixel).toFixed(2)),
      property!.color,
      null,
      null,
      Number(
        (property!.rideHeight! / this.unitLength!.lenthOfEachPixel).toFixed(2)
      ),
      0.5
    );
    wallMesh.name = ObjName.DrawingWall;
    floor.add(wallMesh);
    this.clickPoss.pop();
  };

  onKeyup_DrawWallComp = (event: any) => {
    const floor = this.currentFloor!;
    switch (event.keyCode) {
      case 32: // Space
        this.drawWallComp();
        break;
    }
  };

  drawWallComp = () => {
    const floor = this.currentFloor!;
    const property = this.state.selectedWallProp;
    this.controls_o.enablePan = true;

    removeObjectsByName(floor, ObjName.DrawingWall);
    let wallMesh = MESH.createWallMesh(
      this.clickPoss,
      Number((property!.width! / this.unitLength!.lenthOfEachPixel).toFixed(2)),
      Number((property!.height! / this.unitLength!.lenthOfEachPixel).toFixed(2)),
      property!.color,
      null,
      null,
      Number(
        (property!.rideHeight! / this.unitLength!.lenthOfEachPixel).toFixed(2)
      ),
      this.state.selectedWallProp!.transparency! / 100
    );
    wallMesh.userData.name = property?.name;
    floor.add(wallMesh);

    this.clickPoss = [];
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawWall, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_DrawWall, false);
    // this.deleteGhostCursor();
    this.showDrawingRightMenu = false;
    
    let n = GLINE.AllLines.length;
    const ud = wallMesh.userData;
    GLINE.addLines(ud.points, ud.uuid, false);

    // BUG
    n = this.AllVertex.length;
    for (let i = 1; i <= ud.points.length; i++){
      this.AllVertex[n-i][1] = ud.uuid;
    }
    
  }

  redrawWall = () => {
    const floor = this.currentFloor!;
    this.showDrawingRightMenu = false;
    removeObjectsByName(floor, ObjName.DrawingWall);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_DrawWall, false);
    this.clickPoss = [];
    this.setState({
      showRightClickMenu: false,
    });
  };

  pipeType2Color = (type: any) =>{
    if(type === PipeType.electric) return "#FF0000";
    else if(type === PipeType.gas) return "#FF5F00";
    else if(type === PipeType.water) return "#0489F0";
  }
  //////////// 拾取
  onMouseDown_Pick = (event: any) => {
    if (event.button !== 0) return;
    // 防止点击穿透
    if (!document.getElementById("canvas-container")!.contains(event.target)) {
      return;
    }

    // temp
    // console.log(this.camera_p.position);

    // // 选中文字的逻辑单独处理(no needed)
    // // console.log(event.target);
    // if (
    //   event.target instanceof HTMLDivElement &&
    //   event.target.className === "label"
    // ) {
    //   // console.log("click text label");
    //   return;
    // }
    // if (
    //   event.target instanceof HTMLImageElement &&
    //   event.target.className === "label"
    // ) {
    //   // console.log("click img label");
    //   return;
    // }

    let mouse = this.getMouseStdPos(event);
    if (this.is2D) this.raycaster.setFromCamera(mouse, this.camera_o);
    else this.raycaster.setFromCamera(mouse, this.camera_p);

    // intersects是交点数组
    this.intersects = this.raycaster.intersectObjects(this.scene.children);
    if (this.intersects.length > 0) {
      // 如果选中物体了
      // 本次选中的物体
      var obj = this.intersects[0].object;
      if (obj.visible == false) obj = this.intersects[1].object;
      console.log("pick", obj);

      // 多选
      // console.log("event.ctrlKey", event.ctrlKey);
      if (event.ctrlKey === true) {
        this.multiPick(obj);
        return;
      }
      // 已经有多选的物体，此时取消所有选中物体的状态
      if (this.multiPickedObjs.length !== 0 && event.ctrlKey == false) {
        this.cancelPickedState(this.multiPickedObjs);
        this.multiPickedObjs = [];
        this.isMultiPick = false;
        return;
      }

      // 单选
      if (this.pickedObj !== null) {
        // 如果已经有选中的物体了
        // 取消该物体的选中状态，隐藏面板
        // console.log('取消选中');
        this.cancelPickedState([this.pickedObj]);

        if (this.pickedObj !== null) this.pickedObj.name = "";
        this.pickedObj = null;
      } 

      this.pickedObj = obj;
      this.setPickedState(obj, false);

    } else {
      // 如果没选中物体
      // 取消该物体的选中状态，隐藏面板
      this.cancelPickedState([this.pickedObj]);
      if (this.pickedObj !== null) this.pickedObj.name = "";
      this.pickedObj = null;

      // 取消多选选中状态
      this.isMultiPick = false;
      this.isOneTubePick = false;
      this.cancelPickedState(this.multiPickedObjs);
      this.multiPickedObjs = [];
    }
  };

  cancelPickedState = (objs: Array<any>, cancelPanel: boolean = true) => {
    // 如果在多选状态下，可能不需要取消panel的显示
    // console.log("cancel pick", objs);
    // 取消该物体的选中状态，隐藏面板
    for (let obj of objs) {
      // debugger;
      if (obj === null || obj.userData === undefined) continue;
      switch (obj.userData.type) {
        case ObjType.Wall:
        case ObjType.Room:
          obj.material.color.set(obj.userData.color);
          if (cancelPanel) {
            this.setState({
              selectedWallProp: undefined,
              selectedRoomProp: undefined,
            });
          }
          break;

        case ObjType.Hole:
          obj.material.color.set(obj.userData.color);
          if (cancelPanel) {
            this.setState({
              selectedHoleProp: undefined
            });
          }
          break;

        case ObjType.Fillet:
        case ObjType.Tube:
          console.log("取消管道");
          console.log(this.pickedObj);
          if(this.tubeGroup.userData.tubeID === -1){
            // 取消高亮
            var t = 0;
            var oneTube: any;
            let tubeNumber = this.tubeGroup.children.length;
            for (t = 0; t < tubeNumber; t++) {
              oneTube = this.tubeGroup.children[t];
              if(oneTube.userData.pipeType === PipeType.others){
                oneTube.material.color.set(oneTube.userData.color);
              }
              else{
                if(oneTube.userData.type === ObjType.Tube){
                  oneTube.material.color.set("#FFFFFF");
                }else{
                  oneTube.material.color.set(this.pipeType2Color(oneTube.userData.pipeType));
                }
                
              }
            }
          }else{
            var tubeID = this.tubeGroup.userData.tubeID;
              for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
                var tempTubeGroup = this.MultiTubeInfo.get(tubeID)![i];
                let tubeNumber = tempTubeGroup.children.length;
                for (t = 0; t < tubeNumber; t++) {
                  oneTube = tempTubeGroup.children[t];
                  if(oneTube.userData.pipeType === PipeType.others){
                    oneTube.material.color.set(oneTube.userData.color);
                  }
                  else{
                    if(oneTube.userData.type === ObjType.Tube){
                      oneTube.material.color.set("#FFFFFF");
                    }else{
                      oneTube.material.color.set(this.pipeType2Color(oneTube.userData.pipeType));
                    }
                  }
                }
              }
          }

          this.setState({
            selectedPipeProp: undefined
          });
          break;

        case ObjType.Model:
          removeObjectsByName(this.scene, ObjName.BoxHelper);
          if (cancelPanel) {
            this.setState({
              selectedModelProp: undefined,
            })
          }
          break;

        case ObjType.Text:
          if (cancelPanel) {
            this.setState({
              selectedTextProp: undefined,
            });
          }
          obj.material.color.set(0xffffff);
          // this.pickedObj.element.style.textShadow =
          //   "-1px 0 rgba(255, 255, 255, 1), 0 1px rgba(255, 255, 255, 1), 1px 0 rgba(255, 255, 255, 1), 0 -1px rgba(255, 255, 255, 1)";
          break;

        case ObjType.Picture:
          // this.pickedObj.element.style.borderStyle = "none";
          if (cancelPanel) {
            this.setState({
              selectedPicProp: undefined,
            });
            // obj.material.color.set(0x000000);
          }
          break;

        default:
          break;
      }
    }
    // this.pickedObj = null;
    if (cancelPanel) {
      this.setState({
        selectedMultiProp: undefined,
      });
    }
  };

  private isOneTubePick = false;
  setPickedState = (
    obj: any,
    isMultiObj: boolean,
    isMultiType: boolean = false
  ) => {
    if (isMultiObj) {
      // TODO: 多选不需要显示具体值
      this.isMultiPick = true;
      switch (obj.userData.type) {
        case ObjType.Ground:
        case ObjType.BlankGround:
          break;

        case ObjType.Room:
          obj.material.color.set(0xaa0000);
          if (!isMultiType) {
            this.setState({
              selectedRoomProp: {
                name: "",
                color: "#000000",
                height: undefined,
                rideHeight: undefined,
                transparency: undefined,
                area: undefined,
              },
            });
          }
          break;

        case ObjType.Wall:
          obj.material.color.set(0xaa0000);
          if (!isMultiType) {
            this.setState({
              selectedWallProp: {
                name: "",
                color: "#000000",
                width: undefined,
                height: undefined,
                rideHeight: undefined,
              },
            });
          }
          break;

        case ObjType.Tube:
          // TODO: 选中tube
          // console.log('选中tube');
          // if (!this.is2D) this.Switch2D_3D();
          this.pickedObj = obj;
          this.tubeGroup = obj.parent;
          this.isOneTubePick = true;
          console.log("this.pickedObj.userData.radius",this.pickedObj.userData.radius);
          this.setState({
            selectedPipeProp: {
              name: this.tubeGroup.userData.name,
              color: this.tubeGroup.userData.color,
              radius: Number(
                (
                  this.pickedObj.userData.radius *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              rideHeight: Number(
                (
                  this.pickedObj.userData.stPoint.z *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              type: this.pickedObj.userData.pipeType,
              pattern: PipePattern.default,
            },
          });
          this.pickedObj.material.color.set(0xaa0000);
          // this.onChangeTubeHeight(20);
          break;

        case ObjType.Fillet:
          // TODO: 选中fillet
          // console.log('选中fillet');
          if (!this.is2D) this.switch2D_3D();
          this.tubeGroup = obj.parent;
          this.onChangeTubeColor(0xaa0000);
          break;

        case ObjType.Model:
          obj = obj.parent;
          var box = new THREE.Box3();
          box.setFromObject(obj);
          var helper = new THREE.Box3Helper(box, new THREE.Color(0x0000ff));
          helper.name = ObjName.BoxHelper;
          this.scene.add(helper);
          // box.copy(this.pickedObj.geomerty.boundingBox).applyMatrix4(this.pickedObj.matrixWorld);
          break;

        case ObjType.Picture:
          break;

        case ObjType.Text:
          break;

        default:
          break;
      }
    } else {
      // 单选要显示具体值
      switch (obj.userData.type) {
        case ObjType.Ground:
        case ObjType.BlankGround:
          break;

        case ObjType.Room:
          this.pickedObj = obj;
          obj.material.color.set(0xaa0000);
          this.setState({
            selectedRoomProp: {
              name: this.pickedObj.userData.name,
              color: this.pickedObj.userData.color,
              height: Number(
                (
                  this.pickedObj.userData.height *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              rideHeight: Number(
                (
                  this.pickedObj.userData.rideHeight *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              transparency: this.pickedObj.userData.transparency * 100,
              area: caculateArea(
                this.pickedObj.userData.points,
                this.unitLength
              )!,
            },
          });
          break;

        case ObjType.Wall:
          this.pickedObj = obj;
          obj.material.color.set(0xaa0000);
          this.setState({
            selectedWallProp: {
              name: this.pickedObj.userData.name,
              color: this.pickedObj.userData.color,
              width: Number(
                (
                  this.pickedObj.userData.width *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              height: Number(
                (
                  this.pickedObj.userData.height *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              rideHeight: Number(
                (
                  this.pickedObj.userData.rideHeight *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              transparency: this.pickedObj.userData.transparency * 100,
            },
          });
          break;

        case ObjType.Hole:
          this.pickedObj = obj;
          obj.material.color.set(0xaa0000);
          this.setState({
            selectedHoleProp: {
              name: this.pickedObj.userData.name,
              color: this.pickedObj.userData.color,
              height: Number(
                (
                  this.pickedObj.userData.height *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              rideHeight: Number(
                (
                  this.pickedObj.userData.rideHeight *
                  this.unitLength!.lenthOfEachPixel
                ).toFixed(2)
              ),
              transparency: this.pickedObj.userData.transparency * 100,
              area: caculateArea(
                this.pickedObj.userData.points,
                this.unitLength
              )!,
            },
          });
          break;


        case ObjType.Fillet:
        case ObjType.Tube:

          console.log('选中tube');
          this.pickedObj = obj;
          this.tubeGroup = obj.parent;
          if(this.tubeGroup.userData.tubeID === -1){
            if(this.tubeGroup.children.length === 0) break;
            this.setState({
              selectedPipeProp: {
                name: this.tubeGroup.userData.name,
                color: this.tubeGroup.userData.color,
                radius: Number(
                  (
                    this.pickedObj.userData.radius *
                    this.unitLength!.lenthOfEachPixel
                  ).toFixed(2)
                ),
                rideHeight: Number(
                  (
                    this.tubeGroup.userData.height *
                    this.unitLength!.lenthOfEachPixel
                  ).toFixed(2)
                ),
                type: this.pickedObj.userData.pipeType,
                pattern: PipePattern.default,
              },
            });
            console.log(this.tubeGroup);
            // 高亮
            var t = 0;
            var oneTube: any;
            let tubeNumber = this.tubeGroup.children.length;
            for (t = 0; t < tubeNumber; t++) {
              oneTube = this.tubeGroup.children[t];
              oneTube.material.color.set(0xaa0000);
            }
          }else{
            this.setState({
              selectedPipeProp: {
                name: this.tubeGroup.userData.name,
                color: this.tubeGroup.userData.color,
                radius: Number(
                  (
                    this.pickedObj.userData.radius *
                    this.unitLength!.lenthOfEachPixel
                  ).toFixed(2)
                ),
                rideHeight: Number(
                  (
                    this.MultiTubeInfo
                    .get(this.tubeGroup.userData.tubeID)![0]
                    .userData.height *
                    this.unitLength!.lenthOfEachPixel
                  ).toFixed(2)
                ),
                type: this.pickedObj.userData.pipeType,
                pattern: PipePattern.default,
              },
            });

            var tubeID = this.tubeGroup.userData.tubeID;
            for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
              var tempTubeGroup = this.MultiTubeInfo.get(tubeID)![i];
              let tubeNumber = tempTubeGroup.children.length;
              for (t = 0; t < tubeNumber; t++) {
                oneTube = tempTubeGroup.children[t];
                oneTube.material.color.set(0xaa0000);
              }
            }
          }
          break;

            console.log('选中fillet');
            this.pickedObj = obj;
            this.tubeGroup = obj.parent;
            if(this.tubeGroup.userData.tubeID === -1){
              if(this.tubeGroup.children.length === 0) break;
              this.setState({
                selectedPipeProp: {
                  name: this.tubeGroup.userData.name,
                  color: this.tubeGroup.userData.color,
                  radius: Number(
                    (
                      this.pickedObj.userData.radius *
                      this.unitLength!.lenthOfEachPixel
                    ).toFixed(2)
                  ),
                  rideHeight: Number(
                    (
                      this.tubeGroup.userData.height *
                      this.unitLength!.lenthOfEachPixel
                    ).toFixed(2)
                  ),
                  type: this.pickedObj.userData.pipeType,
                  pattern: PipePattern.default,
                },
              });
              console.log(this.tubeGroup);
              // 高亮
              var t = 0;
              var oneTube: any;
              let tubeNumber = this.tubeGroup.children.length;
              for (t = 0; t < tubeNumber; t++) {
                oneTube = this.tubeGroup.children[t];
                oneTube.material.color.set(0xaa0000);
              }
            }else{
              this.setState({
                selectedPipeProp: {
                  name: this.tubeGroup.userData.name,
                  color: this.tubeGroup.userData.color,
                  radius: Number(
                    (
                      this.pickedObj.userData.radius *
                      this.unitLength!.lenthOfEachPixel
                    ).toFixed(2)
                  ),
                  rideHeight: Number(
                    (
                      this.MultiTubeInfo
                      .get(this.tubeGroup.userData.tubeID)![0]
                      .userData.height *
                      this.unitLength!.lenthOfEachPixel
                    ).toFixed(2)
                  ),
                  type: this.pickedObj.userData.pipeType,
                  pattern: PipePattern.default,
                },
              });

              var tubeID = this.tubeGroup.userData.tubeID;
              for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
                var tempTubeGroup = this.MultiTubeInfo.get(tubeID)![i];
                let tubeNumber = tempTubeGroup.children.length;
                for (t = 0; t < tubeNumber; t++) {
                  oneTube = tempTubeGroup.children[t];
                  oneTube.material.color.set(0xaa0000);
                }
              }
            }
            break;
  
        case ObjType.Model:
          this.pickedObj = obj.parent;
          var box = new THREE.Box3();
          box.setFromObject(this.pickedObj);
          var helper = new THREE.Box3Helper(box, new THREE.Color(0x0000ff));
          helper.name = ObjName.BoxHelper;
          this.scene.add(helper);
          const ud = obj.userData;
          this.setState({
            selectedModelProp: {
              name: ud.data.name,
              imgUrl: ud.data.icon,
              scaleX: ud.scale.x,
              scaleY: ud.scale.z,
              scaleZ: ud.scale.y,
              rideHeight: Number((ud.position.z * this.unitLength!.lenthOfEachPixel).toFixed(2)),
            }
          })
          // box.copy(this.pickedObj.geomerty.boundingBox).applyMatrix4(this.pickedObj.matrixWorld);
          break;

        case ObjType.Picture:
          this.pickedObj = obj;
          // obj.material.color.set(0x0000aa);
          this.setState({
            selectedPicProp: {
              name: obj.userData.name!,
              rideHeight: obj.userData.rideHeight * this.unitLength!.lenthOfEachPixel,
              height: obj.userData.height,
              width: obj.userData.width,
            },
          });
          break;

        case ObjType.Text:
          this.pickedObj = obj;
          obj.material.color.set(0xaa0000);
          this.setState({
            selectedTextProp: {
              content: obj.userData.content,
              rideHeight: obj.userData.pos.z * this.unitLength!.lenthOfEachPixel,
            },
          });
          break;

        default:
          break;
      }
    }
  };

  private multiPickedObjs: any = [];
  multiPick = (obj: any) => {
    const objs = this.multiPickedObjs;

    if (obj.userData.type === ObjType.Ground || obj.userData.type === ObjType.BlankGround)
      return;

    // if (obj.userData.type === ObjType.Model)
    //   obj = obj.parent;

    if (objs.indexOf(obj) !== -1) {
      // 点击的是已选中的，则取消选中
      this.cancelPickedState([obj], false);
      objs.splice(objs.indexOf(obj), 1);
      if (objs.length === 0) this.isMultiPick = false;
      return;
    } else {
      objs.push(obj);
    }
    if (this.pickedObj !== null) {
      this.multiPickedObjs.push(this.pickedObj);
      this.pickedObj = null;
    }

    let type = objs[0].userData.type;
    let singleType = true;
    for (let i = 1; i < objs.length; i++) {
      if (type !== objs[i].userData.type) {
        singleType = false;
        break;
      }
    }

    if (singleType) {
      // this.setState({
      //   selectedMultiProp: undefined,
      // });
      this.setPickedState(obj, true);
    } else {
      this.setPickedState(obj, true, true);
      this.setState({
        selectedWallProp: undefined,
        selectedRoomProp: undefined,
        selectedPipeProp: undefined,
      });
      this.setState({
        selectedMultiProp: {
          name: "",
          rideHeight: 0,
        },
      });
    }

    //console.log("objs", objs);
  };

  startPick = () => {
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_Pick, false);
    this.canPickPicOrText = true;
  };

  ///////// 画房间
  // 点击绘制多边形房间的响应
  onClickDrawPolRoom = () => {
    // this.props.setIsDrawingRoomType(true);
    this.clickPoss = [];
    if (!this.is2D) this.switch2D_3D();

    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawPolRoom, false);
    // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupDrawPolRoom, false);
    // document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchLines, false);
  };


  private showDrawingRightMenu = false;
  onMouseDown_DrawPolRoom = (event: any) => {
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    const floor = this.currentFloor!;

    let mouse;
    let newPoint = this.getMousePos(event, this.camera_o, floor.children);
    let lastClickPoint = this.clickPoss[this.clickPoss.length - 1];
    if(event.shiftKey === true) {
      console.log("shift");
      mouse = getHorizonVertiPoint(lastClickPoint, newPoint);
    }else{
      mouse = this.getMousePos(event, this.camera_o, floor.children);
    }
    if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
    this.clickPoss.push(mouse);
    
    // console.log(this.clickPoss.length)
    // 如果是第一次点击
    if (this.clickPoss.length === 1) {
      // 开始绘制半透明物体
      this.props.setIsDrawingRoomType(true);
      document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_DrawPolRoom, false);
      this.showDrawingRightMenu = true;
    }
    removeObjectsByName(floor, ObjName.DrawingRoom);

    let mesh = MESH.createPolRoomMesh(
      this.clickPoss,
      this.state.selectedRoomProp!.height! / this.unitLength!.lenthOfEachPixel,
      this.state.selectedRoomProp!.color,
      null,
      null,
      this.state.selectedRoomProp!.rideHeight! /
        this.unitLength!.lenthOfEachPixel,
      0.5
    );

    this.AllVertex.push(
      [new THREE.Vector2(mouse.x, mouse.y), mesh.userData.uuid]
    )

    this.setState({
      selectedRoomProp: {
        name: this.state.selectedRoomProp!.name,
        color: this.state.selectedRoomProp!.color,
        height: this.state.selectedRoomProp!.height,
        rideHeight: this.state.selectedRoomProp!.rideHeight,
        transparency: this.state.selectedRoomProp!.transparency,
        area: caculateArea(this.clickPoss, this.unitLength)!,
      },
    });
    mesh.name = ObjName.DrawingRoom;
    floor.add(mesh);

    // let material = new THREE.MeshLambertMaterial({
    //   color: 0x888888, // 注意颜色
    //   transparent: true,
    //   opacity: 0.5,
    // });
    // this.drawGeometry(this.clickPoss, 50, material, this.scene, true);
  };

  onMouseMove_DrawPolRoom = (event: any) => {
    // if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    const floor = this.currentFloor!;

    let mouse;
    if(event.shiftKey === true) {
      console.log("shift");
      let newPoint = this.getMousePos(event, this.camera_o, floor.children);
      let lastClickPoint = this.clickPoss[this.clickPoss.length - 1];
      mouse = getHorizonVertiPoint(lastClickPoint, newPoint);
    }else{
      mouse = this.getMousePosSafe(event, this.camera_o, floor.children);
      if (mouse === null) return;
    }
    if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
    this.clickPoss.push(mouse);

    removeObjectsByName(floor, ObjName.DrawingRoom);
    removeObjectsByName(floor, ObjName.Drawing);

    let mesh = MESH.createPolRoomMesh(
      this.clickPoss,
      this.state.selectedRoomProp!.height! / this.unitLength!.lenthOfEachPixel,
      this.state.selectedRoomProp!.color,
      null,
      null,
      this.state.selectedRoomProp!.rideHeight! /
        this.unitLength!.lenthOfEachPixel,
      0.5
    );
    mesh.name = ObjName.DrawingRoom;
    this.state.selectedRoomProp!.area = caculateArea(
      this.clickPoss,
      this.unitLength
    )!;
    floor.add(mesh);

    
    let line = MESH.createLineMesh(this.clickPoss.slice(-2), 0xaa0000, 0.002);
    line.name = ObjName.Drawing;
    floor.add(line);

    this.clickPoss.pop();
  };

  onClickDrawPolRoomComp = (event: any) => {
    this.props.setIsDrawingRoomType(false);
    const floor = this.currentFloor!;

    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawPolRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawPolRoom,
      false
    );
    // document.getElementById("canvas-container")!.removeEventListener("keyup", this.onKeyupDrawPolRoom, false);

    removeObjectsByName(floor, ObjName.DrawingRoom);
    removeObjectsByName(floor, ObjName.Drawing);

    let mesh = MESH.createPolRoomMesh(
      this.clickPoss,
      this.state.selectedRoomProp!.height! / this.unitLength!.lenthOfEachPixel,
      this.state.selectedRoomProp!.color,
      null,
      null,
      this.state.selectedRoomProp!.rideHeight! /
        this.unitLength!.lenthOfEachPixel,
      this.state.selectedRoomProp!.transparency! / 100
    );
    mesh.userData.name = this.state.selectedRoomProp!.name;
    mesh.userData.transparency =
      this.state.selectedRoomProp!.transparency! / 100;
    floor.add(mesh);
    this.clickPoss = [];
    this.showDrawingRightMenu = false;

    this.onClickDrawRoom();

    const ud = mesh.userData;
    let n = ud.points.length;
    GLINE.addLines(ud.points, ud.uuid, true);

    n = GLINE.AllLines.length;
    console.log(GLINE.AllLines);
    for (let i = 1; i <= mesh.userData.points.length; i++){
      GLINE.AllLines[n-i][4] = mesh.userData.uuid;
    }
  };

  // 绘制矩形房间
  onClickDrawRectRoom = () => {
    this.props.setIsDrawingRoomType(true);
    this.clickPoss = [];
    if (!this.is2D) this.switch2D_3D();
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawRectRoom, false);
    // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupDrawRectRoom, false);
  };

  onMouseDown_DrawRectRoom = (event: any) => {
    const floor = this.currentFloor!;
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    let mouse = this.getMousePos(event, this.camera_o, floor.children);
    if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
    this.clickPoss.push(mouse);
    // 如果是第一次点击，那么要开始绘制半透明物体
    if (this.clickPoss.length === 1) {
      this.props.setIsDrawingRoomType(true);
      document.getElementById("canvas-container")!.addEventListener(
        "mousemove",
        this.onMouseMove_DrawRectRoom,
        false
      );
    } else {
      this.props.setIsDrawingRoomType(false);
      removeObjectsByName(floor, ObjName.DrawingRoom);

      document.getElementById("canvas-container")!.removeEventListener(
        "mousemove",
        this.onMouseMove_DrawRectRoom,
        false
      );
      document.getElementById("canvas-container")!.removeEventListener(
        "mousedown",
        this.onMouseDown_DrawRectRoom,
        false
      );
      let mesh = MESH.createRectRoomMesh(
        this.clickPoss[0],
        this.clickPoss[1],
        this.state.selectedRoomProp!.height! /
          this.unitLength!.lenthOfEachPixel,
        this.state.selectedRoomProp!.color,
        null,
        null,
        this.state.selectedRoomProp!.rideHeight! /
          this.unitLength!.lenthOfEachPixel,
        this.state.selectedRoomProp!.transparency! / 100
      );

      const ud = mesh.userData;
      let pnts = mesh.userData.points;
      this.LastClickPoint = null;
      // add info to all lines
      GLINE.addLines(ud.points, ud.uuid, true);
      for(let i = 0; i < 4; i++){
        let st = pnts[i];
        let ed = pnts[(i+1)%4];
        this.AllVertex.push(
          [new THREE.Vector2(ed.x, ed.y), mesh.userData.uuid]
        );
      }

      mesh.userData.name = this.state.selectedRoomProp!.name;
      mesh.userData.transparency =
        this.state.selectedRoomProp!.transparency! / 100;
      this.setState((state) => ({
        selectedRoomProp: {
          name: state.selectedRoomProp!.name,
          color: state.selectedRoomProp!.color,
          height: state.selectedRoomProp!.height,
          rideHeight: state.selectedRoomProp!.rideHeight,
          transparency: state.selectedRoomProp!.transparency,
          area: caculateArea(mesh.userData.points, this.unitLength)!,
        },
      }));

      this.clickPoss = [];
      floor.add(mesh);
      this.onClickDrawRoom();
    }
  };

  onMouseMove_DrawRectRoom = (event: any) => {
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    const floor = this.currentFloor!;
    let mouse = this.getMousePosSafe(event, this.camera_o, floor.children);
    if (mouse === null) return;
    if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
    this.clickPoss.push(mouse);

    removeObjectsByName(floor, ObjName.DrawingRoom);

    let mesh = MESH.createRectRoomMesh(
      this.clickPoss[0],
      this.clickPoss[1],
      this.state.selectedRoomProp!.height! / this.unitLength!.lenthOfEachPixel,
      this.state.selectedRoomProp!.color,
      null,
      null,
      this.state.selectedRoomProp!.rideHeight! /
        this.unitLength!.lenthOfEachPixel,
      0.5
    );
    mesh.name = ObjName.DrawingRoom;
    floor.add(mesh);

    this.clickPoss.pop();
  };

  onClickDrawRoom = () => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    if (!this.is2D) this.switch2D_3D();
    if (this.props.mode !== Mode.RoomDrawing)
      this.removeSidebarEvent();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Room, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Room, true);

    if (this.state.selectedRoomProp === undefined) {
      this.setState({
        selectedRoomProp: DefaultValues.defaultRoomProp,
      });
    }
    this.generateGhostCursor();
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawRoom, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchLines, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchVertex, false);
  };

  onMouseDown_DrawRoom = (event: any) => {
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    switch (this.props.roomDrawingMode) {
      case RoomDrawingMode.Rectangle:
        this.onMouseDown_DrawRectRoom(event);
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_DrawRectRoom,
          false
        );
        break;
      case RoomDrawingMode.Pollygon:
        this.onMouseDown_DrawPolRoom(event);
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_DrawPolRoom,
          false
        );
        // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupDrawPolRoom);
        break;
      case RoomDrawingMode.Circle:
        break;
    }
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawRoom, false);
  };

  redrawRoom = () => {
    const floor = this.currentFloor!;
    this.props.setIsDrawingRoomType(false);

    removeObjectsByName(floor, ObjName.DrawingRoom);
    removeObjectsByName(floor, ObjName.Drawing);

    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawPolRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawRectRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawPolRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawRectRoom,
      false
    );
    this.clickPoss = [];

    this.onClickDrawRoom();
  };

  /////// 拉伸房间
  onClickStretchRoom = () => {
    const floor = this.currentFloor!;

    // console.log(this.pickedObj);
    if (this.pickedObj !== null) {
      this.setState({showFilter: false});
      this.controls_o.enablePan = false;
      this.pickedObj.name = ObjName.EditShape;
      this.pickedObj.material.color.set(this.pickedObj.userData.color);
      if (this.pickedObj.userData.type == ObjType.Room) {
        this.editVers = [];
        // console.log("pickedObj", this.pickedObj);

        this.editVers = this.pickedObj.userData.points.slice();
        this.editVers = interpVec3(this.editVers);
        

        // 产生点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);

        this.setState({selectedRoomProp: undefined});
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_StretchRoom,
          false
        );
        document.getElementById("canvas-container")!.addEventListener("mouseup", this.onMouseUp_StretchRoom, false);
        document.addEventListener("keyup", this.onKeyupStretchRoomComp, false);
        document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
        this.canPickPicOrText = false;
        this.props.setIsDrawing(true);
      }
    }
  };

  onMouseDown_StretchRoom = (event: any) => {
    this.mouse = this.getMouseStdPos(event);
    this.raycaster.setFromCamera(this.mouse, this.camera_o);
    this.raycaster.params.Points!.threshold = 4;
    // this.editPntAttr = this.editPoints.geometry.attributes;
    // console.log(this.editPoints.geometry);
    let intersects = this.raycaster.intersectObject(this.editPoints);
    if (intersects.length > 0) {
      console.log(intersects[0].index);
      this.INTERSECTED = intersects[0].index;
    }
    document.addEventListener("mousemove", this.onMouseMove_StretchRoom, false);
  };

  onMouseMove_StretchRoom = (event: any) => {
    const floor = this.currentFloor!;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    // this.raycaster.setFromCamera(mouse, this.camera_o);
    console.log(this.editVers);
    console.log(this.INTERSECTED);

    let new_room = scaleRectRoom(this.editVers, mouse, this.INTERSECTED);
    this.editVers = interpVec3(new_room);

    
    const ud = this.pickedObj.userData;
    if (this.pickedObj.userData.type == ObjType.Room ) {
      removeObjectsByName(floor, ObjName.EditShape);
      let mesh = MESH.createPolRoomMesh(
        new_room, 
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    }
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(this.editVers);
    floor.add(this.editPoints);
  };

  onMouseUp_StretchRoom = (event: any) => {
    document.removeEventListener("mousemove", this.onMouseMove_StretchRoom, false);

    const floor = this.currentFloor!;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    // this.raycaster.setFromCamera(mouse, this.camera_o);
    console.log(this.editVers[this.INTERSECTED]);

    let new_room = scaleRectRoom(this.editVers, mouse, this.INTERSECTED);
    this.editVers = interpVec3(new_room);

    removeObjectsByName(floor, ObjName.EditShape);
    const ud = this.pickedObj.userData;
    if (this.pickedObj.userData.type == ObjType.Room ) {
      let mesh = MESH.createPolRoomMesh(
        new_room,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    }
    removeObjectsByName(floor,ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(this.editVers);
    floor.add(this.editPoints);

    this.INTERSECTED = null;
  };

  onKeyupStretchRoomComp = (event: any) => {
    if (event.keyCode != 32 && event.keyCode != 27) return;
    this.controls_o.enablePan = false;
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editVers = null;
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_StretchRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener("mouseup", this.onMouseUp_StretchRoom, false);
    document.removeEventListener("keyup", this.onKeyupStretchRoomComp, false);
    this.startPick();

    let editObj = floor.getObjectByName(ObjName.EditShape);
    if (editObj) {
      editObj.name = "";
    }
    this.setState({showFilter: true});
    this.props.setIsDrawing(false);
    this.pickedObj.name = "";
    this.pickedObj = null;
  };


  //////// 楼板打洞
  onClickDrawHole = () => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    if (!this.is2D) this.switch2D_3D();
    if (this.props.mode !== Mode.HoleDrawing)
      this.removeSidebarEvent();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Hole, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Hole, true);

    if (this.state.selectedHoleProp === undefined) {
      this.setState({
        selectedHoleProp: DefaultValues.defaultHoleProp,
      });
    }
    this.generateGhostCursor();
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawHole, false);
    // document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchLines, false);
    // document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_CatchVertex, false);
  };

  onMouseDown_DrawHole = (event: any) => {
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    switch (this.props.holeDrawingMode) {
      case HoleDrawingMode.Rectangle:
        this.onMouseDown_DrawRectHole(event);
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_DrawRectHole,
          false
        );
        break;
      case HoleDrawingMode.Pollygon:
        this.onMouseDown_DrawPolHole(event);
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_DrawPolHole,
          false
        );
        // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupDrawPolHole);
        break;
      case HoleDrawingMode.Circle:
        break;
    }
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawHole, false);
  };


    // 矩形洞
    onClickDrawRectHol = () => {
      this.props.setIsDrawingHoleType(true);
      this.clickPoss = [];
      if (!this.is2D) this.switch2D_3D();
      document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawRectHole, false);
      // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupDrawRectRoom, false);
    };
  
    windowAlert = (str : string) => {
      const modal = document.createElement('div');
      modal.style.position = 'fixed';
      modal.style.top = '10%';
      modal.style.left = '50%';
      modal.style.transform = 'translateX(-50%)';
      modal.style.padding = '40px';
      modal.style.background = '#ffffff';
      modal.style.border = '2px solid #3498db';
      modal.style.boxShadow = '0 0 20px rgba(0, 0, 0, 0.2)';
      modal.style.zIndex = '1000';
      modal.style.textAlign = 'center';
      modal.style.width = '500px';
      modal.style.margin = 'auto';
      modal.style.fontSize = '18px';
      modal.style.color = '#333333';      
      modal.innerHTML = '<p>' + str + '</p><button id="modalBtn">OK</button>';
      document.body.appendChild(modal);
      const modalBtn = document.getElementById('modalBtn');
      modalBtn!.addEventListener('click', () => {
        modal.remove();
      });
    }
  
    onMouseDown_DrawRectHole = (event: any) => {
      const floor = this.currentFloor!;
      if (event.button !== 0) return;
      if (!document.getElementById("canvas-container")!.contains(event.target))
        return;
  
      let mouse = this.getMousePos(event, this.camera_o, floor.children);
      if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
      this.clickPoss.push(mouse);
      // 如果是第一次点击，那么要开始绘制半透明物体
      if (this.clickPoss.length === 1) {
        this.props.setIsDrawingHoleType(true);
        document.getElementById("canvas-container")!.addEventListener(
          "mousemove",
          this.onMouseMove_DrawRectHole,
          false
        );
      } else {
        this.props.setIsDrawingHoleType(false);
        removeObjectsByName(floor, ObjName.DrawingHole);
  
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawRectHole,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawRectHole,
          false
        );
        var minX = Math.min(this.clickPoss[0].x, this.clickPoss[1].x);
        var minY = Math.min(this.clickPoss[0].y, this.clickPoss[1].y);
        var maxX = Math.max(this.clickPoss[0].x, this.clickPoss[1].x);
        var maxY = Math.max(this.clickPoss[0].y, this.clickPoss[1].y);
        var lowerConner = new Vector2(minX, minY);
        var upperConner = new Vector2(maxX, maxY);
        var p1 = new Vector2(minX, minY);
        var p2 = new Vector2(maxX, minY);
        var p3 = new Vector2(maxX, maxY);
        var p4 = new Vector2(minX, maxY);
        var polyPoints = [p1, p2, p3, p4];
        var interFlag = 0;
        for(let i = 0; i < this.AllPolyHoles.length; i++){
          
          // if(isRectIntersect(
          //   this.AllHoles[i][0], 
          //   this.AllHoles[i][1],
          //   lowerConner,
          //   upperConner
          //   )){
          //     interFlag = 1;
          //     break;
          //   }
          if(isPolyIntersect(
            this.AllPolyHoles[i][1], 
            polyPoints
            )){
              interFlag = 1;
              break;
          }

          // console.log("test inter:", interFlag);
        }
        var floorPoints: THREE.Vector2[] = [];
        console.log(floor);
        for(let obj of floor.children){
          console.log(obj)
          if(obj.userData.type === ObjType.Floor){
            console.log("ObjType.Floor");
            for(let i = 0; i < obj.userData.points.length; i++){
              floorPoints.push(new THREE.Vector2(
                obj.userData.points[i].x,
                obj.userData.points[i].y));
            }
            
            break;
          }  
        }
        
        console.log("floorPoints", floorPoints)
        
        if(!isPolyInside(polyPoints, floorPoints)){
          this.windowAlert("超出楼板范围")
          this.props.setMode(Mode.Browsing);
          this.onClickDrawRectHol();
          return;
        } 
        if(interFlag === 1){
          this.windowAlert("楼板打洞不能交叉！")

          this.clickPoss = [];
          this.onClickDrawRectHol(); 
          return;
        } 
        let mesh = MESH.createRectRoomMesh(
          this.clickPoss[0],
          this.clickPoss[1],
          this.state.selectedHoleProp!.height! /
            this.unitLength!.lenthOfEachPixel,
            this.state.selectedHoleProp!.color!,
          null,
          null,
          this.state.selectedHoleProp!.rideHeight! /
            this.unitLength!.lenthOfEachPixel,
          this.state.selectedHoleProp!.transparency! / 100,
          true
        );
        // this.AllHoles.push([lowerConner, upperConner]);
        this.AllPolyHoles.push([this.holeId, polyPoints]);
        this.holeId++;
        const ud = mesh.userData;
        let pnts = mesh.userData.points;
        this.LastClickPoint = null;
        // add info to all lines
        GLINE.addLines(ud.points, ud.uuid, true);
        for(let i = 0; i < 4; i++){
          let st = pnts[i];
          let ed = pnts[(i+1)%4];
          this.AllVertex.push(
            [new THREE.Vector2(ed.x, ed.y), mesh.userData.uuid]
          );
        }
  
        mesh.userData.name = this.state.selectedHoleProp!.name;
        mesh.userData.transparency =
          this.state.selectedHoleProp!.transparency! / 100;
        this.setState((state) => ({
          selectedHoleProp: {
            name: state.selectedHoleProp!.name,
            color: state.selectedHoleProp!.color,
            height: state.selectedHoleProp!.height,
            rideHeight: state.selectedHoleProp!.rideHeight,
            transparency: state.selectedHoleProp!.transparency,
            area: caculateArea(mesh.userData.points, this.unitLength)!,
          },
        }));
  
        this.clickPoss = [];
        floor.add(mesh);
        this.onClickDrawHole();
      }
    };
  
    onMouseMove_DrawRectHole = (event: any) => {
      if (!document.getElementById("canvas-container")!.contains(event.target))
        return;
      const floor = this.currentFloor!;
      let mouse = this.getMousePosSafe(event, this.camera_o, floor.children);
      if (mouse === null) return;
      if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
      this.clickPoss.push(mouse);
  
      removeObjectsByName(floor, ObjName.DrawingHole);
      removeObjectsByName(floor, ObjName.Drawing);
      let mesh = MESH.createRectRoomMesh(
        this.clickPoss[0],
        this.clickPoss[1],
        this.state.selectedHoleProp!.height! / this.unitLength!.lenthOfEachPixel,
        this.state.selectedHoleProp!.color,
        null,
        null,
        this.state.selectedHoleProp!.rideHeight! /
          this.unitLength!.lenthOfEachPixel,
        0.5,
        true
      );
      mesh.name = ObjName.DrawingHole;
      floor.add(mesh);
  
      this.clickPoss.pop();
    };

    // 多边形洞
    onClickDrawPolHole = () => {
      console.log("start_draw_poly_hole")
      this.clickPoss = [];
      if (!this.is2D) this.switch2D_3D();
      document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DrawPolHole, false);
    }

    onMouseDown_DrawPolHole = (event: any) => {
      if (event.button !== 0) return;
      if (!document.getElementById("canvas-container")!.contains(event.target))
        return;
  
      const floor = this.currentFloor!;
  
      let mouse;
      let newPoint = this.getMousePos(event, this.camera_o, floor.children);
      let lastClickPoint = this.clickPoss[this.clickPoss.length - 1];
      mouse = this.getMousePos(event, this.camera_o, floor.children);
      
      if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
      this.clickPoss.push(mouse);
      
      // console.log(this.clickPoss.length)
      // 如果是第一次点击
      if (this.clickPoss.length === 1) {
        // 开始绘制半透明物体
        this.props.setIsDrawingHoleType(true);
        document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_DrawPolHole, false);
        this.showDrawingRightMenu = true;
      }
      removeObjectsByName(floor, ObjName.DrawingHole);
  
      let mesh = MESH.createPolRoomMesh(
        this.clickPoss,
        this.state.selectedHoleProp!.height! / this.unitLength!.lenthOfEachPixel,
        this.state.selectedHoleProp!.color,
        null,
        null,
        this.state.selectedHoleProp!.rideHeight! /
          this.unitLength!.lenthOfEachPixel,
        0.5,
        true
      );
  
      this.AllVertex.push(
        [new THREE.Vector2(mouse.x, mouse.y), mesh.userData.uuid]
      )
  
      this.setState({
        selectedHoleProp: {
          name: this.state.selectedHoleProp!.name,
          color: this.state.selectedHoleProp!.color,
          height: this.state.selectedHoleProp!.height,
          rideHeight: this.state.selectedHoleProp!.rideHeight,
          transparency: this.state.selectedHoleProp!.transparency,
          area: caculateArea(this.clickPoss, this.unitLength)!,
        },
      });
      mesh.name = ObjName.DrawingHole;
      floor.add(mesh);
  
      // let material = new THREE.MeshLambertMaterial({
      //   color: 0x888888, // 注意颜色
      //   transparent: true,
      //   opacity: 0.5,
      // });
      // this.drawGeometry(this.clickPoss, 50, material, this.scene, true);
    };


    onMouseMove_DrawPolHole = (event: any) => {
      // if (event.button !== 0) return;
      if (!document.getElementById("canvas-container")!.contains(event.target))
        return;
  
      const floor = this.currentFloor!;
  
      let mouse;

      mouse = this.getMousePosSafe(event, this.camera_o, floor.children);
      if (mouse === null) return;

      if (this.GhostCursor) mouse = this.GhostCursor.position.clone();
      this.clickPoss.push(mouse);
  
      removeObjectsByName(floor, ObjName.DrawingHole);
      removeObjectsByName(floor, ObjName.Drawing);
  
      let mesh = MESH.createPolRoomMesh(
        this.clickPoss,
        this.state.selectedHoleProp!.height! / this.unitLength!.lenthOfEachPixel,
        this.state.selectedHoleProp!.color,
        null,
        null,
        this.state.selectedHoleProp!.rideHeight! /
          this.unitLength!.lenthOfEachPixel,
        0.5,
        true
      );
      mesh.name = ObjName.DrawingHole;
      this.state.selectedHoleProp!.area = caculateArea(
        this.clickPoss,
        this.unitLength
      )!;
      floor.add(mesh);
  
      
      let line = MESH.createLineMesh(this.clickPoss.slice(-2), 0xaa0000, 0.002);
      line.name = ObjName.Drawing;
      floor.add(line);
  
      this.clickPoss.pop();
    };

    onClickDrawPolHoleComp = (event: any) => {
      this.props.setIsDrawingHoleType(false);
      const floor = this.currentFloor!;
  
      document.getElementById("canvas-container")!.removeEventListener(
        "mousemove",
        this.onMouseMove_DrawPolHole,
        false
      );
      document.getElementById("canvas-container")!.removeEventListener(
        "mousedown",
        this.onMouseDown_DrawPolHole,
        false
      );
      // document.getElementById("canvas-container")!.removeEventListener("keyup", this.onKeyupDrawPolRoom, false);
  
      removeObjectsByName(floor, ObjName.DrawingHole);
      removeObjectsByName(floor, ObjName.Drawing);
  
      var poly = []
      for(var i = 0; i < this.clickPoss.length; i++){
        poly.push(new THREE.Vector2(this.clickPoss[i].x, this.clickPoss[i].y));
      }

      var floorPoints: THREE.Vector2[] = [];
      console.log(floor);
      for(let obj of floor.children){
        console.log(obj)
        if(obj.userData.type === ObjType.Floor){
          console.log("ObjType.Floor");
          for(let i = 0; i < obj.userData.points.length; i++){
            floorPoints.push(new THREE.Vector2(
              obj.userData.points[i].x,
              obj.userData.points[i].y));
          }
          
          break;
        }  
      }
      
      console.log("floorPoints", floorPoints)
      
      if(!isPolyInside(poly, floorPoints)){
        this.windowAlert("超出楼板范围")

        this.clickPoss = [];
        this.onClickDrawPolHole();
        return;
      } 


      var interFlag = 0;
      for(let i = 0; i < this.AllPolyHoles.length; i++){
        if(isPolyIntersect(
          this.AllPolyHoles[i][1], 
          poly
          )){
            interFlag = 1;
            break;
          }
      }
      if(interFlag === 1){
        this.windowAlert("楼板打洞不能交叉");
        this.clickPoss = [];
        this.onClickDrawPolHole();
        return;
      } 

      let mesh = MESH.createPolRoomMesh(
        this.clickPoss,
        this.state.selectedHoleProp!.height! / this.unitLength!.lenthOfEachPixel,
        this.state.selectedHoleProp!.color,
        null,
        null,
        this.state.selectedHoleProp!.rideHeight! /
          this.unitLength!.lenthOfEachPixel,
        this.state.selectedHoleProp!.transparency! / 100,
        true
      );
      mesh.userData.name = this.state.selectedHoleProp!.name;
      mesh.userData.transparency =
        this.state.selectedHoleProp!.transparency! / 100;
      floor.add(mesh);

      
      this.AllPolyHoles.push([this.holeId, poly]);
      this.holeId++;
      this.clickPoss = [];
      this.showDrawingRightMenu = false;
  
      this.onClickDrawHole();
  
      const ud = mesh.userData;
      let n = ud.points.length;
      GLINE.addLines(ud.points, ud.uuid, true);
  
      n = GLINE.AllLines.length;
      console.log(GLINE.AllLines);
      for (let i = 1; i <= mesh.userData.points.length; i++){
        GLINE.AllLines[n-i][4] = mesh.userData.uuid;
      }
    };


  //////// 编辑房间和墙体形状
  private editPoints: any;
  private editVers: any = null;
  private isChangeTubeShape3D : boolean = false;
  onClickChangeShape = (event: any) => {
    const floor = this.currentFloor!;

    // console.log(this.pickedObj);
    if (this.pickedObj !== null) {
      this.setState({showFilter: false});
      this.pickedObj.name = ObjName.EditShape;
      this.pickedObj.material.color.set(this.pickedObj.userData.color);
      this.canPickPicOrText = false;
      if (this.pickedObj.userData.type == ObjType.Room || 
          this.pickedObj.userData.type == ObjType.Hole ||
          this.pickedObj.userData.type == ObjType.Wall) {
        this.controls_o.enablePan = false;
        this.editVers = [];
        // console.log("pickedObj", this.pickedObj);

        this.editVers = this.pickedObj.userData.points;

        // 产生点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);

      this.setState({selectedRoomProp: undefined});
      this.setState({selectedHoleProp: undefined});
      this.setState({selectedWallProp: undefined});
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_ChangeShape,
          false
        );
        document.getElementById("canvas-container")!.addEventListener("mouseup", this.onMouseUp_ChangeShape, false);
        document.addEventListener("keyup", this.onKeyupChangeShapeComp, false);
        document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
        this.props.setIsDrawing(true);
        console.log(this.pickedObj.name);
      }else if(this.pickedObj.userData.type == ObjType.Tube||
        this.pickedObj.userData.type == ObjType.Fillet){
        this.controls_o.enablePan = false;
        this.controls_p.enablePan = false;
        this.onChangeTubeColor(this.tubeGroup.userData.color);
        this.editVers = [];

        for(let i = 0; i < this.tubeGroup.children.length; i++){
          if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
            this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
          }
          if(i == this.tubeGroup.children.length - 1){
            var tempTube  = this.tubeGroup.children[i];
            var oldPoint = tempTube.userData.edPoint;
            var times = 0;
            var radius = this.tubeGroup.userData.radius;
            var newPoint = new THREE.Vector3(
              oldPoint.x - times* radius * tempTube.userData.edDir.x ,
              oldPoint.y - times* radius * tempTube.userData.edDir.y ,
              oldPoint.z - times* radius * tempTube.userData.edDir.z 
            );
            this.editVers.push(newPoint);
          }
        }
        
        
        console.log(this.tubeGroup.children.length);
        console.log("this.editVers", this.editVers);
        this.editPoints = MESH.createEditPoints(
          this.editVers, 
          this.tubeGroup.userData.rotation, 
          0x880000,
          ObjName.EditShapePnt,
          8.0,
          0.6,
          true);
        floor.add(this.editPoints);

        this.setState({selectedPipeProp: undefined});
        document.getElementById("canvas-container")!.addEventListener(
          "mousedown",
          this.onMouseDown_ChangeShape,
          false
        );
        document.getElementById("canvas-container")!.addEventListener("mouseup", this.onMouseUp_ChangeShape, false);
        document.addEventListener("keyup", this.onKeyupChangeShapeComp, false);
        document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
        this.props.setIsDrawing(true);
        console.log(this.pickedObj.name);
      }
    }
  };
  
  private originUd = null;
  // 总的编辑房间顶点，包括移动、添加、删除
  // 操作的出口：
  //
  onClickEditPnt = (event: any) => {
    const floor = this.currentFloor!;

    // console.log(this.pickedObj);
    if (this.pickedObj == null) return;
    this.originUd = JSON.parse(JSON.stringify(this.pickedObj.userData));
    this.setState({showFilter: false});
    this.pickedObj.name = ObjName.EditShape;
    this.pickedObj.material.color.set(this.pickedObj.userData.color);
    if (this.pickedObj.userData.type == ObjType.Room || 
        this.pickedObj.userData.type == ObjType.Hole ||
        this.pickedObj.userData.type == ObjType.Wall) {
      this.controls_o.enablePan = false;
      this.editVers = [];
      // console.log("pickedObj", this.pickedObj);

      this.editVers = this.pickedObj.userData.points;

      // 产生点
      this.editPoints = MESH.createEditPoints(this.editVers);
      floor.add(this.editPoints);

      this.setState({selectedRoomProp: undefined});
      this.setState({selectedWallProp: undefined});
      this.setState({selectedHoleProp: undefined});
      document.getElementById("canvas-container")!.addEventListener(
        "mousedown",
        this.onMouseDown_EditPnt,
        false
      );
      document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_EditPnt, false);
      // document.getElementById("canvas-container")!.addEventListener("mouseup", this.onMouseUp_ChangeShape, false);
      document.addEventListener("keyup", this.onKeyupEditPntComp, false);
      document.removeEventListener("keydown", this.onClickEsc, false);
      document.addEventListener("keyup", this. onKeyupDel, false);
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
      this.props.setIsDrawing(true);
      console.log(this.pickedObj.name);
    }
  }

  onMouseMove_EditPnt = (event: any) => {
    // 判断光标离线段的距离，显示添加顶点的红点
    const floor = this.currentFloor!;
    if(this.addRedPnt !== null)
      floor.remove(this.addRedPnt);
    this.addRedPnt  = null;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    const ud = this.pickedObj.userData;
    // 如果是墙或管道等不封闭的形状
    let isclosed = ud.type === ObjType.Room || ud.type === ObjType.Floor;
    let res = calNearestLine(ud.points, mouse, isclosed);
    let foot = res.foot, idx = res.idx, dist = res.dist;
    if (foot === null || dist > 5) return;
    // 判断新加的点距离线段端点的距离
    let dist1 = calPntsDist(foot, ud.points[idx]);
    let dist2 = calPntsDist(foot, ud.points[(idx+1)%ud.points.length]);
    console.log(dist1, dist2);
    if (dist1 < 5 || dist2 < 5) return;

    // TODO: tube need to pass in rotation?
    if (this.pickedObj.userData.type === ObjType.Floor) {
      this.addRedPnt = MESH.createEditPoints([foot], [0,0,0,'XYZ'], 0xf80000, ObjName.EditShapePnt, 12.0, 1.0);
    }
    else {
      this.addRedPnt = MESH.createEditPoints([foot], [0,0,0,'XYZ'], 0x880000);
    }
    floor.add(this.addRedPnt);
  }

  private mouseDownTime_EditPnt: any = null;
  onMouseDown_EditPnt = (event: any) => {
    const floor = this.currentFloor!;
    if(this.addRedPnt !== null)
      floor.remove(this.addRedPnt);
    this.addRedPnt  = null;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    const ud = this.pickedObj.userData;
    // 如果是墙或管道等不封闭的形状
    let isclosed = ud.type !== ObjType.Wall;
    let res = calNearestLine(ud.points, mouse, isclosed);
    let foot = res.foot, idx = res.idx, dist = res.dist;
    if (foot === null || dist > 5) return;
    // 判断新加的点距离线段端点的距离
    let dist1 = calPntsDist(foot, ud.points[idx]);
    let dist2 = calPntsDist(foot, ud.points[(idx+1)%ud.points.length]);
    if (dist1 < 5 || dist2 < 5) {
      ////// 选中顶点
      // 获取选中顶点的索引
      this.mouseDownTime_EditPnt = event.timeStamp;
      this.mouse = this.getMouseStdPos(event);
      this.raycaster.setFromCamera(this.mouse, this.camera_o);
      this.raycaster.params.Points!.threshold = 4;
      let intersects = this.raycaster.intersectObject(this.editPoints);
      if (intersects.length > 0) {
        // console.log('success', intersects[0].index);
        this.INTERSECTED = intersects[0].index;
      }
      this.onMouseDown_ChangeShape(event);
      document.addEventListener("mouseup", this.onMouseUp_EditPnt, false);
    }
    else {
      // 选中边
      this.onMouseDown_AddPnt(event);
    }
  }

  onMouseUp_EditPnt = (event: any) => {
    // need to save INTERSECTED
    let flag = false;
    if (this.mouseDownTime_EditPnt && 
      event.timeStamp - this.mouseDownTime_EditPnt < 200) {
      // 点击
      flag = true;
    }
    if (flag) var t = this.INTERSECTED;
    // 拖拽，由于松开之前可能已经移动了，因此无论长按还是短按，都需要执行松开拖拽函数
    this.onMouseUp_ChangeShape(event);
    if (flag) this.INTERSECTED = t;
  }

  onKeyupDel = (event: any) => {
    if (this.INTERSECTED === null) return;
    // press d
    if (event.keyCode != 68 && event.keyCode != 27) return;
    document.addEventListener("keydown", this.onClickEsc, false);
    this.delPnt();
  }

  onKeyupEditPntComp = (event: any) => {
    const floor = this.currentFloor!;
    if (event.keyCode != 32 && event.keyCode != 27) return;
    if (event.keyCode == 27) {
      // console.log('esc esc esc');
      // console.log('this.pickedObj', this.pickedObj);
      floor.remove(this.pickedObj);
      removeObjectsByName(floor, ObjName.EditShape);
      removeObjectsByName(floor, ObjName.Drawing);
      this.pickedObj = this.cancelEditPnt(this.originUd);
      this.pickedObj.name = ObjName.EditShape;
      console.log(2333, this.pickedObj);
      floor.add(this.pickedObj);
    }
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_AddPnt,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_AddPnt, false);
    document.removeEventListener("keyup", this.onKeyupAddPntComp, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_EditPnt, false);
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_EditPnt,
      false
    );
    document.removeEventListener("keyup", this.onKeyupEditPntComp, false);
    document.addEventListener("keydown", this.onClickEsc, false);

    this.controls_o.enablePan = true;
    removeObjectsByName(floor, ObjName.EditShapePnt);
    const ud = this.pickedObj.userData;
    GLINE.removeLinesByObjUuid(ud.uuid);
    GLINE.addLines(ud.points, ud.uuid, ud.type == ObjType.Room);
    this.editVers = null;
    if (this.addRedPnt)
      floor.remove(this.addRedPnt);
    this.addRedPnt = null;
    
    this.INTERSECTED = null;
    this.startPick();

    let editObj = floor.getObjectByName(ObjName.EditShape);
    if (editObj) {
      editObj.name = "";
    }
    this.setState({showFilter: true});
    this.props.setIsDrawing(false);
    this.pickedObj.name = "";
    if (this.pickedObj.userData.type === ObjType.Floor){
      this.pickedObj.material.opacity = 0.5;
      this.pickedObj.material.transparent = true;
      removeObjectsByName(floor, ObjName.Drawing);
    }
    this.pickedObj = null;
    this.originUd = null;
  }

  cancelEditPnt = (ud: any) => {
    let mesh = null;
    if (ud.type == ObjType.Room) {
      mesh = MESH.createPolRoomMesh(
        ud.points, 
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh.userData.name = ud.name;
    }
    else if (ud.type == ObjType.Wall) {
      mesh = MESH.createWallMesh(
        ud.points,
        ud.width,
        ud.height,
        ud.color,
        ud.uuid,
        // ud.rotation,
        [0,0,0,'XYZ'],
        ud.rideHeight,
        ud.transparency,
      );
      mesh.userData.name = ud.name;
    }
    else if (ud.type == ObjType.Floor) {
      let material = new THREE.MeshLambertMaterial({
        color: 0xaaaaaa,
        // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
        transparent: true,
        opacity: 0.5,
        depthTest: true,
        side: THREE.DoubleSide,
      }); 
      let newGeo = GEO.createPlaneGeometry(ud.points);
      mesh = new THREE.Mesh(newGeo, material);
      mesh.userData.points = ud.points;
      mesh.userData.type = ObjType.Floor;
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
    }
    return mesh;
  }

  onClickAddPoint = () => {
    const floor = this.currentFloor!;

    if (this.pickedObj !== null) {
      this.setState({showFilter: false});
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
      this.canPickPicOrText = false;

      this.pickedObj.name = ObjName.EditShape;
      this.pickedObj.material.color.set(this.pickedObj.userData.color);
      if (this.pickedObj.userData.type == ObjType.Room) {
        this.editVers = [];
        this.editVers = this.pickedObj.userData.points;
        // console.log(this.editVers);

        // 产生红色顶点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);

        this.setState({selectedRoomProp: undefined});        
      } 
      else if (this.pickedObj.userData.type == ObjType.Wall) {
        this.editVers = [];
        this.editVers = this.pickedObj.userData.points;

        // 产生红色顶点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);
        
        this.setState({selectedWallProp: undefined});
      } 
      else if (this.pickedObj.userData.type === ObjType.Floor) {
        this.editVers = [];
        this.editVers = this.pickedObj.userData.points;

        // 产生红色顶点
        this.editPoints = MESH.createEditPoints(this.editVers, [0,0,0,'XYZ'], 0xf80000, ObjName.EditShapePnt, 12.0, 1.0);
        floor.add(this.editPoints);
      }
    }
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_AddPnt, false);
    document.addEventListener("keyup", this.onKeyupAddPntComp, false);
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_AddPnt, false);
    this.canPickPicOrText = false;
    this.props.setIsDrawing(true);
  };

  private addRedPnt: any = null;
  onMouseMove_AddPnt = (event: any) => {
    const floor = this.currentFloor!;
    if(this.addRedPnt !== null)
      floor.remove(this.addRedPnt);
    this.addRedPnt  = null;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    const ud = this.pickedObj.userData;
    // 如果是墙或管道等不封闭的形状
    let isclosed = ud.type === ObjType.Room || ud.type === ObjType.Floor;
    let res = calNearestLine(ud.points, mouse, isclosed);
    let foot = res.foot, idx = res.idx, dist = res.dist;
    if (foot === null || dist > 5) return;
    // 判断新加的点距离线段端点的距离
    let dist1 = calPntsDist(foot, ud.points[idx]);
    let dist2 = calPntsDist(foot, ud.points[(idx+1)%ud.points.length]);
    console.log(dist1, dist2);
    if (dist1 < 5 || dist2 < 5) return;
    if (this.pickedObj.userData.type === ObjType.Floor) {
      this.addRedPnt = MESH.createEditPoints([foot], [0,0,0,'XYZ'], 0xf80000, ObjName.EditShapePnt, 12.0, 1.0);
    }
    else { //if (ud.type === ObjType.Ground || ud.type === ObjType.BlankGround) {
      this.addRedPnt = MESH.createEditPoints([foot], [0,0,0,'XYZ'], 0x880000);
    }
    floor.add(this.addRedPnt);
  };

  onMouseDown_AddPnt = (event: any) => {
    // document.removeEventListener("mousemove", this.onMouseMove_ChangeShape, false);

    const floor = this.currentFloor!;
    const ud = this.pickedObj.userData;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    // 如果是墙或管道等不封闭的形状
    let isclosed = ud.type === ObjType.Room || ud.type === ObjType.Floor;
    let res = calNearestLine(ud.points, mouse, isclosed);
    if (res.foot === null || res.dist > 5) return;
    let dist1 = calPntsDist(res.foot, ud.points[res.idx]);
    let dist2 = calPntsDist(res.foot, ud.points[(res.idx+1)%ud.points.length]);
    if (dist1 < 5 || dist2 < 5) return;

    let mesh;
    if (ud.type === ObjType.Room) {
      mesh = this.addRoomPoint(this.pickedObj, res.foot, res.idx);
    } 
    else if(ud.type === ObjType.Hole) {
      mesh = this.addHolePoint(this.pickedObj, res.foot, res.idx)
    }
    else if (ud.type === ObjType.Wall) {
      mesh = this.addWallPoint(this.pickedObj, res.foot, res.idx);
    }
    else { //if (ud.type === ObjType.Ground || ud.type === ObjType.BlankGround) {
      mesh = this.addGroundPoint(this.pickedObj, res.foot, res.idx);
    }

    removeObjectsByName(floor, ObjName.EditShape);
    mesh.userData.name = ud.name;
    mesh.name = ObjName.EditShape;
    floor.add(mesh);
    this.pickedObj = mesh;

    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(mesh.userData.points);
    floor.add(this.editPoints);

    console.log(mesh.userData.points);
  };

  onKeyupAddPntComp = (event: any) => {
    if (event.keyCode != 32) return;
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editVers = null;
    if (this.addRedPnt)
      floor.remove(this.addRedPnt);
    this.addRedPnt = null;
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_AddPnt,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_AddPnt, false);
    document.removeEventListener("keyup", this.onKeyupAddPntComp, false);
    this.startPick();

    this.pickedObj.name = "";
    this.pickedObj = null;
    let editObj = floor.getObjectByName(ObjName.EditShape);
    if (editObj) {
      editObj.name = "";
    }
    this.setState({showFilter: true});
    this.props.setIsDrawing(false);
  };

  addRoomPoint = (pickedRoom: any, newPnt: Vector3, idx: number) => {
    const ud = pickedRoom.userData;
    let points = this.editVers.slice(0);
    points.splice(idx + 1, 0, newPnt);
    this.editVers = points.slice(0);
    // console.log('old', ud.points);
    // console.log('new', points);
    let isHole = false;
    let newRoom = MESH.createPolRoomMesh(
      points,
      ud.height,
      ud.color,
      ud.uuid,
      // ud.rotation,
      [0,0,0,'XYZ'],
      ud.rideHeight,
      ud.transparency,
      isHole
    );
    return newRoom;
  };

  addHolePoint = (pickedHole: any, newPnt: Vector3, idx: number) => {
    const ud = pickedHole.userData;
    let points = this.editVers.slice(0);
    points.splice(idx + 1, 0, newPnt);
    this.editVers = points.slice(0);
    // console.log('old', ud.points);
    // console.log('new', points);
    let newHole = MESH.createPolRoomMesh(
      points,
      ud.height,
      ud.color,
      ud.uuid,
      // ud.rotation,
      [0,0,0,'XYZ'],
      ud.rideHeight,
      ud.transparency,
      true
    );
    return newHole;
  };

  addWallPoint = (pickedWall: any, newPnt: Vector3, idx: number) => {
    const ud = pickedWall.userData;
    // let points = ud.points.slice(0);
    this.editVers.splice(idx + 1, 0, newPnt);
    let points = this.editVers.slice(0);
    // console.log('old', ud.points);
    // console.log('new', points);
    let newWall = MESH.createWallMesh(
      points,
      ud.width,
      ud.height,
      ud.color,
      ud.uuid,
      // ud.rotation,
      [0,0,0,'XYZ'],
      ud.rideHeight,
      ud.transparency,
    );
    return newWall;
  };

  addGroundPoint = (pickedGround: THREE.Mesh<THREE.ShapeGeometry, THREE.Material>, newPnt: Vector3, idx: number) => {
    let material = pickedGround.material;
    // let geo = pickedGround.geometry;
    const ud = pickedGround.userData;
    let points = this.editVers.slice(0);
    // console.log("idx", idx);
    // console.log("old", points);
    points.splice(idx + 1, 0, newPnt);
    // console.log("new", points);
    this.editVers = points.slice(0);

    let newGeo = GEO.createPlaneGeometry(points);
    let newGround = new THREE.Mesh(newGeo, material);
    newGround.userData.points = points;
    newGround.userData.type = ud.type;
    return newGround;
  }

  private INTERSECTED: any; // 选中顶点的index
  // private editPntAttr: any;
  onMouseDown_ChangeShape = (event: any) => {
    this.mouse = this.getMouseStdPos(event);
    this.raycaster.setFromCamera(this.mouse, this.camera_o);
    if(this.isChangeTubeShape3D){
      this.raycaster.setFromCamera(this.mouse, this.camera_p);
    }
    this.raycaster.params.Points!.threshold = 4;
    // this.editPntAttr = this.editPoints.geometry.attributes;
    // console.log(this.editPoints.geometry);
    let intersects = this.raycaster.intersectObject(this.editPoints);
    if (intersects.length > 0) {
      console.log(intersects[0].index);
      this.INTERSECTED = intersects[0].index;
      document.addEventListener("mousemove", this.onMouseMove_ChangeShape, false);
    }
    if(this.isChangeTubeShape3D){
      this.refPlane = this.createRefPlane();
      this.refPlane.position.set(
        this.editVers[this.INTERSECTED].x,
        this.editVers[this.INTERSECTED].y,
        0
      );
      this.currentFloor!.add(this.refPlane);
    }
  };

  onMouseMove_ChangeShape = (event: any) => {
    
    const floor = this.currentFloor!;
    var mouse;
    if(!this.isChangeTubeShape3D){
      mouse = this.getMousePos(event, this.camera_o, floor.children);
      // this.raycaster.setFromCamera(mouse, this.camera_o);
      console.log(this.editVers);
      console.log(this.INTERSECTED);
      this.editVers[this.INTERSECTED].x = mouse.x;
      this.editVers[this.INTERSECTED].y = mouse.y;
    }else{
      this.setState({
        adjustHeightMenuProps: {
          isVisible: true, 
          height: -1, 
          position: {x: event.x, y: event.y}},
      });
      mouse = this.getMousePos(event, this.camera_p, floor.children);
      this.editVers[this.INTERSECTED].z = mouse.z;
    }
    

    
    const ud = this.pickedObj.userData;
    if (this.pickedObj.userData.type == ObjType.Room ) {
      removeObjectsByName(floor, ObjName.EditShape);
      let mesh = MESH.createPolRoomMesh(
        this.editVers,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    } else if(this.pickedObj.userData.type == ObjType.Hole){
      removeObjectsByName(floor, ObjName.EditShape);
      console.log("ud.color: ", ud.color)
      let mesh = MESH.createPolRoomMesh(
        this.editVers,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
        true
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    }
    
    else if (this.pickedObj.userData.type == ObjType.Wall) {
      removeObjectsByName(floor, ObjName.EditShape);
      let mesh = MESH.createWallMesh(
        this.editVers,
        ud.width,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    } else if (this.pickedObj.userData.type == ObjType.Tube||
      this.pickedObj.userData.type == ObjType.Fillet){
        if(this.tubeGroup.userData.tubeID >= 0){
          this.onMouseMove_ChangeShape_MultiTube(new Vector2(mouse.x, mouse.y));
        }else{
          console.log("previous tubegroup", this.tubeGroup);

          // 先删除管道
          var temp_uuids = [];
          for(let i = 0; i < this.tubeGroup.children.length; i++){
            if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
              this.tubeGroup.children[i].userData.edDir.z === 0){
                temp_uuids.push(this.tubeGroup.children[i].uuid);
              }
          }
          var tempDir = this.tubeGroup.children[this.tubeGroup.children.length - 1].userData.edDir;
      
  
          for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
            let oneTube = this.tubeGroup.children[i];
            this.tubeGroup.remove(oneTube);
          }
          // 再根据editVers重建管道
          let radius = this.tubeGroup.userData.radius;
          let color = this.tubeGroup.userData.color;
          let pipeType = this.tubeGroup.userData.pipeType;
          let textureDir = this.tubeGroup.userData.textureDir;
          let tempEditVers = [];
          for(let i = 0; i < this.editVers.length - 1; i++){
            tempEditVers.push(this.editVers[i]);
          }
          var oldPoint = this.editVers[this.editVers.length - 1];

          this.setState({
            adjustHeightMenuProps: {
              isVisible: true, 
              height: Number((oldPoint.z * this.unitLength!.lenthOfEachPixel).toFixed(2)), 
              position: {x: event.x, y: event.y}},
          });


          var times = 2;
          tempEditVers.push(
            new THREE.Vector3(
              oldPoint.x,
              oldPoint.y  ,
              oldPoint.z 
            )
          )
  
  
          let newTubeGroup = MESH.createWholeTubeMeshWithoutSpare(tempEditVers,
            radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
          console.log("now tubegroup", newTubeGroup);
          let cnt = 0;
          var k = 0;
          for(let i = 0; i < newTubeGroup.length; i++){
            cnt++;
            this.tubeGroup.add(newTubeGroup[i]);
            if(newTubeGroup[i].userData.type === ObjType.Tube &&
              newTubeGroup[i].userData.edDir.z === 0){
              console.log("Tube", i);
              for(let j = 0; j < GLINE.AllLines.length; j++){
                if(GLINE.AllLines[j][4] === temp_uuids[k]){
                  console.log("Find", k);
                  GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
                  GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
                  GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
                  k++;
                  break;
                }
              }
            }
          }
          console.log("cnt", cnt);
        }
        removeObjectsByName(floor, ObjName.EditShapePnt);
        this.editPoints = MESH.createEditPoints(this.editVers, ud.rotation);
        floor.add(this.editPoints);
        return;
    } else if (ud.type === ObjType.Floor) {
      // let material = this.pickedObj.material.clone();
      // material.opacity = 0.5;
      // material.transparent = true;
      // material.color = 0x88888;
      // material.map = null;
      let material = new THREE.MeshLambertMaterial({
        color: 0xaaaaaa,
        // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
        transparent: true,
        opacity: 1 - ud.transparent,
        depthTest: true,
        side: THREE.DoubleSide,
      }); 
      this.pickedObj.material = material;
      // console.log("material", material);
      let points = ud.points.slice(0);
  
      let newGeo = GEO.createPlaneGeometry(points);
      let newGround = new THREE.Mesh(newGeo, material);
      newGround.userData.points = points;

      removeObjectsByName(floor, ObjName.EditShape);
      newGround!.userData.name = ud.name;
      newGround!.userData.transparent = ud.transparent;
      newGround!.name = ObjName.EditShape;
      floor.add(newGround);
    }
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(this.editVers, ud.rotation);
    floor.add(this.editPoints);
  };

  onMouseMove_ChangeShape_OneFloorofMultiTube = (pos : Vector2, currenntTubeGroup: THREE.Group, type : Number) => {
    // type 0 : lowest
    // type 1 : middel
    // type 2 : highest
    this.tubeGroup = currenntTubeGroup;
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
      }
      if(i == this.tubeGroup.children.length - 1){
        this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
      }
    }
    if((type === 0 && this.tubeGroup.userData.drawType === 1)
      ||(type === 2 && this.tubeGroup.userData.drawType === 0)
      ){
      var size = this.editVers.length;
      this.editVers[size - 1].x = pos.x;
      this.editVers[size - 1].y = pos.y;
      this.editVers[size - 2].x = pos.x;
      this.editVers[size - 2].y = pos.y;
    }else if(type === 1){
      this.editVers[0].x = pos.x;
      this.editVers[0].y = pos.y;
      this.editVers[1].x = pos.x;
      this.editVers[1].y = pos.y;
    }else if((type === 0 && this.tubeGroup.userData.drawType === 0)
    ||(type === 2 && this.tubeGroup.userData.drawType === 1)){
      this.editVers[0].x = pos.x;
      this.editVers[0].y = pos.y;
      this.editVers[1].x = pos.x;
      this.editVers[1].y = pos.y;
    }else{
      // do nothing
    }

    // 先删除管道
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }

    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }

    // 再根据editVers重建管道
    let color = this.tubeGroup.userData.color;
    let radius = this.tubeGroup.userData.radius;
    let pipeType = this.tubeGroup.userData.pipeType;
    let textureDir = this.tubeGroup.userData.textureDir;
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, this.tubeGroup.userData.center);
    console.log("now tubegroup", newTubeGroup);
    let cnt = 0;
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      cnt++;
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
            GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
    }

    
  }

  onMouseMove_ChangeShape_MultiTube = (pos : Vector2) => {
    var tempTubeGroup = this.tubeGroup;
    console.log("akjgnsi", this.tubeGroup);
    var tubeID = this.tubeGroup.userData.tubeID;
    var tempFloor = this.currentFloorNum;
    console.log("temmpFloor", tempFloor);
    var min_floor = 1000;
    var max_floor = -1;
    for(var tg of this.MultiTubeInfo.get(tubeID)!){
      if(tg.userData.floor < min_floor){
        min_floor = tg.userData.floor;
      }
      if(tg.userData.floor > max_floor){
        max_floor = tg.userData.floor;
      }
    }

    // 选点不在竖直跨楼层部分的情况
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        this.editVers.push(this.tubeGroup.children[i].userData.stPoint);
      }
      if(i == this.tubeGroup.children.length - 1){
        this.editVers.push(this.tubeGroup.children[i].userData.edPoint);
      }
    }
    console.log("inntersect", this.INTERSECTED);
    console.log("this.tubeGroup.userData.floor == min_floor",[this.tubeGroup.userData.floor, min_floor]);
    if(
      (this.tubeGroup.userData.floor == min_floor && 
        (
          (
          this.INTERSECTED != this.editVers.length - 1 &&
          this.INTERSECTED != this.editVers.length - 2 &&
          this.tubeGroup.userData.drawType == 1
          ) 
          ||
          (
          this.INTERSECTED != 0 &&
          this.INTERSECTED != 1 &&
          this.tubeGroup.userData.drawType == 0
          )
        )
      )
      ||
      (this.tubeGroup.userData.floor == max_floor && 
        (
          (
            this.INTERSECTED != 0 &&
            this.INTERSECTED != 1 &&
            this.tubeGroup.userData.drawType == 1
          )
          ||
          (
            this.INTERSECTED != this.editVers.length - 1 &&
            this.INTERSECTED != this.editVers.length - 2 &&
            this.tubeGroup.userData.drawType == 0
          )
        )
      )
    ){
      this.onMouseMove_ChangeShape_OneFloorofMultiTube(pos, this.tubeGroup, 4);
      this.cancelPickedState([this.pickedObj]);
      return;
    }

    var tempEditVers;
    
    for(var tg of this.MultiTubeInfo.get(tubeID)!){
      console.log("tg.userData.floor === tempFloor", [tg.userData.floor, tempFloor]);
      if(tg.userData.floor == min_floor){
        this.onMouseMove_ChangeShape_OneFloorofMultiTube(pos, tg, 0);
        
      }else if(tg.userData.floor == max_floor){
        
        this.onMouseMove_ChangeShape_OneFloorofMultiTube(pos, tg, 2);
        
      }else{
        
        this.onMouseMove_ChangeShape_OneFloorofMultiTube(pos, tg, 1);
        
      }
      if(tg.userData.floor === tempFloor){
        tempEditVers = this.editVers;
        console.log("floor + editvers", [tempFloor, tempEditVers]);
      }
    }
    this.editVers = tempEditVers;
    this.tubeGroup = tempTubeGroup;
  }

  onMouseUp_ChangeShape = (event: any) => {
    if (this.INTERSECTED == null) return;
    if (this.isChangeTubeShape3D){
      this.setState({
        adjustHeightMenuProps: {
          isVisible: false, 
          height: -1, 
          position: {x: event.x, y: event.y}},
      });
    }
    document.removeEventListener("mousemove", this.onMouseMove_ChangeShape, false);

    const floor = this.currentFloor!;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    // this.raycaster.setFromCamera(mouse, this.camera_o);
    console.log(this.editVers[this.INTERSECTED]);
    this.editVers[this.INTERSECTED].x = mouse.x;
    this.editVers[this.INTERSECTED].y = mouse.y;

    removeObjectsByName(floor, ObjName.EditShape);
    const ud = this.pickedObj.userData;
    if (this.pickedObj.userData.type == ObjType.Room ||
        this.pickedObj.userData.type == ObjType.Hole
      ) {
      let isHole = false;
      if(this.pickedObj.userData.type === ObjType.Hole){
        isHole = true;
      }
      let mesh = MESH.createPolRoomMesh(
        this.editVers,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
        isHole
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    } else if (this.pickedObj.userData.type == ObjType.Wall) {
      let mesh = MESH.createWallMesh(
        this.editVers,
        ud.width,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
    } else if (this.pickedObj.userData.type == ObjType.Tube||
      this.pickedObj.userData.type == ObjType.Fillet){
        
    } else if (ud.type === ObjType.Floor) {
      let material = this.pickedObj.material;
      let points = ud.points.slice(0);
  
      let newGeo = GEO.createPlaneGeometry(points);
      let newGround = new THREE.Mesh(newGeo, material);
      newGround.userData.points = points;
      newGround!.userData.name = ud.name;
      newGround!.userData.type = ud.type;
      newGround!.userData.transparent = ud.transparent;
      newGround!.name = ObjName.EditShape;
      console.log("ground", newGround);
      floor.add(newGround);

    }
    removeObjectsByName(floor,ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(this.editVers);
    floor.add(this.editPoints);

    this.INTERSECTED = null;
  };

  onKeyupChangeShapeComp = (event: any) => {
    if (event.keyCode != 32) return;
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.EditShapePnt);
    if(this.pickedObj.userData.type !== ObjType.Tube){
      GLINE.removeLinesByObjUuid(this.pickedObj.userData.uuid);
      GLINE.addLines(this.pickedObj.userData.points, this.pickedObj.userData.uuid, false);
  
    }
    this.editVers = null;
    this.controls_o.enablePan = true;
    if(this.isChangeTubeShape3D){
      this.isChangeTubeShape3D = false;
      this.controls_p.enablePan = true;
    }
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_ChangeShape,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener("mouseup", this.onMouseUp_ChangeShape, false);
    document.removeEventListener("keyup", this.onKeyupChangeShapeComp, false);
    this.startPick();

    this.pickedObj.name = "";
    this.pickedObj = null;
    let editObj = floor.getObjectByName(ObjName.EditShape);
    if (editObj) {
      editObj.name = "";
    }
    this.setState({showFilter: true});
    this.props.setIsDrawing(false);
  };

  //////// 删除房间和墙体顶点
  onClickDelPoint = (event: any) => {
    const floor = this.currentFloor!;

    // console.log(this.pickedObj);
    if (this.pickedObj !== null) {
      this.setState({showFilter: false});
      this.pickedObj.name = ObjName.EditShape;
      this.pickedObj.material.color.set(this.pickedObj.userData.color);
      if (this.pickedObj.userData.type == ObjType.Room || 
          this.pickedObj.userData.type == ObjType.Hole ||
          this.pickedObj.userData.type == ObjType.Wall) {
        this.editVers = [];
        // console.log("pickedObj", this.pickedObj);

        this.editVers = this.pickedObj.userData.points;

        // 产生点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);

        this.setState({selectedRoomProp: undefined});
        this.setState({selectedHoleProp: undefined});
        document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_DelPoint, false);
        document.addEventListener("keyup", this.onKeyupDelPointComp, false);
        document.removeEventListener("keydown", this.onClickEsc, false);
        document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
        this.canPickPicOrText = false;
        this.props.setIsDrawing(true);
        console.log(this.pickedObj.name);
      }
    }
  };

  onMouseDown_DelPoint = (event: any) => {
    const floor = this.currentFloor!;
    this.mouse = this.getMouseStdPos(event);
    this.raycaster.setFromCamera(this.mouse, this.camera_o);
    this.raycaster.params.Points!.threshold = 4;
    // this.editPntAttr = this.editPoints.geometry.attributes;
    // console.log(this.editPoints.geometry);
    let intersects = this.raycaster.intersectObject(this.editPoints);
    if (intersects.length > 0) {
      console.log(intersects[0].index);
      this.INTERSECTED = intersects[0].index;
      this.delPnt();
    }
  };

  delPnt = () => {
    if (this.pickedObj.userData.type === ObjType.Room || this.pickedObj.userData.type === ObjType.Floor) {
      if (this.editVers.length <= 3) {
        setTimeout(function() {
          alert("房间顶点数不能少于3!");
        }, 100);
        return;
      }
    } else if (this.pickedObj.userData.type === ObjType.Wall) {
      if (this.editVers.length <= 2) {
        setTimeout(function() {
          alert("墙体顶点数不能少于2!");
        }, 100);
        return;
      }
    }
    this.editVers.splice(this.INTERSECTED, 1);
    const floor = this.currentFloor!;

    removeObjectsByName(floor, ObjName.EditShape);
    const ud = this.pickedObj.userData;
    if (this.pickedObj.userData.type == ObjType.Room  ||
        this.pickedObj.userData.type == ObjType.Hole
      ) {
      let isHole = false;
      if(this.pickedObj.userData.type === ObjType.Hole){
        isHole = true;
      }
      let mesh = MESH.createPolRoomMesh(
        this.editVers,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
        isHole
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
      this.pickedObj = mesh;
    } else if (this.pickedObj.userData.type == ObjType.Wall) {
      let mesh = MESH.createWallMesh(
        this.editVers,
        ud.width,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      mesh!.name = ObjName.EditShape;
      floor.add(mesh);
      this.pickedObj = mesh;
    } else if (this.pickedObj.userData.type == ObjType.Floor) {
      let material = this.pickedObj.material;
      let newGeo = GEO.createPlaneGeometry(this.editVers);
      let newGround = new THREE.Mesh(newGeo, material);
      newGround.userData.points = this.editVers;
      newGround.userData.type = ObjType.Floor;
      newGround!.userData.name = ud.name;
      newGround!.userData.transparent = ud.transparent;
      newGround!.name = ObjName.EditShape;
      floor.add(newGround);
      this.pickedObj = newGround;
    }
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editPoints = MESH.createEditPoints(this.editVers);
    floor.add(this.editPoints);

    this.INTERSECTED = null;
  }

  onKeyupDelPointComp = (event: any) => {
    if (event.keyCode != 32 && event.keyCode != 27) return;
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.editVers = null;

    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DelPoint, false);
    document.removeEventListener("keyup", this.onKeyupDelPointComp, false);
    document.addEventListener("keydown", this.onClickEsc, false);
    this.startPick();

    this.pickedObj.name = "";
    this.pickedObj = null;
    let editObj = floor.getObjectByName(ObjName.EditShape);
    if (editObj) {
      editObj.name = "";
    }
    this.setState({showFilter: true});
    this.props.setIsDrawing(false);
  };

  //////// 房间直线转圆弧
  onClick_RoomLineToCircle = () =>{
    const floor = this.currentFloor!;

    if (this.pickedObj !== null) {
      this.setState({showFilter: false});
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
      this.canPickPicOrText = false;

      this.pickedObj.name = ObjName.EditShape;
      this.pickedObj.material.color.set(this.pickedObj.userData.color);
      if (this.pickedObj.userData.type == ObjType.Room) {
        this.editVers = [];
        this.editVers = this.pickedObj.userData.points;
        // console.log(this.editVers);

        // 产生红色顶点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);

        this.setState({selectedRoomProp: undefined});        
      } else if (this.pickedObj.userData.type == ObjType.Wall) {
        this.editVers = [];
        this.editVers = this.pickedObj.userData.points;

        // 产生红色顶点
        this.editPoints = MESH.createEditPoints(this.editVers);
        floor.add(this.editPoints);
        
        this.setState({selectedWallProp: undefined});
      }
    }
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_SelectRoomLineToCircle, false);
    this.canPickPicOrText = false;
    this.props.setIsDrawing(true);
  }


  onMouseDown_SelectRoomLineToCircle=(event:any) => {
    const floor = this.currentFloor!;
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);
    const ud = this.pickedObj.userData;
    // 如果是墙或管道等不封闭的形状
    let isclosed = ud.type === ObjType.Room;
    let res = calNearestLine(ud.points, mouse, isclosed);
    let foot = res.foot, idx = res.idx, dist = res.dist;
    this.RoomToCircleIdx = idx;
    var mesh = MESH.createGreenLineMesh(this.pickedObj.userData.points[idx],
      this.pickedObj.userData.points[idx + 1]
      );
    floor.add(mesh);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_RoomLineToCircle, false);
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_RoomLineToCircleComp, false);
  }

  private RoomToCircleCenter: any = null;
  private RoomToCircleRadius: number = 0;
  private RoomToCircleIdx: number = -1;
  private RoomToCircleMeshCircle: any = null;
  private RoomToCirclePoints: Vector2[] = [];

  onMouseMove_RoomLineToCircle = (event: any) =>{
    const floor = this.currentFloor!;
    if(this.RoomToCircleMeshCircle != null){
      floor.remove(this.RoomToCircleMeshCircle);
    }
    const mouse: any = this.getMousePos(event, this.camera_o, floor.children);

    var p1 = this.pickedObj.userData.points[this.RoomToCircleIdx];
    var p2 = this.pickedObj.userData.points[(this.RoomToCircleIdx + 1) % this.pickedObj.userData.points.length];
    var p3 = mouse;

    var room_center = this.pickedObj.position;
    var p1p2 = new Vector2(p2.x, p2.y).sub(new Vector2(p1.x, p1.y));
    var p1rc = new Vector2(room_center.x, room_center.y).sub(new Vector2(p1.x, p1.y));
    var flag_rc = p1rc.cross(p1p2);
    var p1p3 = new Vector2(p3.x, p3.y).sub(new Vector2(p1.x, p1.y));
    var flag_p3 = p1p3.cross(p1p2);
    if(flag_p3 * flag_rc > 0) return;
    [this.RoomToCircleMeshCircle,
    this.RoomToCircleCenter,
    this.RoomToCircleRadius,
    this.RoomToCirclePoints] = MESH.createGreenCircleMesh(p1,p2,mouse,room_center);

    floor.add(this.RoomToCircleMeshCircle);
  }

  onMouseDown_RoomLineToCircleComp = (event: any) =>{
    if (event.button === 0){
      var points = this.pickedObj.userData.points;
      // var p1 = new Vector2(points[this.RoomToCircleIdx].x, points[this.RoomToCircleIdx].y);
      // var p2 = new Vector2(points[(this.RoomToCircleIdx + 1) % points.length].x,
      //                       points[(this.RoomToCircleIdx + 1) % points.length].y);
      // var o  = this.RoomToCircleCenter;
      // var p1_p2 = p2.clone().sub(p1);
      // var p1_o = o.clone().sub(p1);
      // var flag = p1_o.cross(p1_p2);
      // if(flag < 0) return;
      if(!this.RoomToCircleMeshCircle) return;
      var zvalue = points[0].z;
      var index = this.RoomToCircleIdx;
      for(var i = 1; i < this.RoomToCirclePoints.length - 1; i++){
        points.splice(index + i, 0, 
          new Vector3(
          this.RoomToCirclePoints[i].x, 
          this.RoomToCirclePoints[i].y, 
          zvalue
        ));
      }
      // points = []
      // for(var i = 0; i < this.RoomToCirclePoints.length; i++){
      //   points.push(
      //     new Vector3(
      //           this.RoomToCirclePoints[i].x, 
      //           this.RoomToCirclePoints[i].y, 
      //           zvalue)
      //   );
      // }
      const floor = this.currentFloor!;
      const ud = this.pickedObj.userData;
      let mesh = MESH.createPolRoomMesh(
        points,
        ud.height,
        ud.color,
        ud.uuid,
        ud.rotation,
        ud.rideHeight,
        ud.transparency,
      );
      mesh!.userData.name = ud.name;
      floor.add(mesh);
      
      removeObjectsByName(floor, ObjName.EditShape);
      removeObjectsByName(floor, ObjName.LineToCircleSelectLine);
      removeObjectsByName(floor, ObjName.EditShapePnt);
      this.RoomToCircleMeshCircle = null;
      this.editVers = null;
      this.pickedObj.name = "";
      this.pickedObj = null;
      let editObj = floor.getObjectByName(ObjName.EditShape);
      if (editObj) {
        editObj.name = "";
      }
      this.setState({showFilter: true});
      this.props.setIsDrawing(false);
      document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_RoomLineToCircle, false);
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_SelectRoomLineToCircle, false);
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onClick_RoomLineToCircle , false);
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_RoomLineToCircleComp, false);
      this.startPick();
    }
    
  }

  /////// 上传底图
  onUploadBaseMap = (evt: CustomEvent) => {
    let self = this;
    const fileElement = evt.detail.target as HTMLInputElement;
    if (
      fileElement === null ||
      fileElement.files === undefined ||
      fileElement.files === null ||
      fileElement.files.length === 0
    ) {
      return;
    }
    var fileData = fileElement.files[0]; //获取到一个FileList对象中的第一个文件(File 对象),是我们上传的文件
    var pettern = /^image/;

    console.info(fileData.type);

    if (!pettern.test(fileData.type)) {
      alert("图片格式不正确！");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(fileData); //异步读取文件内容，结果用data:url的字符串形式表示
    /*当读取操作成功完成时调用*/
    reader.onload = function (e) {
      if (typeof this.result === "string") {
        const floor = self.currentFloor!;
        let removeList = [];
        // 删除场景中已有的底图和楼板
        for (var obj of floor.children) {
          if (obj.userData.type) {
            if (obj.userData.type === ObjType.Ground){
              removeList.push(obj);
              console.log("Ground del");
            }
            if (obj.userData.type === ObjType.BlankGround){
              removeList.push(obj);
              console.log("BlankGround del");
            }
            if (obj.userData.type === ObjType.Floor) {
              removeList.push(obj);
            }
          }
        }
        for (let obj of removeList) {
          floor.remove(obj);
        }
        MESH.createPlane(this.result, floor);
        self.setState({showImportBasemapHint: false});
        alert("底图上传成功！");
      }
    };
    reader.onerror = function (e) {
      alert("底图上传失败！请检查图片是否有误。");
    }

    // FILE.loadBaseMap(evt, function (e) {
    //     if (typeof this.result === "string") {
    //       self.createPlane(this.result);
    //     });
  };

  private multiPickGroup: THREE.Group | undefined = undefined;
  ///////// 平移/旋转
  onClickTransObj = (event: any) => {
    const pickedObj = this.pickedObj;
    const scene = this.scene;
    const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;

    this.setState({
      showRightClickMenu: false,
      selectedPipeProp: undefined,
      selectedRoomProp: undefined,
      selectedWallProp: undefined,
      selectedMultiProp: undefined,
      showFilter: false,
    });
    // console.log(this.pickedObj.userData.type);
    if (pickedObj !== null) {
      // 单个物体
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
      this.canPickPicOrText = false;

      if (
        this.pickedObj.userData.type == ObjType.Tube ||
        this.pickedObj.userData.type == ObjType.Fillet
      ) {
        transCtrl.attach(this.tubeGroup);
        scene.add(transCtrl);

        var t = 0;
        var oneTube: any;
        let tubeNumber = this.tubeGroup.children.length;
        for (t = 0; t < tubeNumber; t++) {
          oneTube = this.tubeGroup.children[t];
          if(oneTube.userData.pipeType === PipeType.others){
            oneTube.material.color.set(oneTube.userData.color);
          }
          else{
            if(oneTube.userData.type === ObjType.Tube){
              oneTube.material.color.set("#FFFFFF");
            }else{
              oneTube.material.color.set(this.pipeType2Color(oneTube.userData.pipeType));
            }
            
          }
        }

        this.origCenter.copy(this.tubeGroup.position);
        document.addEventListener("keyup", this.onKeyupTransTubeComp, false);
        document.addEventListener("keyup", this.onChangeEditMode, false);

      } else {
        this.cancelPickedState([pickedObj]);

        transCtrl.attach(pickedObj);
        scene.add(transCtrl);

        // this.origCenter = getCenterPoint(pickedObj.geometry);

        this.origCenter.copy(pickedObj.position);
        console.log("origCenter", this.origCenter);

        document.addEventListener("keyup", this.onKeyupTransObjComp, false);
      }

      this.controls_o.enableZoom = false;
      this.controls_p.enableZoom = false;
      // console.log("center", c);

      this.props.setIsDrawing(true);
      document.removeEventListener("keydown", this.onClickEsc, false);
    } 
    else if (this.multiPickedObjs.length !== 0) {
      // 多个物体
      document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
      this.canPickPicOrText = false;
      // debugger;

      const group = new THREE.Group();
      this.cancelPickedState(this.multiPickedObjs);

      for (let obj of this.multiPickedObjs) {
        if (
          obj.userData.type == ObjType.Tube ||
          obj.userData.type == ObjType.Fillet
        ) {
          // TODO: 多选管道
          // transCtrl.attach(this.tubeGroup);
          // scene.add(transCtrl);
  
          this.onChangeTubeColor(this.tubeGroup.userData.color);
  
          // document.getElementById("canvas-container")!.addEventListener("keyup", this.onKeyupTransTubeComp, false);
          // document.getElementById("canvas-container")!.addEventListener("keyup", this.onChangeEditMode, false);
        } else {
          group.add(obj);
        }
      }
      this.origCenter.copy(group.position);
      // console.log("origCenter", this.origCenter);
      transCtrl.attach(group);
      scene.add(group);
      scene.add(transCtrl);
      this.multiPickGroup = group;

      document.addEventListener("keyup", this.onKeyupTransMulObjsComp, false);

      this.controls_o.enableZoom = false;
      this.controls_p.enableZoom = false;
    }
  };

  onKeyupTransObjComp = (event: any) => {
    if (event.keyCode == 32 || event.keyCode == 27) {
      // space
      const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
      const floor = this.currentFloor;
      if (event.keyCode == 27) {
        this.pickedObj = transCtrl.object!;
        const ud = transCtrl.object!.userData;
        transCtrl.detach();
        this.scene.remove(transCtrl);
        if (ud.type === ObjType.Room) {
          let mesh = MESH.createPolRoomMesh(
            ud.points,
            ud.height,
            ud.color,
            ud.uuid,
            [0,0,0,'XYZ'],
            ud.rideHeight,
            ud.transparency,
          );
          mesh.userData.name = ud.name;
          floor?.remove(this.pickedObj);
          floor?.add(mesh);
          this.pickedObj = mesh;
        } 
        else if (ud.type === ObjType.Wall) {
          let mesh = MESH.createWallMesh(
            ud.points,
            ud.width,
            ud.height,
            ud.color,
            ud.uuid,
            [0,0,0,'XYZ'],
            ud.rideHeight,
            ud.transparency,
          );
          mesh.userData.name = ud.name;
          floor?.remove(this.pickedObj);
          floor?.add(mesh);
          this.pickedObj = mesh;
        }

      }
      else{
        this.pickedObj = transCtrl.object;

        transCtrl.detach();
        this.scene.remove(transCtrl);
        // this.scene.remove(this.transCtrl_o);
        // this.scene.remove(this.transCtrl_p);

        if (transCtrl.getMode() == "translate") {
          console.log(this.pickedObj);
          let center = this.pickedObj.position;
          console.log("center", center);
          let delta = new Vector3(
            center.x - this.origCenter.x,
            center.y - this.origCenter.y,
            center.z - this.origCenter.z
          );

          console.log("delta", delta);
          if (this.pickedObj.userData.points !== undefined) {
            for (let i = 0; i < this.pickedObj.userData.points.length; i++) {
              // debugger;
              // this.pickedObj.userData.points[i].add(delta);
              this.pickedObj.userData.points[i].x += delta.x;
              this.pickedObj.userData.points[i].y += delta.y;
              this.pickedObj.userData.points[i].z += delta.z;
            }
            const ud = this.pickedObj.userData;
            if (ud.type == ObjType.Room || ud.type == ObjType.Wall) {
              GLINE.removeLinesByObjUuid(ud.uuid);
              GLINE.addLines(ud.points, ud.uuid, ud.type === ObjType.Room);
            }
          }
          if (this.pickedObj.userData.pos !== undefined) {
            this.pickedObj.userData.pos.x += delta.x;
            this.pickedObj.userData.pos.y += delta.y;
            this.pickedObj.userData.pos.z += delta.z;
          }
        } else if (transCtrl.getMode() == "rotate") {
          const floor = this.currentFloor;
          var box = new THREE.Box3().setFromObject(this.pickedObj);
          var center = box.getCenter(new THREE.Vector3());
          const points = this.pickedObj.userData.points;
          const ud = this.pickedObj.userData;
          let angel = this.pickedObj.rotation.toArray()[2];
          console.log('rotation', this.pickedObj.rotation.toArray());
          console.log('angel', angel);
          for (let i = 0; i < points.length; i++) {
            let p = rotatePoint(points[i], center, angel);
            points[i].x = p.x;
            points[i].y = p.y;
          }

          if (ud.type === ObjType.Room || ud.type === ObjType.Hole) {
          let isHole = false;
          if(this.pickedObj.userData.type === ObjType.Hole){
            isHole = true;
          }
            let mesh = MESH.createPolRoomMesh(
              points,
              ud.height,
              ud.color,
              ud.uuid,
              [0,0,0,'XYZ'],
              ud.rideHeight,
              ud.transparency,
            isHole
            );
            mesh.userData.name = ud.name;
            floor?.remove(this.pickedObj);
            floor?.add(mesh);
            this.pickedObj = mesh;
            GLINE.removeLinesByObjUuid(mesh.userData.uuid);
            GLINE.addLines(mesh.userData.points, mesh.userData.uuid, true);
          } 
          else if (ud.type === ObjType.Wall) {
            let mesh = MESH.createWallMesh(
              points,
              ud.width,
              ud.height,
              ud.color,
              ud.uuid,
              [0,0,0,'XYZ'],
              ud.rideHeight,
              ud.transparency,
            );
            mesh.userData.name = ud.name;
            floor?.remove(this.pickedObj);
            floor?.add(mesh);
            this.pickedObj = mesh;
            GLINE.removeLinesByObjUuid(mesh.userData.uuid);
            GLINE.addLines(mesh.userData.points, mesh.userData.uuid, false);
          }


          // this.pickedObj.userData.rotation = this.pickedObj.rotation.toArray();
        }
      }

      this.pickedObj = null;
      this.controls_o.enableZoom = true;
      this.controls_p.enableZoom = true;
      document.removeEventListener("keyup", this.onKeyupTransObjComp, false);
      this.setState({showFilter: true});
      this.props.setIsDrawing(false);
      this.startPick();
      document.addEventListener("keydown", this.onClickEsc, false);
    }
  };

  onKeyupTransMulObjsComp = (event: any) => {
    const group = this.multiPickGroup!;
    const scene = this.scene;
    const floor = this.currentFloor!;

    if (event.keyCode == 32) {
      const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;

      transCtrl.detach();
      scene.remove(this.transCtrl_o);
      scene.remove(this.transCtrl_p);

      const center = group.position;
      console.log("center", center);
      const delta = new Vector3(
        center.x - this.origCenter.x,
        center.y - this.origCenter.y,
        center.z - this.origCenter.z
      );
      console.log("delta", delta);

      const list = [];
      for (let obj of group.children) {
        list.push(obj);
      }
      for (let obj of list) {
        const p = obj.position.clone();
        floor.add(obj);
        obj.position.set(p.x+delta.x, p.y+delta.y, p.z+delta.z);
        // obj.position.set(p.x, p.y, p.z);
        for (let i = 0; i < obj.userData.points.length; i++) {
          // debugger;
          // this.pickedObj.userData.points[i].add(delta);

          obj.userData.points[i].x += delta.x;
          obj.userData.points[i].y += delta.y;
          obj.userData.points[i].z += delta.z;
        }
      }
      this.multiPickedObjs = [];
      this.multiPickGroup = undefined;
      this.isMultiPick = false;
      this.controls_o.enableZoom = true;
      this.controls_p.enableZoom = true;
      document.removeEventListener("keyup", this.onKeyupTransMulObjsComp, false);
      this.setState({showFilter: true});
      this.startPick();
    }
  };


  onChangeTubeTranslate = (delta : Vector3, currenntTubeGroup: THREE.Group | null = null) =>{
    if(!currenntTubeGroup){

    }else{
      this.tubeGroup = currenntTubeGroup;
    };
    var center = this.tubeGroup.userData.center;

    // 添加进editVers
    this.editVers = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type == ObjType.Tube){
        this.editVers.push(
          this.tubeGroup.children[i].userData.stPoint.add(delta)
          );
      }
      if(i == this.tubeGroup.children.length - 1){
        this.editVers.push(
          this.tubeGroup.children[i].userData.edPoint.add(delta)
        );
      }
    }
    console.log("editVers", this.editVers);
    // 先删除管道
    var temp_uuids = [];
    for(let i = 0; i < this.tubeGroup.children.length; i++){
      if(this.tubeGroup.children[i].userData.type === ObjType.Tube &&
        this.tubeGroup.children[i].userData.edDir.z === 0){
          temp_uuids.push(this.tubeGroup.children[i].uuid);
        }
    }

    for(let i = this.tubeGroup.children.length - 1; i >= 0; i--){
      let oneTube = this.tubeGroup.children[i];
      this.tubeGroup.remove(oneTube);
    }
    // 再根据editVers重建管道
    let color = this.tubeGroup.userData.color;
    let radius = this.tubeGroup.userData.radius;
    let pipeType = this.tubeGroup.userData.pipeType;
    let textureDir = this.tubeGroup.userData.textureDir;
    let newTubeGroup = MESH.createWholeTubeMesh(this.editVers,
      radius, color, pipeType, textureDir, center);
    console.log("now tubegroup", newTubeGroup);
    let cnt = 0;
    var k = 0;
    for(let i = 0; i < newTubeGroup.length; i++){
      cnt++;
      this.tubeGroup.add(newTubeGroup[i]);
      if(newTubeGroup[i].userData.type === ObjType.Tube &&
        newTubeGroup[i].userData.edDir.z === 0){
        console.log("Tube", i);
        for(let j = 0; j < GLINE.AllLines.length; j++){
          if(GLINE.AllLines[j][4] === temp_uuids[k]){
            console.log("Find", k);
            GLINE.AllLines[j][0] = newTubeGroup[i].userData.stPoint;
            GLINE.AllLines[j][1] = newTubeGroup[i].userData.edDir;
            GLINE.AllLines[j][4] = newTubeGroup[i].uuid;
            k++;
            break;
          }
        }
      }
    }
    // 保存新数据
    // this.tubeGroup.userData.center = center.clone().add(delta);
  }


  onKeyupTransTubeComp = (event: any) => {
    if (event.keyCode == 32 || event.keyCode == 27) {
      // space
      const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;

      transCtrl.detach();
      this.scene.remove(transCtrl);

      if (transCtrl.getMode() == "translate"){
        let center = this.tubeGroup.position;
        console.log("center",center);
        let delta = new Vector3(
          center.x - this.origCenter.x,
          center.y - this.origCenter.y,
          center.z - this.origCenter.z
        );
        console.log("delta",delta);
        
      
        for(let i = 0;i < this.tubeGroup.children.length ;i++){
          let oneTube = this.tubeGroup.children[i];
          if(oneTube.userData.type === ObjType.Tube){
            let stP = oneTube.userData.stPoint;
            let edP = oneTube.userData.edPoint;
            oneTube.userData.stPoint = new THREE.Vector3(stP.x + delta.x,stP.y + delta.y,stP.z + delta.z);
            oneTube.userData.edPoint = new THREE.Vector3(edP.x + delta.x,edP.y + delta.y,edP.z + delta.z);
            // 修改辅助线信息
            for(let j = 0; j < GLINE.AllLines.length; j++){
              if(GLINE.AllLines[j][4] === oneTube.uuid){
                console.log("Find");
                GLINE.AllLines[j][0].addVectors(GLINE.AllLines[j][0], delta);
                break;
              }
            }
          }else{
            let pt = oneTube.userData.point;
            oneTube.userData.point = new THREE.Vector3(pt.x + delta.x, pt.y + delta.y, pt.z + delta.z);
          }  
        }
        this.tubeGroup.userData.center = center;

        var tubeID = this.tubeGroup.userData.tubeID;
        var currentTubeUuid = this.tubeGroup.uuid;
        if(tubeID >= 0){
          for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
            if(currentTubeUuid !== this.MultiTubeInfo.get(tubeID)![i].uuid)
            this.onChangeTubeTranslate(delta, this.MultiTubeInfo.get(tubeID)![i]);
          }
        }
        
      }else{
        this.tubeGroup.userData.rotation = this.tubeGroup.rotation.toArray();
        this.tubeGroup.userData.center = this.tubeGroup.position;
      }
      
    


      this.pickedObj = null;
      this.controls_o.enableZoom = true;
      this.controls_p.enableZoom = true;
      document.removeEventListener("keyup", this.onKeyupTransTubeComp, false);
      document.removeEventListener("keyup", this.onChangeEditMode, false);
      this.setState({showFilter: true});
      this.props.setIsDrawing(false);
      this.startPick();
      document.addEventListener("keydown", this.onClickEsc, false);
    }
  };

  onChangeEditMode = (event: any) => {
    if (event.keyCode == 69) {
      if (!this.is2D) this.switch2D_3D();
      this.transCtrl_o.setMode("rotate");
    }
    if (event.keyCode == 87) {
      this.transCtrl_o.setMode("translate");
      this.transCtrl_p.setMode("translate");
    }
  };

  ///////// 加载模型
  private modelData?: ModelItem = undefined;
  onClickAddModel = (event: any) => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    if (!this.is2D) {
      this.switch2D_3D();
    }
    if (this.props.mode !== Mode.ModelAdding)
      this.removeSidebarEvent();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Model, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Model, true);

    this.setState({ isAddingModel: true });
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_AddModel, false);
    this.deleteGhostCursor();
  };

  onMouseDown_AddModel = (event: any) => {
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    const floor = this.currentFloor!;

    let rideHeight = 3 / this.unitLength!.lenthOfEachPixel;

    let pos = this.getMousePos(event, this.camera_o, [floor]);
    pos.setZ(rideHeight);

    if (this.modelData !== undefined) {
      MESH.loadModel(this.modelData!, pos, floor);
      // this.modelData = undefined;
      // this.ModelAddPanelRef.current!.cleanSelState();
    }
  };

  private canPickPicOrText = true;
  //////// 加载文字
  onMouseDown_PickText = (obj: CSS2DObject) => {
    if (this.props.mode !== Mode.Browsing || !this.canPickPicOrText) return;

    if (this.pickedObj !== null && this.pickedObj.userData.type !== "ground") {
      this.cancelPickedState([this.pickedObj]);
      this.pickedObj = null;
      // return;
    }

    this.pickedObj = obj;
    obj.element.style.textShadow =
      "-1px 0 rgba(255, 0, 0, 1), 0 1px rgba(255, 0, 0, 1), 1px 0 rgba(255, 0, 0, 1), 0 -1px rgba(255, 0, 0, 1)";
    this.setState({
      selectedTextProp: {
        content: obj.element.textContent!,
        rideHeight: obj.userData.rideHeight * this.unitLength!.lenthOfEachPixel,
      },
    });
    // console.log("text", obj);
  };

  onClickAddText = (event: any) => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    if (!this.is2D) {
      this.switch2D_3D();
    }

    if (this.props.mode !== Mode.TextAdding)
      this.removeSidebarEvent();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Text, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Text, true);

    this.setState({
      selectedTextProp: DefaultValues.defaultTextProp,
    });
    
    this.deleteGhostCursor();
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_AddText, false);
  };

  onMouseDown_AddText = (event: any) => {
    const floor = this.currentFloor!;
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;

    let rideHeight =
      this.state.selectedTextProp!.rideHeight /
      this.unitLength!.lenthOfEachPixel;
    let content = this.state.selectedTextProp!.content;

    let pos = this.getMousePos(event, this.camera_o, [floor]);
    pos.setZ(rideHeight);
    const textLabel = MESH.createTextLabel(
      content,
      pos,
      this.onMouseDown_PickText
    );
    floor.add(textLabel);
  };

  onClickAddTextComp = () => {
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_AddText, false);
  };

  //////// 加载图片
  private picUrl = "";
  private picName = "";
  onClickAddPic = (event: any) => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    this.props.setIsDrawing(true);
    if (!this.is2D) {
      this.switch2D_3D();
    }

    if (this.props.mode !== Mode.PicAdding)
      this.removeSidebarEvent();

    // 进入绘制模式后，类型筛选器自动勾选对应类型
    setTypeVis(ObjType.Picture, true, [this.currentFloor!]);
    this.TypeFilterRef.current!.setCheckState(ObjType.Picture, true);

    this.setState({
      isAddingPic: true,
    });

    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_AddPic, false);
    // document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_AddPic, false);
    this.deleteGhostCursor();
    // temp
    // document.getElementById("canvas-container")!.addEventListener("keydown", this.onClickAddPicComp, false);
  };


  getMousePosPic = (event: any, camera: any, plane: any) => {
    let std_mouse = this.getMouseStdPos(event);
    let raycaster = new THREE.Raycaster();
    // 从相机发射一条射线，经过鼠标点击位置 mouse为鼠标的二维设备坐标，camera射线起点处的相机
    raycaster.setFromCamera(std_mouse, camera);
    // intersects是交点数组
    const intersects = raycaster.intersectObjects(plane);
    console.log(intersects[0]);
    if(intersects[0] === undefined){
      return undefined;
    }
    return intersects[0].point;
  };


  private PicOnMouseMove : any = null;
  onMouseMove_AddPic = (event: any) => {
    const floor = this.currentFloor!;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (this.picUrl === "") return;
    // removeObjectsByName(floor, ObjName.DrawingPic);
    if(this.PicOnMouseMove !== null){
      floor.remove(this.PicOnMouseMove);
    }
    var rideHeight = 3 / this.unitLength!.lenthOfEachPixel;

    const pos: any = this.getMousePosPic(event, this.camera_o, floor.children);
    if(pos === undefined) return;
    pos!.setZ(rideHeight);
    this.PicOnMouseMove = MESH.createPicLabel(
      this.picUrl,
      pos!,
      ObjName.DrawingPic,
      this.onMouseDown_PickPic
    );
    // this.PicOnMouseMove.name = ObjName.DrawingPic;
    // removeObjectsByName(floor, ObjName.DrawingPic);
    if(this.PicOnMouseMove !== null) floor.add(this.PicOnMouseMove);
  }

  onMouseDown_AddPic = (event: any) => {
    const floor = this.currentFloor!;
    if (event.button !== 0) return;
    if (!document.getElementById("canvas-container")!.contains(event.target))
      return;
    if (this.picUrl === "") return;

    let rideHeight = 3 / this.unitLength!.lenthOfEachPixel;
    // let picSrc = "https://prod-saas-public-read-file.oss-cn-shanghai.aliyuncs.com/picturefile/svg/卫生间.svg";

    let pos = this.getMousePosPic(event, this.camera_o, floor.children);
    if(pos === undefined) return;
    pos!.setZ(rideHeight);
    const picLabel = MESH.createPicLabel(
      this.picUrl,
      pos!,
      this.picName,
      this.onMouseDown_PickPic
    );
    floor.add(picLabel);
  };

  onClickAddPicComp = (event: any) => {
    // console.log("pic comp");
    // if (event.keyCode !== 32)
    //   return;
    this.picUrl = "";
    this.picName = "";
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_AddPic, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_AddPic, false);
    // temp
    document.removeEventListener("keydown", this.onClickAddPicComp, false);
  };

  onMouseDown_PickPic = (obj: CSS2DObject) => {
    if (this.props.mode !== Mode.Browsing || !this.canPickPicOrText) return;

    console.log(this.pickedObj);
    if (this.pickedObj !== null && this.pickedObj.userData.type !== "ground") {
      this.cancelPickedState([this.pickedObj]);
      this.pickedObj = null;
      // return;
    }

    this.pickedObj = obj;

    obj.element.style.borderStyle = "solid";
    // obj.element.style.borderColor = "red";
    // obj.element.style.borderWidth = "2px";
    console.log("change color");

    this.setState({
      selectedPicProp: {
        name: obj.userData.name!,
        rideHeight: obj.userData.rideHeight * this.unitLength!.lenthOfEachPixel,
        height: obj.userData.height,
        width: obj.userData.width,
      },
    });
  };

  ///////// 保存
  onClickSave = () => {
    // console.log(1, this.scene);

    const floorsJson = FILE.floors2Json(this.floors!, this.floor2IdxDict);

    const data = {
      name: "",
      len_of_pixel: this.unitLength!.lenthOfEachPixel,
      floor_height: this.floorHeight,
      floors: floorsJson,
      guide_lines: GLINE.AllLines,
    };

    // console.log("data", data);

    FILE.save2Local(data);
  };

  /////// 加载项目
  onUploadProj = (evt: CustomEvent) => {
    let self = this;
    const fileElement = evt.detail.target as HTMLInputElement;
    if (
      fileElement === null ||
      fileElement.files === undefined ||
      fileElement.files === null ||
      fileElement.files.length === 0
    ) {
      return;
    }

    var fileData = fileElement.files[0]; //获取到一个FileList对象中的第一个文件(File 对象),是我们上传的文件
    var reader = new FileReader();
    reader.readAsText(fileData); //异步读取文件内容
    /*当读取操作成功完成时调用*/
    reader.onload = function (e) {
      if (typeof this.result === "string") {
        // self.createPlane(this.result);
        // console.log(this.result);
        const j = JSON.parse(this.result);
        self.scene = MESH.initScene();
        hideLabel(self.floors!);
        self.loadProj(j);

        self.TypeFilterRef.current!.setCheckState(ObjType.Wall, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Room, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Model, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Picture, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Text, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Tube, true);
        self.TypeFilterRef.current!.setCheckState(ObjType.Ground, true);

        // 检测是否有底图
        for (let obj of self.currentFloor!.children) {
          if (obj.userData.type !== undefined && obj.userData.type === ObjType.Ground){
            self.setState({showImportBasemapHint: false});
            return;
          }
        }
        alert("本层楼无绘制信息，请导入底图！");
        self.setState({showImportBasemapHint: true});
      }
    };
  };

  loadProj = (j: any) => {
    // var loader = new THREE.ObjectLoader();
    this.unitLength!.lenthOfEachPixel = j.len_of_pixel;
    if (j.floor_height !== undefined) {
      this.floorHeight = j.floor_height;
    }

    // [this.floors, this.floor2IdxDict] = this.json2Floors(j.floors);
    // let tmpAllLines;
    let tmpMultiTube;
    let maxTubeID;
    [this.floors, this.floor2IdxDict, tmpMultiTube, maxTubeID] = FILE.json2Floors(
      j.floors,
      this.onMouseDown_PickText,
      this.onMouseDown_PickPic
    );
    GLINE.setFromJsonList(j.guide_lines);
    this.MultiTubeInfo = tmpMultiTube;
    this.tubeID = maxTubeID[0];
    if(!(this.MultiTubeInfo instanceof Map)){
      this.MultiTubeInfo = new Map();
    }
    console.log("init tubeID",this.tubeID);
    if(Number.isNaN(this.tubeID) || this.tubeID === undefined){
      this.tubeID = -1;
    }
    this.currentFloor = this.floors![4];
    this.scene.add(this.currentFloor);
    this.updateExistTypeState();
  };

  //////// 切换楼层
  switchFloor = (evt: CustomEvent) => {
    this.removeSidebarEvent();
    this.startPick();

    const scene = this.scene;
    const newFloor = evt.detail;
    // 当前楼层号
    this.currentFloorNum = newFloor;

    console.log('switchFloor', newFloor);

    if (!this.isMultiFloor){
      // 单层模式下
      if (this.is2D) {
        this.renderer.render(scene, this.camera_o);
        this.labelRenderer.render(scene, this.camera_o);
      } else {
        this.renderer.render(scene, this.camera_p);
        this.labelRenderer.render(scene, this.camera_p);
      }
      
      scene.remove(this.currentFloor!);
      hideLabel([this.currentFloor!]);
      this.currentFloor = this.floors![this.floor2Idx(newFloor)];

      showLabel([this.currentFloor]);

      scene.add(this.currentFloor);
      this.updateExistTypeState();

      // // 检测是否有底图
      // for (let obj of this.currentFloor.children) {
      //   if (obj.userData.type !== undefined && obj.userData.type === ObjType.Ground){
      //     this.setState({showImportBasemapHint: false});
      //     return;
      //   }
      // }
      // alert("本层楼无绘制信息，请导入底图！");
      // this.setState({showImportBasemapHint: true});

    } else {
      // 多层模式下
      var floorNum = this.currentFloorNum;
      const height = this.floorHeight / this.unitLength!.lenthOfEachPixel;
      if (floorNum > 0) floorNum--;
      CAM.setMultiFloorView(this.camera_p, this.controls_p, floorNum * height);
      this.updateExistTypeState();
      this.currentFloor = this.floors![this.floor2Idx(newFloor)];
    }
    
    // 检测是否有底图
    for (let obj of this.currentFloor.children) {
      if (obj.userData.type !== undefined && obj.userData.type === ObjType.Ground){
        this.setState({showImportBasemapHint: false});
        return;
      }
    }
    alert("本层楼无绘制信息，请导入底图！");
    this.setState({showImportBasemapHint: true});
  };

  // 右键响应
  onRightClick = (event: any) => {
    if (event.button != 2) {
      // 如果不是右键点击
      if (document.getElementById("canvas-container")!.contains(event.target)) {
        // 如果点在画布上
        if (this.state.showRightClickMenu)
          this.setState({
            showRightClickMenu: false,
          });
      }
    } else if (!document.getElementById("canvas-container")!.contains(event.target)){
      return;
    } else {
      // console.log("right click");
      if (
        this.pickedObj !== null &&
        (this.pickedObj.userData.type === ObjType.Text ||
          this.pickedObj.userData.type === ObjType.Picture)
      ) {
        // const x = this.pickedObj.element.offsetX;
        // const y = this.pickedObj.element.offsetY;
        // console.log(x, y);
        this.setState({
          rightClickPosition: { x: event.x - 65, y: event.y - 60 },
        });
      } else {
        this.setState({
          rightClickPosition: { x: event.offsetX, y: event.offsetY },
        });
      }
      // console.log(this.state);
      this.setState({
        showRightClickMenu: true,
      });
    }
  };

  removeSidebarEvent = () => {
    // wall
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_DrawWall, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawWall, false);
    // pick
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
    // room
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawPolRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawRectRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawRoom, false);
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawPolRoom,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawRectRoom,
      false
    );
    this.clickPoss = [];

    // hole
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawHole,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawPolHole,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousedown",
      this.onMouseDown_DrawRectHole,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawRectHole,
      false
    );
    document.getElementById("canvas-container")!.removeEventListener(
      "mousemove",
      this.onMouseMove_DrawPolHole,
      false
    );
    this.clickPoss = [];
    
    // text
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_AddText, false);
    // pic
    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_AddPic, false);
    this.picName = "";
    this.picUrl = "";
    // model
    this.modelData = undefined;
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_AddModel, false);
    // tube
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_DrawTube, false);
    document.removeEventListener("keydown", this.onKeydown_DrawTubeComp, false);
    document.removeEventListener("keydown", this.onKeydown_Switch2D_3D, false);

    // is editing room and wall
    if (this.currentFloor!.getObjectByName(ObjName.EditShapePnt) !== undefined) {
      this.onKeyupEditPntComp({ keyCode: 27 });
    }

    if (this.pickedObj !== null){
      this.cancelPickedState([this.pickedObj]);
      this.pickedObj = null;
    }
    if (this.multiPickedObjs.length > 0){
      this.cancelPickedState(this.multiPickedObjs);
      this.multiPickedObjs = [];
    }

    this.setState({ selectedWallProp: undefined });
    this.setState({ selectedRoomProp: undefined });
    this.setState({ selectedHoleProp: undefined });
    this.setState({ selectedPipeProp: undefined });
    this.setState({ selectedTextProp: undefined });
    this.setState({ isAddingPic: false });
    this.setState({ isAddingModel: false });
    this.setState({ showRightClickMenu: false });

    const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
    // transCtrl.detach();
    // this.scene.remove(transCtrl);
    if (transCtrl.object != undefined) {
      this.onKeyupTransObjComp({keyCode:32});
    }
    
    removeObjectsByName(this.currentFloor!, ObjName.Drawing);
    removeObjectsByName(this.currentFloor!, ObjName.DrawingRoom);
    removeObjectsByName(this.currentFloor!, ObjName.DrawingWall);
    this.deleteGhostCursor();
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
  };

  backToBrowseMode = () => {
    // this.removeSidebarEvent();

    // line to circle
    const floor = this.currentFloor!;
    removeObjectsByName(floor, ObjName.LineToCircleSelectLine);
    removeObjectsByName(floor, ObjName.EditShapePnt);
    this.RoomToCircleMeshCircle = null;
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_RoomLineToCircle, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_SelectRoomLineToCircle, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onClick_RoomLineToCircle , false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_RoomLineToCircleComp, false);



    this.clearSupportLines();
    this.deleteGhostCursor();
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchLines, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchVertex, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchFootroot, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMoveShowGhostCursor, false);
    this.LastClickPoint = null;
  
    // edit state
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_EditPnt, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_EditPnt, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_AddPnt, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CursorMoveOnLines, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchVertex, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchLines, false);
    document.getElementById("canvas-container")!.removeEventListener("mousemove", this.onMouseMove_CatchFootroot, false);
    if (floor.getObjectByProperty('uuid', this.FootrootLineUuid)!){
      floor.remove(floor.getObjectByProperty('uuid', this.FootrootLineUuid)!);
      this.FootrootLineUuid = "a";
    }
    

    this.props.setIsDrawing(false);
    this.props.setIsDrawingRoomType(false);
    this.props.setIsDrawingHoleType(false);

    switch (this.props.mode) {
      case Mode.WallDrawing:
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawWall,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawWall,
          false
        );
        this.clickPoss = [];
        this.setState({
          selectedWallProp: undefined,
        });
        break;

      case Mode.RoomDrawing:
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawRoom,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawPolRoom,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawRectRoom,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawRectRoom,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawPolRoom,
          false
        );
        this.clickPoss = [];
        this.setState({
          selectedRoomProp: undefined,
        });
        removeObjectsByName(floor, ObjName.Drawing);
        break;

      case Mode.HoleDrawing:
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawHole,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawPolHole,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawRectHole,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawRectHole,
          false
        );
        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawPolHole,
          false
        );
        this.clickPoss = [];
        this.setState({
          selectedHoleProp: undefined,
        });
        console.log("back from drawing mode")
        removeObjectsByName(floor, ObjName.Drawing);
        removeObjectsByName(floor, ObjName.DrawingHole);
        break;

      case Mode.TextAdding:
        this.setState({
          selectedTextProp: undefined,
        });
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_AddText,
          false
        );
        break;

      case Mode.PicAdding:
        this.setState({ isAddingPic: false });
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_AddPic,
          false
        );
        this.picUrl = "";
        this.picName = "";
        break;

      case Mode.ModelAdding:
        this.setState({ isAddingModel: false });
        this.modelData = undefined;
        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_AddModel,
          false
        );
        break;

      case Mode.PipeDrawing:
        removeObjectsByName(floor, ObjName.DrawingTube);

        this.setState({ selectedPipeProp: undefined });
        this.currentFloor!.remove(this.refPlane);

        document.getElementById("canvas-container")!.removeEventListener(
          "mousemove",
          this.onMouseMove_DrawTube,
          false
        );
        // delete all lines info
        let tubeNumber = this.tubeGroup.children.length;
        for (var i = tubeNumber - 1; i >= 0; i--) {
          var oneTube = this.tubeGroup.children[i];
          console.log(oneTube.uuid);
          for(var j = GLINE.AllLines.length - 1; j >= 0; j--){
            if(oneTube.uuid === GLINE.AllLines[j][4]){
              GLINE.AllLines.splice(j, 1);
            }
          }
        }
        console.log(GLINE.AllLines);


        

        this.currentFloor!.remove(this.tubeGroup);
        var tubeID = this.tubeGroup.userData.tubeID;
        if(tubeID >= 0){
          this.deleteOneMultiTubeByTubeID(tubeID);
        }
        

        document.getElementById("canvas-container")!.removeEventListener(
          "mousedown",
          this.onMouseDown_DrawTube,
          false
        );
        document.removeEventListener(
          "keydown",
          this.onKeydown_DrawTubeComp,
          false
        );
        document.removeEventListener(
          "keydown",
          this.onKeydown_Switch2D_3D,
          false
        );
        break;
      case Mode.Browsing:
        if (this.pickedObj !== null)
          this.cancelPickedState([this.pickedObj]);
        if (this.multiPickedObjs.length > 0)
          this.cancelPickedState(this.multiPickedObjs);
        break;
    }
    
    removeObjectsByName(floor, ObjName.DrawingRoom);
    this.props.setMode(Mode.Browsing);
    GLINE.deactivateAll();

    this.startPick();
    this.updateExistTypeState();
  };

  onClickEsc = (event: any) => {
    if (event.keyCode == 27) {
      this.removeSidebarEvent();
      this.backToBrowseMode();
    }
  };

  delObj = () => {
    const floor = this.currentFloor!;

    if (!this.isMultiPick) {
      if(this.pickedObj.userData.type == ObjType.Tube ||
        this.pickedObj.userData.type == ObjType.Fillet){
          if(this.tubeGroup.userData.tubeID < 0){
            floor.remove(this.tubeGroup); 
          }else{
            this.deleteOneMultiTubeByTubeID(this.tubeGroup.userData.tubeID);
          }
             
      }else{
        let uuid = this.pickedObj.userData.uuid;
        for(var j = GLINE.AllLines.length - 1; j >= 0; j--){
          if(uuid === GLINE.AllLines[j][4]){
            GLINE.AllLines.splice(j, 1);
          }
        }
        this.removeAllVecByUuid(this.pickedObj.userData.uuid);
        GLINE.removeLinesByObjUuid(this.pickedObj.userData.uuid);
        floor.remove(this.pickedObj);
        this.pickedObj = null;
      }
    } else {
      if(this.isOneTubePick){
        if(this.pickedObj === this.tubeGroup.children[this.tubeGroup.children.length - 1]){
          this.tubeGroup.remove(this.tubeGroup.children[this.tubeGroup.children.length - 2]);
          this.tubeGroup.remove(this.pickedObj);
        }else if(this.pickedObj === this.tubeGroup.children[0]){
          this.tubeGroup.remove(this.pickedObj);
          this.tubeGroup.remove(this.tubeGroup.children[0]);
        }
      }else{
        for (let obj of this.multiPickedObjs) {
          floor.remove(obj);
        }
      }
      this.isOneTubePick = false;
      this.multiPickedObjs = [];
      this.isMultiPick = false;
    }

    this.setState({
      selectedWallProp: undefined,
      selectedHoleProp: undefined,
      selectedPipeProp: undefined,
      selectedRoomProp: undefined,
      selectedTextProp: undefined,
      selectedPicProp: undefined,
      selectedModelProp: undefined,
      selectedMultiProp: undefined,
    });
  };

  moveObj = () => {
    this.onClickTransObj(null);
    const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
    transCtrl.setMode("translate");
    transCtrl.showX = true;
    transCtrl.showY = true;
    transCtrl.showZ = false;
  };

  rotateObj = () => {
    this.onClickTransObj(null);
    const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
    transCtrl.setMode("rotate");
    transCtrl.showX = false;
    transCtrl.showY = false;
    transCtrl.showZ = true;

  };

  // scaleObj = () => {
  //   this.onClickTransObj(null);
  //   const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
  //   transCtrl.setMode("scale");
  //   transCtrl.showX = false;
  //   transCtrl.showY = true;
  //   transCtrl.showZ = false;
  // };


  copyObjs = (objs: THREE.Object3D[]) => {
    var group = new THREE.Group;

    for (let obj of objs) {
      switch (obj.userData.type) {
        case ObjType.Room:
          var newMesh: any = MESH.copyRoom(obj.userData);
          break;
        case ObjType.Wall:
          var newMesh: any = MESH.copyWall(obj.userData);
          break;
        case ObjType.Model:
          // debugger;
          var newMesh: any = MESH.copyModel(obj.parent!);
          break;
        case ObjType.Tube:
          // TODO: tube copy
          break;
        default:
          continue;
      }
      group.add(newMesh);
    }
    return group;
  }

  onClickMultiCopyComp = (event: any) => {
    if (event.keyCode !== 32) return;
    document.removeEventListener("keydown", this.onClickMultiCopyComp, false);

    const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
    const scene = this.scene;
    const floor = this.currentFloor!;
    const group = this.multiPickGroup!;
    const list = [];

    var delta = group.position;

    for (let obj of group.children)
      list.push(obj);

    for (let obj of list) {
      floor.add(obj);
      switch (obj.userData.type) {
        case ObjType.Wall:
        case ObjType.Room:
          for (var p of obj.userData.points) {
            p.x += delta.x;
            p.y += delta.y;
          }
          var pos = obj.position;
          obj.position.set(pos.x+delta.x, pos.y+delta.y, pos.z+delta.z);
          break;
        case ObjType.Model:
          // TODO
          obj.userData.position.x += delta.x;
          obj.userData.position.y += delta.y;
          var pos = obj.position;
          obj.position.set(pos.x+delta.x, pos.y+delta.y, pos.z+delta.z);
          break;
        case ObjType.Tube:
          // TODO: tube
          break;
        default:
          continue;
      }
    }

    scene.remove(transCtrl);

    transCtrl.detach();
    this.multiPickGroup = undefined;

  }

  currentRightClickMenu = (): RightClickMenuItem[] => {
    // For example, if there is no action now, you should return []
    // if (some condition) { return []; }

    if (this.state.showRightClickMenu) {
      switch (this.props.mode) {
        case Mode.Browsing:
          if (this.isMultiPick) {
            if(this.isOneTubePick) return this.onePipePickRightClickMenuItems;
            return this.multiPickRightClickMenuItems;
          }
          if (this.state.selectedWallProp) return this.wallPickRightClickMenuItems;
          else if (this.state.selectedRoomProp) return this.roomPickRightClickMenuItems;
          else if (this.state.selectedHoleProp) return this.holePickRightClickMenuItems;
          else if (this.state.selectedModelProp) return this.modelPickRightClickMenuItems;
          else if (this.state.selectedTextProp) return this.textPickRightClickMenuItems;
          else if (this.state.selectedPicProp) return this.picPickRightClickMenuItems;
          else if (this.state.selectedPipeProp) return this.pipePickRightClickMenuItems;
          else {
            return [];
          }
        case Mode.WallDrawing:
          if (this.showDrawingRightMenu)
            return this.wallDrawingRightClickMenuItems;
          else
            return [];
        case Mode.RoomDrawing:
          if (this.showDrawingRightMenu)
            return this.roomDrawingRightClickMenuItems;
          else 
            return [];
        case Mode.HoleDrawing:
          if (this.showDrawingRightMenu)
            return this.holeDrawingRightClickMenuItems;
          else 
            return [];
        case Mode.ModelAdding:
          return [];
        case Mode.PicAdding:
          return [];
        case Mode.TextAdding:
          return [];
        case Mode.PipeDrawing:
          if(this.tubeGroup.children.length > 0) return this.pipeDrawingRightClickMenuItems;
          else return this.pipeDrawingNoTubesRightClickMenuItems;
      }
    } else {
      return [];
    }
    return [];
  };

  onClickEditFloorSlab = () => {
    if (this.unitLength!.lenthOfEachPixel === 0) {
      window.alert("未设置比例尺！请设置比例尺后绘制。")
      this.props.setMode(Mode.Browsing);
      return;
    }
    const floor = this.currentFloor!;
    this.setState({showFilter: false});
    this.controls_o.enablePan = false;
    this.editVers = [];

    let floorSlab = null;

    for (let obj of floor.children) {
      if(obj.userData.type !== undefined && obj.userData.type === ObjType.Floor)
        floorSlab = obj;
    }
    
    // this.pickedObj = blankGnd!.clone();
    // this.pickedObj.userData.type = ObjType.Floor;
    // this.pickedObj.material.opacity = 0.5;
    // this.pickedObj.material.transparent = true;
    // // todo: change color
    this.pickedObj = floorSlab;
    console.log(floorSlab);
    this.editVers = this.pickedObj!.userData.points;
    this.pickedObj.name = ObjName.EditShape;
    this.originUd = JSON.parse(JSON.stringify(this.pickedObj.userData));;

    // 产生点
    this.editPoints = MESH.createEditPoints(this.editVers, [0,0,0,'XYZ'], 0xf80000, ObjName.EditShapePnt, 12.0, 1.0);
    floor.add(this.editPoints);

    // 产生辅助平面
    let plane_mesh = this.createRefPlane(true);
    plane_mesh.name = ObjName.Drawing;
    floor.add(plane_mesh);

    document.getElementById("canvas-container")!.addEventListener("mousedown", this.onMouseDown_EditPnt, false);
    document.getElementById("canvas-container")!.addEventListener("mousemove", this.onMouseMove_EditPnt, false);
    document.addEventListener("keyup", this.onKeyupEditPntComp, false);
    document.removeEventListener("keydown", this.onClickEsc, false);
    document.addEventListener("keyup", this. onKeyupDel, false);
    document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
    this.props.setIsDrawing(true);
    // console.log(this.pickedObj.name);
  }

  onClickCopyFromFloor = () => {
    var copyFromNum = prompt("从几楼复制: ");
    if (copyFromNum === null) return;
    if(isNaN(parseFloat(copyFromNum)) || !Number.isInteger(Number(copyFromNum))){
      alert("请输入有效楼层!");
      return;
    }
    let copyNum = this.floor2Idx(Number(copyFromNum));
    if(this.floors![copyNum] === undefined){
      alert("该楼层未建立底图，请输入有效楼层!");
      return;
    }

    let copyType = {
      ground: false,
      wall: true,
      room: true,
      model: true,
      picture: true,
      text: true,
      tube: true,
      floorSlab: true,
    }

    let scrFloor = this.floors![copyNum];
    let tarFloor = this.currentFloor!;

    let removeList = [];
    // 删除场景中已有的楼板
    for (var obj of tarFloor.children) {
      if (obj.userData.type) {
        if (obj.userData.type === ObjType.Floor) {
          removeList.push(obj);
        }
      }
    }
    for (let obj of removeList) {
      tarFloor.remove(obj);
    }

    MESH.copyFloor(scrFloor, tarFloor, copyType, this.onMouseDown_PickText, this.onMouseDown_PickPic);
  }

  private wallDrawingRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "完成",
      disabled: false,
      onClick: () => {
        this.setState({
          showRightClickMenu: false,
        });
        if (this.clickPoss.length < 2) {
          setTimeout(function() {
            alert("墙体至少需要2点才能确认！");
          }, 100);
          return;
        }
        this.drawWallComp();
        this.onClickDrawWall();
      },
    },
    {
      label: "重新绘制",
      disabled: false,
      onClick: this.redrawWall,
    },
  ];

  private wallPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        const floor = this.currentFloor!;
        var mesh = MESH.copyWall(this.pickedObj.userData);
        mesh.position.setX(this.pickedObj.position.x + 5);
        this.pickedObj.material.color.set(this.pickedObj.userData.color);
        this.pickedObj = mesh;
        floor.add(this.pickedObj);
        this.moveObj();
      },
    },
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "旋转",
      disabled: false,
      onClick: () => {
        this.rotateObj();
      },
    },
    {
      label: "编辑顶点",
      disabled: false,
      onClick: () => {
        this.onClickEditPnt(null);
        this.setState({ showRightClickMenu: false });
        if (!this.is2D) this.switch2D_3D();
      },
    },
    // {
    //   label: "编辑形状",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickChangeShape(null);
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    // {
    //   label: "添加顶点",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickAddPoint();
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    // {
    //   label: "删除顶点",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickDelPoint(null);
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    // {
    //   label: "线段转弧度",
    //   onClick: () => {},
    // },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];

  private roomDrawingRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "完成",
      disabled: false,
      onClick: () => {
        this.setState({ showRightClickMenu: false });
        if (this.props.roomDrawingMode == RoomDrawingMode.Pollygon) {
          if (this.clickPoss.length < 3) {
            setTimeout(function() {
              alert("房间至少需要3点才能确认！");
            }, 100);
            return;
          }
          this.onClickDrawPolRoomComp(null);
        }
      },
    },
    {
      label: "重新绘制",
      disabled: false,
      onClick: () => {
        this.redrawRoom();
        this.showDrawingRightMenu = false;
        this.setState({ showRightClickMenu: false });
      },
    },
  ];

  private holeDrawingRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "完成",
      disabled: false,
      onClick: () => {
        this.setState({ showRightClickMenu: false });
        if (this.props.holeDrawingMode == HoleDrawingMode.Pollygon) {
          if (this.clickPoss.length < 3) {
            setTimeout(function() {
              alert("打洞至少需要3点才能确认！");
            }, 100);
            return;
          }
          this.onClickDrawPolHoleComp(null);
        }
      },
    }
    // {
    //   label: "重新绘制",
    //   disabled: false,
    //   onClick: () => {
    //     this.redrawRoom();
    //     this.showDrawingRightMenu = false;
    //     this.setState({ showRightClickMenu: false });
    //   },
    // },
  ];

  private roomPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        const floor = this.currentFloor!;

        let mesh = MESH.copyRoom(this.pickedObj.userData);
        mesh.position.setX(this.pickedObj.position.x + 5);
        this.pickedObj.material.color.set(this.pickedObj.userData.color);
        this.pickedObj = mesh;
        floor.add(this.pickedObj);
        this.moveObj();
      },
    },
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "旋转",
      disabled: false,
      onClick: () => {
        this.rotateObj();
      },
    },
    // {
    //   label: "编辑形状",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickChangeShape(null);
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    {
      label: "编辑顶点",
      disabled: false,
      onClick: () => {
        this.onClickEditPnt(null);
        this.setState({ showRightClickMenu: false });
        if (!this.is2D) this.switch2D_3D();
      },
    },
    // {
    //   label: "添加顶点",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickAddPoint();
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    // {
    //   label: "删除顶点",
    //   disabled: false,
    //   onClick: () => {
    //     this.onClickDelPoint(null);
    //     this.setState({ showRightClickMenu: false });
    //     if (!this.is2D) this.switch2D_3D();
    //   },
    // },
    {
      label: "拉伸顶点",
      disabled: false,
      onClick: () => {
        this.setState({ showRightClickMenu: false });
        if (!isRectangle(this.pickedObj.userData.points)) {
          alert("选中的不是矩形房间！拉伸功能只支持矩形房间！");
          return;
        }
        this.onClickStretchRoom();
        if (!this.is2D) this.switch2D_3D();
      },
    },
    {
      label: "线段转弧度",
      disabled: false,
      onClick: () => {
        this.onClick_RoomLineToCircle();
        this.setState({ showRightClickMenu: false });
        if (!this.is2D) this.switch2D_3D();
      },
    },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];

  private holePickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "旋转",
      disabled: false,
      onClick: () => {
        this.rotateObj();
      },
    },
    {
      label: "编辑顶点",
      disabled: false,
      onClick: () => {
        this.onClickEditPnt(null);
        this.setState({ showRightClickMenu: false });
        if (!this.is2D) this.switch2D_3D();
      },
    },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.setState({ showRightClickMenu: false });
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];


  private pipeDrawingRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "后退一步",
      disabled: false,
      onClick: () => {
        this.backOneStepTube();
      },
    },
    {
      label: "完成",
      disabled: false,
      onClick: () => {
        this.drawTubeComp();
        this.setState({ showRightClickMenu: false });
        this.onClickDrawTube();
      },
    },
    {
      label: "重新绘制",
      disabled: false,
      onClick: () => {
        if(this.tubeGroup.userData.tubeID < 0){
          this.redrawTube();
          this.onClickDrawTube();
        }else{
          this.redrawTube_multiFloor();
          this.onClickDrawTube();
        }
        
      },
    },
    {
      label: "跨楼层绘制",
      disabled: false,
      onClick: () => {
        this.mutilFloorTubeDrawingFix(2);
      },
    }
  ];

  private pipeDrawingNoTubesRightClickMenuItems: RightClickMenuItem[] = [
    // 不显示右键
  ];

  private pipePickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "水平移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "编辑形状",
      disabled: false,
      onClick: () => {
        this.onClickChangeShape(null);
        this.setState({ showRightClickMenu: false });
        if(!this.is2D){
          this.isChangeTubeShape3D = true;

        }
        // if (!this.is2D) this.switch2D_3D();
      },
    },
    {
      label: "调整整段高度",
      disabled: false,
      onClick: () => {
        var inputHeight = prompt("请输入高度: ");
        if(inputHeight === null){

        }else if(isNaN(parseFloat(inputHeight))){

        }else{
          if(this.tubeGroup.userData.tubeID >= 0){
            this.changeMultiTubeGroupHeight(Number(inputHeight))
          }else{
            this.changeTubeGroupHeight(Number(inputHeight));
          }
          this.setState({
            selectedPipeProp: undefined
          });
        }
        
        this.setState({
          showRightClickMenu: false,
        });
      },
    },
    {
      label: "改变管线方向",
      disabled: false,
      onClick: () => {
        if(this.tubeGroup.userData.tubeID >= 0){
          this.switchMultiTubeDir();
        }else{
          this.switchTubeDir();
        }
        this.setState({
          showRightClickMenu: false,
        });
      },
    },
    {
      label: "删除整条管线",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.setState({
          showRightClickMenu: false,
        });
        this.updateExistTypeState();
      },
    },
    // {
    //   label: "旋转",
    //   disabled: false,
    //   onClick: () => {
    //     this.rotateObj();
    //     this.setState({
    //       showRightClickMenu: false,
    //     });
    //     this.updateExistTypeState();
    //   },
    // }
  ];

  addOneLastTube = (event: any)=>{
    if(event.button !== 0) return;
    var lastClickPoint = this.pickedObj.userData.edPoint;
    var dir1 = this.pickedObj.userData.edDir;
    var dir2 = new THREE.Vector3();
    var isExtend = 0;
    var newPoint = new THREE.Vector3();
    //处于2D视角下时，管道只能增加水平方向的关键点
    if (this.is2D) {
      //获取新关键点的位置
      newPoint = this.getMousePos(
        event,
        this.camera_o,
        this.currentFloor!.children
      );
      newPoint.setZ(lastClickPoint.z);

      // 吸附
      // 首先计算夹角
      dir2.setX(newPoint.x - lastClickPoint.x);
      dir2.setY(newPoint.y - lastClickPoint.y);
      dir2.setZ(newPoint.z - lastClickPoint.z);
      dir2.normalize();
      dir1.normalize();
      if(dir1.z === 0){
        // 只有前一根是横着的才需要吸附
        var cos_theta = dir2.dot(dir1);
        console.log("cos_theta",cos_theta);
        if(this.tubePoints.length === 1){

        }else{
          if(cos_theta > Math.sqrt(2) / 2 && cos_theta < 1){
            // 应当延长上一根管道的情况
            // 吸附点击位置到延长线上（垂直吸附过去）
            let dx = dir1.x;
            let dy = dir1.y;
            let x0 = lastClickPoint.x;
            let y0 = lastClickPoint.y;
            let x1 = newPoint.x;
            let y1 = newPoint.y;
            let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
            newPoint.setX(x0 + dx * t);
            newPoint.setY(y0 + dy * t); 

            isExtend = 1;
          }else{
            // 应当与上根管道垂直的情况
            if(dir2.cross(dir1).z > 0){
              let dx = -dir1.y;
              let dy = dir1.x;
              let x0 = lastClickPoint.x;
              let y0 = lastClickPoint.y;
              let x1 = newPoint.x;
              let y1 = newPoint.y;
              let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
              newPoint.setX(x0 + dx * t);
              newPoint.setY(y0 + dy * t); 
            }else{
              let dx = dir1.y;
              let dy = -dir1.x;
              let x0 = lastClickPoint.x;
              let y0 = lastClickPoint.y;
              let x1 = newPoint.x;
              let y1 = newPoint.y;
              let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
              newPoint.setX(x0 + dx * t);
              newPoint.setY(y0 + dy * t); 
            }
          }
        }
      
        // 更新dir2
        dir2.setX(newPoint.x - lastClickPoint.x);
        dir2.setY(newPoint.y - lastClickPoint.y);
        dir2.setZ(newPoint.z - lastClickPoint.z);
        dir2.normalize();
      }
      
    }else {
      //处于3D视角下时，管道只能增加垂直方向的关键点
      newPoint = this.getMousePos(
        event,
        this.camera_p,
        this.currentFloor!.children
      );

      //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
      newPoint.setX(lastClickPoint.x);
      newPoint.setY(lastClickPoint.y);
      let diff_Z = newPoint.z - lastClickPoint.z;

      //垂直方向新关键点的方向只需判断z轴即可
      dir2.setX(0);
      dir2.setY(0);
      dir2.setZ(diff_Z);
      dir2.normalize();
    }
    
    var filletMesh = MESH.createOneFilletMesh(
      dir1,
      dir2,
      lastClickPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType
    );
    var tubeMesh = MESH.createOneTubeMesh(
      dir1,
      dir2,
      lastClickPoint,
      newPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    if(isExtend === 0){
      this.tubeGroup?.add(filletMesh);
    }
    this.tubeGroup?.add(tubeMesh);
    tubeMesh.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    filletMesh.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );  
    document.getElementById("canvas-container")!.removeEventListener("mousedown",this.addOneLastTube,false);
    this.currentFloor!.remove(this.refPlane);
    this.backToBrowseMode();
    
  }

  addOneFirstTube = (event: any)=>{
    if(event.button !== 0) return;
    var lastClickPoint = this.pickedObj.userData.stPoint;
    var dir1 = this.pickedObj.userData.edDir;
    var dir2 = new THREE.Vector3();
    dir1.multiplyScalar(-1);
    var isExtend = 0;
    var newPoint = new THREE.Vector3();
    //处于2D视角下时，管道只能增加水平方向的关键点
    if (this.is2D) {
      //获取新关键点的位置
      newPoint = this.getMousePos(
        event,
        this.camera_o,
        this.currentFloor!.children
      );
      newPoint.setZ(lastClickPoint.z);

      // 吸附
      // 首先计算夹角
      dir2.setX(newPoint.x - lastClickPoint.x);
      dir2.setY(newPoint.y - lastClickPoint.y);
      dir2.setZ(newPoint.z - lastClickPoint.z);
      dir2.normalize();
      dir1.normalize();
      if(dir1.z === 0){
        // 只有前一根是横着的才需要吸附
        var cos_theta = dir2.dot(dir1);
        console.log("cos_theta",cos_theta);
        if(this.tubePoints.length === 1){

        }else{
          if(cos_theta > Math.sqrt(2) / 2 && cos_theta < 1){
            // 应当延长上一根管道的情况
            // 吸附点击位置到延长线上（垂直吸附过去）
            let dx = dir1.x;
            let dy = dir1.y;
            let x0 = lastClickPoint.x;
            let y0 = lastClickPoint.y;
            let x1 = newPoint.x;
            let y1 = newPoint.y;
            let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
            newPoint.setX(x0 + dx * t);
            newPoint.setY(y0 + dy * t); 

            isExtend = 1;
          }else{
            // 应当与上根管道垂直的情况
            if(dir2.cross(dir1).z > 0){
              let dx = -dir1.y;
              let dy = dir1.x;
              let x0 = lastClickPoint.x;
              let y0 = lastClickPoint.y;
              let x1 = newPoint.x;
              let y1 = newPoint.y;
              let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
              newPoint.setX(x0 + dx * t);
              newPoint.setY(y0 + dy * t); 
            }else{
              let dx = dir1.y;
              let dy = -dir1.x;
              let x0 = lastClickPoint.x;
              let y0 = lastClickPoint.y;
              let x1 = newPoint.x;
              let y1 = newPoint.y;
              let t = (x1 - x0)*dx + (y1 - y0)*dy/(dx * dx + dy * dy);
              newPoint.setX(x0 + dx * t);
              newPoint.setY(y0 + dy * t); 
            }
          }
        }
      
        // 更新dir2
        dir2.setX(newPoint.x - lastClickPoint.x);
        dir2.setY(newPoint.y - lastClickPoint.y);
        dir2.setZ(newPoint.z - lastClickPoint.z);
        dir2.normalize();

        dir2.multiplyScalar(-1);
        dir1.multiplyScalar(-1);

        newPoint.setX(newPoint.x - dir1.x * this.tubeGroup.userData.radius * 2);
        newPoint.setY(newPoint.y - dir1.y * this.tubeGroup.userData.radius * 2);
        newPoint.setZ(newPoint.z - dir1.z * this.tubeGroup.userData.radius * 2);
    
        lastClickPoint.setX(lastClickPoint.x - dir1.x * this.tubeGroup.userData.radius * 2);
        lastClickPoint.setY(lastClickPoint.y - dir1.y * this.tubeGroup.userData.radius * 2);
        lastClickPoint.setZ(lastClickPoint.z - dir1.z * this.tubeGroup.userData.radius * 2);
    
      }
    }else {
      //处于3D视角下时，管道只能增加垂直方向的关键点
      newPoint = this.getMousePos(
        event,
        this.camera_p,
        this.currentFloor!.children
      );

      //由于只改变垂直坐标，因此水平坐标值与上一个关键点相同
      newPoint.setX(lastClickPoint.x);
      newPoint.setY(lastClickPoint.y);
      let diff_Z = lastClickPoint.z - newPoint.z;

      //垂直方向新关键点的方向只需判断z轴即可
      dir2.setX(0);
      dir2.setY(0);
      dir2.setZ(diff_Z);
      dir2.normalize();

      dir1.multiplyScalar(-1);

      newPoint.setX(newPoint.x - dir1.x * this.tubeGroup.userData.radius * 2);
      newPoint.setY(newPoint.y - dir1.y * this.tubeGroup.userData.radius * 2);
      // newPoint.setZ(newPoint.z - dir1.z * this.tubeGroup.userData.radius * 2);
  
      lastClickPoint.setX(lastClickPoint.x - dir1.x * this.tubeGroup.userData.radius * 2);
      lastClickPoint.setY(lastClickPoint.y - dir1.y * this.tubeGroup.userData.radius * 2);
      // lastClickPoint.setZ(lastClickPoint.z - dir1.z * this.tubeGroup.userData.radius * 2);
  
    }

   
    var filletMesh = MESH.createOneFilletMesh(
      dir2,
      dir1,
      lastClickPoint,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType
    );
    var t;
    if(dir1.z === -1 && dir2.z === -1){
      t = new THREE.Vector3(lastClickPoint.x,lastClickPoint.y,lastClickPoint.z - 2 * this.tubeGroup.userData.radius);
    }else if(dir1.z === 1 && dir2.z === 1){
      t = new THREE.Vector3(lastClickPoint.x,lastClickPoint.y,lastClickPoint.z + 2 * this.tubeGroup.userData.radius);
    }else{
      t = lastClickPoint;
    }
    var tubeMesh = MESH.createOneTubeMesh(
      new THREE.Vector3(0,0,0),
      dir2,
      newPoint,
      t,
      this.tubeGroup.userData.color,
      this.tubeGroup.userData.radius,
      this.tubeGroup.userData.pipeType,
      this.tubeGroup.userData.textureDir
    );
    this.tubeGroup?.add(filletMesh,tubeMesh);
    // this.tubeGroup.children.splice(0,0,filletMesh);
    // this.tubeGroup.children.splice(0,0,tubeMesh);
    
    tubeMesh.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );
    filletMesh.geometry.translate(
      -this.tubeGroup.userData.center.x,
      -this.tubeGroup.userData.center.y,
      -this.tubeGroup.userData.center.z
    );  
    this.tubeGroup.children.pop();
    this.tubeGroup.children.pop();
    this.tubeGroup.children.splice(0,0,filletMesh);
    this.tubeGroup.children.splice(0,0,tubeMesh);

    document.getElementById("canvas-container")!.removeEventListener("mousedown",this.addOneFirstTube,false);
    this.currentFloor!.remove(this.refPlane);
    this.backToBrowseMode();
  }

  getNotEmptyFloorIdx = () => {
    var idxes: number[] = [];
    for (var i = 0; i < this.floors!.length; i++) {
      if (!isEmptyFloor(this.floors![i])) {
        idxes.push(this.idx2FloorDict[i]);
      }
    }
    return idxes;
  }


  private onePipePickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "延伸",
      disabled: false,
      onClick: () => {
        
        this.refPlane = this.createRefPlane();
        this.currentFloor!.add(this.refPlane);
        if(this.pickedObj === this.tubeGroup.children[0]){
          var firstClickPoint = this.pickedObj.userData.stPoint;
          this.refPlane.position.set(firstClickPoint.x, 
            firstClickPoint.y,
             0);
          document.addEventListener("keydown", this.onKeydown_Switch2D_3D, false);
          document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
          this.canPickPicOrText = false;
          document.getElementById("canvas-container")!.addEventListener("mousedown",this.addOneFirstTube,false);
  
          this.isOneTubePick = false;
          this.isMultiPick = false;
          this.setState({
            showRightClickMenu: false,
             });
        }else if(this.pickedObj === this.tubeGroup.children[this.tubeGroup.children.length - 1]){
          var lastClickPoint = this.pickedObj.userData.edPoint;
          this.refPlane.position.set(lastClickPoint.x, 
            lastClickPoint.y,
             0);
          document.addEventListener("keydown", this.onKeydown_Switch2D_3D, false);
          document.getElementById("canvas-container")!.removeEventListener("mousedown", this.onMouseDown_Pick, false);
          this.canPickPicOrText = false;
          document.getElementById("canvas-container")!.addEventListener("mousedown",this.addOneLastTube,false);
  
          this.isOneTubePick = false;
          this.isMultiPick = false;
          this.setState({
            showRightClickMenu: false,
          });
        }else{
          this.setState({
            showRightClickMenu: false,
          });
        }
        this.currentFloor!.remove(this.refPlane);
      },
      
    },
    {
      label: "调整高度",
      disabled: false,
      onClick: () => {
        var inputHeight = Number(prompt("请输入高度: "));
        this.onChangeTubeHeight_fix2(inputHeight);
        this.setState({
          selectedPipeProp: undefined
        });
        this.setState({
          showRightClickMenu: false,
        });
      },
    },
    {
      label: "删除单根管道",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.setState({
          showRightClickMenu: false,
        });
        this.updateExistTypeState();
      },
    }
  ];

  private modelPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        const floor = this.currentFloor!;
        // 深拷贝
        const oldObj = this.pickedObj;
        this.pickedObj = MESH.copyModel(oldObj);
        floor.add(this.pickedObj);
        this.moveObj();
      },
    },
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "旋转",
      disabled: false,
      onClick: () => {
        this.rotateObj();
      },
    },
    // {
    //   label: "缩放",
    //   onClick: () => {
    //     this.scaleObj();
    //   },
    // },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        const scene = this.scene;
        removeObjectsByName(scene, ObjName.BoxHelper);
        this.delObj();
      },
    },
  ];

  private textPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        const floor = this.currentFloor!;
        var label = MESH.copyText(
          this.pickedObj.userData,
          this.onMouseDown_PickText,
        );
        this.cancelPickedState([this.pickedObj]);
        this.pickedObj = label;
        floor.add(this.pickedObj);
        this.onClickTransObj(null);
        this.transCtrl_o.setMode("translate");
        this.transCtrl_p.setMode("translate");
        this.transCtrl_o.showZ = false;
        this.transCtrl_p.showZ = false;

      },
    },
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];

  private picPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        const floor = this.currentFloor!;
        var label = MESH.copyPic(
          this.pickedObj.userData,
          this.onMouseDown_PickPic,
        );
        this.cancelPickedState([this.pickedObj]);
        this.pickedObj = label;
        floor.add(this.pickedObj);
        this.onClickTransObj(null);
        this.transCtrl_o.setMode("translate");
        this.transCtrl_p.setMode("translate");
        this.transCtrl_o.showZ = false;
        this.transCtrl_p.showZ = false;

      },
    },
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];

  private multiPickRightClickMenuItems: RightClickMenuItem[] = [
    {
      label: "移动",
      disabled: false,
      onClick: () => {
        this.moveObj();
      },
    },
    {
      label: "复制",
      disabled: false,
      onClick: () => {
        var group = this.copyObjs(this.multiPickedObjs);
        this.scene.add(group);
        this.setState({
          showRightClickMenu: false,
          selectedPipeProp: undefined,
          selectedRoomProp: undefined,
          selectedWallProp: undefined,
          selectedMultiProp: undefined,
        });
        const transCtrl = this.is2D ? this.transCtrl_o : this.transCtrl_p;
        transCtrl.attach(group);
        transCtrl.setMode("translate");
        transCtrl.showX = true;
        transCtrl.showY = true;
        transCtrl.showZ = false;
        this.scene.add(transCtrl);
        this.multiPickGroup = group;
        
        document.addEventListener("keydown", this.onClickMultiCopyComp, false);
        
      },
    },
    {
      label: "删除",
      disabled: false,
      onClick: () => {
        this.delObj();
        this.updateExistTypeState();
      },
    },
  ];

  editWall(mode: "name" | "width" | "height" | "color" | "rideHeight" | "transparency") {
    const objs =
      this.pickedObj !== null ? this.pickedObj : this.multiPickedObjs;
    console.log(this.isMultiPick);
    const floor = this.currentFloor!;

    if (!this.isMultiPick) {
      //单选
      const ud = this.pickedObj.userData;
      const prop = this.state.selectedWallProp;

      // TODO: 用物体原本的属性而非面板上的属性
      switch (mode) {
        case "name":
          break;

        case "width":
          break;

        case "height":
          break;

        case "color":
          break;

        case "rideHeight":
          break;

        case "transparency":
          break;
      }

      let newMesh = MESH.createWallMesh(
        ud.points,
        prop!.width! / this.unitLength!.lenthOfEachPixel,
        prop!.height! / this.unitLength!.lenthOfEachPixel,
        prop!.color,
        ud.uuid,
        ud.rotation,
        prop!.rideHeight! / this.unitLength!.lenthOfEachPixel,
        prop!.transparency! / 100,
      );
      newMesh.userData.name = prop!.name;
      floor.remove(this.pickedObj);
      floor.add(newMesh);
      this.pickedObj = newMesh;
    } else {
      // 多选
      let tempMultiPickedObj = [];
      for (let i = objs.length - 1; i >= 0; i--) {
        //必须从后往前
        const ud = objs[i].userData;
        let w, h, r;

        switch (mode) {
          case "name":
            break;
          case "width":
            w = ud.width / this.unitLength!.lenthOfEachPixel;
            h = ud.height;
            r = ud.rideHeight;
            break;
          case "height":
            w = ud.width;
            h = ud.height / this.unitLength!.lenthOfEachPixel;
            r = ud.rideHeight;
            break;

          case "color":
            w = ud.width;
            h = ud.height;
            r = ud.rideHeight;
            break;

          case "rideHeight":
            w = ud.width;
            h = ud.height;
            r = ud.rideHeight / this.unitLength!.lenthOfEachPixel;
            break;
        }
        let newMesh = MESH.createWallMesh(ud.points, w, h, ud.color, ud.uuid, ud.rotation, r, ud.transparency);
        newMesh.userData.name = ud.name;
        floor.remove(objs[i]);
        floor.add(newMesh);
        tempMultiPickedObj.push(newMesh);
      }
      this.multiPickedObjs = tempMultiPickedObj;
    }
  }

  editRoom(mode: "name" | "transparency" | "height" | "color" | "rideHeight") {
    const floor = this.currentFloor!;

    if (!this.isMultiPick) {
      const ud = this.pickedObj.userData;
      const prop = this.state.selectedRoomProp;
      let newMesh = MESH.createPolRoomMesh(
        ud.points,
        prop!.height! / this.unitLength!.lenthOfEachPixel,
        prop!.color,
        ud.uuid,
        ud.rotation,
        prop!.rideHeight! / this.unitLength!.lenthOfEachPixel,
        prop!.transparency! / 100
      );
      newMesh.userData.name = prop!.name;
      newMesh.userData.transparency = prop!.transparency! / 100;
      floor.remove(this.pickedObj);
      floor.add(newMesh);
      this.pickedObj = newMesh;
    } else {
      const objs = this.multiPickedObjs;
      let tempMultiPickedObj = [];
      for (let i = objs.length - 1; i >= 0; i--) {
        const ud = objs[i].userData;
        let h = ud.height;
        let r = ud.rideHdight;
        let c = ud.color;
        let t = ud.transparency;

        // BUG: 修改颜色和透明度
        switch (mode) {
          case "name":
            break;
          case "transparency":
            break;
          case "height":
            h = ud.height / this.unitLength!.lenthOfEachPixel;
            r = ud.rideHeight;
            break;

          case "color":
            break;

          case "rideHeight":
            h = ud.height;
            r = ud.rideHeight / this.unitLength!.lenthOfEachPixel;
            break;
        }
        let newMesh = MESH.createPolRoomMesh(
          ud.points,
          h,
          ud.color,
          ud.uuid,
          ud.rotation,
          r,
          ud.transparency
        );
        newMesh.userData.name = ud.name;
        newMesh.userData.transparency = ud.transparency;
        floor.remove(objs[i]);
        floor.add(newMesh);
        tempMultiPickedObj.push(newMesh);
      }
      this.multiPickedObjs = tempMultiPickedObj;
    }
  }

  setCompassAngle = () => {
    const ctrl = this.is2D ? this.controls_o : this.controls_p;
    var angle = 0;
    if (ctrl !== undefined) {
      angle = ctrl.getAzimuthalAngle();
    }
    angle *= 180 / Math.PI;
    this.setState({ compassAngle: angle });
    // console.log("setCompassAngle", angle);
  };

  ConditionalWallPropertyPanel() {
    if (this.state.selectedWallProp) {
      return (
        <WallPropertyPanel
          wallProperty={this.state.selectedWallProp}
          key={ObjType.Wall}
          onNameChange={(name) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.name = name;
              }
            } else {
              this.state.selectedWallProp!.name = name;
            }

            if (this.props.mode == Mode.Browsing) this.editWall("name");
            else DefaultValues.defaultWallProp.name = name;
          }}
          onColorChange={(color) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.color = color;
              }
            } else {
              this.state.selectedWallProp!.color = color;
            }
            if (this.props.mode == Mode.Browsing) this.editWall("color");
            else DefaultValues.defaultWallProp.color = color;
          }}
          onWidthChange={(width) => {
            // console.log(this.multiPickedObjs[0].userData.width * this.unitLength!.lenthOfEachPixel);
            // console.log(this.multiPickedObjs[0].userData.height * this.unitLength!.lenthOfEachPixel);
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.width = width;
              }
              console.log(
                this.multiPickedObjs[0].userData.width *
                  this.unitLength!.lenthOfEachPixel
              );
              console.log(
                this.multiPickedObjs[0].userData.height *
                  this.unitLength!.lenthOfEachPixel
              );
            } else {
              this.state.selectedWallProp!.width = width;
            }
            if (this.props.mode == Mode.Browsing) this.editWall("width");
            else DefaultValues.defaultWallProp.width = width;
            // console.log(this.multiPickedObjs[0].userData.width * this.unitLength!.lenthOfEachPixel);
            // console.log(this.multiPickedObjs[0].userData.height * this.unitLength!.lenthOfEachPixel);
          }}
          onHeightChange={(rideHeight) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.rideHeight = rideHeight;
              }
            } else {
              this.state.selectedWallProp!.rideHeight = rideHeight;
            }

            if (this.props.mode == Mode.Browsing) this.editWall("rideHeight");
            else DefaultValues.defaultWallProp.rideHeight = rideHeight;
          }}
          onLengthChange={(height) => {
            console.log("changed");
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.height = height;
              }
            } else {
              this.state.selectedWallProp!.height = height;
            }

            if (this.props.mode == Mode.Browsing) this.editWall("height");
            else DefaultValues.defaultWallProp.height = height;
          }}
          onTransparencyChange={(transparency) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.transparency = transparency / 100;
              }
            } else {
              this.state.selectedWallProp!.transparency = transparency;
            }
            if (this.props.mode == Mode.Browsing) this.editWall("transparency");
            else DefaultValues.defaultWallProp.transparency = transparency;
          }}
        />
      );
    }
  }

  ConditionalRoomPropertyPanel() {
    if (this.state.selectedRoomProp) {
      return (
        <RoomPropertyPanel
          roomProperty={this.state.selectedRoomProp}
          key={ObjType.Room}
          onNameChange={(name) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.name = name;
              }
            } else {
              this.state.selectedRoomProp!.name = name;
            }
            if (this.props.mode == Mode.Browsing) this.editRoom("name");
            else DefaultValues.defaultRoomProp.name = name;
          }}
          onColorChange={(color) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.color = color;
              }
            } else {
              this.state.selectedRoomProp!.color = color;
            }
            if (this.props.mode == Mode.Browsing) this.editRoom("color");
            else DefaultValues.defaultRoomProp.color = color;
          }}
          onHeightChange={(rideHeight) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.rideHeight = rideHeight;
              }
            } else {
              this.state.selectedRoomProp!.rideHeight = rideHeight;
            }
            if (this.props.mode == Mode.Browsing) this.editRoom("rideHeight");
            else DefaultValues.defaultRoomProp.rideHeight = rideHeight;
          }}
          onLengthChange={(height) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.height = height;
              }
            } else {
              this.state.selectedRoomProp!.height = height;
            }
            if (this.props.mode == Mode.Browsing) this.editRoom("height");
            else DefaultValues.defaultRoomProp.height = height;
          }}
          onTransparencyChange={(transparency) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.transparency = transparency / 100;
              }
            } else {
              this.state.selectedRoomProp!.transparency = transparency;
            }
            if (this.props.mode == Mode.Browsing) this.editRoom("transparency");
            else DefaultValues.defaultRoomProp.transparency = transparency;
          }}
          onAreaChange={(area) => {
            // this.state.selectedRoomProperty!.transparency = area;
            // if ( this.props.mode == Mode.Browsing ) this.editRoom();
          }}
        />
      );
    }
  }

  ConditionalHolePropertyPanel() {
    if (this.state.selectedHoleProp) {
      return (
        <HolePropertyPanel
          holeProperty={this.state.selectedHoleProp}
          key={ObjType.Hole}
          onNameChange={(name) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.name = name;
              }
            } else {
              this.state.selectedHoleProp!.name = name;
            }
            // if (this.props.mode == Mode.Browsing) this.editRoom("name");
            // else DefaultValues.defaultRoomProp.name = name;
          }}
          onColorChange={(color) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.color = color;
              }
            } else {
              this.state.selectedHoleProp!.color = color;
            }
            // if (this.props.mode == Mode.Browsing) this.editRoom("color");
            // else DefaultValues.defaultRoomProp.color = color;
          }}
          onHeightChange={(rideHeight) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.rideHeight = rideHeight;
              }
            } else {
              this.state.selectedHoleProp!.rideHeight = rideHeight;
            }
            // if (this.props.mode == Mode.Browsing) this.editRoom("rideHeight");
            // else DefaultValues.defaultRoomProp.rideHeight = rideHeight;
          }}
          onLengthChange={(height) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.height = height;
              }
            } else {
              this.state.selectedHoleProp!.height = height;
            }
            // if (this.props.mode == Mode.Browsing) this.editRoom("height");
            // else DefaultValues.defaultRoomProp.height = height;
          }}
          onTransparencyChange={(transparency) => {
            if (this.isMultiPick) {
              for (let i = 0; i < this.multiPickedObjs.length; i++) {
                this.multiPickedObjs[i].userData.transparency = transparency / 100;
              }
            } else {
              this.state.selectedHoleProp!.transparency = transparency;
            }
            // if (this.props.mode == Mode.Browsing) this.editRoom("transparency");
            // else DefaultValues.defaultRoomProp.transparency = transparency;
          }}
          onAreaChange={(area) => {
            // this.state.selectedRoomProperty!.transparency = area;
            // if ( this.props.mode == Mode.Browsing ) this.editRoom();
          }}
        />
      );
    }
  }

  ConditionalPipePropertyPanel() {
    if (this.state.selectedPipeProp) {
      return (
        <PipePropertyPanel
          key={ObjType.Tube}
          pipeProperty={this.state.selectedPipeProp}
          onNameChange={(name) => {
            this.state.selectedPipeProp!.name = name;
            this.tubeGroup.userData.name = name;
          }}

          onHeightChange={(height) => {
            console.log("height", height);
            this.state.selectedPipeProp!.rideHeight = height;
            if (this.props.mode == Mode.Browsing)
              if(this.isMultiPick) this.onChangeTubeHeight_fix2(height);
              else{
                if(this.tubeGroup.userData.tubeID >= 0){
                  this.changeMultiTubeGroupHeight(height)
                }else{
                  this.changeTubeGroupHeight(height);
                }
              }
          }}

          onRadiusChange={(radius) => {
            console.log("radius", radius);
            this.state.selectedPipeProp!.radius = radius;
            if (this.props.mode == Mode.Browsing){
              this.onChangeTubeRadius(radius);
              if(this.tubeGroup.userData.tubeID >= 0){
                this.changeMultiTubeRadius(radius);
              }
            }
          }}
          onColorChange={(color) => {
            this.state.selectedPipeProp!.color = color;
            console.log("color:", color.substr(1, 6));
            console.log("color:", parseInt(color.substr(1, 6), 16));
            if (this.props.mode == Mode.Browsing){
              this.onChangeTubeColor(parseInt(color.substr(1, 6), 16));
              this.tubeGroup.userData.color = color;
              this.pickedObj.userData.color = color;
              //夸楼层管道需要把目标楼层的那一段也变色
              if(this.tubeGroup.userData.tubeID >= 0){
                // let oneTube: any;
                // for(let i = 0;i < this.floors!.length;i++){
                //   let cFloor = this.floors![i]; 
                //   for(let j = 0; j < cFloor.children.length; j++){
                //     let tempObj = cFloor.children[j];
                //     if(tempObj.userData.type === ObjType.Tube && 
                //       tempObj.userData.tubeID === this.tubeGroup.userData.tubeID
                //       ){
                //       let tubeNumber = tempObj.children.length;
                //       for(let k = 0;k < tubeNumber;k++){
                //         oneTube = tempObj.children[k];
                //         oneTube.material.color.set(parseInt(color.substr(1, 6), 16));
                //         oneTube.userData.color = color;
                //       }
                //     }
                //   }
                // }
                this.changeMultiTubeColor(parseInt(color.substr(1, 6), 16));
                var tubeID = this.tubeGroup.userData.tubeID;
                for(let i = 0; i < this.MultiTubeInfo.get(tubeID)!.length; i++){
                  this.MultiTubeInfo.get(tubeID)![i].userData.color = color;
                }
              }
            }
              

            
            
          }}
          onPatternChange={(pattern) => {
            this.state.selectedPipeProp!.pattern = pattern;
          }}
          onTypeChange={(type) => {
            this.state.selectedPipeProp!.type = type;
            if (this.props.mode == Mode.Browsing){
              this.onChangeTubeType(type);
              if(this.tubeGroup.userData.tubeID >= 0){
                // this.onChangeTubeType_multi(type);
                this.changeMultiTubeType(type);
              }
            }
          }}
        />
      );
    }
  }

  ConditionalMultiPropertyPanel() {
    if (this.state.selectedMultiProp) {
      return (
        <MultiPropertyPanel
          multiProperty={this.state.selectedMultiProp}
          onNameChange={(name) => {}}
          onHeightChange={(height) => {}}
        />
      );
    }
  }

  ConditionalTextPropertyPanel() {
    if (this.state.selectedTextProp) {
      return (
        <TextPropertyPanel
          textProperty={this.state.selectedTextProp}
          key={ObjType.Text}
          onContentChange={(content) => {
            this.state.selectedTextProp!.content = content;
            const ud = this.pickedObj.userData;
            if (this.props.mode == Mode.Browsing) {
              let new_text = MESH.createTextLabel(
                content,
                new Vector3(ud.pos.x, ud.pos.y, ud.pos.z),
                null,
                ud.uuid
              );
              this.pickedObj.userData.content = content;
              this.currentFloor?.remove(this.pickedObj);
              this.currentFloor?.add(new_text);
              this.pickedObj = new_text;

              // this.pickedObj.userData.content = content;
            } else {
              DefaultValues.defaultTextProp.content = content;
            }
          }}
          onHeightChange={(rideHeight) => {
            this.state.selectedTextProp!.rideHeight = rideHeight;
            if (this.props.mode == Mode.Browsing) {
              const x = this.pickedObj.position.x;
              const y = this.pickedObj.position.y;
              const z = Number(
                (rideHeight / this.unitLength?.lenthOfEachPixel!).toFixed(2)
              );
              this.pickedObj.position.set(x, y, z);
              this.pickedObj.userData.pos.z = z;
            } else {
              DefaultValues.defaultTextProp.rideHeight = rideHeight;
            }
          }}
        />
      );
    }
  }

  ConditionalPicPropertyPanel() {
    if (this.state.selectedPicProp) {
      return (
        <PicPropertyPanel
          picProperty={this.state.selectedPicProp}
          onNameChange={(name) => {
            this.state.selectedPicProp!.name = name;
            if (this.props.mode == Mode.Browsing) {
              this.pickedObj.userData.name = name;
            }
          }}
          onRideHeightChange={(rideHeight) => {
            this.state.selectedPicProp!.rideHeight = rideHeight;
            if (this.props.mode == Mode.Browsing) {
              const x = this.pickedObj.position.x;
              const y = this.pickedObj.position.y;
              this.pickedObj.position.set(
                x,
                y,
                rideHeight / this.unitLength?.lenthOfEachPixel!
              );

              this.pickedObj.userData.rideHeight =
                rideHeight / this.unitLength?.lenthOfEachPixel!;
            }
          }}
          onHeightChange={(height) => {
            this.state.selectedPicProp!.height = height;
            if (this.props.mode == Mode.Browsing) {
              this.pickedObj.element.style.height = height + "px";

              this.pickedObj.userData.height = height;
            }
          }}
          onWidthChange={(width) => {
            this.state.selectedPicProp!.width = width;
            if (this.props.mode == Mode.Browsing) {
              this.pickedObj.element.style.width = width + "px";

              this.pickedObj.userData.width = width;
            }
          }}
        />
      );
    }
  }

  ConditionalPicAddPanel() {
    if (this.state.isAddingPic) {
      return (
        <PicAddPanel
          key={ObjType.Picture}
          getPicProps={(url, name) => {
            this.picUrl = url;
            this.picName = name;
          }}
          itemData={this.state.imgList}
        />
      );
    }
  }

  ConditionalModelAddPanel() {
    if (this.state.isAddingModel) {
      return (
        <ModelAddPanel
          key={ObjType.Model}
          getItemData={(data: ModelItem) => {
            this.modelData = data;
          }}
          data={this.state.modelDataRaw}
          onRef={this.ModelAddPanelRef}
        />
      );
    }
  }

  ConditionalModelPropertyPanel() {
    if (this.state.selectedModelProp) {
      return (
        <ModelPropertyPanel
          modelProps={this.state.selectedModelProp}
          onScaleChange={(scale:any)=>{
            this.pickedObj.scale.set(scale.x, scale.z, scale.y);
            this.pickedObj.userData.scale = this.pickedObj.scale;
            this.cancelPickedState([this.pickedObj], false);
          }}
          onRideHeightChange={(rideHeight) => {
            this.state.selectedModelProp!.rideHeight = rideHeight;
            if (this.props.mode == Mode.Browsing) {
              const x = this.pickedObj.position.x;
              const y = this.pickedObj.position.y;
              this.pickedObj.position.set(
                x,
                y,
                rideHeight / this.unitLength?.lenthOfEachPixel!
              );

              this.pickedObj.userData.rideHeight =
                rideHeight / this.unitLength?.lenthOfEachPixel!;
            }
            this.pickedObj.userData.position = new THREE.Vector3(
              this.pickedObj.position.x,
              this.pickedObj.position.y,
              rideHeight / this.unitLength?.lenthOfEachPixel!
            );
            removeObjectsByName(this.scene, ObjName.BoxHelper);
            var box = new THREE.Box3();
             box.setFromObject(this.pickedObj);
             var helper = new THREE.Box3Helper(box, new THREE.Color(0x0000ff));
              helper.name = ObjName.BoxHelper;
             this.scene.add(helper);
          }}
        />
      );
    }
  }

  render() {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          id="canvas-container"
          style={{ width: "100%", height: "100%" , 
            position: "relative", 
            zIndex: 0,
          }}
          ref={this.divRef}
        >
          {/* <canvas
            ref={this.canvasRef}
            id="canvas"
            style={{ width: "100%", height: "100%" }}
          ></canvas> */}
        </div>
        <BrowserOperationHints />
        <WallDrawingOperationHints />
        <RoomDrawingOperationHints />
        <PipelineDrawingOperationHints />
        <ModelAddingOperationHints />
        <PicAddingOperationHints />
        <TextAddingOperationHints />
        {this.ConditionalWallPropertyPanel()}
        {this.ConditionalRoomPropertyPanel()}
        {this.ConditionalHolePropertyPanel()}
        {this.ConditionalPipePropertyPanel()}
        {this.ConditionalMultiPropertyPanel()}
        {this.ConditionalTextPropertyPanel()}
        {this.ConditionalPicPropertyPanel()}
        {this.ConditionalPicAddPanel()}
        {this.ConditionalModelAddPanel()}
        {this.ConditionalModelPropertyPanel()}
        <RightClickMenu
          items={this.currentRightClickMenu()}
          position={this.state.rightClickPosition}
        />
        <AdjustHeightMenu
          isVisible = {this.state.adjustHeightMenuProps.isVisible}
          height = {this.state.adjustHeightMenuProps.height}
          position = {
            this.state.adjustHeightMenuProps.position
          }
        />
        <ScaleIcon value={this.state.scaleIconProp!} />
        <CompassIcon
          angle={this.state.compassAngle}
          onClick={this.onClickViewRestore}
        />
        <TypeFilter
          onRef={this.TypeFilterRef}
          onChange={(type, visible)=>{
            setTypeVis(type, visible, this.floors!);
          }}
          displayInBrowse={
            !this.state.selectedWallProp && 
            !this.state.selectedRoomProp && 
            !this.state.selectedPicProp && 
            !this.state.selectedTextProp && 
            !this.state.selectedPipeProp &&
            !this.state.selectedMultiProp && 
            !this.state.selectedModelProp &&
            this.state.showFilter
          }
          displayType={this.state.existType}
        />
        <ImportBasemapHint
          display={this.state.showImportBasemapHint}
          handleCopy={(srcFloorNum: string, copyType: any) => {
            let scrFloor = this.floors![this.floor2Idx(parseInt(srcFloorNum))];
            let tarFloor = this.currentFloor!;
            console.log("srcFloor",scrFloor);
            console.log("tarFloor",tarFloor);
            MESH.copyFloor(scrFloor, tarFloor, copyType, this.onMouseDown_PickText, this.onMouseDown_PickPic);
            this.setState({showImportBasemapHint: false});
          }}
          getNotEmptyFloorIdx={()=>{return this.getNotEmptyFloorIdx()}}
        />
      </Stack>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    mode: state.ui.mode,
    roomDrawingMode: state.ui.roomDrawingMode,
    holeDrawingMode: state.ui.holeDrawingMode,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    setMode: (mode: Mode) => dispatch(setMode(mode)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderContent);
