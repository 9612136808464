export enum RenderingUIEvent {
  Switch2D3D = "Switch2D3D",
  DrawWall = "DrawBoxWall",
  DrawPolHole = "DrawPolRoom",
  DrawPolHoleComp = "DrawPolRoomComp",
  DrawPolRoom = "DrawPolHole",
  DrawPolRoomComp = "DrawPolHoleComp",
  DrawRoom = "DrawRoom",
  DrawHole = "DrawHole",
  DrawTube = "DrawTube",
  AddText = "AddText",
  AddPic = "AddPic",
  Save = "Save",
  Pick = "Pick",
  AddModel = "AddModel",
  ChangeShape = "ChangeShape",
  ChangeShapeComp = "ChangeShapeComp",
  ChangeUnitLength = "ChangeUnitLength",
  EditObj = "EditObj",
  UploadBaseMap = "UploadBaseMap",
  LoadProj = "LoadProj",
  ViewRestore = "ViewRestore",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  SwitchFloor = "SwitchFloor",
  SwitchFloorMode = "SwitchFloorMode",
  SwitchBasemapMode = "SwitchBasemapMode",
  SetFloorHeight = "SetFloorHeight",
  LocateByUuid = "LocateByUuid",
  SetTypeVis = "SetTypeVis",
  EditFloorSlab = "EditFloorSlab",
  CopyFromFloor = "CopyFromFloor",
}
