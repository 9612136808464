import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface HeaderFloorSelectProps {
  onChange: (floor: number) => void,
  floor: number,
  setFloor: (floor: number) => void,
  isDrawing: boolean,
  setIsDrawing: (isDrawing: boolean)=>void
};

export const HeaderFloorSelect: React.FC<HeaderFloorSelectProps> = (props) => {
  // const [floor, setFloor] = React.useState(1);
  const {floor, setFloor , onChange, isDrawing} = props;

  const handleChange = (event: SelectChangeEvent) => {
    let floor = parseInt(event.target.value);
    setFloor(floor);
    onChange(floor);
  };

  return (
    // <Box sx={{ minWidth: 120 }}>
      <FormControl>
        <InputLabel>楼层</InputLabel>
        <Select
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={floor.toString()}
          defaultValue={'1'}
          label="floor"
          onChange={handleChange}
          disabled = {isDrawing}
        >
          <MenuItem value={-4}>B4</MenuItem>
          <MenuItem value={-3}>B3</MenuItem>
          <MenuItem value={-2}>B2</MenuItem>
          <MenuItem value={-1}>B1</MenuItem>
          <MenuItem value={1}>F1</MenuItem>
          <MenuItem value={2}>F2</MenuItem>
          <MenuItem value={3}>F3</MenuItem>
          <MenuItem value={4}>F4</MenuItem>
          <MenuItem value={5}>F5</MenuItem>
          <MenuItem value={6}>F6</MenuItem>
          <MenuItem value={7}>F7</MenuItem>
          <MenuItem value={8}>F8</MenuItem>
          <MenuItem value={9}>F9</MenuItem>
          <MenuItem value={10}>F10</MenuItem>
          <MenuItem value={11}>F11</MenuItem>
          <MenuItem value={12}>F12</MenuItem>
          <MenuItem value={13}>F13</MenuItem>
          <MenuItem value={14}>F14</MenuItem>
          <MenuItem value={15}>F15</MenuItem>
        </Select>
      </FormControl>
    // </Box>
  );
}