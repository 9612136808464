import * as THREE from "three";
import { Vector3 } from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OrbitControls } from 'three-stdlib/controls/OrbitControls'
import {
  CSS2DRenderer,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

/*************
 * 与 Camera 和 OrbitControls 有关的逻辑在此实现
 */


/**
 * 根据uuid定位物体
 * @param uuid 
 * @param camera 
 * @param floors 
 * @param control 
 * @returns 
 */
export function locateByUuid (
  uuid: string, 
  camera: THREE.Camera, 
  floors: THREE.Group[],
  control: OrbitControls,
) {
  for (let floor of floors) {
    for (let obj of floor.children) {
      if (obj.userData.uuid !== undefined && 
              obj.userData.uuid === uuid) {
          const p = obj.position;
          camera.position.set(p.x, p.y - 300, p.z + 200);
          camera.lookAt(p);
          control.update();
          return;
      }
    }
  }
};

/**
 * 视角缩放
 * @param factor 小于 1 缩小，大于 1 放大
 * @param camera 
 * @param control 
 */
export function zoom (
  factor: number,
  camera: THREE.OrthographicCamera | THREE.PerspectiveCamera,
  control: OrbitControls,
) {
  if (camera instanceof THREE.OrthographicCamera){
    camera.zoom *= factor;
    camera.updateProjectionMatrix();
  } else if (camera instanceof THREE.PerspectiveCamera) {
    const target = control.target;

    camera.position.x = (camera.position.x - target.x) / factor + target.x;
    camera.position.y = (camera.position.y - target.y) / factor + target.y;
    camera.position.z = (camera.position.z - target.z) / factor + target.z;
    control.update();
  }
}

/**
 * 初始化 camera 和 control
 * @param divWidth 
 * @param divHeight 
 * @param scene 
 * @param renderer 
 * @returns 字典 { camera_p, controls_p, camera_o, controls_o }
 */
export function init (
  divWidth: number,
  divHeight: number,
  scene: THREE.Scene,
  renderer: THREE.WebGLRenderer | CSS2DRenderer,
) {
    const k = divWidth / divHeight;
    const s = 200;
    const camera_p = new THREE.PerspectiveCamera(45, k, 1, 100000);

    const camera_o = new THREE.OrthographicCamera(
      -s * k,
      s * k,
      s,
      -s,
      1,
      100000
    );
    camera_p.up.set(0, 0, 1);
    camera_p.position.set(0, -900, 600);
    camera_p.lookAt(scene.position);
    camera_o.up.set(0, 0, 1);
    camera_o.position.set(0, 0, 500);
    camera_o.lookAt(scene.position);
    camera_o.zoom = 0.5;
    camera_o.updateProjectionMatrix();
    // camera_o.layers.enableAll();

    // set control
    const controls_p = new OrbitControls(camera_p, renderer.domElement);
    controls_p.minPolarAngle = 0;
    controls_p.maxPolarAngle = Math.PI / 2 + 0.1;
    controls_p.maxDistance = 2800;
    controls_p.minDistance = 100;
    controls_p.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      RIGHT: THREE.MOUSE.LEFT,
      MIDDLE: THREE.MOUSE.MIDDLE,
    };

    const controls_o = new OrbitControls(camera_o, renderer.domElement);
    controls_o.minPolarAngle = 0;
    controls_o.maxPolarAngle = 0;
    controls_o.maxZoom = 4;
    controls_o.minZoom = 0.08;
    controls_o.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      RIGHT: THREE.MOUSE.LEFT,
      MIDDLE: THREE.MOUSE.MIDDLE,
    };

    return {
      camera_p: camera_p,
      controls_p: controls_p,
      camera_o: camera_o,
      controls_o: controls_o,
    }
}

/**
 * 二维/三维视角切换
 * @param is2D 
 * @param camera_o 
 * @param camera_p 
 * @param controls_o 
 * @param controls_p 
 * @returns 返回 !is2D
 */
export function switch2D3D (
  is2D: boolean,
  camera_o: THREE.Camera,
  camera_p: THREE.Camera,
  controls_o: OrbitControls,
  controls_p: OrbitControls,
) {
  if (is2D) {
    // 切换为3D
    controls_p.target.copy(controls_o.target);
    controls_p.update();

    controls_p.enabled = true;
    controls_o.enabled = false;
    let angle = new Vector3(controls_o.getAzimuthalAngle(), 0, 0);
    controls_p.setAzimuthalAngle(angle.x);
  } else {
    // 切换为2D
    controls_o.target.copy(controls_p.target);
    controls_o.update();

    let z = camera_p.position.z;
    camera_o.position.setZ(z);
    controls_p.enabled = false;
    controls_o.enabled = true;
    let angle = new Vector3(controls_p.getAzimuthalAngle(), 0, 0);
    controls_o.setAzimuthalAngle(angle.x);
  }
  return !is2D;
}

export function restoreMultiFloorView (
  camera_p: THREE.Camera,
  controls_p: OrbitControls,
  height: number = 0,
) {
  const v = new Vector3(0,0,0+height);
  camera_p.position.set(0, -1950, 390 + height);
  // controls_p.update();
  camera_p.lookAt(0,0,0+height);
  controls_p.target.copy(v);
  controls_p.update();
}

export function setMultiFloorView (
  camera_p: THREE.Camera,
  controls_p: OrbitControls,
  height: number,
) {
  const v = new Vector3(0,0,0+height);
  camera_p.position.setZ(height)
  camera_p.lookAt(0,0,0+height);
  controls_p.target.copy(v);
  controls_p.update();
}
