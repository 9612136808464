import { 
  Typography, 
  Stack, 
  ImageList, 
  ImageListItem, 
  ImageListItemBar, 
  ButtonGroup,
  Accordion,
  AccordionSummary,
  Tooltip
} from "@mui/material";
import React from "react";
import { ModelAddSideButton } from "./component/ModelAddSideButton";
import { ModelAddAccordion } from "./component/ModelAddAccordion";
import { ModelIdx } from "./component/ModelAddItem";


export interface ModelItem {
  objUrl: string;
  mtlUrl: string;
  jpgUrl?: Array<string>;
  name: string;
  icon: string;
}

export interface ModelItemRaw {
  file: {
    jpgFiles?: Array<{
      name: string;
      url: string;
    }>;
    objUrl: string;
    mtlUrl: {
      name: string;
      url: string;
    }
  };
  name: string;
  icon: string;  // icon url
}

export interface ModelSubCat {
  name: string;   // 二级分类
  model: Array<ModelItemRaw>;
}

export interface ModelPrimaryCat {
  name: string;
  child: Array<ModelSubCat>;
}

interface ModelAddPanelProps {
  getItemData: (data: ModelItem) => void; // 将url和name传递回 RenderContent
  data: Array<ModelPrimaryCat>;
  onRef: React.RefObject<any>;
}

export const ModelAddPanel: React.FC<ModelAddPanelProps> = ({
  getItemData,
  data,
  onRef,
}) => {
  const d: ModelIdx = {priIdx: -1, subIdx: -1, idx: -1};
  const [selItemIdx, setSelItemIdx] = React.useState(d);
  const [dataState, setDataState] = React.useState(data);
  // 选中的一级分类
  const [selPriIdxState, setSelPriIdxState] = React.useState(0);

  const handleClickPriBtn = (i: number) => {
    setSelPriIdxState(i);
  }

  const handleClickItem = (data: ModelItem, idx: ModelIdx) => {
    getItemData(data);
    setSelItemIdx(idx);
  }
  React.useImperativeHandle(onRef, () => {
    return {
      cleanSelState: cleanSelState,
    };
  });


  const cleanSelState = () => {
    const d: ModelIdx = {priIdx: -1, subIdx: -1, idx: -1};
    setSelItemIdx(d);
  }

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        width: "350px",
        height: "550px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          padding: "6px",
        }}
      >
        模型
      </Typography>
      <Stack 
        direction="row" 
        spacing={0}
        sx={{
          backgroundColor: "white",
          height: "550px",
        }}
      >
        <ButtonGroup
          orientation="vertical"
          aria-label="vertical outlined button group"
          sx={{
            // position: "absolute",
            // top: "10px",
            width: "90px",
            // height: "60px",
            overflowY: "auto",
            borderRadius: "0px",
          }}
        >
          {
            dataState.map((data, idx) => {
              return (
                <ModelAddSideButton
                  index={idx}
                  name={data.name}
                  selected={selPriIdxState}
                  onClick={(i: number) => handleClickPriBtn(i)}
                  key={data.name}
                />
              )
            })
          }
        </ButtonGroup>
        <Stack
          sx={{
            // position: "absolute",
            // top: "10px",
            width: "100%",
            // height: "60px",
            overflowY: "auto",
            borderRadius: "0px",
          }}
        >
          {
            dataState[selPriIdxState].child.map((subCatData, subIdx) => (
              <ModelAddAccordion 
                data={subCatData}
                priIdx={selPriIdxState}
                subIdx={subIdx}
                selItemIdx={selItemIdx} 
                getSelItemData={(data: ModelItem, idx: ModelIdx) => handleClickItem(data, idx)}
                key={subCatData.name}
              />
            ))
          }
          {/* <ModelAddAccordion 
            data={dataState[0].child[0]}
            priIdx={0}
            subIdx={0}
            selItemIdx={selItemIdx} 
            getSelItemData={(data: ModelItem, idx: ModelIdx) => handleClickItem(data, idx)}          
          />
          <ModelAddAccordion 
            data={dataState[1].child[0]}
            priIdx={0}
            subIdx={0}
            selItemIdx={selItemIdx} 
            getSelItemData={(data: ModelItem, idx: ModelIdx) => handleClickItem(data, idx)}           
          /> */}
        </Stack>
      </Stack>
      <Stack
        sx={{
          padding: "6px 16px",
          backgroundColor: "white",
          overflow: 'auto',
          maxHeight:'500px',
        }}
        spacing={0}
      >
        
      </Stack>
    </Stack> 
  );
};


/////////// PicAddItem
interface PicAddItemProps {
  name: string, 
  url: string,
  index: number,      // 当前图片的编号
  selected: number,   // 被选中的图片是哪个编号
  onClick: (i: number, url: string, name: string) => void;
}

const PicAddItem: React.FC<PicAddItemProps> = ({
  name,
  url,
  index,
  selected,
  onClick,
}) => {
  const [nameState, setNameState] = React.useState(name);
  const [urlState, setUrlState] = React.useState(url);
  const [indexState, setIndexState] = React.useState(index);


  return (
    <ImageListItem 
      // key={urlState} 
      sx={{
        width:"40px", 
        height:"50px",
        alignItems:"center",
      }}
      >
      <img
        src={urlState}
        alt={nameState}
        loading="lazy"
        height="40px" 
        width="40px"
        style={{
          borderWidth: selected === indexState ? "2px" : "1px",
          borderStyle: "solid",
          borderColor: selected === indexState ? "#ff0000" : "#d0d0d0",
          padding: "2px"
        }}
        onClick={
          (event: any) => {
            onClick(indexState, urlState, nameState);
          }
        }
      />
      <ImageListItemBar
        subtitle={nameState}
        position="below"
        // sx={{
        //   textOverflow: "ellipsis"
        // }}
      />
    </ImageListItem>
  )

}