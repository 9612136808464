import React from "react";
import { selectMode } from "../../slices/uiSlice";
import { useSelector } from "react-redux";
import { Mode } from "../../enums/UI/Mode";
import { setMode } from "../../slices/uiSlice";
import { useAppDispatch } from "../../app/store";

interface SideBarButtonProps {
  bindMode: Mode;
  image: string;
  selectedImage: string;
  onClick: () => void;
}

export const SideBarButton: React.FC<SideBarButtonProps> = ({
  bindMode,
  image,
  selectedImage,
  onClick,
}) => {
  const mode = useSelector(selectMode);
  const dispatch = useAppDispatch();

  return (
    <div style={{ width: "100%" }}>
      <img
        alt="logo"
        src={mode === bindMode ? selectedImage : image}
        style={{
          width: "100%",
          padding: "8px",
          backgroundColor: mode === bindMode ? "white" : "transparent",
          borderRadius: "4px",
        }}
        onClick={() => {
          dispatch(setMode(bindMode));
          onClick();
        }}
      />
    </div>
  );
};
