import { Stack } from "@mui/material";
import React from "react";
import { RoomDrawingMode } from "../../enums/UI/RoomDrawingMode";
import { RoomDrawingModeButton } from "./RoomDrawingModeButton";

interface RoomDrawingModeMenuProps {
  isDrawingRoomType: boolean
}

export const RoomDrawingModeMenu: React.FC<RoomDrawingModeMenuProps> = (props) => {
  const {isDrawingRoomType} = props;
  return (
    <div
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
        width: "40px",
      }}
    >
      <Stack spacing={0}>
        <RoomDrawingModeButton
          image="images/sidebar/room/rectangle-normal.png"
          selectedImage="images/sidebar/room/rectangle-selected.png"
          bindMode={RoomDrawingMode.Rectangle}
          onClick={() => {}}
          disabled = {isDrawingRoomType}
        />
        <RoomDrawingModeButton
          image="images/sidebar/room/irregular-normal.png"
          selectedImage="images/sidebar/room/irregular-selected.png"
          bindMode={RoomDrawingMode.Pollygon}
          onClick={() => {}}
          disabled = {isDrawingRoomType}
        />
        {/* <RoomDrawingModeButton
          image="images/sidebar/room/circle-normal.png"
          selectedImage="images/sidebar/room/circle-selected.png"
          bindMode={RoomDrawingMode.Circle}
          onClick={() => {}}
          disabled = {isDrawingRoomType}
        /> */}
      </Stack>
    </div>
  );
};
