import { Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { selectMode } from "../../../slices/uiSlice";
import { useSelector } from "react-redux";
import { Mode } from "../../../enums/UI/Mode";

interface BrowserOperationHintsProps {
  
}



export const BrowserOperationHints: React.FC<
  BrowserOperationHintsProps
> = ({}) => {
  const mode = useSelector(selectMode);

  const [isShowExpand, setIsShowExpand] = React.useState(false);

  const handleUp = () =>{
    setIsShowExpand(!isShowExpand);
  }

  let text = "1.点击左上角指北针恢复视角\n2、选中墙体/房间/模型/图标/文字/管线后，右击可打开菜单操作\n3、如果房间或墙重叠，请设置高度不一样\n4、Ctrl+左键多选，右键打开菜单操作\n5、左侧工具栏，点击后即可开始绘制，结束绘制状态点击左侧移动/查看按钮或ESC快捷键恢复到查看状态"
  let isExpandDiv;

  if(text.length > 30){
    if(isShowExpand) {
      isExpandDiv = (<button onClick = {handleUp} style = {{right : "35px",position : "absolute",bottom : "10px"}}>折叠</button>)
    } else {
      isExpandDiv = (<button onClick = {handleUp} style = {{right : "35px",position : "absolute",bottom : "10px"}}>展开</button>)
    }
  } else {
      isExpandDiv = '';
  }


  return (
    <Stack
      style={{
        position: "absolute",
        right: "10px",
        bottom: "10px",
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "10px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
        border: "1px solid #ccc",
        maxWidth: "300px",
        display: mode === Mode.Browsing ? "block" : "none",
      }}
      spacing={0.5}
    >
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          marginBottom: "20px"
        }}
      >
        操作提示
      </Typography>
      <Typography variant="body1"
      style = {isShowExpand ?
        {whiteSpace: 'pre-wrap', marginBottom: "20px"}:
        {
          whiteSpace: 'pre-wrap',
          display:'none'
        }
      }
      >{text}
        </Typography>
      {isExpandDiv}
      
    </Stack>
  );
};
