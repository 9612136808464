import React from "react";
import { Box, Stack } from "@mui/material";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Header } from "./components/header/Header";
import { default as RenderContent } from "./components/RenderContent";

interface AppProps {}

export const App: React.FC<AppProps> = ({}) => {
  const [floor, setFloor] = React.useState(1);
  const [isDrawing,setIsDrawing] = React.useState(false);
  const [isDrawingRoomType,setIsDrawingRoomType] = React.useState(false);
  const [isDrawingHoleType,setIsDrawingHoleType] = React.useState(false);
  return (
    <Stack direction="row" sx={{ width: "100vw", height: "100vh" }}>
      <Box
        sx={{
          width: "65px",
          height: "100%",
        }}
      >
        <Sidebar 
        isDrawingRoomType = {isDrawingRoomType}
        isDrawingHoleType = {isDrawingHoleType}
        />
      </Box>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Header 
          floor = {floor}
          setFloor = {setFloor}

          isDrawing = {isDrawing}
          setIsDrawing = {setIsDrawing}
        />
        <RenderContent 
          parentFloor = {floor}
          setFloor = {setFloor}

          isDrawing = {isDrawing}
          setIsDrawing = {setIsDrawing}

          setIsDrawingRoomType = {setIsDrawingRoomType}

          setIsDrawingHoleType = {setIsDrawingHoleType}
        />
      </Stack>
    </Stack>
  );
};
