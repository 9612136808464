import { Typography, Stack, Input, Tooltip } from "@mui/material";
import React from "react";
import { ModelProperty } from "../../RenderContent";

interface ModelPropertyPanelProps {
  modelProps: ModelProperty;
  onScaleChange: (scale: any) => void;
  onRideHeightChange: (rideHeight: number) => void;
}

export const ModelPropertyPanel: React.FC<ModelPropertyPanelProps> = ({
  modelProps,
  onScaleChange,
  onRideHeightChange,
}) => {
  const [nameState, setContentState] = React.useState(modelProps.name);
  const [imgUrlState, setImgUrlState] = React.useState(modelProps.imgUrl);
  const [scaleXState, setScaleXState] = React.useState(modelProps.scaleX);
  const [scaleYState, setScaleYState] = React.useState(modelProps.scaleY);
  const [scaleZState, setScaleZState] = React.useState(modelProps.scaleZ);
  const [rideHeightState, setRideHeightState] = React.useState(modelProps.rideHeight);

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        maxWidth: "250px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.25)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          backgroundColor: "#4767C7",
          color: "white",
          padding: "6px",
        }}
      >
        模型
      </Typography>
      <Stack
        sx={{
          padding: "6px 16px",
          backgroundColor: "white",
        }}
        spacing={0}
      >
        <img
          src={imgUrlState}
          alt={nameState}
          loading="lazy"
          // height="40%" 
          width="40%"
          style={{ alignSelf: 'center' }}
        />
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "100px" }}>
            名称
          </Typography>
          <Input
            value={nameState}
            onChange={(e: any) => {
              setContentState(e.target.value);
              // onContentChange(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
            readOnly={true}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "100px" }}>
            比例x
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0.01", step: "0.1" }}
            value={scaleXState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onScaleChange({x:value, y:scaleYState, z:scaleZState});
              }
              setScaleXState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "100px" }}>
            比例y
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0.01", step: "0.1" }}
            value={scaleYState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onScaleChange({x:scaleXState, y:value, z:scaleZState});
              }
              setScaleYState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography variant="caption" sx={{ width: "100px" }}>
            比例z
          </Typography>
          <Input
            type="number"
            inputProps={{ min: "0.01", step: "0.1" }}
            value={scaleZState}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onScaleChange({x:scaleXState, y:scaleYState, z:value});
              }
              setScaleZState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Tooltip title = "离地高度(m)">
            <Typography variant="caption" sx={{ width: "100px" }} noWrap={true}>
              离地高度(m)
            </Typography>
          </Tooltip>
          <Input
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={Math.round(rideHeightState * 100) / 100}
            onChange={(e: any) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                onRideHeightChange(value);
              }
              setRideHeightState(value);
            }}
            sx={{
              width: "100%",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
