import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { RenderingUIEvent } from "../../../enums/UI/RendererUIEvent";
import { textAlign } from "@mui/system";

interface CopyFloorCmptProps {
  switchCopyFloor: (isCopyFloor: boolean) => void,
  handleCopy: (srcFloorNum: string, copyType: any) => void,
  getNotEmptyFloorIdx: () => number[],
}

export const CopyFloorCmpt : React.FC<CopyFloorCmptProps> = ({
  switchCopyFloor,
  handleCopy,
  getNotEmptyFloorIdx,
}) => {
  const [srcFloorNum, setSrcFloorNum] = React.useState('');
  const [basemapChecked, setBasemapChecked] = React.useState(false);
  const [floorSlabChecked, setFloorSlabChecked] = React.useState(false);
  const [wallChecked, setWallChecked] = React.useState(false);
  const [roomChecked, setRoomChecked] = React.useState(false);
  const [modelChecked, setModelChecked] = React.useState(false);
  const [picChecked, setPicChecked] = React.useState(false);
  const [textChecked, setTextChecked] = React.useState(false);
  const [tubeChecked, setTubeChecked] = React.useState(false);

  const onClickCancel = () => {
    switchCopyFloor(false);
  }

  const onClickCopy = () => {
    var copyType = {
      ground: basemapChecked,
      wall: wallChecked,
      room: roomChecked,
      model: modelChecked,
      picture: picChecked,
      text: textChecked,
      tube: tubeChecked,
      floorSlab: floorSlabChecked,
    };
    handleCopy(srcFloorNum, copyType);
  }

  const setFloorItem = () => {
    var ls = [];
    let validIdx = getNotEmptyFloorIdx();
    for (let i = 0; i < validIdx.length; i++) {
      ls.push(<MenuItem key={i} value={validIdx[i]}>{validIdx[i]}F</MenuItem>);
    }
    return ls;
  }

  const onChangeSrcFloorNum = (e: SelectChangeEvent) => {
    setSrcFloorNum(e.target.value as string);
  }

  const onChangeBasemapChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasemapChecked(e.target.checked);
  }
  const onChangeFloorSlabChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFloorSlabChecked(e.target.checked);
  }
  const onChangeWallChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWallChecked(e.target.checked);
  }
  const onChangeRoomChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomChecked(e.target.checked);
  }
  const onChangeModelChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModelChecked(e.target.checked);
  }
  const onChangePicChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPicChecked(e.target.checked);
  }
  const onChangeTextChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextChecked(e.target.checked);
  }
  const onChangeTubeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTubeChecked(e.target.checked);
  }

  return (
    <Stack
    sx={{
      width: "100%",
      height: "115%",
      position: "absolute",
      margin: "auto",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "white",
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle: "soild",
      borderColor: "gray",
      WebkitBorderRadius: "5px",
      }}
    >
      <Typography
      variant="h5"
      sx={{
        textAlign: "center",
        color: "black",
        marginTop: "7%",
        // marginBottom: "10%",
        fontWeight: "bold",
      }}
      >
        复制楼层
      </Typography>
      <Stack 
      direction="row"
      sx={{
        marginTop: "8%",
      }}
      >
        <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          color: "black",
          margin: "auto",
          marginRight: "3%",
        }}
        >
          选择要复制的楼层名
        </Typography>
        <FormControl
        sx={{
          width: "300px",
          margin: "auto",
          marginLeft: "3%",
        }}
        >
          <Select
            labelId="copy-floor-label"
            id="copy-floor"
            // value={age}
            onChange={onChangeSrcFloorNum}
            displayEmpty
          >
            {
              setFloorItem()
            }
            
          </Select>
        </FormControl>
      </Stack>
      <FormGroup
      sx={{
        marginLeft: "45%",
        marginTop: "3%",
      }}>
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeBasemapChecked}/>} label="复制底图信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeFloorSlabChecked}/>} label="复制楼板" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeWallChecked} />} label="复制墙体信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeRoomChecked} />} label="复制房间信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeModelChecked} />} label="复制模型信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangePicChecked} />} label="复制图片信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeTextChecked} />} label="复制文字信息" />
        <FormControlLabel control={<Checkbox size="small" onChange={onChangeTubeChecked} />} label="复制管线信息（不包含跨楼层管线）" />
      </FormGroup>
      <Stack
      direction="row"
      spacing={3}
      justifyContent="flex-end"
      alignItems="flex-end"
      sx={{
        // marginTop: "8%",
        marginBottom: "3%",
        position: "absolute",
        bottom: "0",
        right: "0",
        verticalAlign: "bottom",
        marginRight: "3%",
      }}
      >
        <Button
        variant="contained"
        onClick={onClickCancel}
        sx={{
          height: "44px",
          width: "102px",
        }}
        >
          取消
        </Button>
        <Button
        variant="contained"
        onClick={onClickCopy}
        sx={{
          height: "44px",
          width: "102px",
        }}
        >
          确认
        </Button>
      </Stack>
    </Stack>
  )
}