import { Button, Divider, Stack } from "@mui/material";
import React from "react";

interface RightClickMenuProps {
  items: RightClickMenuItem[];
  position: RightClickPosition;
}

export interface RightClickMenuItem {
  label: string;
  onClick: () => void;
  disabled: boolean;
}

// Position relative to the canvas(in pixel).
// (0, 0) is the top left corner.
export interface RightClickPosition {
  x: number;
  y: number;
}

export const RightClickMenu: React.FC<RightClickMenuProps> = ({
  items,
  position
}) => {
  return (
    <div
      style={{
        position: "absolute",
        left: `${position.x}px`,
        top: `${position.y}px`,
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
        maxWidth: "150px",
      }}
    >
      <Stack spacing={0}>
        {items.map((item) => (
          <div>
            <Button sx={{ width: "100%" }} onClick={item.onClick} disabled = {item.disabled}>
              {item.label}
            </Button>
            <Divider />
          </div>
        ))}
      </Stack>
    </div>
  );
};
