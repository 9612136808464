import { Button, Stack } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { selectMode } from "../../slices/uiSlice";
import { useSelector } from "react-redux";
import { Mode } from "../../enums/UI/Mode";
import { RenderingUIEvent } from "../../enums/UI/RendererUIEvent";
import { ImportBasemapCmpt } from "./component/ImportBasemapCmpt";
import { CopyFloorCmpt } from "./component/CopyFloorCmpt";

interface ImportBasemapHintProps {
  display: boolean,
  handleCopy: (srcFloorNum: string, copyType: any) => void,
  getNotEmptyFloorIdx: () => number[],
}


export const ImportBasemapHint: React.FC<
ImportBasemapHintProps
> = ({
  display,
  handleCopy,
  getNotEmptyFloorIdx,
}) => {
  const [isCopyFloor, setIsCopyFloor] = React.useState(false);

  const onHandleCopy = (srcFloorNum: string, copyType: any) => {
    handleCopy(srcFloorNum, copyType);
    setIsCopyFloor(false);
  }

  const displayCmpt = () => {
    if (isCopyFloor) {
      return (<CopyFloorCmpt switchCopyFloor={setIsCopyFloor} handleCopy={onHandleCopy} getNotEmptyFloorIdx={getNotEmptyFloorIdx}/>);
    } else {
      return (<ImportBasemapCmpt switchCopyFloor={setIsCopyFloor}/>);
    }
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor:"#f4f6fb",
        display: display ? "block" : "none",
      }}
    >
      <Stack
        sx={{
          width: "70%",
          height: "65%",
          position: "absolute",
          margin: "auto",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        {displayCmpt()}
      </Stack>
    </Stack>
  )
}