import saveAs from "file-saver";
import * as THREE from "three";
import { ObjType } from "../enums/ObjType";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import MESH from "../util/MeshCreator";
import GEO from "../util/GeometryCreator";
import { ObjName } from "../enums/ObjName";

export function save2Local (data: any) {
  const content = JSON.stringify(data);
  localStorage.setItem('scene', content);
  const blob = new Blob([content], {type: "text/plain;charset=utf-8"});
  
  saveAs(blob, "scene.json");
}

export function loadBaseMap (evt: CustomEvent, onLoad: (p: any) => void) {
  const fileElement = evt.detail.target as HTMLInputElement;
    if (
      fileElement === null ||
      fileElement.files === undefined ||
      fileElement.files === null ||
      fileElement.files.length === 0
    ) {
      return;
    }
    var fileData = fileElement.files[0]; //获取到一个FileList对象中的第一个文件(File 对象),是我们上传的文件
    var pettern = /^image/;

    // console.info(fileData.type);

    if (!pettern.test(fileData.type)) {
      alert("图片格式不正确");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(fileData); //异步读取文件内容，结果用data:url的字符串形式表示
    /*当读取操作成功完成时调用*/
    reader.onload = function (e) {
        if (typeof this.result === "string") {
          // onload(this.result);
        }
      };

}

export function floors2Json (floors: Array<THREE.Group>, floor2idx: any) {
  const floorsJson: Array<any> = [];

  for (var i = 0; i < floors.length; i++) {
    const floor = floors[i];

    const textList = [];
    const picList = [];
    const wallList = [];
    const roomList = [];
    // const removeList = [];
    const modelList = [];
    const groundJson = [];
    const tubeList: any[] = [];
    const slabList = [];


    for (var it of floor.children){
      switch (it.userData.type){
        case ObjType.Text:
          // it.element.parentNode.removeChild(it.element);
          textList.push(it.userData);
          break;

        case ObjType.Picture:
          // it.element.parentNode.removeChild(it.element);
          picList.push(it.userData);
          break;

        case ObjType.Wall:
          wallList.push(it.userData);
          break;

        case ObjType.Room:
          roomList.push(it.userData);
          break;

        case ObjType.Model:
          modelList.push(it.userData);
          break;

        case ObjType.Ground:
        case ObjType.BlankGround:
          groundJson.push(it.toJSON());
          break;

        case ObjType.Tube:
          let oneTube = [];
          for(var item of it.children){
            oneTube.push(item.userData);
          }
          if(oneTube.length > 0) oneTube.push(it.userData);
          tubeList.push(oneTube);
          break;

        case ObjType.Floor:
          slabList.push(it.userData);
          break;

        default:
          break;
      }
    }

    const floorNum = findKey(floor2idx, i);
    const floorJson = {
      floor_num: floorNum,
      text: textList,
      pic: picList,
      wall: wallList,
      room: roomList,
      model: modelList,
      tube: tubeList,
      ground: groundJson,
      slab: slabList,
    };
    floorsJson.push(floorJson);
  }

  return floorsJson;
}

export function json2Floors (
  floorsJson: Array<any>, 
  onClickText: (obj: CSS2DObject) => void, 
  onClickPic: (obj: CSS2DObject) => void, 
) {
  console.log(floorsJson);
  // debugger;
  const floors = [];
  const floor2Idx: any = {};
  const allLines: any = [];
  const multiTubeInfo: Map<number, THREE.Group[]> = new Map();
  const maxTubeIDs : any = [];
  var maxTubeID = -1;
  const loader = new THREE.ObjectLoader();

  for (var i = 0; i < floorsJson.length; i++) {
    const floor = new THREE.Group();
    const floorJson = floorsJson[i];
    floor2Idx[floorJson.floor_num] = i;

    var w = 0;
    var h = 0;
    for (var it of floorJson.ground) {
      const ground: any = loader.parse(it);
      MESH.assignUVs(ground.geometry);
      floor.add(ground);
      w = ground.geometry.parameters.width;
      h = ground.geometry.parameters.height;
    }

    if (floorJson.slab !== undefined){
      for (var it of floorJson.slab) {
        let material = new THREE.MeshLambertMaterial({
          color: 0xaaaaaa,
          transparent: true,
          opacity: 1 - it.transparent,
          depthTest: true,
          depthWrite: false,
          side: THREE.DoubleSide,
        });
        let pnts = it.points;
        let newGeo = GEO.createPlaneGeometry(pnts);
        let slab = new THREE.Mesh(newGeo, material);
        slab.userData.points = pnts;
        slab.userData.type = ObjType.Floor;
        slab.userData.transparent = it.transparent;
        console.log("slab", slab);
        floor.add(slab);
      }
    }

    if (floorJson.ground.length < 2) {
      // 添加无底图的地面
      // let geo2 = new THREE.PlaneGeometry(w, h);
      const shape = new THREE.Shape();
      shape.moveTo(-w/2, h/2);
      shape.lineTo(-w/2, -h/2);
      shape.lineTo(w/2, -h/2);
      shape.lineTo(w/2, h/2);
      shape.lineTo(-w/2, h/2);
      let geo2 = new THREE.ShapeGeometry(shape);
      let material2 = new THREE.MeshLambertMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      });
      let mesh2 = new THREE.Mesh(geo2, material2);
      mesh2.name = ObjName.Ground;
      mesh2.userData.type = ObjType.BlankGround;
      mesh2.visible = false;
      floor.add(mesh2);
    }

    for (var it of floorJson.text){
      let textLabel = MESH.createTextLabel(it.content, it.pos, onClickText, it.uuid);
      floor.add(textLabel);
    }

    for (var it of floorJson.pic){
      let picLabel = MESH.createPicLabel(it.imgSrc, it.pos, it.name, onClickPic, it.uuid);
      floor.add(picLabel);
    }

    for (var it of floorJson.wall){
      let mesh = MESH.createWallMesh(it.points, it.width, it.height, it.color, it.uuid, it.rotation, it.rideHeight, it.transparency);
      mesh.userData.name = it.name;
      floor.add(mesh);
    }

    for (var it of floorJson.room){
      let mesh = MESH.createPolRoomMesh(it.points, it.height, it.color, it.uuid, it.rotation, it.rideHeight, it.transparency);
      mesh.userData.name = it.name;
      floor.add(mesh);
    }

    for (var it of floorJson.model) {
      MESH.loadModel(it.data, it.position, floor, it.uuid, it.rotation, it.scale);
    }

    var cnt = 0;
    
    for (var it of floorJson.tube) {
      if(it.length === 0) continue;
      var tubeGroup = new THREE.Group();
      // console.log(it);
      if(it.length > 0){
        var tubeGroupInfo = it[it.length - 1];
        console.log("tubeGroupInfo",tubeGroupInfo);
        it.pop();
        tubeGroup.userData.type = tubeGroupInfo["type"];
        tubeGroup.userData.textureDir = tubeGroupInfo["textureDir"];
        tubeGroup.userData.color = tubeGroupInfo["color"];
        tubeGroup.userData.pipeType = tubeGroupInfo["pipeType"];
        tubeGroup.userData.name = tubeGroupInfo["name"];
        tubeGroup.userData.tubeID = tubeGroupInfo["tubeID"];
        tubeGroup.userData.height = tubeGroupInfo["height"];
        tubeGroup.userData.radius = tubeGroupInfo["radius"];
        tubeGroup.userData.center = tubeGroupInfo["center"];
        tubeGroup.userData.rotation = tubeGroupInfo["rotation"];

        var tubeID = tubeGroup.userData.tubeID
        maxTubeID = Math.max(maxTubeID, tubeID);
        if(tubeID >= 0){
          if(multiTubeInfo.has(tubeID)){
            var tg = multiTubeInfo.get(tubeID);
            tg!.push(tubeGroup);
            multiTubeInfo.set(tubeID, tg!);
          }else{
            multiTubeInfo.set(tubeID, [tubeGroup]);
          }
        }
        
      }
      // console.log("tubeGroup.userData.center",tubeGroup.userData.center);
      
      for(var item of it){
        cnt++;
        if(item.type === ObjType.Tube){
          let mesh = MESH.createOneTubeMesh(
            item.stDir,
            item.edDir,
            item.stPoint,
            item.edPoint,
            item.color,
            item.radius,
            item.pipeType,
            item.textureDir
            );
            mesh.geometry.translate(
              -tubeGroup.userData.center.x,
              -tubeGroup.userData.center.y,
              -tubeGroup.userData.center.z
            );
            tubeGroup.add(mesh);
            console.log(cnt);

          allLines.push([
            new THREE.Vector2(item.stPoint.x, item.stPoint.y), 
            new THREE.Vector2(item.edDir.x, item.edDir.y),
            0,
            0,
            mesh.uuid
          ]);
        }else{
          let mesh = MESH.createOneFilletMesh(
            item.stDir,
            item.edDir,
            item.point,
            item.color,
            item.radius,
            item.pipeType
          );
          mesh.geometry.translate(
            -tubeGroup.userData.center.x,
            -tubeGroup.userData.center.y,
            -tubeGroup.userData.center.z
          );
          tubeGroup.add(mesh);
          console.log(cnt);
        }

      }
      tubeGroup.position.set(
        tubeGroup.userData.center.x,
        tubeGroup.userData.center.y,
        tubeGroup.userData.center.z,
      );
      // if(tubeGroup.userData.rotation !== undefined){
      //   tubeGroup.rotation.fromArray(tubeGroup.userData.rotation);
      // }
        
       
      floor.add(tubeGroup);
    }

    floors.push(floor);
  }

  console.log("floors", floors);

  maxTubeIDs.push(maxTubeID);
  return [floors, floor2Idx, allLines, multiTubeInfo, maxTubeIDs];
  
}

function findKey (data: any, value: any, compare = (a: any, b: any) => a === b) {
  return Object.keys(data).find(k => compare(data[k], value))
}
