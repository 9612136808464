import * as THREE from "three";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import {TubeGeometry,Vector, Vector2, Vector3} from "three";
import { ObjType } from "../enums/ObjType";
import { SwapVertTwoTone } from "@mui/icons-material";
import { toFixedPnts } from "./CalculateFunc";

export enum TubeType{
  Electric,
  Water,
  Gas,
  Other
}

export enum FlowDirection{
  positive,
  neutral,
  negative
}

class CircleCurve<T extends Vector> extends THREE.Curve<T> {
  radius: number;
  direction: Vector3;
  x: number;
  y: number;
  z: number;
  type: string;
  constructor(radius: number, center: Vector3, direction: Vector3,type: string) {
    super();
    //圆角的半径
    this.radius = radius;
    //圆角的朝向
    this.direction = direction;
    console.log("this.direction",this.direction);
    //圆角圆心的位置
    this.x = center.x;
    this.y = center.y;
    this.z = center.z;

    // 水平0 竖直1
    this.type = type;
  }

  getPoint: any = (t: number, optionalTarget = new THREE.Vector3()) => {
    //圆角路径上的点相对于圆心的偏移量
    var dx = 0;
    var dy = 0;
    var dz = 0;
    //t为弧线上的一个参考变量，其取值范围为[0, 1]，由于路径为圆弧且只在坐标系某两个坐标轴组成的平面上
    //因此只需要让其中两个偏移量的平方和为1即可获得正确的偏移量，方向由圆角朝向确定
    if(this.type === "0") {
      console.log("case 0");

      // dir表示角alpha，则应该旋转 theta = alpha - pi/4
      let temp_dx = t;
      let temp_dy = Math.sqrt(1 - t * t);

      let sin_theta = this.direction.y * Math.sqrt(2)/2 - this.direction.x * Math.sqrt(2)/2;
      let cos_theta = this.direction.x * Math.sqrt(2)/2 + this.direction.y * Math.sqrt(2)/2;
      dx = cos_theta * temp_dx - sin_theta * temp_dy;
      dy = sin_theta * temp_dx + cos_theta * temp_dy;
      dz = 0;
    } else if(this.type === "10" || this.type === "01"){
      console.log("case 10");
      let temp_dx = t;
      let temp_dz = Math.sqrt(1 - t * t);
      let temp_dy = 0;
      if(this.type === "01"){
        temp_dx = -temp_dx;
        temp_dz = -temp_dz;
      }
      // 从xoz平面旋转过来
      let sin_alpha = this.direction.y;
      let cos_alpha = this.direction.x;
      dx = cos_alpha * temp_dx - sin_alpha * temp_dy;
      dy = sin_alpha * temp_dx + cos_alpha * temp_dy;
      dz = temp_dz;

      
    }else if(this.type === "11"|| this.type === "02"){
      console.log("case 11");
      let temp_dx = t;
      let temp_dz = - Math.sqrt(1 - t * t);
      let temp_dy = 0;
      if(this.type === "02"){
        temp_dx = -temp_dx;
        temp_dz = -temp_dz;
      }
      // 从xoz平面旋转过来
      let sin_alpha = this.direction.y;
      let cos_alpha = this.direction.x;
      dx = cos_alpha * temp_dx - sin_alpha * temp_dy;
      dy = sin_alpha * temp_dx + cos_alpha * temp_dy;
      dz = temp_dz;
    }

    //计算方式决定了圆角路径上点的值会随半径(在弧线基类中为scale)变化呈现正确的值
    //但是其圆心位置的坐标值会被放大半径值的倍数，因此需要将其缩小至原本的位置
    const tx = dx + this.x / this.radius;
    const ty = dy + this.y / this.radius;
    const tz = dz + this.z / this.radius;
    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.radius);
  };
}

class AnyCircleCurve<T extends Vector> extends THREE.Curve<T> {
  radius: number;
  stDir: Vector3;
  edDir: Vector3;
  center: Vector3;
  constructor(radius: number, center: Vector3, stDir: Vector3, edDir: Vector3) {
    super();
    this.radius = radius;
    this.center = center;

    // this.a = stDir.multiplyScalar(1);
    // this.b = new THREE.Vector3(
    //   -1.0 * edDir.x,
    //   -1.0 * edDir.y,
    //   -1.0 * edDir.z,
    // )

    this.stDir = stDir;
    this.edDir = edDir;
  }

  getPoint: any = (t: number, optionalTarget = new THREE.Vector3()) => { 
    
    var a = new THREE.Vector3(
      this.stDir.x,
      this.stDir.y,
      this.stDir.z,
    )
    var b = new THREE.Vector3(
      this.edDir.x,
      this.edDir.y,
      this.edDir.z,
    )

    var T = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3().crossVectors(a, b),
      -Math.PI / 2
    )
    
    a.applyMatrix4(T);
    b.applyMatrix4(T);

    var c = new THREE.Vector3().lerpVectors(a, b, t);
    c.normalize();
    
    const tx = c.x + this.center.x / this.radius;
    const ty = c.y + this.center.y / this.radius;
    const tz = c.z + this.center.z / this.radius;
    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.radius);
  };
}


export default{
  /**
   * 绘制多边形
   * 
   * @param points 顺时针或逆时针，不需要封闭 
   * @param height 
   * @param rideHeight 
   * @returns 
   */
  createExtrudeGeometry(
    points: Vector3[],
    height: number,
    rideHeight: number = 0
  ){
    let shape = new THREE.Shape();
    let len = points.length;
    // if(len <= 2)
    //   return null;
    shape.moveTo(points[len-1].x, points[len-1].y);
    for(let p of points){
      shape.lineTo(p.x, p.y);
    }
    let geometry = new THREE.ExtrudeGeometry(shape, {
      depth: height, //拉伸长度
      bevelEnabled: false, //无圆角
    });

    return geometry;
  },


  /**
   * 绘制一块墙体
   * 
   * @param stPoint 
   * @param edPoint 
   * @param width 
   * @param height 
   * @param rideHeight 离地高度 TODO
   * @returns 
   */
  createOneWallGeometry(
    stPoint: Vector3,
    edPoint: Vector3,
    width: number,
    height: number,
    rideHeight: number = 0,
  ){
    let p = getOneWallPoint(stPoint, edPoint, width);
    let geometry = this.createExtrudeGeometry(p, height, rideHeight);

    return geometry;
  },


  /**
   * 连续绘制墙体
   * 
   * @param points 
   * @param width 
   * @param height 
   * @param rideHeight
   */
  createWallGeometry(
    points: Vector3[],
    width: number,
    height: number,
    rideHeight: number = 0,
  ){
    points = toFixedPnts(points);
    if(points.length < 3){
      var geomerty =  this.createOneWallGeometry(
        points[0],
        points[1],
        width,
        height,
        rideHeight
      );
    }else{
      var forward: Vector3[] = [];
      var reverse: Vector3[] = [];

      let p1, t1;
        t1 = getOneWallPoint(points[0], points[1], width);
        p1 = t1[0];
        forward.push(points[0]);
        reverse.push(p1);

      for(let i = 0; i < points.length - 2; i++){
        if(i != 0){
          forward.pop();
          reverse.pop();
        }
        const a = points[i];
        const b = points[i + 1];
        const c = points[i + 2];

        let p1, p2, q1, q2, t1, t2;
        t1 = getOneWallPoint(a, b, width);
        p1 = t1[0];
        p2 = t1[3];
        t2 = getOneWallPoint(b, c, width);
        q1 = t2[0];
        q2 = t2[3];

        let r = getIntrPoint(p1, p2, q1, q2); // 交点，拐角处的真实坐标
        
        forward.push(b, c);
        reverse.push(r, q2);
      }
      var realPoints = forward.concat(reverse.reverse());
      
      var geomerty = this.createExtrudeGeometry(
        realPoints,
        height,
        rideHeight
        );
        // console.log("points", realPoints);
    }

    return geomerty;
  },

  createLineGeometry(
    points: Vector3[],
  ) {
    var geomerty = new LineGeometry();
    var p = [];
    for(var point of points){
      p.push(point.x, point.y, point.z);
    }
    geomerty.setPositions(p);
    return geomerty;

    // return new THREE.BufferGeometry().setFromPoints(points);
  },

  createTubeGeometryWithoutSpare(
    dirSt: Vector3,
    dirEd: Vector3,
    stPoint: Vector3,
    edPoint: Vector3,
    radius: number
  ){
    //分别为管道平行方向和切面的段数
    var segments = 64;
    var radiusSegments = Number((radius*8).toFixed());

    //判断新增管道方向是否与上一段管道相同，若相同则无需预留圆角空间
    // var notStraight = Math.abs(
    //   (dirEd.x-dirSt.x)|
    //   (dirEd.y-dirSt.y)|
    //   (dirEd.z-dirSt.z)
    //   );
    var notStraight = 1;
    if(Math.abs(dirSt.x - dirEd.x) < Number.EPSILON 
    && Math.abs(dirSt.y - dirEd.y) < Number.EPSILON  
    && Math.abs(dirSt.z - dirEd.z) < Number.EPSILON ) notStraight = 0;
    // var pSt = new THREE.Vector3;
    // if(dirSt === dirEd){
    //   pSt = stPoint;
    // }else{

    // }
    
    //实际绘制的管道起点位置
    var times = 2;
    if(Math.abs(dirSt.x) < Number.EPSILON 
    && Math.abs(dirSt.y) < Number.EPSILON  
    && Math.abs(dirSt.z) < Number.EPSILON ){
      var pSt = new THREE.Vector3(
        stPoint.x ,
        stPoint.y ,
        stPoint.z 
      );
    }else{
      var pSt = new THREE.Vector3(
        stPoint.x + times* radius * dirEd.x * (2*notStraight-1),
        stPoint.y + times* radius * dirEd.y * (2*notStraight-1),
        stPoint.z + times* radius * dirEd.z * (2*notStraight-1)
      );
    }
    

    //实际绘制的管道终点位置
    var pEd = new THREE.Vector3(
      edPoint.x ,
      edPoint.y ,
      edPoint.z 
    );

    //管道的路径
    var linePath = new THREE.LineCurve3(pSt, pEd);
    var tubeGeometry = new THREE.TubeGeometry(
      linePath,
      segments,
      radius,
      radiusSegments
    );
    return tubeGeometry;
  },

  createTubeGeometry(
    dirSt: Vector3,
    dirEd: Vector3,
    stPoint: Vector3,
    edPoint: Vector3,
    radius: number
  ){
    //分别为管道平行方向和切面的段数
    var segments = 64;
    var radiusSegments = Number((radius*8).toFixed());

    //判断新增管道方向是否与上一段管道相同，若相同则无需预留圆角空间
    // var notStraight = Math.abs(
    //   (dirEd.x-dirSt.x)|
    //   (dirEd.y-dirSt.y)|
    //   (dirEd.z-dirSt.z)
    //   );
    var notStraight = 1;
    if(Math.abs(dirSt.x - dirEd.x) < Number.EPSILON 
    && Math.abs(dirSt.y - dirEd.y) < Number.EPSILON  
    && Math.abs(dirSt.z - dirEd.z) < Number.EPSILON ) notStraight = 0;
    // var pSt = new THREE.Vector3;
    // if(dirSt === dirEd){
    //   pSt = stPoint;
    // }else{

    // }
    
    //实际绘制的管道起点位置
    var times = 2;
    if(Math.abs(dirSt.x) < Number.EPSILON 
    && Math.abs(dirSt.y) < Number.EPSILON  
    && Math.abs(dirSt.z) < Number.EPSILON ){
      var pSt = new THREE.Vector3(
        stPoint.x ,
        stPoint.y ,
        stPoint.z 
      );
    }else{
      var pSt = new THREE.Vector3(
        stPoint.x + times* radius * dirEd.x * (2*notStraight-1),
        stPoint.y + times* radius * dirEd.y * (2*notStraight-1),
        stPoint.z + times* radius * dirEd.z * (2*notStraight-1)
      );
    }
    

    //实际绘制的管道终点位置
    var pEd = new THREE.Vector3(
      edPoint.x - times* radius * dirEd.x,
      edPoint.y - times* radius * dirEd.y,
      edPoint.z - times* radius * dirEd.z
    );

    //管道的路径
    var linePath = new THREE.LineCurve3(pSt, pEd);
    var tubeGeometry = new THREE.TubeGeometry(
      linePath,
      segments,
      radius,
      radiusSegments
    );
    return tubeGeometry;
  },

  createFilletGeometry(
    dirSt: Vector3,
    dirEd: Vector3,
    point: Vector3,
    radius: number,
  ){
    //圆角切面段数
    var radiusSegments = Number((radius*8).toFixed());

    // console.log(dirSt);
    // dirSt.normalize();
    
    // dirEd.normalize();
    //圆角的朝向，通过其相连的两段管道方向相减而得
    var dir = new THREE.Vector3(
      dirSt.x - dirEd.x,
      dirSt.y - dirEd.y,
      dirSt.z - dirEd.z
    ); 
    dir.normalize();

    //圆角路径的圆心，在其朝向方向上各偏移一个半径的距离即可
    var center = new THREE.Vector3(
      point.x - dir.x * radius * Math.sqrt(2),
      point.y - dir.y * radius * Math.sqrt(2),
      point.z - dir.z * radius * Math.sqrt(2)
    );
    
    //参考CircleCurve注释
    var filletPath;
    // if(dirEd.z === 0){
    //   if(dirSt.z === 0){
    //     filletPath = new CircleCurve(
    //       radius,
    //       center,
    //       dir,
    //       "0"
    //     )
    //   }else if(dirSt.z < 0){
    //     filletPath = new CircleCurve(
    //       radius,
    //       center,
    //       dirEd,
    //       "01"
    //     )
    //   }else{
    //     filletPath = new CircleCurve(
    //       radius,
    //       center,
    //       dirEd,
    //       "02"
    //     )
    //   }
    // }else if(dirEd.z < 0 ){
    //   // 向下
    //   filletPath = new CircleCurve(
    //     radius,
    //     center,
    //     dirSt,
    //     "10"
    //   )
    // }else{
    //   // 向上
    //   filletPath = new CircleCurve(
    //     radius,
    //     center,
    //     dirSt,
    //     "11"
    //   )
    // }

    // filletPath = new AnyCircleCurve(
    //   radius,
    //   center,
    //   dirSt,
    //   dirEd
    // );
    
    var times = 2;
    var points = [
      new Vector3(point.x - dirSt.x * radius * times, 
                  point.y - dirSt.y * radius * times,
                  point.z - dirSt.z * radius * times),
      new Vector3(point.x, point.y, point.z),
      new Vector3(point.x + dirEd.x * radius * times, 
        point.y + dirEd.y * radius * times,
        point.z + dirEd.z * radius * times)
    ]
    filletPath = new THREE.QuadraticBezierCurve3(points[0],points[1],points[2]);
    var filletGeometry = new THREE.TubeGeometry(
      filletPath as any,
      6,
      radius,
      radiusSegments
    );
    return filletGeometry;
  },

  createTubeGeometryWithList(
    points: Vector3[],
    radius: number
  ){
    var radiusSegments = Number((radius*8).toFixed());
    console.log(points);
    var curve = new THREE.CatmullRomCurve3(points);
    var tubeGeometry = new THREE.TubeGeometry(
      curve as any,
      6,
      radius,
      radiusSegments
    );
    return tubeGeometry;
  },

  createPlaneGeometry(points: Vector3[]) {
    const shape = new THREE.Shape();
    shape.moveTo(points[0].x, points[0].y);
    for (let p of points){
      shape.lineTo(p.x, p.y);
    }
    shape.lineTo(points[0].x, points[0].y);
    return new THREE.ShapeGeometry(shape);
  }
}


/**
 * 输入两直线上的两点，获取直线交点
 * 
 * @param a 第一条直线的第一个点
 * @param b 第一条直线的第二个点
 * @param c 第二条直线的第一个点
 * @param d 第二条直线的第二个点
 * @returns 
 */
function getIntrPoint(
  a: Vector3,
  b: Vector3,
  c: Vector3,
  d: Vector3
){
  // 如果分母为0 则平行或共线, 不相交  
  var denominator = (b.y - a.y)*(d.x - c.x) - (a.x - b.x)*(c.y - d.y);  
  if (denominator == 0) {  
    return b;
  }  

  // 线段所在直线的交点坐标 (x , y)      
  var x = ( (b.x - a.x) * (d.x - c.x) * (c.y - a.y)   
              + (b.y - a.y) * (d.x - c.x) * a.x   
              - (d.y - c.y) * (b.x - a.x) * c.x ) / denominator ;  
  var y = -( (b.y - a.y) * (d.y - c.y) * (c.x - a.x)   
              + (b.x - a.x) * (d.y - c.y) * a.y   
              - (d.x - c.x) * (b.y - a.y) * c.y ) / denominator; 

  var p = new Vector3(x, y);

  return p;
}

/**
 * 两点确定一块墙的坐标
 * 
 * @param stPoint 
 * @param edPoint 
 * @param width 
 * @returns 顺时针或逆时针排列的点，先返回st端的两点，在返回ed端的两点
 */
function getOneWallPoint(
  stPoint: Vector3,
  edPoint: Vector3,
  width: number,
){
  let dis = Math.sqrt(
    Math.pow(stPoint.x - edPoint.x, 2) + Math.pow(stPoint.y - edPoint.y, 2)
  );
  let sine = (stPoint.y - edPoint.y) / (dis + 1e-10);
  let cosi = (edPoint.x - stPoint.x) / (dis + 1e-10);

  // 按顺时针或逆时针排序
  let p = new Array();
  p.push(new Vector3(
    stPoint.x + (sine * width),
    stPoint.y + (cosi * width)
  ));
  p.push(stPoint);
  p.push(edPoint);
  p.push(new Vector3(
    edPoint.x + (sine * width),
    edPoint.y + (cosi * width)
  ));
  
  // // 按顺时针或逆时针排序
  // let p = new Array();
  // p.push(new Vector2(
  //   stPoint.x - (sine * width) / 2,
  //   stPoint.y - (cosi * width) / 2
  // ));
  // p.push(new Vector2(
  //   stPoint.x + (sine * width) / 2,
  //   stPoint.y + (cosi * width) / 2
  // ))
  // p.push(new Vector2(
  //   edPoint.x + (sine * width) / 2,
  //   edPoint.y + (cosi * width) / 2
  // ));
  // p.push(new Vector2(
  //   edPoint.x - (sine * width) / 2,
  //   edPoint.y - (cosi * width) / 2
  // ));
  
  return p;
}
