import { 
  Typography, 
  ImageList, 
  AccordionDetails,
  AccordionSummary,
  Accordion
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { ModelAddItem, ModelIdx } from "./ModelAddItem";
import { ModelSubCat, ModelItemRaw, ModelItem } from "../ModelAddPanel";

interface ModelAddAccordionProps {
  // onClickItem: (url: string, name: string) => void; // 将url和name传递回 RenderContent
  data: ModelSubCat;
  priIdx: number;
  subIdx: number;
  // selPriIdx: number;
  selItemIdx: ModelIdx;
  getSelItemData: (data: ModelItem, idx: ModelIdx) => void;
}

export const ModelAddAccordion: React.FC<ModelAddAccordionProps> = ({
  data,
  priIdx,
  subIdx,
  // selPriIdx,
  selItemIdx,
  getSelItemData,
}) => {
  const [dataState, setDataState] = React.useState(data);
  const [subIdxState, setSubIdxState] = React.useState(subIdx);
  const [priIdxState, setPriIdxState] = React.useState(priIdx);
  // const [selItemData, setSelItemData] = 
  
  const handleClick = (i: ModelIdx, data: ModelItem) => {
    getSelItemData(data, i);
  }

  return (
    <Accordion
          sx={{
            width: "100%",
            padding: "0px",
            boxShadow: 'none',
            // height: "40px"
          }}
          TransitionProps={{ unmountOnExit: true }}
          disableGutters={true}
          elevation={0}
          expanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: "6px",
        }}
        // aria-controls="panel2a-content"
        // id="panel2a-header"
      >
        <Typography variant="caption" > {dataState.name} </Typography>
      </AccordionSummary>
      <AccordionDetails
      >
        <ImageList cols={3} >
          {
            dataState.model.map((data, idx) => {
              return (
                <ModelAddItem
                  data={data}
                  index={{priIdx: priIdxState, subIdx: subIdxState, idx: idx}}
                  selected={selItemIdx}
                  onClick={(i: ModelIdx, data: ModelItem) => handleClick(i, data)}
                  key={data.name}
                />
              )
            })
          }
        </ImageList>
      </AccordionDetails>
    </Accordion>
  )
}