import { PipePattern, PipeType } from "../enums/PipeProp";

export {defaultWallProp, defaultRoomProp, defaultHoleProp, defaultTextProp, defaultTubeProp};

var defaultWallProp = {
  name: "新建墙体",
  color: "#888888",
  width: 0.3,
  height: 3,
  rideHeight: 0,
  transparency: 0,
};

var defaultRoomProp = {
  name: "新建房间",
  color: "#888888",
  height: 3,
  rideHeight: 0,
  transparency: 0,
  area: 0,
};

var defaultHoleProp = {
  name: "新建楼板打洞",
  color: "#000000",
  height: 0.2,
  rideHeight: 0,
  transparency: 0,
  area: 0,
};

var defaultTextProp = {
  content: "房间",
  rideHeight: 3.5,
}

var defaultTubeProp = {
  name: "新建管道",
  color: "#0ac6af",
  radius: 0.15,
  rideHeight: 3,
  type: PipeType.others,
  pattern: PipePattern.default,
}