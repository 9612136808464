import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store";
import { RoomDrawingModeButton } from "../components/sidebar/RoomDrawingModeButton";
import { HoleDrawingModeButton } from "../components/sidebar/HoleDrawingModeButton";
import { Mode } from "../enums/UI/Mode";
import { RoomDrawingMode } from "../enums/UI/RoomDrawingMode";
import { HoleDrawingMode } from "../enums/UI/HoleDrawingMode";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    mode: Mode.Browsing,
    roomDrawingMode: RoomDrawingMode.Rectangle,
    holeDrawingMode: HoleDrawingMode.Rectangle,
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setRoomDrawingMode: (state, action) => {
      state.roomDrawingMode = action.payload;
    },
    setHoleDrawingMode: (state, action) => {
      state.holeDrawingMode = action.payload;
    },
  },
});

export default uiSlice.reducer;

export const { setMode } = uiSlice.actions;
export const selectMode = (state: RootState) => state.ui.mode;

export const { setRoomDrawingMode } = uiSlice.actions;
export const selectRoomDrawingMode = (state: RootState) =>
  state.ui.roomDrawingMode;

export const { setHoleDrawingMode } = uiSlice.actions;
export const selectHoleDrawingMode = (state: RootState) =>
  state.ui.holeDrawingMode;
