import {
  Typography,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { selectMode } from "../../slices/uiSlice";
import React, { useState } from "react";
import { Mode } from "../../enums/UI/Mode";
import { useSelector } from "react-redux";
import { ObjType } from "../../enums/ObjType";

interface TypeFilterProps {
  onChange: (type: ObjType | string, visible: boolean) => void;
  displayInBrowse: boolean;
  displayType: any;
  onRef: React.RefObject<any>;
}

export const TypeFilter: React.FC<TypeFilterProps> = ({
  onChange,
  displayInBrowse,
  displayType,
  onRef,
}) => {
  const mode = useSelector(selectMode);
  var s: any = {wall: true, room: true, model: true, picture: true, text: true, tube: true};
  const [checkState, setCheckState] = useState(s);

  // const labels = ["墙体", "房间", "模型", "图片", "文字", "管线", "底图"];
  const labels = ["墙体", "房间", "模型", "图片", "文字", "管线"];
  const names = [
    ObjType.Wall,
    ObjType.Room,
    ObjType.Model,
    ObjType.Picture,
    ObjType.Text,
    ObjType.Tube,
    ObjType.Ground,
  ];

  React.useImperativeHandle(onRef, () => {
    return {
      setCheckState: (type: ObjType, check: boolean) => {
        var c: any = {};
        Object.assign(c, checkState);
        c[type] = check;
        setCheckState(c);
      },
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const name = event.target.name;
    var c: any = {};
    Object.assign(c, checkState);
    c[name] = checked;
    setCheckState(c);
    onChange(name, checked);
  };


  const createItems = () => {
    const items = [];
    const bools = [];

    bools.push(displayType.wall);
    bools.push(displayType.room);
    bools.push(displayType.model);
    bools.push(displayType.picture);
    bools.push(displayType.text);
    bools.push(displayType.tube);
    bools.push(displayType.ground);

    for (let i = 0; i < labels.length; i++) {
      if (bools[i]) {
        items.push(
          <FormControlLabel
            control={
              <Checkbox
                checked={checkState[names[i]]}
                size="small"
                onChange={handleChange}
                sx={{
                  color: "#a0a0a0",
                }}
              />
            }
            label={labels[i]}
            name={names[i]}
            key={names[i]}
            style={{
              color: "white",
              textShadow:
                "-1px 0 rgba(0, 0, 0, 1), 0 1px rgba(0, 0, 0, 1), 1px 0 rgba(0, 0, 0, 1), 0 -1px rgba(0, 0, 0, 1)",
            }}
          />
        );
      }
    }

    return items;
  };

  return (
    <Stack
      style={{
        position: "absolute",
        top: "10px",
        right: "0px",
        display: mode === Mode.Browsing && displayInBrowse ? "block" : "none",
      }}
    >
      <FormGroup>{createItems()}</FormGroup>
    </Stack>
  );
};
