import { Button, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { RenderingUIEvent } from "../../enums/UI/RendererUIEvent";
import { HeaderFloorSelect } from "./HeaderFloorSelect";
import { HeaderIconLabelButton } from "./HeaderIconLabelButton";
interface HeaderProps {
  floor: number,
  setFloor: (floor: number) => void,

  isDrawing: boolean,
  setIsDrawing: (isDrawing: boolean)=>void
}

export const Header: React.FC<HeaderProps> = (props) => {
  const dispatchRenderingUIEvent = (
    event: RenderingUIEvent,
    payload: any = null
  ) => {
    document.dispatchEvent(new CustomEvent(event, { detail: payload }));
  };

  const {floor, setFloor, isDrawing, setIsDrawing} = props;
  // const [floor, setFloor] = React.useState(1);
  // const changeFloor = (floor: number) =>{
  //   setFloor(floor);
  // }

  var uploadBasemapBtnRef = React.createRef<HTMLInputElement>();
  var loadProjBtnRef = React.createRef<HTMLInputElement>();


  return (
    <Box
      sx={{
        backgroundColor: "rgba(229, 235, 249, 1)",
        width: "100%",
        height: "60px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
        sx={{ height: "100%", margin: "0px 10px" }}
      >
        {/* <HeaderIconLabelButton
          icon="images/header/u47.png"
          label="导入参考图"
          onClick={() => {}}
        /> */}
        {/* <HeaderIconLabelButton
          icon="images/header/u45.png"
          label="比例尺"
          onClick={() => {dispatchRenderingUIEvent(RenderingUIEvent.ChangeUnitLength)}}
        /> */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.Switch2D3D)}
          disabled = {isDrawing}
        >
          2D/3D 切换
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.Save)}
          disabled = {isDrawing}
        >
          保存
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.ZoomIn)}
        >
          +
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.ZoomOut)}
        >
          -
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            dispatchRenderingUIEvent(RenderingUIEvent.ChangeUnitLength)
          }
          disabled = {isDrawing}
        >
          设置比例尺
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.SwitchBasemapMode)}
        >
          隐藏底图
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.SetFloorHeight)}
          disabled = {isDrawing}
        >
          设置层高
        </Button>
        <input
          type="file"
          ref={uploadBasemapBtnRef}
          accept=".jpg,.jpeg,.png,"
          hidden
          onClick={(event)=> { (event.target as HTMLInputElement).value = "" }} 
          onChange={(e) =>
            dispatchRenderingUIEvent(RenderingUIEvent.UploadBaseMap, e)
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {uploadBasemapBtnRef.current!.click()}}
        >
          导入底图
        </Button>
        <input
          type="file"
          ref={loadProjBtnRef}
          hidden
          accept=".json"
          onChange={(e) =>
            dispatchRenderingUIEvent(RenderingUIEvent.LoadProj, e)
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {loadProjBtnRef.current!.click()}}
        >
          加载项目
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.SwitchFloorMode)}
          disabled = {isDrawing}
        >
          单/多层切换
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.EditFloorSlab)}
          disabled = {isDrawing}
        >
          楼板编辑
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatchRenderingUIEvent(RenderingUIEvent.CopyFromFloor)}
          disabled = {isDrawing}
        >
          从楼层复制
        </Button>
        <HeaderFloorSelect
          onChange={(floor) => 
            dispatchRenderingUIEvent(RenderingUIEvent.SwitchFloor, floor)
          }
          floor = {floor}
          setFloor = {setFloor}

          isDrawing = {isDrawing}
          setIsDrawing = {setIsDrawing}
        />
      </Stack>
    </Box>
  );
};
