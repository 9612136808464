export class UnitLength {
  lenthOfEachPixel = 0;
  /*
      用于封装点击判断的全局变量，避免全局变量滥用
      */
  ClickTimes: any = 0; // 判断是第几次点击
  firstClickpos: any; //第一次点击点位置
  // intersects: any;
  changeUnitLenth = (pos1: any, pos2: any, realLen = 1) => {
    //设置时默认为垂直或水平
    let detX = Math.abs(pos1.x - pos2.x) ** 2;
    let detY = Math.abs(pos1.y - pos2.y) ** 2;
    var d = Math.sqrt(detX + detY);
    // if (detX > detY) {
    //   if (detX !== 0) this.lenthOfEachPixel = 1 / detX;
    // } else {
    //   if (detY !== 0) this.lenthOfEachPixel = 1 / detY;
    // }
    this.lenthOfEachPixel = realLen / d;
    // console.log(this.lenthOfEachPixel);
  };
}