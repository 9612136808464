// mesh name 的一些固定用法
export enum ObjName {
  Drawing = "obj_drawing",
  // Drawing2 = "obj_drawing_2",
  DrawingWall = "drawing_wall",
  DrawingRoom = "drawing_room",
  DrawingHole = "drawing_hole",
  DrawingTube = "drawing_tube",
  DrawingPic = "drawing_pic",
  GhostCursor = "ghost_cursor",
  Moving = "obj_moving",
  Ground = "ground_plane",
  EditShape = "edit_shape",
  Picked = "picked_obj",
  EditShapePnt = "edit_shape_points",
  BoxHelper = "box_helper",
  LineToCircleSelectLine = "select_green_line",
  BlackPlane = "black_plane",

}
