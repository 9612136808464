import { 
  ImageListItem, 
  ImageListItemBar,
  Tooltip
} from "@mui/material";
import React from "react";

interface PicAddItemProps {
  name: string, 
  url: string,
  index: number,      // 当前图片的编号
  selected: number,   // 被选中的图片是哪个编号
  onClick: (i: number, url: string, name: string) => void;
}

export const PicAddItem: React.FC<PicAddItemProps> = ({
  name,
  url,
  index,
  selected,
  onClick,
}) => {
  const [nameState, setNameState] = React.useState(name);
  const [urlState, setUrlState] = React.useState(url);
  const [indexState, setIndexState] = React.useState(index);


  return (
    <ImageListItem 
      // key={urlState} 
      sx={{
        width:"40px", 
        height:"50px",
        alignItems:"center",
      }}
      >
      <img
        src={urlState}
        alt={nameState}
        loading="lazy"
        height="40px" 
        width="40px"
        style={{
          borderWidth: selected === indexState ? "2px" : "1px",
          borderStyle: "solid",
          borderColor: selected === indexState ? "#ff0000" : "#d0d0d0",
          padding: "2px"
        }}
        onClick={
          (event: any) => {
            onClick(indexState, urlState, nameState);
          }
        }
      />
      <Tooltip title = {nameState}>
      <ImageListItemBar
        subtitle={nameState}
        position="below"
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          marginLeft: "auto",
          marginRight: "auto",
          width: "inherit",
          textAlign: "center"
        }}
      />
      </Tooltip>
    </ImageListItem>
  )

}