export enum Mode {
  Browsing = "Browsing",
  WallDrawing = "WallDrawing",
  RoomDrawing = "RoomDrawing",
  HoleDrawing = "HoleDrawing",
  ModelAdding = "ModelAdding",
  PicAdding = "PhotoAdding",
  TextAdding = "TextAdding",
  PipeDrawing = "PipeDrawing",
}
